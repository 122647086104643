import { Check } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { ConfirmationState } from "@phoenix/common/proto/types";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFilters } from "../../state";
import FilterButton from "./FilterButton";

const choices: {
  value: ConfirmationState;
  label: (t: TFunction) => string;
}[] = [
  {
    value: ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED,
    label: (t) => t("state.unconfirmed"),
  },
  {
    value: ConfirmationState.CONFIRMATION_STATE_CONFIRMED,
    label: (t) => t("state.confirmed"),
  },
  {
    value: ConfirmationState.CONFIRMATION_STATE_WATCH,
    label: (t) => t("state.watch"),
  },
  {
    value: ConfirmationState.CONFIRMATION_STATE_REJECTED,
    label: (t) => t("state.rejected"),
  },
];

export default function StateButton() {
  const { t } = useTranslation("reportsList");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = !!anchorEl;
  const [filter, setFilter] = useFilters();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleToggle = (value: ConfirmationState) => {
    if (filter.states.includes(value)) {
      setFilter((prev) => ({
        ...prev,
        states: [...prev.states.filter((state) => state !== value)],
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        states: [...prev.states, value],
      }));
    }
  };

  return (
    <>
      <FilterButton onClick={handleClick}>{t("stateButtonLabel")}</FilterButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {choices.map((choice, idx) => (
          <MenuItem onClick={() => handleToggle(choice.value)} key={idx}>
            {filter.states.includes(choice.value) ? (
              <>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                {choice.label(t)}
              </>
            ) : (
              <ListItemText inset>{choice.label(t)}</ListItemText>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
