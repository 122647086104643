/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";

export const protobufPackage = "phoenix.auth.v0";

export interface AuthReq {
  email: string;
  password: string;
}

export interface AuthRes {
  userId: number;
  allowedScopes: string[];
}

export interface CompletePasswordResetReq {
  passwordResetToken: string;
  newPassword: string;
}

export interface ListMyScopesRes {
  allowedScopes: string[];
}

function createBaseAuthReq(): AuthReq {
  return { email: "", password: "" };
}

export const AuthReq = {
  encode(message: AuthReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthReq {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: AuthReq): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthReq>, I>>(base?: I): AuthReq {
    return AuthReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthReq>, I>>(object: I): AuthReq {
    const message = createBaseAuthReq();
    message.email = object.email ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseAuthRes(): AuthRes {
  return { userId: 0, allowedScopes: [] };
}

export const AuthRes = {
  encode(message: AuthRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int64(message.userId);
    }
    for (const v of message.allowedScopes) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.allowedScopes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AuthRes {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      allowedScopes: globalThis.Array.isArray(object?.allowedScopes)
        ? object.allowedScopes.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: AuthRes): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.allowedScopes?.length) {
      obj.allowedScopes = message.allowedScopes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AuthRes>, I>>(base?: I): AuthRes {
    return AuthRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthRes>, I>>(object: I): AuthRes {
    const message = createBaseAuthRes();
    message.userId = object.userId ?? 0;
    message.allowedScopes = object.allowedScopes?.map((e) => e) || [];
    return message;
  },
};

function createBaseCompletePasswordResetReq(): CompletePasswordResetReq {
  return { passwordResetToken: "", newPassword: "" };
}

export const CompletePasswordResetReq = {
  encode(message: CompletePasswordResetReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.passwordResetToken !== "") {
      writer.uint32(10).string(message.passwordResetToken);
    }
    if (message.newPassword !== "") {
      writer.uint32(18).string(message.newPassword);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompletePasswordResetReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompletePasswordResetReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.passwordResetToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompletePasswordResetReq {
    return {
      passwordResetToken: isSet(object.passwordResetToken) ? globalThis.String(object.passwordResetToken) : "",
      newPassword: isSet(object.newPassword) ? globalThis.String(object.newPassword) : "",
    };
  },

  toJSON(message: CompletePasswordResetReq): unknown {
    const obj: any = {};
    if (message.passwordResetToken !== "") {
      obj.passwordResetToken = message.passwordResetToken;
    }
    if (message.newPassword !== "") {
      obj.newPassword = message.newPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompletePasswordResetReq>, I>>(base?: I): CompletePasswordResetReq {
    return CompletePasswordResetReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompletePasswordResetReq>, I>>(object: I): CompletePasswordResetReq {
    const message = createBaseCompletePasswordResetReq();
    message.passwordResetToken = object.passwordResetToken ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

function createBaseListMyScopesRes(): ListMyScopesRes {
  return { allowedScopes: [] };
}

export const ListMyScopesRes = {
  encode(message: ListMyScopesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.allowedScopes) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMyScopesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMyScopesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.allowedScopes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMyScopesRes {
    return {
      allowedScopes: globalThis.Array.isArray(object?.allowedScopes)
        ? object.allowedScopes.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ListMyScopesRes): unknown {
    const obj: any = {};
    if (message.allowedScopes?.length) {
      obj.allowedScopes = message.allowedScopes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMyScopesRes>, I>>(base?: I): ListMyScopesRes {
    return ListMyScopesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMyScopesRes>, I>>(object: I): ListMyScopesRes {
    const message = createBaseListMyScopesRes();
    message.allowedScopes = object.allowedScopes?.map((e) => e) || [];
    return message;
  },
};

export interface Auth {
  /** Log a user in, creating a session */
  authenticate(request: DeepPartial<AuthReq>, metadata?: grpc.Metadata): Promise<AuthRes>;
  /** Log a user out, removing their session */
  deauthenticate(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Change the current user's password */
  completePasswordReset(request: DeepPartial<CompletePasswordResetReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Return a list of scopes for the current user */
  listMyScopes(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListMyScopesRes>;
}

export class AuthClientImpl implements Auth {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.authenticate = this.authenticate.bind(this);
    this.deauthenticate = this.deauthenticate.bind(this);
    this.completePasswordReset = this.completePasswordReset.bind(this);
    this.listMyScopes = this.listMyScopes.bind(this);
  }

  authenticate(request: DeepPartial<AuthReq>, metadata?: grpc.Metadata): Promise<AuthRes> {
    return this.rpc.unary(AuthAuthenticateDesc, AuthReq.fromPartial(request), metadata);
  }

  deauthenticate(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AuthDeauthenticateDesc, Empty.fromPartial(request), metadata);
  }

  completePasswordReset(request: DeepPartial<CompletePasswordResetReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AuthCompletePasswordResetDesc, CompletePasswordResetReq.fromPartial(request), metadata);
  }

  listMyScopes(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListMyScopesRes> {
    return this.rpc.unary(AuthListMyScopesDesc, Empty.fromPartial(request), metadata);
  }
}

export const AuthDesc = { serviceName: "phoenix.auth.v0.Auth" };

export const AuthAuthenticateDesc: UnaryMethodDefinitionish = {
  methodName: "Authenticate",
  service: AuthDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AuthDeauthenticateDesc: UnaryMethodDefinitionish = {
  methodName: "Deauthenticate",
  service: AuthDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AuthCompletePasswordResetDesc: UnaryMethodDefinitionish = {
  methodName: "CompletePasswordReset",
  service: AuthDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CompletePasswordResetReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AuthListMyScopesDesc: UnaryMethodDefinitionish = {
  methodName: "ListMyScopes",
  service: AuthDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMyScopesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
