export function formatTime(d: Date) {
  return d.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
}

const timeFormatter = new Intl.DateTimeFormat(undefined, {
  minute: "2-digit",
  hour: "2-digit",
  hour12: false,
});
const monthFormatter = new Intl.DateTimeFormat(undefined, {
  minute: "2-digit",
  hour: "2-digit",
  hour12: false,
  day: "numeric",
  month: "short",
});
const yearFormatter = new Intl.DateTimeFormat(undefined, {
  minute: "2-digit",
  hour: "2-digit",
  hour12: false,
  day: "numeric",
  month: "short",
  year: "numeric",
});
/** @param upto - max unit to show in the formatted string */
export function formatDate(d: Date, upto: "time" | "month" | "year" = "year") {
  return upto === "time"
    ? timeFormatter.format(d)
    : upto === "month"
    ? monthFormatter.format(d)
    : yearFormatter.format(d);
}

const narrowRelativeFormat = new Intl.RelativeTimeFormat(undefined, {
  style: "narrow",
});
const relativeFormat = new Intl.RelativeTimeFormat(undefined);

export function relativeTimeFormat(ms: number, narrow?: boolean) {
  const format = narrow ? narrowRelativeFormat : relativeFormat;
  const abs = Math.abs(ms);
  if (abs < 1000 * 60) {
    return format.format(Math.trunc(ms / 1000), "seconds");
  } else if (abs < 1000 * 60 * 60) {
    return format.format(Math.trunc(ms / 1000 / 60), "minutes");
  } else if (abs < 1000 * 60 * 60 * 24) {
    return format.format(Math.trunc(ms / 1000 / 60 / 60), "hours");
  } else if (abs < 1000 * 60 * 60 * 24 * 15) {
    return format.format(Math.trunc(ms / 1000 / 60 / 60 / 24), "days");
  } else {
    return format.format(Math.trunc(ms / 1000 / 60 / 60 / 24 / 7), "weeks");
  }
}

export function timeAgo(d: Date, narrow?: boolean) {
  const msDiff = d.getTime() - Date.now();
  return relativeTimeFormat(msDiff, narrow);
}
