import { Box, Typography, useTheme } from "@mui/material";
import { SatelliteSource as SatelliteSourcePb } from "@phoenix/common/proto/reports";
import { formatTime } from "@phoenix/common/utils/date";
import { Trans, useTranslation } from "react-i18next";

import { confidenceLevelColors, confidenceLevels } from "../../constants";

export default function SatelliteSource({
  source,
}: {
  source: SatelliteSourcePb;
}) {
  const { t } = useTranslation(["common", "report"]);
  const theme = useTheme();

  return (
    <>
      <Typography variant="h3">
        {t("report:ReportView.satelliteSourceHeading", {
          satelliteName: source.satelliteName,
        })}
      </Typography>
      <Box sx={{ marginTop: 1 }} />
      <Typography>
        <Trans
          ns="report"
          i18nKey="ReportView.satelliteSourceInfo"
          components={{
            confidenceColor: (
              <b
                style={{
                  color: confidenceLevelColors(theme).get(
                    source.detectionSummary!.confidence
                  ),
                }}
              />
            ),
            b: <b />,
            br: <br />,
          }}
          count={source.detectionIds.length}
          values={{
            confidence: confidenceLevels(t).get(
              source.detectionSummary!.confidence
            ),
            firstDetection: formatTime(source.firstDetection!),
            latestDetection: formatTime(source.latestDetection!),
          }}
        />
      </Typography>
      {
        <table>
          {source.detectionSummary?.fireRadiativePower ? (
            <tr>
              <td colSpan={3}>
                <Typography variant="caption" align="right" component="p">
                  {t("report:ReportView.maxFRP")}
                </Typography>
                <Typography align="right">
                  {source.detectionSummary.fireRadiativePower}MW
                </Typography>
              </td>
            </tr>
          ) : null}
          {source.detectionSummary?.observedBt4 &&
          source.detectionSummary?.observedBt11 ? (
            <>
              <tr>
                <td>
                  <Typography variant="caption" align="right" component="p">
                    {t("report:ReportView.avgObsBT4")}
                  </Typography>
                  <Typography align="right">
                    {source.detectionSummary.observedBt4.toFixed(2)}
                  </Typography>
                </td>
                {source.detectionSummary.ambientBt4 ? (
                  <>
                    <td>
                      <Typography variant="caption" align="right" component="p">
                        {t("report:ReportView.avgAmbBT4")}
                      </Typography>
                      <Typography align="right">
                        {source.detectionSummary.ambientBt4.toFixed(2)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="caption" align="right" component="p">
                        {t("report:ReportView.diffBT4")}
                      </Typography>
                      <Typography align="right">
                        {(
                          source.detectionSummary.observedBt4 -
                          source.detectionSummary.ambientBt4
                        ).toFixed(2)}
                        K
                      </Typography>
                    </td>
                  </>
                ) : null}
              </tr>
              <tr>
                <td>
                  <Typography variant="caption" align="right" component="p">
                    {t("report:ReportView.avgObsBT11")}
                  </Typography>
                  <Typography align="right">
                    {source.detectionSummary.observedBt11.toFixed(2)}
                  </Typography>
                </td>
                {source.detectionSummary.ambientBt11 ? (
                  <>
                    <td>
                      <Typography variant="caption" align="right" component="p">
                        {t("report:ReportView.avgAmbBT11")}
                      </Typography>
                      <Typography align="right">
                        {source.detectionSummary.ambientBt11.toFixed(2)}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="caption" align="right" component="p">
                        {t("report:ReportView.diffBT11")}
                      </Typography>
                      <Typography align="right">
                        {(
                          source.detectionSummary.observedBt11 -
                          source.detectionSummary.ambientBt11
                        ).toFixed(2)}
                        K
                      </Typography>
                    </td>
                  </>
                ) : null}
              </tr>
              <tr>
                <td>
                  <Typography variant="caption" align="right" component="p">
                    {t("report:ReportView.diffObs")}
                  </Typography>
                  <Typography align="right">
                    {(
                      source.detectionSummary.observedBt4 -
                      source.detectionSummary.observedBt11
                    ).toFixed(2)}
                    K
                  </Typography>
                </td>
                {source.detectionSummary.ambientBt4 &&
                source.detectionSummary.ambientBt11 ? (
                  <>
                    <td>
                      <Typography variant="caption" align="right" component="p">
                        {t("report:ReportView.diffAmb")}
                      </Typography>
                      <Typography align="right">
                        {(
                          source.detectionSummary.ambientBt4 -
                          source.detectionSummary.ambientBt11
                        ).toFixed(2)}
                        K
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="caption" align="right" component="p">
                        {t("report:ReportView.diffOverall")}
                      </Typography>
                      <Typography align="right">
                        {(
                          source.detectionSummary.observedBt4 -
                          source.detectionSummary.observedBt11 -
                          (source.detectionSummary.ambientBt4 -
                            source.detectionSummary.ambientBt11)
                        ).toFixed(2)}
                        K
                      </Typography>
                    </td>
                  </>
                ) : null}
              </tr>
            </>
          ) : null}
        </table>
      }
    </>
  );
}
