import { styled } from "@mui/material";

const FiltersPanel = styled("div")(({ theme }) => ({
  display: "grid",
  [theme.breakpoints.down("md")]: {
    gridTemplateRows: "repeat(2, auto)",
    gridAutoFlow: "column",
    gridAutoColumns: "min-content",
    "&& > * ": {
      marginInlineEnd: theme.spacing(1),
      marginBlockEnd: theme.spacing(1),
    },
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(auto-fit, 33%)",
    gridAutoFlow: "row",
    gridAutoRows: "min-content",
    "&& > * ": {
      marginInlineEnd: theme.spacing(2),
      marginBlockEnd: theme.spacing(1),
    },
  },
}));

export default FiltersPanel;
