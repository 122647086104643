import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useReportsQueryOptions } from "../features/reportsList/useReportsQuery";
import { imageRangeKey, reportKey, reportPizzasKey } from "../queryKeys";

/** Invalidates in the query cache all queries related to report(s).
 * For use eg. after a review action
 */
export default function useInvalidateReports() {
  const queryClient = useQueryClient();
  const { queryKey: reportsListQueryKey } = useReportsQueryOptions();

  return useCallback(
    (reportIds: number[]) => {
      for (const id of reportIds) {
        queryClient.invalidateQueries(reportKey(id));
      }
      queryClient.invalidateQueries([
        imageRangeKey({
          centerImageId: 0,
          sameViewOnly: true,
        })[0],
      ]);
      queryClient.invalidateQueries(reportsListQueryKey);
      queryClient.invalidateQueries([reportPizzasKey()[0]]);
    },
    [queryClient, reportsListQueryKey]
  );
}
