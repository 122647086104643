import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      if (namespace !== "common") {
        import(`../features/${namespace}/locales/${language}.json`)
          .then((resources) => {
            callback(null, resources);
          })
          .catch((error) => {
            callback(error, null);
          });
        return;
      }

      import(`./common/${language}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .init({
    fallbackLng: "en",
    debug: !import.meta.env.PROD,
    interpolation: {
      // React does this by default already
      escapeValue: false,
    },
    // preload all translations because otherwise changing between two
    // routes/namespaces which both have the map cause it to remount and error
    ns: ["auth", "camera", "common", "error", "home", "report", "reportsList"],
  });

export default i18n;
