import { Box, ButtonBase, styled, Typography } from "@mui/material";
import ImageTimeline from "@phoenix/common/components/compound/ImageTimeline";
import LoadableImg from "@phoenix/common/components/compound/LoadableImage";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import { ImageDetection } from "@phoenix/common/proto/detections";
import {
  CameraImage,
  ListCameraImageRangeRes,
} from "@phoenix/common/proto/images";
import { CameraView } from "@phoenix/common/proto/reports";
import service from "@phoenix/common/service";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { usePreloadImages } from "@phoenix/common/utils/usePreloadImage";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import {
  CSSProperties,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import DetectionsOverlay from "../../../components/compound/DetectionsOverlay";
import { useAuthContext } from "../../../features/auth/AuthProvider";
import { viewingImageTypeAtom } from "../../../features/state";
import { cameraKey, imageRangeKey, reportKey } from "../../../queryKeys";
import ErrorWithIcon from "../ErrorWithIcon";
import MouseZoomContainer from "../MouseZoomContainer";
import ImageOptionsRow from "./ImageOptionsRow";

type CameraImageWithDate = Omit<CameraImage, "takenAt"> & {
  takenAt: Date;
};

const preloadBack = 1;
const preloadForward = 1;
const latestImagesRefetchInterval = 15000;

const NoImageContainer = styled("div")({
  height: "100%",
  aspectRatio: "1920 / 1080",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const MiminumAspectRatioContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "imageHeight",
})<{
  imageHeight?: CSSProperties["maxHeight"];
}>(({ imageHeight }) => ({
  height: imageHeight,
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: imageHeight ? undefined : "100%",
  "&:before": imageHeight
    ? undefined
    : {
        paddingBottom: "56.25%",
        content: "''",
        float: "left",
      },
  "&:after": imageHeight
    ? undefined
    : {
        display: "table",
        content: "''",
        clear: "both",
      },
}));

export default function CameraImageTimeline({
  cameraId,
  sourceId,
  sameViewOnly,
  visibleRangeMinutes = 60,
  initialImageId,
  reportId,
  onDetectionClick,
  onImageClick,
  onImageContextMenu,
  imageHeight,
}: {
  cameraId: number;
  sourceId: number | null;
  sameViewOnly: boolean;
  visibleRangeMinutes?: number;
  initialImageId: number;
  reportId: number | null;
  onDetectionClick?: (detection: ImageDetection, event: MouseEvent) => void;
  onImageClick?: (imageId: number, event: MouseEvent) => void;
  onImageContextMenu?: (
    imageId: number,
    imageCoords: { x: number; y: number },
    event: MouseEvent
  ) => void;
  imageHeight?: CSSProperties["maxHeight"];
}) {
  const { t } = useTranslation("common");
  const {
    state: { scopes },
  } = useAuthContext();

  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(
    null
  );
  //range of the marks visible in Date() milliseconds
  const [currentMarksRange, setCurrentMarksRange] = useState<
    null | [number, number]
  >(null);
  const [imageDimensions, setImageDimensions] = useState<
    null | [number, number]
  >(null);
  const [subtractionImageError, setSubtractionImageError] = useState(false);
  const imageType = useAtomValue(viewingImageTypeAtom);
  const [centerImageId, setCenterImageId] = useState(initialImageId);

  const reportQuery = useQuery(
    reportKey(reportId!),
    () =>
      service.reports.getReport(
        reportId!,
        !scopes.includes("reports.get_report")
      ),
    { enabled: !!reportId }
  );

  const cameraQuery = useQuery(cameraKey(cameraId), () =>
    service.cameras.getCamera(cameraId)
  );

  const imagesQuery = useInfiniteQuery<ListCameraImageRangeRes, RpcError>(
    imageRangeKey({
      centerImageId,
      sameViewOnly,
    }),
    ({ pageParam }) =>
      service.cameras.getImageRange({
        sameViewOnly,
        centerImage: centerImageId,
        pageToken: pageParam,
        viewerOnly: !scopes.includes("images.list_camera_image_range"),
      }),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageToken || undefined,
      getPreviousPageParam: (firstPage) => firstPage.prevPageToken || undefined,
      // refetch every X secs if the images query has no next page ie. it's at the end
      refetchInterval: (data) =>
        data?.pages.length === 0 ||
        !data?.pages[data?.pages.length - 1].nextPageToken
          ? latestImagesRefetchInterval
          : false,
      // if we were viewing the latest image and the incoming latest image is different,
      // jump to the new latest image
      onSuccess: (data) => {
        const lastPage = data.pages[data.pages.length - 1];
        if (
          viewingMostRecent &&
          currentImage.id !== lastPage.images[lastPage.images.length - 1].id
        ) {
          jumpTo("mostRecent");
        } else {
          cameraQuery.refetch();
        }
        if (reportId) reportQuery.refetch();
      },
      refetchIntervalInBackground: true,
    }
  );

  const images = useMemo(
    () => imagesQuery.data?.pages.flatMap((page) => page.images) ?? [],
    [imagesQuery.data]
  );
  const currentImage =
    currentImageIndex !== null ? images[currentImageIndex] : null;

  const [imagesToPreload, setImagesToPreload] = useState<string[]>([]);
  usePreloadImages(imagesToPreload);
  // update images to preload when current image changes
  // this may be low-hanging fruit for optimization
  useEffect(() => {
    if (!currentImage) return;
    const imageIndex = images.findIndex(
      (image) => image.id === currentImage.id
    );
    setImagesToPreload(
      images
        .slice(imageIndex - preloadBack, imageIndex + preloadForward + 1)
        .map((image) =>
          imageType === "standard" ? image.url : image.subtractionUrl
        )
    );
  }, [currentImage, images, imageType]);

  // wait for data to load before setting the initial image
  const haveSetCenterImage = useRef(false);
  useEffect(() => {
    if (!haveSetCenterImage.current && images.length > 0) {
      const centerImageIndex = images.findIndex(
        (image) => image.id === centerImageId
      );
      setCurrentImageIndex(
        centerImageIndex !== -1 ? centerImageIndex : images.length - 1
      );
      setImagesToPreload(
        images
          .slice(
            centerImageIndex - preloadBack,
            centerImageIndex + preloadForward + 1
          )
          .map((image) => image.url)
      );
      haveSetCenterImage.current = true;
    }
  }, [images, centerImageId]);

  const imgRef = useRef<HTMLImageElement>(null);
  const handleImageLoad = () => {
    setImageDimensions([
      imgRef.current?.naturalWidth ?? 0,
      imgRef.current?.naturalHeight ?? 0,
    ]);
  };
  const handleSubtractionImageLoad = () => {
    setSubtractionImageError(false);
  };
  const handleSubtractionImageError = () => {
    setSubtractionImageError(true);
  };

  const loadNext = async () => {
    const res = await imagesQuery.fetchNextPage();
    const newData = res.data?.pages.flatMap((page) => page.images) ?? [];
    const newIndex = newData.findIndex(
      (image) => image.takenAt!.getTime() === currentImage!.takenAt!.getTime()
    );
    if (newIndex !== -1) setCurrentImageIndex(newIndex);
    else throw Error("Couldn't find image in timeline");
  };

  const loadPrevious = async () => {
    const res = await imagesQuery.fetchPreviousPage();
    const newData = res.data?.pages.flatMap((page) => page.images) ?? [];
    const newIndex = newData.findIndex(
      (image) => image.takenAt!.getTime() === currentImage!.takenAt!.getTime()
    );
    if (newIndex !== -1) setCurrentImageIndex(newIndex);
    else throw Error("Couldn't find image in timeline");
  };

  const handleImageContextMenu: MouseEventHandler<SVGSVGElement> | undefined =
    onImageContextMenu
      ? (event) => {
          if (!currentImage) return;
          onImageContextMenu(
            currentImage.id,
            {
              x: event.nativeEvent.offsetX / event.currentTarget.clientWidth,
              y: event.nativeEvent.offsetY / event.currentTarget.clientHeight,
            },
            event
          );
        }
      : undefined;

  const currentCameraSource = reportQuery.data?.sources.find(
    (
      source
    ): source is {
      source: { $case: "cameraView"; cameraView: CameraView };
    } =>
      source.source?.$case === "cameraView" &&
      source.source.cameraView.id === sourceId
  );

  const jumpTo = async (
    where: "firstDetection" | "latestActivity" | "mostRecent"
  ) => {
    let newCenterImageId: number;
    if (where === "mostRecent") {
      const res = await cameraQuery.refetch();
      if (res.status !== "success") return;
      newCenterImageId = res.data.latestImageId;
    } else {
      if (reportQuery.status !== "success") return;
      if (!currentCameraSource) {
        console.warn("Couldn't find current camera source.");
        return;
      }
      if (where === "firstDetection") {
        newCenterImageId =
          currentCameraSource.source.cameraView.firstDetectionImageId;
      } else if (where === "latestActivity") {
        newCenterImageId =
          currentCameraSource.source.cameraView.lastDetectionImageId;
      } else {
        throw Error("Unknown jumpTo param");
      }
    }
    if (newCenterImageId === centerImageId) {
      //same center image, just change the current image
      const targetImageIndex = images.findIndex(
        (image) => image.id === centerImageId
      );
      if (targetImageIndex === -1) return;
      setCurrentImageIndex(targetImageIndex);
      const targetTime = images[targetImageIndex].takenAt!;
      setCurrentMarksRange([
        targetTime.getTime() - (visibleRangeMinutes / 2) * 60 * 1000,
        targetTime.getTime() + (visibleRangeMinutes / 2) * 60 * 1000,
      ]);
    } else {
      haveSetCenterImage.current = false;
      setCurrentImageIndex(null);
      setCenterImageId(newCenterImageId);
      setCurrentMarksRange(null);
    }
  };

  const viewingMostRecent =
    !!imagesQuery.data &&
    !imagesQuery.hasNextPage &&
    images.length > 0 &&
    images[images.length - 1].id == currentImage?.id;
  const viewingFirstDetection =
    currentImage?.id ===
    currentCameraSource?.source.cameraView.firstDetectionImageId;
  const viewingLatestActivity =
    currentImage?.id ===
    currentCameraSource?.source.cameraView.lastDetectionImageId;

  const image = !currentImage ? null : (
    <MouseZoomContainer disablePan={!!onImageClick}>
      <Box position="relative">
        <LoadableImg
          src={currentImage.url}
          ref={imgRef}
          onLoad={handleImageLoad}
          style={{ maxHeight: imageHeight }}
        />
        {imageDimensions ? (
          <DetectionsOverlay
            detections={currentImage.detections}
            reportId={reportId ?? null}
            viewboxWidth={imageDimensions[0]}
            viewboxHeight={imageDimensions[1]}
            onDetectionClick={onDetectionClick}
            onContextMenu={handleImageContextMenu}
          />
        ) : null}
      </Box>
      {imageType === "subtraction" && (
        <Box position="relative" data-no-zoom={subtractionImageError}>
          <LoadableImg
            src={currentImage.subtractionUrl}
            onLoad={handleSubtractionImageLoad}
            onError={handleSubtractionImageError}
            style={{ maxHeight: imageHeight }}
          />
          {subtractionImageError && (
            <Typography p={2} minWidth={100}>
              {t(
                "components.CameraImageTimeline.noSubtractionImageAvailableText"
              )}
            </Typography>
          )}
          {!subtractionImageError && imageDimensions ? (
            <DetectionsOverlay
              detections={currentImage.detections}
              reportId={reportId ?? null}
              viewboxWidth={imageDimensions[0]}
              viewboxHeight={imageDimensions[1]}
              onDetectionClick={onDetectionClick}
              onContextMenu={handleImageContextMenu}
            />
          ) : null}
        </Box>
      )}
    </MouseZoomContainer>
  );

  return (
    <>
      <ImageTimeline
        sortedImages={images as CameraImageWithDate[]}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        currentMarksRange={currentMarksRange}
        setCurrentMarksRange={setCurrentMarksRange}
        hasPrev={!!imagesQuery.hasPreviousPage}
        hasNext={!!imagesQuery.hasNextPage}
        loadPrev={loadPrevious}
        loadNext={loadNext}
        loadingPrev={imagesQuery.isFetchingPreviousPage}
        loadingNext={imagesQuery.isFetchingNextPage}
        visibleRangeMinutes={visibleRangeMinutes}
        rotateLabels
      >
        <MiminumAspectRatioContainer imageHeight={imageHeight}>
          {imagesQuery.status === "loading" ||
          (imagesQuery.status !== "error" && !currentImage) ? (
            <NoImageContainer>
              <CircularProgress />
            </NoImageContainer>
          ) : imagesQuery.status === "error" ? (
            <NoImageContainer>
              <ErrorWithIcon
                message={imagesQuery.error.message}
                fontSize={24}
              />
            </NoImageContainer>
          ) : imagesQuery.status === "success" ? (
            images.length === 0 ? (
              <NoImageContainer>
                <Typography>
                  {t("components.CameraImageTimeline.noImageText")}
                </Typography>
              </NoImageContainer>
            ) : onImageClick && currentImage ? (
              <ButtonBase
                onClick={(event) => onImageClick(currentImage.id, event)}
                sx={{
                  position: "relative",
                  width: "100%",
                  "&:focus-visible": { outline: "2px solid white" },
                }}
              >
                {image}
              </ButtonBase>
            ) : (
              <Box sx={{ position: "relative" }}>{image}</Box>
            )
          ) : null}
        </MiminumAspectRatioContainer>
      </ImageTimeline>
      <ImageOptionsRow
        currentDetections={
          currentImageIndex && images[currentImageIndex]
            ? images[currentImageIndex].detections
            : null
        }
        isCameraSource={!!sourceId}
        jumpTo={jumpTo}
        viewingMostRecent={viewingMostRecent}
        viewingFirstDetection={viewingFirstDetection}
        viewingLatestActivity={viewingLatestActivity}
      />
    </>
  );
}
