import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { atom, useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import niceGrpcErrors from "./niceGrpcErrors";

type SnackbarMessage = { kind: "error" | "success" | "info"; message: string };
const snackbarMessagesAtom = atom<SnackbarMessage[]>([]);

export default function useSnackbar() {
  const setSnackbarMessages = useSetAtom(snackbarMessagesAtom);
  const showSnackbar = useCallback(
    (message: SnackbarMessage) =>
      setSnackbarMessages((prev) => [...prev, message]),
    [setSnackbarMessages]
  );
  return showSnackbar;
}

export function GlobalSnackbar() {
  const { t } = useTranslation(["common"]);
  const [snackbarMessages, setSnackbarMessages] = useAtom(snackbarMessagesAtom);
  const [currentMessage, setCurrentMessage] = useState<SnackbarMessage | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const handleClose = (e: unknown, reason: SnackbarCloseReason) => {
    if (reason !== "clickaway") setOpen(false);
  };

  useEffect(() => {
    if (currentMessage || open) return;
    if (snackbarMessages.length > 0) {
      const [message, ...leftoverMessages] = snackbarMessages;
      setCurrentMessage(message);
      setSnackbarMessages(leftoverMessages);
      setOpen(true);
    }
  }, [snackbarMessages, currentMessage, setSnackbarMessages, open]);

  const handleTransitionExited = () => {
    setCurrentMessage(null);
  };

  return (
    <Snackbar
      key={currentMessage?.message}
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleTransitionExited }}
    >
      {currentMessage ? (
        <Alert
          severity={currentMessage.kind}
          onClose={(e) => handleClose(e, "escapeKeyDown")}
        >
          {niceGrpcErrors(currentMessage.message, t)}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
}
