export async function reverseGeocode({
  latitude,
  longitude,
  language = "en",
}: {
  latitude: number;
  longitude: number;
  language: string;
}): Promise<string> {
  const types = "region%2Ccountry%2Cdistrict%2Clocality%2Cneighborhood%2Cplace";
  const accessToken = import.meta.env.VITE_MAPBOX_GEOCODING_TOKEN;
  const res = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?limit=1&types=${types}&language=${language}&access_token=${accessToken}`
  );
  if (!res.ok) {
    throw Error(`Reverse geocode ${res.status}: ${res.statusText}`);
  }
  const data = await res.json();
  if (data.features.length === 0) {
    throw Error("No results for geocoding");
  }
  return data.features[0].place_name;
}
