import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  emphasize,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { ReportSummary } from "@phoenix/common/proto/reports";
import { formatDate, timeAgo } from "@phoenix/common/utils/date";
import { useAtomValue, useSetAtom } from "jotai";
import { memo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LocationWithFallback from "../../components/simple/LocationWithFallback";
import PeriodicRerender from "../../components/simple/PeriodicRerender";
import StateChip from "../../components/simple/StateChip";
import { routeTo } from "../../routes";
import { idColorHash } from "../../utils/colorHashes";
import { reportListScrollIdAtom, timeStyleAtom } from "../state";

const StyledCard = styled(Card, {
  shouldForwardProp: (key) => key !== "highlight",
})<{ highlight: boolean }>(({ theme, highlight }) => ({
  backgroundColor: highlight
    ? emphasize(theme.palette.background.paper, 0.1)
    : theme.palette.background.paper,
}));
const DataRow = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  justifyContent: "space-between",
  "& > * + *": { marginLeft: theme.spacing(1), flexShrink: 0 },
}));
const EllipsisDiv = styled("div")({
  textOverflow: "ellipsis",
  overflowX: "hidden",
  whiteSpace: "nowrap",
});

const ReportCard = memo(_ReportCard);
export default ReportCard;

function _ReportCard({
  report,
  highlight,
  onMouseEnter,
  onMouseLeave,
}: {
  report: ReportSummary;
  highlight?: boolean;
  onMouseEnter?: (report: ReportSummary) => void;
  onMouseLeave?: (report: ReportSummary) => void;
}) {
  const { t } = useTranslation("reportsList");
  const theme = useTheme();
  const setReportListScrollId = useSetAtom(reportListScrollIdAtom);
  const sourceNames = report.sourceSummaries
    .map((source) =>
      source.source?.$case === "cameraViewSummary"
        ? source.source.cameraViewSummary.cameraDisplayName ?? ""
        : source.source?.$case === "satelliteSourceSummary"
        ? source.source.satelliteSourceSummary.satelliteName ?? ""
        : ""
    )
    .filter((name) => !!name)
    .join(", ");

  return (
    <Link
      id={`report-${report.id}`}
      to={routeTo.report(report.id)}
      onClick={() => setReportListScrollId(report.id)}
    >
      <StyledCard
        onMouseEnter={() => onMouseEnter?.(report)}
        onMouseLeave={() => onMouseLeave?.(report)}
        highlight={!!highlight}
      >
        <CardActionArea>
          <CardContent>
            <Grid container>
              <Grid item xs={6} sx={{ paddingInlineEnd: 2 }}>
                <Typography variant="caption" component="h3">
                  {t("ReportCard.firstDetectionLabel")}
                </Typography>
                <Typography
                  variant="h4"
                  component="p"
                  color={idColorHash(report.id, theme.palette.mode)}
                >
                  <TimeDisplay time={report.firstDetectionTime!} />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="h3">
                  {t("ReportCard.latestDetectionLabel")}
                </Typography>
                <Typography
                  variant="h4"
                  component="p"
                  color={idColorHash(report.id, theme.palette.mode)}
                >
                  <TimeDisplay time={report.latestDetectionTime!} />
                </Typography>
              </Grid>
            </Grid>
            <Box height={(theme) => theme.spacing(1)} />
            <DataRow>
              <EllipsisDiv>
                <Trans t={t} i18nKey="ReportCard.location">
                  <Typography
                    variant="caption"
                    component="h3"
                    sx={{ display: "inline" }}
                  >
                    In{" "}
                  </Typography>
                  <Typography component="span">
                    <LocationWithFallback
                      location={report.location}
                      latitude={report.latitude}
                      longitude={report.longitude}
                    />
                  </Typography>
                </Trans>
              </EllipsisDiv>
            </DataRow>
            <Box height={(theme) => theme.spacing(0.5)} />
            <DataRow>
              <EllipsisDiv>
                <Trans t={t} i18nKey="ReportCard.camera">
                  <Typography
                    variant="caption"
                    component="h3"
                    sx={{ display: "inline" }}
                  >
                    By{" "}
                  </Typography>
                  <Typography component="span">
                    <>{{ sourceNames }}</>
                  </Typography>
                </Trans>
              </EllipsisDiv>
              <DataRow>
                <StateChip state={report.state} />
                <div>
                  <Trans t={t} i18nKey="ReportCard.confidence">
                    <Typography
                      variant="caption"
                      component="h3"
                      sx={{ display: "inline" }}
                    >
                      Conf{" "}
                    </Typography>
                    <Typography component="span">
                      <>{{ confidence: Math.round(report.confidence * 100) }}</>
                    </Typography>
                  </Trans>
                </div>
                <div>
                  <Trans t={t} i18nKey="ReportCard.id">
                    <Typography
                      variant="caption"
                      component="h3"
                      sx={{ display: "inline" }}
                    >
                      ID{" "}
                    </Typography>
                    <Typography component="span">
                      <>{{ id: report.id }}</>
                    </Typography>
                  </Trans>
                </div>
              </DataRow>
            </DataRow>
          </CardContent>
        </CardActionArea>
      </StyledCard>
    </Link>
  );
}

// having a seperate component for this greatly reduces amount of rendering
// when the timeStyle is changed (only text instead of whole card)
function TimeDisplay({ time }: { time: Date }) {
  const timeStyle = useAtomValue(timeStyleAtom);
  return (
    <PeriodicRerender date={time}>
      {() =>
        timeStyle === "relative"
          ? `${timeAgo(time)} (${formatDate(time, "time")})`
          : formatDate(time, "month")
      }
    </PeriodicRerender>
  );
}
