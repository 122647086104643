import { createTheme, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { usePersistedState } from "@phoenix/common/utils/usePersistedState";
import { Context, createContext, ReactNode, useContext, useMemo } from "react";

export const navbarHeightXs = 48;
export const navbarHeightMd = 64;

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#2A282F",
      default: "#201f23",
    },
    primary: {
      main: "#6CB7C6",
      light: "#E6EBE0",
      dark: "#3C6067",
    },
    secondary: {
      main: "#FF5C6C",
    },
  },
  shape: { borderRadius: 12 },
  typography: {
    fontFamily: "Lato, sans-serif",
    h1: { fontSize: "2rem" },
    h2: { fontSize: "1.4rem", color: grey[200] },
    h3: { fontSize: "1.25rem", color: grey[200] },
    h4: { fontSize: "1.1rem", color: grey[200] },
    body1: { color: grey[50] },
    caption: { color: grey[400] },
  },
});

const outdoorTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      paper: "#fff",
      default: grey[100],
    },
  },
  shape: { borderRadius: 12 },
  typography: {
    fontFamily: "Lato, sans-serif",
    h1: { fontSize: "2rem" },
    h2: { fontSize: "1.4rem", color: grey[900] },
    h3: { fontSize: "1.25rem", color: grey[900] },
    h4: { fontSize: "1.1rem", color: grey[900] },
    body1: { color: grey[900] },
    caption: { color: grey[700] },
  },
});

export const themes = {
  dark: {
    theme: darkTheme,
    mapboxThemeUrl:
      "https://api.mapbox.com/styles/v1/fireball-intel/ckx9p9jr9201a15lwmeqgxvlp/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmlyZWJhbGwtaW50ZWwiLCJhIjoiY2tzZm40bnVsMWMxNTJ3cGFvbndzd3R2dCJ9.c5varXwpbFNkgfnLdP8q_A",
  },
  outdoor: {
    theme: outdoorTheme,
    mapboxThemeUrl:
      "https://api.mapbox.com/styles/v1/fireball-intel/cl8jg35f9000414lmp497qxma/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmlyZWJhbGwtaW50ZWwiLCJhIjoiY2tzZm40bnVsMWMxNTJ3cGFvbndzd3R2dCJ9.c5varXwpbFNkgfnLdP8q_A",
  },
} as const;

export const MapThemeUrlContext = createContext<null | {
  mapboxThemeUrl: string;
  themeKey: string;
  setThemeKey: (key: keyof typeof themes) => void;
}>(null);

export function AppThemeProvider({ children }: { children: ReactNode }) {
  const [themeKey, setThemeKey] = usePersistedState<keyof typeof themes>(
    "theme",
    "dark"
  );

  const value = useMemo(
    () => ({
      mapboxThemeUrl: themes[themeKey].mapboxThemeUrl,
      themeKey,
      setThemeKey,
    }),
    [themeKey, setThemeKey]
  );

  return (
    <MapThemeUrlContext.Provider value={value}>
      <ThemeProvider theme={themes[themeKey].theme}>{children}</ThemeProvider>
    </MapThemeUrlContext.Provider>
  );
}

function useMapThemeContext<T>(context: Context<T | null>) {
  const contextValue = useContext(context);
  if (contextValue === null) {
    throw Error("No theme context provided!");
  }
  return contextValue;
}

export const useMapTheme = () => useMapThemeContext(MapThemeUrlContext);
