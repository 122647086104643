import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { reportsSortAtom } from "../../state";
import { ReportsSort } from "../useReportsQuery";
import FilterButton from "./FilterButton";

const clickOrder: ReportsSort[] = [
  { column: "first", order: "desc" },
  { column: "first", order: "asc" },
  { column: "latest", order: "desc" },
  { column: "latest", order: "asc" },
];

export default function SortButton() {
  const { t } = useTranslation("reportsList");
  const [sort, setSort] = useAtom(reportsSortAtom);

  const handleClick = () => {
    const index = clickOrder.findIndex(
      (s) => s.column === sort.column && s.order === sort.order
    );
    setSort(clickOrder[index + 1 >= clickOrder.length ? 0 : index + 1]);
  };

  return (
    <FilterButton
      onClick={handleClick}
      icon={sort.order === "asc" ? <ArrowUpward /> : <ArrowDownward />}
    >
      {sort.column === "latest"
        ? t("ReportCard.latestDetectionLabel")
        : sort.column === "first"
        ? t("ReportCard.firstDetectionLabel")
        : "Unknown"}
    </FilterButton>
  );
}
