import { ArrowCircleUp } from "@mui/icons-material";
import { styled } from "@mui/material";

const Container = styled("div")({
  height: 24,
  width: 24,
  display: "inline-block",
  verticalAlign: "text-bottom",
});

export default function Bearing({
  angle,
  title,
}: {
  angle: number;
  title: string;
}) {
  return (
    <Container title={title} style={{ transform: `rotate(${angle}deg)` }}>
      <ArrowCircleUp />
    </Container>
  );
}
