import { ArrowLeft } from "@mui/icons-material";
import {
  Box,
  Button as MuiButton,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import {
  Report,
  SatelliteSource as SatelliteSourcePb,
} from "@phoenix/common/proto/reports";
import service from "@phoenix/common/service";
import { formatDate, timeAgo } from "@phoenix/common/utils/date";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { latLng } from "leaflet";
import { useEffect, useId, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import ErrorWithIcon from "../../components/compound/ErrorWithIcon";
import CopyButton from "../../components/simple/CopyButton";
import LocationWithFallback from "../../components/simple/LocationWithFallback";
import StateChip from "../../components/simple/StateChip";
import { reportKey } from "../../queryKeys";
import { idColorHash } from "../../utils/colorHashes";
import useSnackbar from "../../utils/useSnackbar";
import { useAuthContext } from "../auth/AuthProvider";
import { useMap } from "../map/MapProvider";
import { useReportsQueryOptions } from "../reportsList/useReportsQuery";
import {
  currentReportSatelliteSourcesAtom,
  currentViewingReportGeojsonAtom,
} from "../state";
import CameraViewDetection from "./CameraViewDetection";
import MergeTimelineDialogButton from "./MergeTimelineDialogButton";
import ReviewActions from "./ReviewActions";
import SatelliteSource from "./SatelliteSource";

const reportDefaultZoom = 10;
const reportMobileHeight = 400;

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.palette.background.paper,
    height: reportMobileHeight,
    overflowY: "auto",
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function ReportView() {
  const { t } = useTranslation("report");
  const {
    state: { scopes },
  } = useAuthContext();
  const canReview = scopes.includes("reports.review");

  const params = useParams<{ id: string | undefined }>();
  const id = parseInt(params.id ?? "0");

  const theme = useTheme();

  const report = useQuery<Report, RpcError>(reportKey(id), () =>
    service.reports.getReport(id, !scopes.includes("reports.get_report"))
  );

  // fly to the report on when data is first available only
  const map = useMap();
  const hasZoomed = useRef(false);
  useEffect(() => {
    if (!hasZoomed.current && report.data) {
      map?.flyTo(
        latLng(report.data!.latitude, report.data!.longitude),
        reportDefaultZoom
      );
      hasZoomed.current = true;
    }
  }, [report, map]);

  // show this report's geojson on the map
  const setCurrentViewingReportGeojson = useSetAtom(
    currentViewingReportGeojsonAtom
  );
  const queryClient = useQueryClient();
  const { filters: reportsFilters, range: reportsRange } =
    useReportsQueryOptions();
  useEffect(() => {
    if (!report.data) return;
    const multiPolygon = JSON.parse(report.data.areaGeojson);
    const feature = {
      type: "Feature" as const,
      id: report.data.id,
      geometry: multiPolygon,
      properties: {},
    };
    setCurrentViewingReportGeojson(feature);

    return () => setCurrentViewingReportGeojson(null);
  }, [
    queryClient,
    report.data,
    reportsFilters,
    reportsRange,
    setCurrentViewingReportGeojson,
  ]);

  const setCurrentReportSatelliteSources = useSetAtom(
    currentReportSatelliteSourcesAtom
  );

  useEffect(() => {
    if (!report.data) return;
    setCurrentReportSatelliteSources(
      report.data.sources
        .map((source) => source.source)
        .filter(
          (
            source
          ): source is {
            $case: "satelliteSource";
            satelliteSource: SatelliteSourcePb;
          } => source?.$case === "satelliteSource"
        )
        .map((source) => source.satelliteSource)
    );

    return () => setCurrentReportSatelliteSources(null);
  }, [report.data, setCurrentReportSatelliteSources]);

  const confidenceId = useId();

  return (
    <Root>
      <BackToListButton />
      {report.status === "loading" ? (
        <>
          <Typography variant="h1">{t("ReportView.title", { id })}</Typography>
          <Box sx={{ marginTop: 2 }} />
          <CircularProgress />
        </>
      ) : report.status === "error" ? (
        <ErrorWithIcon message={report.error.message} fontSize={32} />
      ) : report.data ? (
        <>
          <Box
            sx={{
              marginTop: 2,
              marginBottom: 2,
              display: "flex",
              flexWrap: "wrap",
              justifyItems: "start",
              alignItems: "center",
              rowGap: 1,
              columnGap: 4,
            }}
          >
            <div style={{ marginRight: "auto" }}>
              <Typography variant="h1">
                {t("ReportView.title", { id })}
              </Typography>
              {canReview && <ReviewActions report={report.data} />}
            </div>
            <div>
              <Typography
                variant="caption"
                htmlFor={confidenceId}
                component="label"
                sx={{
                  display: "block",
                  textAlign: "center",
                  lineHeight: 1,
                  color: idColorHash(id, theme.palette.mode),
                }}
              >
                {t("ReportView.confidenceLabel")}
              </Typography>
              <Typography
                id={confidenceId}
                sx={{
                  textAlign: "center",
                  fontSize: 24,
                  fontWeight: "bold",
                  color: idColorHash(id, theme.palette.mode),
                }}
              >
                {Math.round(report.data.confidenceHistory[0].confidence * 100)}
              </Typography>
              <StateChip
                state={report.data.state}
                reason={report.data.reason}
              />
            </div>
          </Box>
          <Box sx={{ "& > *": { marginBottom: 3 } }}>
            <div>
              <LocationSection report={report.data} />
            </div>
            <div>
              <ActivitySection report={report.data} />
            </div>
            <div>
              <DetectionsSection report={report.data} />
            </div>
            {scopes.includes("reports.get_report_merge_timeline") && (
              <div>
                <MergeTimelineDialogButton reportId={report.data.id} />
              </div>
            )}
          </Box>
        </>
      ) : null}
    </Root>
  );
}

function BackToListButton() {
  const { t } = useTranslation("report");
  return (
    <Link to="/">
      <MuiButton variant="text">
        <ArrowLeft />
        {t("backButtonLabel")}
      </MuiButton>
    </Link>
  );
}

function LocationSection({ report }: { report: Report }) {
  const { t } = useTranslation("report");
  const showSnackbar = useSnackbar();
  const coordinates = `${report.latitude.toFixed(
    4
  )}, ${report.longitude.toFixed(4)}`;
  return (
    <>
      <Typography variant="h2">{t("ReportView.locationHeading")}</Typography>
      <Typography sx={{ marginTop: 1 }}>
        <LocationWithFallback
          location={report.location}
          latitude={report.latitude}
          longitude={report.longitude}
        />
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns="max-content auto"
        columnGap={1}
        marginTop={1}
      >
        <Typography variant="caption">
          {t("ReportView.latLongLabel")}
        </Typography>
        <CopyButton
          title={t("ReportView.copyCoordinatesLabel")}
          data={coordinates}
          onError={(e) => {
            showSnackbar({
              kind: "error",
              message: t("ReportView.copyErrorMesage"),
            });
            console.error(e);
          }}
          sx={{
            gridRow: "span 2",
            justifySelf: "start",
            alignSelf: "center",
            position: "relative",
          }}
        />
        <Typography>{coordinates}</Typography>
      </Box>
    </>
  );
}

function ActivitySection({ report }: { report: Report }) {
  const { t } = useTranslation("report");
  return (
    <>
      <Typography variant="h2">{t("ReportView.activityHeading")}</Typography>
      <Grid container sx={{ marginTop: 1 }}>
        <Grid item xs={6}>
          <Typography variant="caption" component="h3">
            {t("ReportView.firstDetectionLabel")}
          </Typography>
          <Typography>{timeAgo(report.firstDetectionTime!)}</Typography>
          <Typography>
            {formatDate(report.firstDetectionTime!, "month")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" component="h3">
            {t("ReportView.latestDetectionLabel")}
          </Typography>
          <Typography>{timeAgo(report.latestDetectionTime!)}</Typography>
          <Typography>
            {formatDate(report.latestDetectionTime!, "month")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

function DetectionsSection({ report }: { report: Report }) {
  const { t } = useTranslation("report");

  return (
    <>
      <Typography variant="h2">{t("ReportView.detectionsHeading")}</Typography>
      {report.sources.map((source) => {
        if (!source.source) return null;
        return (
          <Box
            key={`${report.id}-${
              source.source.$case === "cameraView"
                ? source.source.cameraView.id
                : source.source?.satelliteSource.detectionIds[0]
            }`}
            sx={{ marginTop: 1 }}
          >
            {source.source?.$case === "cameraView" ? (
              <CameraViewDetection
                view={source.source.cameraView}
                report={report}
              />
            ) : source.source?.$case === "satelliteSource" ? (
              <SatelliteSource source={source.source.satelliteSource} />
            ) : (
              <Typography>
                {t("ReportView.unknownSourceTypeMessage")}
              </Typography>
            )}
          </Box>
        );
      })}
    </>
  );
}
