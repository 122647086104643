/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { BoolValue, Int32Value, StringValue } from "./google/protobuf/wrappers";

export const protobufPackage = "phoenix.camera_networks.v0";

export interface CameraNetworkSettings {
  isActive: boolean;
  isHidden: boolean;
  encrypt: boolean;
  reversePan: boolean;
  imageAttempts: number;
  archiveImages: boolean;
}

export interface CameraNetworkSettingsUpdate {
  isActive: boolean | undefined;
  isHidden: boolean | undefined;
  encrypt: boolean | undefined;
  reversePan: boolean | undefined;
  imageAttempts: number | undefined;
  archiveImages: boolean | undefined;
}

export interface CameraNetwork {
  id: number;
  name: string;
  timezone: string;
  networkSettings: CameraNetworkSettings | undefined;
}

export interface CameraNetworkUpdate {
  id: number;
  name: string | undefined;
  timezone: string | undefined;
  networkSettings: CameraNetworkSettingsUpdate | undefined;
}

function createBaseCameraNetworkSettings(): CameraNetworkSettings {
  return {
    isActive: false,
    isHidden: false,
    encrypt: false,
    reversePan: false,
    imageAttempts: 0,
    archiveImages: false,
  };
}

export const CameraNetworkSettings = {
  encode(message: CameraNetworkSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isActive === true) {
      writer.uint32(8).bool(message.isActive);
    }
    if (message.isHidden === true) {
      writer.uint32(16).bool(message.isHidden);
    }
    if (message.encrypt === true) {
      writer.uint32(24).bool(message.encrypt);
    }
    if (message.reversePan === true) {
      writer.uint32(32).bool(message.reversePan);
    }
    if (message.imageAttempts !== 0) {
      writer.uint32(40).int32(message.imageAttempts);
    }
    if (message.archiveImages === true) {
      writer.uint32(48).bool(message.archiveImages);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraNetworkSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraNetworkSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isHidden = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.encrypt = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reversePan = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.imageAttempts = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.archiveImages = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraNetworkSettings {
    return {
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
      isHidden: isSet(object.isHidden) ? globalThis.Boolean(object.isHidden) : false,
      encrypt: isSet(object.encrypt) ? globalThis.Boolean(object.encrypt) : false,
      reversePan: isSet(object.reversePan) ? globalThis.Boolean(object.reversePan) : false,
      imageAttempts: isSet(object.imageAttempts) ? globalThis.Number(object.imageAttempts) : 0,
      archiveImages: isSet(object.archiveImages) ? globalThis.Boolean(object.archiveImages) : false,
    };
  },

  toJSON(message: CameraNetworkSettings): unknown {
    const obj: any = {};
    if (message.isActive === true) {
      obj.isActive = message.isActive;
    }
    if (message.isHidden === true) {
      obj.isHidden = message.isHidden;
    }
    if (message.encrypt === true) {
      obj.encrypt = message.encrypt;
    }
    if (message.reversePan === true) {
      obj.reversePan = message.reversePan;
    }
    if (message.imageAttempts !== 0) {
      obj.imageAttempts = Math.round(message.imageAttempts);
    }
    if (message.archiveImages === true) {
      obj.archiveImages = message.archiveImages;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraNetworkSettings>, I>>(base?: I): CameraNetworkSettings {
    return CameraNetworkSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraNetworkSettings>, I>>(object: I): CameraNetworkSettings {
    const message = createBaseCameraNetworkSettings();
    message.isActive = object.isActive ?? false;
    message.isHidden = object.isHidden ?? false;
    message.encrypt = object.encrypt ?? false;
    message.reversePan = object.reversePan ?? false;
    message.imageAttempts = object.imageAttempts ?? 0;
    message.archiveImages = object.archiveImages ?? false;
    return message;
  },
};

function createBaseCameraNetworkSettingsUpdate(): CameraNetworkSettingsUpdate {
  return {
    isActive: undefined,
    isHidden: undefined,
    encrypt: undefined,
    reversePan: undefined,
    imageAttempts: undefined,
    archiveImages: undefined,
  };
}

export const CameraNetworkSettingsUpdate = {
  encode(message: CameraNetworkSettingsUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isActive !== undefined) {
      BoolValue.encode({ value: message.isActive! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.isHidden !== undefined) {
      BoolValue.encode({ value: message.isHidden! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.encrypt !== undefined) {
      BoolValue.encode({ value: message.encrypt! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.reversePan !== undefined) {
      BoolValue.encode({ value: message.reversePan! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.imageAttempts !== undefined) {
      Int32Value.encode({ value: message.imageAttempts! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.archiveImages !== undefined) {
      BoolValue.encode({ value: message.archiveImages! }, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraNetworkSettingsUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraNetworkSettingsUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.isActive = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.isHidden = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encrypt = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.reversePan = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageAttempts = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.archiveImages = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraNetworkSettingsUpdate {
    return {
      isActive: isSet(object.isActive) ? Boolean(object.isActive) : undefined,
      isHidden: isSet(object.isHidden) ? Boolean(object.isHidden) : undefined,
      encrypt: isSet(object.encrypt) ? Boolean(object.encrypt) : undefined,
      reversePan: isSet(object.reversePan) ? Boolean(object.reversePan) : undefined,
      imageAttempts: isSet(object.imageAttempts) ? Number(object.imageAttempts) : undefined,
      archiveImages: isSet(object.archiveImages) ? Boolean(object.archiveImages) : undefined,
    };
  },

  toJSON(message: CameraNetworkSettingsUpdate): unknown {
    const obj: any = {};
    if (message.isActive !== undefined) {
      obj.isActive = message.isActive;
    }
    if (message.isHidden !== undefined) {
      obj.isHidden = message.isHidden;
    }
    if (message.encrypt !== undefined) {
      obj.encrypt = message.encrypt;
    }
    if (message.reversePan !== undefined) {
      obj.reversePan = message.reversePan;
    }
    if (message.imageAttempts !== undefined) {
      obj.imageAttempts = message.imageAttempts;
    }
    if (message.archiveImages !== undefined) {
      obj.archiveImages = message.archiveImages;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraNetworkSettingsUpdate>, I>>(base?: I): CameraNetworkSettingsUpdate {
    return CameraNetworkSettingsUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraNetworkSettingsUpdate>, I>>(object: I): CameraNetworkSettingsUpdate {
    const message = createBaseCameraNetworkSettingsUpdate();
    message.isActive = object.isActive ?? undefined;
    message.isHidden = object.isHidden ?? undefined;
    message.encrypt = object.encrypt ?? undefined;
    message.reversePan = object.reversePan ?? undefined;
    message.imageAttempts = object.imageAttempts ?? undefined;
    message.archiveImages = object.archiveImages ?? undefined;
    return message;
  },
};

function createBaseCameraNetwork(): CameraNetwork {
  return { id: 0, name: "", timezone: "", networkSettings: undefined };
}

export const CameraNetwork = {
  encode(message: CameraNetwork, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.timezone !== "") {
      writer.uint32(26).string(message.timezone);
    }
    if (message.networkSettings !== undefined) {
      CameraNetworkSettings.encode(message.networkSettings, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraNetwork {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraNetwork();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timezone = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.networkSettings = CameraNetworkSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraNetwork {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
      networkSettings: isSet(object.networkSettings)
        ? CameraNetworkSettings.fromJSON(object.networkSettings)
        : undefined,
    };
  },

  toJSON(message: CameraNetwork): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    if (message.networkSettings !== undefined) {
      obj.networkSettings = CameraNetworkSettings.toJSON(message.networkSettings);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraNetwork>, I>>(base?: I): CameraNetwork {
    return CameraNetwork.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraNetwork>, I>>(object: I): CameraNetwork {
    const message = createBaseCameraNetwork();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.timezone = object.timezone ?? "";
    message.networkSettings = (object.networkSettings !== undefined && object.networkSettings !== null)
      ? CameraNetworkSettings.fromPartial(object.networkSettings)
      : undefined;
    return message;
  },
};

function createBaseCameraNetworkUpdate(): CameraNetworkUpdate {
  return { id: 0, name: undefined, timezone: undefined, networkSettings: undefined };
}

export const CameraNetworkUpdate = {
  encode(message: CameraNetworkUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.timezone !== undefined) {
      StringValue.encode({ value: message.timezone! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.networkSettings !== undefined) {
      CameraNetworkSettingsUpdate.encode(message.networkSettings, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraNetworkUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraNetworkUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timezone = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.networkSettings = CameraNetworkSettingsUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraNetworkUpdate {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : undefined,
      timezone: isSet(object.timezone) ? String(object.timezone) : undefined,
      networkSettings: isSet(object.networkSettings)
        ? CameraNetworkSettingsUpdate.fromJSON(object.networkSettings)
        : undefined,
    };
  },

  toJSON(message: CameraNetworkUpdate): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.timezone !== undefined) {
      obj.timezone = message.timezone;
    }
    if (message.networkSettings !== undefined) {
      obj.networkSettings = CameraNetworkSettingsUpdate.toJSON(message.networkSettings);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraNetworkUpdate>, I>>(base?: I): CameraNetworkUpdate {
    return CameraNetworkUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraNetworkUpdate>, I>>(object: I): CameraNetworkUpdate {
    const message = createBaseCameraNetworkUpdate();
    message.id = object.id ?? 0;
    message.name = object.name ?? undefined;
    message.timezone = object.timezone ?? undefined;
    message.networkSettings = (object.networkSettings !== undefined && object.networkSettings !== null)
      ? CameraNetworkSettingsUpdate.fromPartial(object.networkSettings)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
