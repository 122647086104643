import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  styled,
  useTheme,
} from "@mui/material";

const StyledButton = styled(MuiButton)({
  position: "relative",
});

const StyledSpinner = styled(CircularProgress)({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  margin: "auto",
});

export default function Button({
  loading = false,
  variant = "outlined",
  ...props
}: ButtonProps & {
  loading?: boolean;
}) {
  const theme = useTheme();
  return (
    <StyledButton
      variant={variant}
      disabled={loading || props.disabled}
      {...props}
    >
      {props.children}
      {loading && <StyledSpinner size={theme.typography.button.fontSize} />}
    </StyledButton>
  );
}
