import { grpc } from "@improbable-eng/grpc-web";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import service from "@phoenix/common/service";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import CameraImageTimeline from "../../components/compound/CameraImageTimeline";
import DetectionBoxLegendIcon from "../../components/compound/DetectionBoxLegendIcon";
import ImageContextMenu, {
  ImageContextMenuState,
} from "../../components/compound/ImageContextMenu";
import PageTitle from "../../components/simple/PageTitle";
import { cameraKey } from "../../queryKeys";
import { routeTo } from "../../routes";
import useSnackbar from "../../utils/useSnackbar";

export default function CameraDialog() {
  const { t } = useTranslation(["common", "camera"]);

  const showSnackbar = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();
  const { id: stringId } = useParams<{ id: string }>();
  const id = Number.parseInt(stringId ?? "");

  const [imageContextMenuState, setImageContextMenuState] =
    useState<ImageContextMenuState | null>(null);

  const cameraQuery = useQuery(cameraKey(id), async () => {
    if (!id) {
      throw new RpcError(
        grpc.Code.InvalidArgument,
        t("camera:invalidCameraIdError")
      );
    }
    return service.cameras.getCamera(id);
  });
  const cameraName = cameraQuery.data?.info?.displayName;

  const handleClose = () => navigate(location.state?.from ?? "/");

  const handleImageContextMenu = (
    imageId: number,
    imageCoords: { x: number; y: number },
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    setImageContextMenuState({
      imageId,
      imageCoords,
      position: { left: event.clientX, top: event.clientY },
    });
  };
  const handleImageContextMenuClose = () => setImageContextMenuState(null);

  return (
    <>
      <PageTitle noText>
        {cameraName
          ? t("camera:pageTitle", { cameraName })
          : t("camera:defaultPageTitle")}
      </PageTitle>
      <Dialog open fullWidth maxWidth="lg" onClose={handleClose}>
        <Box display="flex" alignItems="baseline">
          <DialogTitle>
            {cameraName
              ? t("camera:dialogTitle", { cameraName })
              : t("camera:defaultDialogTitle")}
          </DialogTitle>
          <Box sx={{ ml: "auto" }} />
          <DetectionBoxLegendIcon />
          <IconButton
            aria-label={t("common:action.close")}
            onClick={handleClose}
            sx={{
              mr: 1,
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ px: 2, py: 1 }}>
          {!cameraQuery.data ? (
            <CircularProgress />
          ) : !cameraQuery.data.latestImageId ? (
            <Typography sx={{ padding: 8 }} align="center">
              {t("camera:noRecentImagesMessage")}
            </Typography>
          ) : (
            <>
              <CameraImageTimeline
                cameraId={id}
                reportId={null}
                sameViewOnly={false}
                sourceId={null}
                initialImageId={cameraQuery.data.latestImageId}
                onImageContextMenu={handleImageContextMenu}
                imageHeight="60vh"
                onDetectionClick={(detection) =>
                  detection.reportId
                    ? navigate(routeTo.report(detection.reportId))
                    : showSnackbar({
                        kind: "info",
                        message: t("camera:detectionNoReportMessage"),
                      })
                }
              />
              {imageContextMenuState && (
                <ImageContextMenu
                  imageId={imageContextMenuState.imageId}
                  imageCoords={imageContextMenuState.imageCoords}
                  openPosition={imageContextMenuState.position}
                  viewingReportId={null}
                  onClose={handleImageContextMenuClose}
                />
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
