import {
  ArrowLeft,
  ArrowLeftOutlined,
  ArrowRight,
  ArrowRightOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Slider, styled } from "@mui/material";
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo } from "react";

import { formatTime } from "../../utils/date";
import Button from "../simple/Button";

const labelBufferMilliseconds = 2 * 60 * 1000;

const LRButtonsContainer = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
});

const TimelineRow = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  marginBlockStart: theme.spacing(1),
  marginBlockEnd: theme.spacing(1),
  "&& > * + *": {
    marginInlineStart: theme.spacing(1),
  },
}));

const TimelineButton = styled(Button)({
  minWidth: "unset",
});

export default function ImageTimeline<T extends { takenAt: Date }>({
  children,
  sortedImages,
  currentImageIndex,
  setCurrentImageIndex,
  currentMarksRange,
  setCurrentMarksRange,
  hasPrev,
  hasNext,
  loadPrev,
  loadNext,
  loadingPrev,
  loadingNext,
  visibleRangeMinutes,
  rotateLabels,
}: {
  children: ReactNode;
  sortedImages: T[];
  currentImageIndex: number | null;
  setCurrentImageIndex: Dispatch<SetStateAction<number | null>>;
  currentMarksRange: [number, number] | null;
  setCurrentMarksRange: Dispatch<SetStateAction<[number, number] | null>>;
  hasPrev: boolean;
  hasNext: boolean;
  loadPrev: () => Promise<unknown>;
  loadNext: () => Promise<unknown>;
  loadingPrev: boolean;
  loadingNext: boolean;
  visibleRangeMinutes: number;
  rotateLabels?: boolean;
}) {
  const visibleRange = visibleRangeMinutes * 60 * 1000;

  const currentImage =
    currentImageIndex !== null ? sortedImages[currentImageIndex] : null;

  //when the currentImage changes, if currentMarksRange is null, it is
  //the first load, so set the currentMarksRange based on that image
  useEffect(() => {
    if (currentImage && currentMarksRange === null) {
      setCurrentMarksRange([
        currentImage.takenAt.getTime() - visibleRange / 2,
        currentImage.takenAt.getTime() + visibleRange / 2,
      ]);
    }
  }, [currentImage, currentMarksRange, visibleRange]);

  const marks = useMemo(() => {
    const marks = sortedImages.map((image) => ({
      label: formatTime(image.takenAt) as string | null,
      value: image.takenAt.getTime(),
    }));

    // Use an accumulating buffer to not display labels too close
    let buffer = 0;
    for (let i = 0; i < marks.length; i++) {
      if (!marks[i + 1]) {
        continue;
      }
      buffer += marks[i + 1].value - marks[i].value;
      if (buffer < labelBufferMilliseconds) {
        if (currentImage?.takenAt.getTime() !== marks[i].value) {
          marks[i].label = null;
        }
      } else {
        buffer = 0;
      }
    }
    return marks;
  }, [sortedImages, currentImage]);

  const visibleMarks = useMemo(() => {
    if (!currentMarksRange || marks.length === 0) return [];

    let startIndex = marks.reduceRight(
      (acc, current, index) =>
        current.value >= currentMarksRange[0] ? index : acc,
      marks.length - 1
    );

    let endIndex = marks.reduce(
      (acc, current, index) =>
        current.value <= currentMarksRange[1] ? index : acc,
      -1
    );
    //endIndex is exclusive
    endIndex = endIndex + 1;

    if (endIndex <= startIndex) return [];
    return marks.slice(startIndex, endIndex);
  }, [marks, currentMarksRange]);

  const handleChange = (event: unknown, value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    const newImageIndex = sortedImages.findIndex(
      (image) => image.takenAt.getTime() === newValue
    );
    if (newImageIndex === -1) {
      console.warn(`no matching image in timeline for ${newValue}`);
      return;
    }
    setCurrentImageIndex(newImageIndex);
  };

  const moveRangeLeftDisabled =
    !currentMarksRange ||
    sortedImages.length < 1 ||
    (!hasPrev && sortedImages[0].takenAt.getTime() >= currentMarksRange[0]);
  const handleRangeLeft = async () => {
    const newRange: [number, number] | null = currentMarksRange
      ? [
          currentMarksRange[0] - (2 * visibleRange) / 5,
          currentMarksRange[1] - (2 * visibleRange) / 5,
        ]
      : null;
    if (!newRange) return;
    //if the earliest loaded mark is after the new bottom range, load new earlier images
    if (marks[0]?.value > newRange[0]) {
      await loadPrev();
      // sortedImages is frozen in the closure so the range won't move after loading
      // so let's just move it a bit
      setCurrentMarksRange((oldRange) =>
        oldRange
          ? [oldRange[0] - 5 * 60 * 1000, oldRange[1] - 5 * 60 * 1000]
          : null
      );
    }
    //only move the current marks range if there are earlier images
    if (
      currentMarksRange &&
      sortedImages[0].takenAt.getTime() < currentMarksRange[0]
    ) {
      setCurrentMarksRange(newRange);
    }
  };
  const moveRangeRightDisabled =
    !currentMarksRange ||
    sortedImages.length < 1 ||
    (!hasNext &&
      sortedImages[sortedImages.length - 1].takenAt.getTime() <=
        currentMarksRange[1]);
  const handleRangeRight = async () => {
    const newRange: [number, number] | null = currentMarksRange
      ? [
          currentMarksRange[0] + (2 * visibleRange) / 5,
          currentMarksRange[1] + (2 * visibleRange) / 5,
        ]
      : null;
    if (!newRange) return;
    //if the latest loaded mark is before the new top range, load new later images
    if (marks[marks.length - 1]?.value < newRange[1]) {
      await loadNext();
      setCurrentMarksRange((oldRange) =>
        oldRange
          ? [oldRange[0] + 5 * 60 * 1000, oldRange[1] + 5 * 60 * 1000]
          : null
      );
    }
    //only move the current marks range if there are later images
    if (
      currentMarksRange &&
      sortedImages[sortedImages.length - 1].takenAt.getTime() >
        currentMarksRange[1]
    ) {
      setCurrentMarksRange(newRange);
    }
  };

  const handleImageLeft = async () => {
    if (currentImageIndex === null || !currentImage || !currentMarksRange)
      return;

    // load earlier images if we are at the start of the currently loaded ones
    // can't rely on handleRangeLeft to do this due to edge case where the current image
    // is off the side of the timeline (handleRangeLeft would just move the timeline without loading)
    if (currentImageIndex === 0) {
      await loadPrev();
    }
    // there is an unclear edge case where currentImageIndex can still be 0 after loadPrev
    if (!sortedImages[currentImageIndex - 1]) return;
    if (
      sortedImages[currentImageIndex - 1].takenAt.getTime() <
      currentMarksRange[0]
    ) {
      await handleRangeLeft();
    }
    setCurrentImageIndex((i) => {
      return i !== null ? i - 1 : null;
    });
  };
  const handleImageRight = async () => {
    if (currentImageIndex === null || !currentImage || !currentMarksRange)
      return;

    if (currentImageIndex === sortedImages.length - 1) {
      await loadNext();
    }
    // there is an unclear edge case where loadNext may not load any more images
    if (!sortedImages[currentImageIndex + 1]) return;
    if (
      sortedImages[currentImageIndex + 1].takenAt.getTime() >
      currentMarksRange[1]
    ) {
      await handleRangeRight();
    }
    setCurrentImageIndex((i) => {
      return i !== null ? i + 1 : null;
    });
  };

  return (
    <>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {children}
        <LRButtonsContainer>
          <TimelineButton
            onClick={handleImageLeft}
            loading={loadingPrev}
            disabled={
              loadingPrev ||
              (currentImageIndex === 0 && moveRangeLeftDisabled) ||
              (currentImageIndex === 0 && !hasPrev)
            }
            variant="text"
          >
            <ArrowLeftOutlined fontSize="large" />
          </TimelineButton>
          <TimelineButton
            onClick={handleImageRight}
            loading={loadingNext}
            disabled={
              loadingNext ||
              (currentImageIndex === sortedImages.length - 1 &&
                moveRangeRightDisabled) ||
              (currentImageIndex === sortedImages.length - 1 && !hasNext)
            }
            variant="text"
          >
            <ArrowRightOutlined fontSize="large" />
          </TimelineButton>
        </LRButtonsContainer>
      </Box>
      <TimelineRow>
        <TimelineButton
          loading={loadingPrev}
          onClick={handleRangeLeft}
          disabled={moveRangeLeftDisabled || loadingPrev}
          variant="text"
        >
          <ArrowLeft />
        </TimelineButton>
        <Slider
          onChange={handleChange}
          value={currentImage?.takenAt.getTime() ?? 0}
          min={currentMarksRange?.[0] ?? 0}
          max={currentMarksRange?.[1] ?? 0}
          step={null}
          marks={visibleMarks}
          sx={{
            "& .MuiSlider-markLabel": {
              transform: rotateLabels
                ? "translateX(-50%) rotate(70deg)"
                : undefined,
            },
          }}
        />
        <TimelineButton
          loading={loadingNext}
          size="small"
          onClick={handleRangeRight}
          disabled={moveRangeRightDisabled || loadingNext}
          variant="text"
        >
          <ArrowRight />
        </TimelineButton>
      </TimelineRow>
    </>
  );
}
