import "leaflet/dist/leaflet.css";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  emphasize,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@phoenix/common/components/simple/Button";
import StyledLink from "@phoenix/common/components/simple/StyledLink";
import { Empty } from "@phoenix/common/proto/google/protobuf/empty";
import service from "@phoenix/common/service";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useMutation } from "@tanstack/react-query";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Circle, GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { Link } from "react-router-dom";

import MouseZoomContainer from "../../components/compound/MouseZoomContainer";
import { routeTo } from "../../routes";
import { useMapTheme } from "../../theme";
import { ParsedMergeCandidate } from "../../utils/types";
import useInvalidateReports from "../../utils/useInvalidateReports";
import useSnackbar from "../../utils/useSnackbar";

const StyledMapContainer = styled(MapContainer)({
  width: "20vh",
  height: "20vh",
  background: "unset",
});

const CandidateTable = styled("table")({
  "& img": {
    maxWidth: "100%",
    width: "auto",
    height: "20vh",
    display: "block",
  },
  "& svg": {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  "& .imageContainer": {
    overflow: "hidden",
  },
  "& .targetReport": {
    textAlign: "right",
  },
});

export default function MergeDialog({
  source,
  open,
  onCancel,
  onMergeSuccess,
  mergeCandidates,
  onConfirm,
  confirmLoading,
}: {
  source: ParsedMergeCandidate;
  open: boolean;
  onCancel: () => void;
  onMergeSuccess: (finalReportId: number) => void;
  mergeCandidates: ParsedMergeCandidate[];
  onConfirm?: () => void;
  confirmLoading?: boolean;
}) {
  const { t } = useTranslation(["common", "report"]);
  const theme = useTheme();
  const { mapboxThemeUrl } = useMapTheme();
  const showSnackbar = useSnackbar();

  const invalidateReports = useInvalidateReports();
  const mergeInto = useMutation<Empty, RpcError, number>(
    (targetReportId: number) => {
      return service.reports.moveToReport({
        reportId: source.id,
        targetReportId,
      });
    },
    {
      onSuccess: (data, targetReportId) => {
        invalidateReports([targetReportId, source.id]);
        onMergeSuccess(targetReportId);
      },
      onError: (e) => {
        showSnackbar({ kind: "error", message: e.message });
      },
    }
  );

  return (
    <>
      <Dialog open={open} onClose={onCancel} maxWidth="xl">
        <DialogTitle sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {onConfirm
            ? t("report:MergeDialog.preconfirmDialogTitle", {
                reportId: source.id,
              })
            : t("report:MergeDialog.dialogTitle", { reportId: source.id })}
        </DialogTitle>
        <DialogContent>
          <CandidateTable>
            <thead>
              <tr>
                <td>
                  <Typography color={theme.palette.success.main} align="left">
                    {t("report:MergeDialog.thisReportColumnLabel")}
                  </Typography>
                </td>
                <td>
                  <Typography align="center">
                    {t("report:MergeDialog.mapColumnLabel")}
                  </Typography>
                </td>
                <td>
                  <Typography color={theme.palette.warning.main} align="right">
                    {t("report:MergeDialog.candidateColumnLabel")}
                  </Typography>
                </td>
              </tr>
            </thead>
            <tbody>
              {mergeCandidates.map((candidate) => {
                return (
                  <Fragment key={candidate.id}>
                    <tr>
                      <td>
                        {source.data?.$case == "image" ? (
                          <MouseZoomContainer>
                            <Box position="relative" className="imageContainer">
                              <img src={source.data.image.imageUrl} />
                              <svg
                                viewBox="0 0 1 1"
                                filter={`drop-shadow(0.5px 0.5px 0 ${emphasize(
                                  theme.palette.success.main,
                                  0.5
                                )}`}
                              >
                                {source.data.image.detections.map(
                                  (detection, idx) => (
                                    <polygon
                                      key={idx}
                                      points={detection.polygon?.points.reduce(
                                        (prev, current) =>
                                          prev === ""
                                            ? `${current.x} ${current.y}`
                                            : `${prev},${current.x} ${current.y}`,
                                        ""
                                      )}
                                      fill="none"
                                      stroke={
                                        detection.isThisReport
                                          ? theme.palette.success.main
                                          : theme.typography.caption.color
                                      }
                                      strokeWidth={
                                        detection.isThisReport ? 0.005 : 0.002
                                      }
                                    />
                                  )
                                )}
                              </svg>
                            </Box>
                          </MouseZoomContainer>
                        ) : (
                          <Box mr={2}>
                            <Typography>
                              {t(
                                "report:MergeDialog.satelliteCandidateSatellite",
                                {
                                  satelliteName:
                                    source.data?.satellite.satelliteDisplayName,
                                }
                              )}
                            </Typography>
                            {source.data?.satellite.fireRadiativePower ? (
                              <Typography>
                                {t(
                                  "report:MergeDialog.satelliteCandidateFRPLabel"
                                )}
                                <br />
                                {t(
                                  "report:MergeDialog.satelliteCandidateFRPValue",
                                  {
                                    fireRadiativePower:
                                      source.data?.satellite.fireRadiativePower,
                                  }
                                )}
                              </Typography>
                            ) : null}
                          </Box>
                        )}
                      </td>
                      <td>
                        <StyledMapContainer
                          bounds={[
                            [source.latitude - 0.2, source.longitude - 0.2],
                            [source.latitude + 0.2, source.longitude + 0.2],
                            [candidate.latitude, candidate.longitude],
                          ]}
                          boundsOptions={{ padding: [20, 20] }}
                          zoomControl={false}
                          attributionControl={false}
                        >
                          <TileLayer
                            attribution="&copy; Mapbox"
                            url={mapboxThemeUrl}
                          />
                          <GeoJSON
                            interactive={false}
                            data={source.areaGeojson}
                            style={{
                              color: theme.palette.success.main,
                              fillColor: theme.palette.success.main,
                              fill: false,
                              stroke: true,
                              weight: 1,
                            }}
                          />
                          <GeoJSON
                            interactive={false}
                            data={candidate.areaGeojson}
                            style={{
                              color: theme.palette.warning.main,
                              fillColor: theme.palette.warning.main,
                              fill: false,
                              stroke: true,
                              weight: 1,
                            }}
                          />
                          {candidate.data?.$case === "satellite" && (
                            <Circle
                              center={[candidate.latitude, candidate.longitude]}
                              radius={2000}
                              color={theme.palette.warning.main}
                              stroke={true}
                              weight={1}
                              dashArray={[1, 2]}
                              fill={false}
                            />
                          )}

                          {source.data?.$case === "satellite" && (
                            <Circle
                              center={[source.latitude, source.longitude]}
                              radius={2000}
                              color={theme.palette.success.main}
                              stroke={true}
                              weight={1}
                              dashArray={[1, 2]}
                              fill={false}
                            />
                          )}
                        </StyledMapContainer>
                      </td>
                      <td className="targetReport">
                        {candidate.data?.$case == "image" ? (
                          <Link to={routeTo.report(candidate.id)}>
                            <MouseZoomContainer disablePan>
                              <Box
                                position="relative"
                                className="imageContainer"
                              >
                                <img src={candidate.data.image.imageUrl} />
                                <svg
                                  viewBox="0 0 1 1"
                                  filter={`drop-shadow(0.5px 0.5px 0 ${emphasize(
                                    theme.palette.warning.main,
                                    0.5
                                  )}`}
                                >
                                  {candidate.data.image.detections.map(
                                    (detection, idx) => (
                                      <polygon
                                        key={idx}
                                        points={detection.polygon?.points.reduce(
                                          (prev, current) =>
                                            prev === ""
                                              ? `${current.x} ${current.y}`
                                              : `${prev},${current.x} ${current.y}`,
                                          ""
                                        )}
                                        fill="none"
                                        stroke={
                                          detection.isThisReport
                                            ? theme.palette.warning.main
                                            : theme.typography.caption.color
                                        }
                                        strokeWidth={
                                          detection.isThisReport ? 0.005 : 0.002
                                        }
                                      />
                                    )
                                  )}
                                </svg>
                              </Box>
                            </MouseZoomContainer>
                          </Link>
                        ) : (
                          <Box ml={2}>
                            <StyledLink to={routeTo.report(candidate.id)}>
                              {t("report:MergeDialog.satelliteCandidateLink")}
                            </StyledLink>
                            <Typography>
                              {t(
                                "report:MergeDialog.satelliteCandidateSatellite",
                                {
                                  satelliteName:
                                    candidate.data?.satellite
                                      .satelliteDisplayName,
                                }
                              )}
                            </Typography>
                            {candidate.data?.satellite.fireRadiativePower ? (
                              <Typography>
                                {t(
                                  "report:MergeDialog.satelliteCandidateFRPLabel"
                                )}
                                <br />
                                {t(
                                  "report:MergeDialog.satelliteCandidateFRPValue",
                                  {
                                    fireRadiativePower:
                                      candidate.data?.satellite
                                        .fireRadiativePower,
                                  }
                                )}
                              </Typography>
                            ) : null}
                          </Box>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td align="right" colSpan={3}>
                        <Button
                          onClick={() => mergeInto.mutate(candidate.id)}
                          variant="text"
                          sx={{ mb: 1 }}
                          loading={mergeInto.isLoading}
                          disabled={confirmLoading}
                        >
                          {t("report:MergeDialog.mergeIntoButtonLabel", {
                            candidateId: candidate.id,
                          })}
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </CandidateTable>
        </DialogContent>
        <DialogActions>
          {onConfirm && (
            <Button
              variant="text"
              onClick={onConfirm}
              loading={confirmLoading}
              disabled={mergeInto.isLoading}
            >
              {t("report:MergeDialog.confirmButtonLabel")}
            </Button>
          )}
          <Button variant="text" onClick={onCancel}>
            {t("common:action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
