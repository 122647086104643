import "leaflet/dist/leaflet.css";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  emphasize,
  Typography,
} from "@mui/material";
import Button from "@phoenix/common/components/simple/Button";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import { Empty } from "@phoenix/common/proto/google/protobuf/empty";
import {
  GetReportMergeTimelineRes,
  MergeTimelineItem_MergeReason,
  UndoMergeReq,
} from "@phoenix/common/proto/reports";
import { DetectionType } from "@phoenix/common/proto/types";
import service from "@phoenix/common/service";
import { formatDate } from "@phoenix/common/utils/date";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fragment, Key, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorWithIcon from "../../components/compound/ErrorWithIcon";
import {
  mergeTimelineKey,
  reportKey,
  reportPizzasKey,
  reportsKey,
} from "../../queryKeys";
import niceGrpcErrors from "../../utils/niceGrpcErrors";
import useSnackbar from "../../utils/useSnackbar";

export default function MergeTimelineDialogButton({
  reportId,
}: {
  reportId: number;
}) {
  const { t } = useTranslation(["common", "report"]);
  const snackbar = useSnackbar();

  const [open, setOpen] = useState(false);

  const mergeTimelineQuery = useQuery<GetReportMergeTimelineRes, RpcError>(
    mergeTimelineKey(reportId),
    () => service.reports.getMergeTimeline(reportId)
  );

  const queryClient = useQueryClient();
  const undoMutation = useMutation<Empty, RpcError, UndoMergeReq>(
    (req) => service.reports.undoMerge(req),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(mergeTimelineKey(req.reportId));
        queryClient.invalidateQueries(reportKey(req.reportId));
        queryClient.invalidateQueries(reportsKey("all"));
        queryClient.invalidateQueries([reportPizzasKey()[0]]);
      },
      onError: (error) => {
        snackbar({ kind: "error", message: niceGrpcErrors(error.message, t) });
      },
    }
  );

  type TimelineItem = {
    dot:
      | {
          time: Date;
          previousReportId: number;
          cameraDetectionIds: number[];
          satelliteDetectionIds: number[];
          sourceNames: string[];
        }
      | "now"
      | { start: Date };
    line?: {
      cameraDetectionIds: number[];
      satelliteDetectionIds: number[];
    };
  };

  // process the data from the api into a timeline where the dots are
  // manual merges and the lines are grouped automatic merges
  const timelineData =
    mergeTimelineQuery.status === "success"
      ? mergeTimelineQuery.data.items.length > 0
        ? [
            ...mergeTimelineQuery.data.items.reduce<TimelineItem[]>(
              (acc, curr) => {
                const cameraDetectionIds = curr.detectionIds
                  .filter(
                    (id) => id.type === DetectionType.DETECTION_TYPE_CAMERA
                  )
                  .map((id) => id.id);
                const satelliteDetectionIds = curr.detectionIds
                  .filter(
                    (id) => id.type === DetectionType.DETECTION_TYPE_SATELLITE
                  )
                  .map((id) => id.id);

                if (
                  curr.reason ===
                  MergeTimelineItem_MergeReason.MERGE_REASON_MANUAL
                ) {
                  acc.push({
                    dot: {
                      time: curr.time!,
                      previousReportId: curr.previousReportId,
                      cameraDetectionIds,
                      satelliteDetectionIds,
                      sourceNames: curr.sourceDisplayNames,
                    },
                  });
                  return acc;
                } else {
                  const currentLine = acc[acc.length - 1].line;
                  if (currentLine) {
                    currentLine.cameraDetectionIds.push(...cameraDetectionIds);
                    currentLine.satelliteDetectionIds.push(
                      ...satelliteDetectionIds
                    );
                  } else {
                    acc[acc.length - 1].line = {
                      cameraDetectionIds,
                      satelliteDetectionIds,
                    };
                  }
                  return acc;
                }
              },
              [{ dot: "now" }]
            ),
            {
              dot: {
                start:
                  mergeTimelineQuery.data.items[
                    mergeTimelineQuery.data.items.length - 1
                  ].time!,
              },
            },
          ]
        : null
      : null;

  return (
    <>
      <Button variant="text" onClick={() => setOpen(true)}>
        {t("report:MergeTimelineDialogButton.label")}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {t("report:MergeTimelineDialogButton.dialogTitle", { reportId })}
        </DialogTitle>
        <DialogContent>
          {mergeTimelineQuery.status === "loading" ? (
            <CircularProgress />
          ) : mergeTimelineQuery.status === "error" ? (
            <ErrorWithIcon
              fontSize={24}
              message={niceGrpcErrors(mergeTimelineQuery.error.message, t)}
            />
          ) : !timelineData ? (
            <ErrorWithIcon
              fontSize={24}
              message={t(
                "report:MergeTimelineDialogButton.noMergeHistoryError"
              )}
            />
          ) : (
            <>
              <Timeline>
                {timelineData.map((item, idx, arr) => {
                  const dot = item.dot;
                  return {
                    key:
                      typeof dot === "string"
                        ? dot
                        : "start" in dot
                        ? "start"
                        : dot.cameraDetectionIds.join(",") +
                          dot.satelliteDetectionIds.join(","),
                    dot: {
                      action: typeof dot !== "string" && "time" in dot && (
                        <Button
                          variant="text"
                          onClick={() =>
                            undoMutation.mutate({
                              reportId,
                              resynthesizeFollowing: true,
                              detectionIds: [
                                ...dot.cameraDetectionIds.map((id) => ({
                                  id,
                                  type: DetectionType.DETECTION_TYPE_CAMERA,
                                })),
                                ...dot.satelliteDetectionIds.map((id) => ({
                                  id,
                                  type: DetectionType.DETECTION_TYPE_SATELLITE,
                                })),
                              ],
                            })
                          }
                        >
                          {t("report:MergeTimelineDialogButton.undoButton")}
                        </Button>
                      ),
                      content:
                        dot === "now" ? (
                          <Typography variant="h2">
                            {t("report:MergeTimelineDialogButton.nowDotLabel")}
                          </Typography>
                        ) : "start" in dot ? (
                          <Typography variant="h2">
                            {t(
                              "report:MergeTimelineDialogButton.createdDotLabel",
                              {
                                timeString: formatDate(dot.start),
                              }
                            )}
                          </Typography>
                        ) : (
                          <>
                            <Typography variant="h2">
                              {formatDate(dot.time)}
                            </Typography>
                            <Typography>
                              {t("report:MergeTimelineDialogButton.dotLabel", {
                                cameraDetectionCount:
                                  dot.cameraDetectionIds.length,
                                satelliteDetectionCount:
                                  dot.satelliteDetectionIds.length,
                                previousReportId: dot.previousReportId,
                                sourceNames: dot.sourceNames.join(", "),
                              })}
                            </Typography>
                          </>
                        ),
                    },
                    lineContent: idx !== arr.length - 1 && item.line && (
                      <Typography
                        variant="caption"
                        fontSize={(theme) => theme.typography.body1.fontSize}
                      >
                        {t("report:MergeTimelineDialogButton.lineLabel", {
                          cameraDetectionCount:
                            item.line.cameraDetectionIds.length,
                          satelliteDetectionCount:
                            item.line.satelliteDetectionIds.length,
                        })}
                      </Typography>
                    ),
                  };
                })}
              </Timeline>
              {undoMutation.isLoading || mergeTimelineQuery.isFetching ? (
                <CircularProgress overlay />
              ) : null}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

function Timeline({
  children,
}: {
  children: {
    key: Key;
    dot: { action?: ReactNode; content: ReactNode };
    lineContent?: ReactNode;
  }[];
}) {
  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 24px auto"
      justifyItems="center"
      alignItems="center"
      columnGap={2}
    >
      {children.map((item, idx, arr) => (
        <Fragment key={item.key}>
          <div />
          {idx === 0 ? (
            <div />
          ) : (
            <Box
              width={0}
              height="100%"
              borderLeft={(theme) =>
                `solid 2px ${emphasize(theme.palette.primary.main, 0.4)}`
              }
            />
          )}
          <Box justifySelf="start" gridRow="span 3" my={3}>
            {item.dot.content}
          </Box>
          <div>{item.dot.action ?? null}</div>
          <Box
            width={24}
            height={24}
            borderRadius={999}
            bgcolor={(theme) => theme.palette.primary.main}
            border={(theme) =>
              `solid 2px ${emphasize(theme.palette.primary.main, 0.4)}`
            }
          />
          <div />
          {idx !== arr.length - 1 || item.lineContent ? (
            <Box
              width={0}
              height="100%"
              borderLeft={(theme) =>
                `solid 2px ${emphasize(theme.palette.primary.main, 0.4)}`
              }
            />
          ) : (
            <div />
          )}

          {idx !== arr.length - 1 || item.lineContent ? (
            <>
              <div />
              <Box
                width={0}
                height="100%"
                borderLeft={(theme) =>
                  `solid 2px ${emphasize(theme.palette.primary.main, 0.4)}`
                }
              />
              <Box justifySelf="start">{item.lineContent ?? null}</Box>
            </>
          ) : null}
        </Fragment>
      ))}
    </Box>
  );
}
