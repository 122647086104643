import { Chip, ChipProps } from "@mui/material";
import { ReviewReason } from "@phoenix/common/proto/reports";
import { ConfirmationState } from "@phoenix/common/proto/types";
import { useTranslation } from "react-i18next";

import { reviewReasons } from "../../constants";

export default function StateChip({
  state,
  reason,
}: {
  state: ConfirmationState;
  reason?: ReviewReason | string;
}) {
  const { t } = useTranslation("common");
  const color: ChipProps["color"] =
    state === ConfirmationState.CONFIRMATION_STATE_CONFIRMED
      ? "error"
      : state === ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED
      ? "default"
      : state === ConfirmationState.CONFIRMATION_STATE_REJECTED
      ? "default"
      : "warning";
  const variant: ChipProps["variant"] =
    state === ConfirmationState.CONFIRMATION_STATE_CONFIRMED
      ? "filled"
      : state === ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED
      ? "filled"
      : state === ConfirmationState.CONFIRMATION_STATE_REJECTED
      ? "outlined"
      : "filled";
  const reasonLabel = !reason
    ? null
    : typeof reason == "string"
    ? reason
        .split("_")
        .map((r) => r.charAt(0).toUpperCase() + r.substring(1))
        .join(" ")
    : reviewReasons(t).get(reason) ?? "?";
  const label =
    state === ConfirmationState.CONFIRMATION_STATE_CONFIRMED
      ? t("state.confirmed")
      : state === ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED
      ? t("state.unconfirmed")
      : state === ConfirmationState.CONFIRMATION_STATE_REJECTED
      ? reasonLabel
        ? `${t("state.rejected")} (${reasonLabel})`
        : `${t("state.rejected")}`
      : t("state.watch");

  return <Chip label={label} color={color} variant={variant} size="small" />;
}
