import {
  CreateCameraNetworkReq,
  CreateCameraReq,
  CreateUserGroupReq,
  CreateUserReq,
  UpdateCameraNetworkReq,
  UpdateCameraReq,
  UpdateUserGroupReq,
  UpdateUserReq,
} from "../proto/admin";
import { CameraNetwork } from "../proto/camera_networks";
import { DbCamera } from "../proto/db_cameras";
import { User, UserGroup } from "../proto/user";
import client from "./client";

export function resetPassword(userEmail: string) {
  return client.admin.resetPassword({ email: userEmail });
}

export async function listAPIKeys(userId?: number) {
  const res = await client.admin.listApiKeys({ userId });
  return res.apiKeys;
}

export async function createAPIKey(userId: number, expiry: Date) {
  return await client.admin.createApiKey({ userId, expiry });
}

export async function deleteAPIKey(key: string) {
  await client.admin.deleteApiKey({ key });
}

export async function getNetworks() {
  const res = await client.admin.listCameraNetworks({});
  return res.cameraNetworks;
}

export async function addOrUpdateNetworks(data: CameraNetwork[]) {
  await Promise.all(
    data.map(async (network) => {
      if (network.id < 0) {
        const req: CreateCameraNetworkReq = {
          cameraNetwork: { ...network, id: 0 },
        };
        await client.admin.createCameraNetwork(req);
      } else {
        const req: UpdateCameraNetworkReq = {
          cameraNetwork: {
            ...network,
          },
        };
        await client.admin.updateCameraNetwork(req);
      }
    })
  );
}

export async function listDbCameras() {
  const res = await client.admin.listCameras({});
  return res.cameras;
}

export async function addOrUpdateDbCameras(data: DbCamera[]) {
  await Promise.all(
    data.map(async (camera) => {
      if (camera.id < 0) {
        const req: CreateCameraReq = {
          camera: { ...camera, id: 0 },
        };
        await client.admin.createCamera(req);
      } else {
        const req: UpdateCameraReq = {
          camera: {
            id: camera.id,
            name: camera.name,
            displayName: camera.displayName,
            networkId: camera.networkId,
            model: camera.model,
            longitude: camera.longitude,
            latitude: camera.latitude,
            groundElevation: { update: camera.groundElevation },
            heightAboveGround: camera.heightAboveGround,
            displayLocation: camera.displayLocation,
            comment: camera.comment,
            externalLink: camera.externalLink,
            configuration: camera.configuration
              ? {
                  isActive: camera.configuration.isActive,
                  isHidden: camera.configuration.isHidden,
                  encrypt: { update: camera.configuration.encrypt },
                  reversePan: { update: camera.configuration.reversePan },
                  imageAttempts: { update: camera.configuration.imageAttempts },
                  archiveImages: { update: camera.configuration.archiveImages },
                  useOcrPtz: camera.configuration.useOcrPtz,
                  mightRotate: camera.configuration.mightRotate,
                  isRotating: camera.configuration.isRotating,
                  fixedPollInterval: camera.configuration.fixedPollInterval,
                  rotatingPollInterval: {
                    update: camera.configuration.rotatingPollInterval,
                  },
                  rotatingStops: { update: camera.configuration.rotatingStops },
                }
              : undefined,
            calibration: camera.calibration
              ? {
                  basePan: camera.calibration.basePan,
                  baseTilt: camera.calibration.baseTilt,
                  baseFov: camera.calibration.baseFov,
                  baseVfov: camera.calibration.baseVfov,
                  cameraTilt: { update: camera.calibration.cameraTilt },
                  tiltDirection: { update: camera.calibration.tiltDirection },
                  tx: { update: camera.calibration.tx },
                  ty: { update: camera.calibration.ty },
                  tz: { update: camera.calibration.tz },
                  kx: { update: camera.calibration.kx },
                  ky: { update: camera.calibration.ky },
                  kz: { update: camera.calibration.kz },
                  delta: { update: camera.calibration.delta },
                  panOffset: camera.calibration.panOffset,
                  tiltOffset: camera.calibration.tiltOffset,
                }
              : undefined,
          },
        };
        await client.admin.updateCamera(req);
      }
    })
  );
}

export async function getReportDetails(id: number) {
  const res = await client.admin.getReportDetails({ id });
  return res;
}

export async function listUsers(): Promise<User[]> {
  const res = await client.admin.listUsers({});
  return res.users;
}

export async function listUserGroups() {
  const res = await client.admin.listUserGroups({});
  return res.groups;
}

/** This will add the user if it has a negative id */
export async function addOrUpdateUsers(data: User[]) {
  await Promise.all(
    data.map(async (user) => {
      if (user.id < 0) {
        const req: CreateUserReq = { user: { ...user, id: 0 } };
        await client.admin.createUser(req);
      } else {
        // This splitting exists because preferredNotificationMethods
        // is a sub-message in order to allow omitting it in the update message.
        // Here we never omit it, so we just nest the preferredNoticiationMethods
        // of the user object.
        const {
          preferredNotificationMethod,
          ...userWithoutNotificationMethods
        } = user;
        const req: UpdateUserReq = {
          user: {
            ...userWithoutNotificationMethods,
            preferredNotificationMethod: {
              methods: preferredNotificationMethod,
            },
          },
        };
        await client.admin.updateUser(req);
      }
    })
  );
}

/** This will add the user group if it has a negative id */
export async function addOrUpdateUserGroups(data: UserGroup[]) {
  await Promise.all(
    data.map(async (group) => {
      if (group.id < 0) {
        const req: CreateUserGroupReq = { group: { ...group, id: 0 } };
        await client.admin.createUserGroup(req);
      } else {
        const req: UpdateUserGroupReq = { group };
        await client.admin.updateUserGroup(req);
      }
    })
  );
}
