export const userKey = ["user"];
export const cameraKey = (id: number) => ["camera", id];
export const camerasKey = (filters?: unknown) =>
  filters ? ["cameras", filters] : ["cameras"];
export const satellitesKey = ["satellites"];
export const networksKey = ["networks"];
export const reportsKey = (
  args:
    | {
        filters: unknown;
        range: [number, number];
      }
    | "all"
) => (args === "all" ? ["reports"] : ["reports", args.filters, args.range]);
export const reportPizzasKey = (filters?: unknown, range?: [number, number]) =>
  ["reportPizzas", filters, range] as const;
export const reportKey = (id: number) => ["report", id];
export const mergeCandidatesKey = (id: number) => ["mergeCandidates", id];
export const mergeTimelineKey = (reportId: number) => [
  "mergeTimelineKey",
  reportId,
];
export const imageRangeKey = ({
  centerImageId,
  sameViewOnly,
}: {
  centerImageId: number;
  sameViewOnly: boolean;
}) => ["imageRange", centerImageId, sameViewOnly];

export const notificationMethodsKey = ["notificationMethods"];

export const leverKey = ["mlLever"];

export const reverseGeocodeKey = ({
  language,
  latitude,
  longitude,
}: {
  language: string;
  latitude: number;
  longitude: number;
}) => ["reverseGeocode", language, latitude, longitude];
