/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Duration } from "./google/protobuf/duration";
import { BoolValue, DoubleValue, Int32Value, Int64Value, StringValue } from "./google/protobuf/wrappers";
import { BoolValueUpdate, DoubleValueUpdate, DurationUpdate, Int32ValueUpdate } from "./types";

export const protobufPackage = "phoenix.db_cameras.v0";

export const CameraType = {
  CAMERA_TYPE_UNKNOWN: 0,
  CAMERA_TYPE_FIXED: 1,
  CAMERA_TYPE_PTZ: 2,
  UNRECOGNIZED: -1,
} as const;

export type CameraType = typeof CameraType[keyof typeof CameraType];

export namespace CameraType {
  export type CAMERA_TYPE_UNKNOWN = typeof CameraType.CAMERA_TYPE_UNKNOWN;
  export type CAMERA_TYPE_FIXED = typeof CameraType.CAMERA_TYPE_FIXED;
  export type CAMERA_TYPE_PTZ = typeof CameraType.CAMERA_TYPE_PTZ;
  export type UNRECOGNIZED = typeof CameraType.UNRECOGNIZED;
}

export function cameraTypeFromJSON(object: any): CameraType {
  switch (object) {
    case 0:
    case "CAMERA_TYPE_UNKNOWN":
      return CameraType.CAMERA_TYPE_UNKNOWN;
    case 1:
    case "CAMERA_TYPE_FIXED":
      return CameraType.CAMERA_TYPE_FIXED;
    case 2:
    case "CAMERA_TYPE_PTZ":
      return CameraType.CAMERA_TYPE_PTZ;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CameraType.UNRECOGNIZED;
  }
}

export function cameraTypeToJSON(object: CameraType): string {
  switch (object) {
    case CameraType.CAMERA_TYPE_UNKNOWN:
      return "CAMERA_TYPE_UNKNOWN";
    case CameraType.CAMERA_TYPE_FIXED:
      return "CAMERA_TYPE_FIXED";
    case CameraType.CAMERA_TYPE_PTZ:
      return "CAMERA_TYPE_PTZ";
    case CameraType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DbCameraModel {
  name: string;
  type: CameraType;
}

export interface DbCameraConfiguration {
  isActive: boolean;
  isHidden: boolean;
  encrypt: boolean | undefined;
  reversePan: boolean | undefined;
  imageAttempts: number | undefined;
  archiveImages: boolean | undefined;
  useOcrPtz: boolean;
  mightRotate: boolean;
  isRotating: boolean;
  fixedPollInterval: Duration | undefined;
  rotatingPollInterval: Duration | undefined;
  rotatingStops: number | undefined;
}

export interface DbCameraCalibration {
  basePan: number;
  baseTilt: number;
  baseFov: number;
  baseVfov: number;
  cameraTilt: number | undefined;
  tiltDirection: number | undefined;
  tx: number | undefined;
  ty: number | undefined;
  tz: number | undefined;
  kx: number | undefined;
  ky: number | undefined;
  kz: number | undefined;
  delta: number | undefined;
  panOffset: number;
  tiltOffset: number;
}

export interface DbCamera {
  id: number;
  name: string;
  displayName: string;
  networkId: number;
  model: DbCameraModel | undefined;
  longitude: number;
  latitude: number;
  groundElevation: number | undefined;
  heightAboveGround: number;
  displayLocation: string;
  comment: string;
  externalLink: string;
  configuration: DbCameraConfiguration | undefined;
  calibration: DbCameraCalibration | undefined;
}

export interface DbCameraView {
  id: number;
  cameraId: number;
  isEphemeral: boolean;
  pan: number;
  tilt: number;
  fov: number;
  vfov: number;
  viewRangeKm: number;
}

export interface DbCameraModelUpdate {
  name: string | undefined;
  type: CameraType;
}

export interface DbCameraConfigurationUpdate {
  isActive: boolean | undefined;
  isHidden: boolean | undefined;
  encrypt: BoolValueUpdate | undefined;
  reversePan: BoolValueUpdate | undefined;
  imageAttempts: Int32ValueUpdate | undefined;
  archiveImages: BoolValueUpdate | undefined;
  useOcrPtz: boolean | undefined;
  mightRotate: boolean | undefined;
  isRotating: boolean | undefined;
  fixedPollInterval: Duration | undefined;
  rotatingPollInterval: DurationUpdate | undefined;
  rotatingStops: Int32ValueUpdate | undefined;
}

export interface DbCameraCalibrationUpdate {
  basePan: number | undefined;
  baseTilt: number | undefined;
  baseFov: number | undefined;
  baseVfov: number | undefined;
  cameraTilt: DoubleValueUpdate | undefined;
  tiltDirection: DoubleValueUpdate | undefined;
  tx: DoubleValueUpdate | undefined;
  ty: DoubleValueUpdate | undefined;
  tz: DoubleValueUpdate | undefined;
  kx: DoubleValueUpdate | undefined;
  ky: DoubleValueUpdate | undefined;
  kz: DoubleValueUpdate | undefined;
  delta: DoubleValueUpdate | undefined;
  panOffset: number | undefined;
  tiltOffset: number | undefined;
}

export interface DbCameraUpdate {
  id: number;
  name: string | undefined;
  displayName: string | undefined;
  networkId: number | undefined;
  model: DbCameraModelUpdate | undefined;
  longitude: number | undefined;
  latitude: number | undefined;
  groundElevation: DoubleValueUpdate | undefined;
  heightAboveGround: number | undefined;
  displayLocation: string | undefined;
  comment: string | undefined;
  externalLink: string | undefined;
  configuration: DbCameraConfigurationUpdate | undefined;
  calibration: DbCameraCalibrationUpdate | undefined;
}

export interface DbCameraViewUpdate {
  id: number | undefined;
  cameraId: number | undefined;
  isEphemeral: boolean | undefined;
  pan: number | undefined;
  tilt: number | undefined;
  fov: number | undefined;
  vfov: number | undefined;
  viewRangeKm: number | undefined;
}

function createBaseDbCameraModel(): DbCameraModel {
  return { name: "", type: 0 };
}

export const DbCameraModel = {
  encode(message: DbCameraModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraModel {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      type: isSet(object.type) ? cameraTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: DbCameraModel): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.type !== 0) {
      obj.type = cameraTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraModel>, I>>(base?: I): DbCameraModel {
    return DbCameraModel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraModel>, I>>(object: I): DbCameraModel {
    const message = createBaseDbCameraModel();
    message.name = object.name ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseDbCameraConfiguration(): DbCameraConfiguration {
  return {
    isActive: false,
    isHidden: false,
    encrypt: undefined,
    reversePan: undefined,
    imageAttempts: undefined,
    archiveImages: undefined,
    useOcrPtz: false,
    mightRotate: false,
    isRotating: false,
    fixedPollInterval: undefined,
    rotatingPollInterval: undefined,
    rotatingStops: undefined,
  };
}

export const DbCameraConfiguration = {
  encode(message: DbCameraConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isActive === true) {
      writer.uint32(8).bool(message.isActive);
    }
    if (message.isHidden === true) {
      writer.uint32(16).bool(message.isHidden);
    }
    if (message.encrypt !== undefined) {
      BoolValue.encode({ value: message.encrypt! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.reversePan !== undefined) {
      BoolValue.encode({ value: message.reversePan! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.imageAttempts !== undefined) {
      Int32Value.encode({ value: message.imageAttempts! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.archiveImages !== undefined) {
      BoolValue.encode({ value: message.archiveImages! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.useOcrPtz === true) {
      writer.uint32(56).bool(message.useOcrPtz);
    }
    if (message.mightRotate === true) {
      writer.uint32(64).bool(message.mightRotate);
    }
    if (message.isRotating === true) {
      writer.uint32(72).bool(message.isRotating);
    }
    if (message.fixedPollInterval !== undefined) {
      Duration.encode(message.fixedPollInterval, writer.uint32(82).fork()).ldelim();
    }
    if (message.rotatingPollInterval !== undefined) {
      Duration.encode(message.rotatingPollInterval, writer.uint32(90).fork()).ldelim();
    }
    if (message.rotatingStops !== undefined) {
      Int32Value.encode({ value: message.rotatingStops! }, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isHidden = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encrypt = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.reversePan = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageAttempts = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.archiveImages = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.useOcrPtz = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.mightRotate = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isRotating = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.fixedPollInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.rotatingPollInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.rotatingStops = Int32Value.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraConfiguration {
    return {
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
      isHidden: isSet(object.isHidden) ? globalThis.Boolean(object.isHidden) : false,
      encrypt: isSet(object.encrypt) ? Boolean(object.encrypt) : undefined,
      reversePan: isSet(object.reversePan) ? Boolean(object.reversePan) : undefined,
      imageAttempts: isSet(object.imageAttempts) ? Number(object.imageAttempts) : undefined,
      archiveImages: isSet(object.archiveImages) ? Boolean(object.archiveImages) : undefined,
      useOcrPtz: isSet(object.useOcrPtz) ? globalThis.Boolean(object.useOcrPtz) : false,
      mightRotate: isSet(object.mightRotate) ? globalThis.Boolean(object.mightRotate) : false,
      isRotating: isSet(object.isRotating) ? globalThis.Boolean(object.isRotating) : false,
      fixedPollInterval: isSet(object.fixedPollInterval) ? Duration.fromJSON(object.fixedPollInterval) : undefined,
      rotatingPollInterval: isSet(object.rotatingPollInterval)
        ? Duration.fromJSON(object.rotatingPollInterval)
        : undefined,
      rotatingStops: isSet(object.rotatingStops) ? Number(object.rotatingStops) : undefined,
    };
  },

  toJSON(message: DbCameraConfiguration): unknown {
    const obj: any = {};
    if (message.isActive === true) {
      obj.isActive = message.isActive;
    }
    if (message.isHidden === true) {
      obj.isHidden = message.isHidden;
    }
    if (message.encrypt !== undefined) {
      obj.encrypt = message.encrypt;
    }
    if (message.reversePan !== undefined) {
      obj.reversePan = message.reversePan;
    }
    if (message.imageAttempts !== undefined) {
      obj.imageAttempts = message.imageAttempts;
    }
    if (message.archiveImages !== undefined) {
      obj.archiveImages = message.archiveImages;
    }
    if (message.useOcrPtz === true) {
      obj.useOcrPtz = message.useOcrPtz;
    }
    if (message.mightRotate === true) {
      obj.mightRotate = message.mightRotate;
    }
    if (message.isRotating === true) {
      obj.isRotating = message.isRotating;
    }
    if (message.fixedPollInterval !== undefined) {
      obj.fixedPollInterval = Duration.toJSON(message.fixedPollInterval);
    }
    if (message.rotatingPollInterval !== undefined) {
      obj.rotatingPollInterval = Duration.toJSON(message.rotatingPollInterval);
    }
    if (message.rotatingStops !== undefined) {
      obj.rotatingStops = message.rotatingStops;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraConfiguration>, I>>(base?: I): DbCameraConfiguration {
    return DbCameraConfiguration.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraConfiguration>, I>>(object: I): DbCameraConfiguration {
    const message = createBaseDbCameraConfiguration();
    message.isActive = object.isActive ?? false;
    message.isHidden = object.isHidden ?? false;
    message.encrypt = object.encrypt ?? undefined;
    message.reversePan = object.reversePan ?? undefined;
    message.imageAttempts = object.imageAttempts ?? undefined;
    message.archiveImages = object.archiveImages ?? undefined;
    message.useOcrPtz = object.useOcrPtz ?? false;
    message.mightRotate = object.mightRotate ?? false;
    message.isRotating = object.isRotating ?? false;
    message.fixedPollInterval = (object.fixedPollInterval !== undefined && object.fixedPollInterval !== null)
      ? Duration.fromPartial(object.fixedPollInterval)
      : undefined;
    message.rotatingPollInterval = (object.rotatingPollInterval !== undefined && object.rotatingPollInterval !== null)
      ? Duration.fromPartial(object.rotatingPollInterval)
      : undefined;
    message.rotatingStops = object.rotatingStops ?? undefined;
    return message;
  },
};

function createBaseDbCameraCalibration(): DbCameraCalibration {
  return {
    basePan: 0,
    baseTilt: 0,
    baseFov: 0,
    baseVfov: 0,
    cameraTilt: undefined,
    tiltDirection: undefined,
    tx: undefined,
    ty: undefined,
    tz: undefined,
    kx: undefined,
    ky: undefined,
    kz: undefined,
    delta: undefined,
    panOffset: 0,
    tiltOffset: 0,
  };
}

export const DbCameraCalibration = {
  encode(message: DbCameraCalibration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.basePan !== 0) {
      writer.uint32(9).double(message.basePan);
    }
    if (message.baseTilt !== 0) {
      writer.uint32(17).double(message.baseTilt);
    }
    if (message.baseFov !== 0) {
      writer.uint32(25).double(message.baseFov);
    }
    if (message.baseVfov !== 0) {
      writer.uint32(33).double(message.baseVfov);
    }
    if (message.cameraTilt !== undefined) {
      DoubleValue.encode({ value: message.cameraTilt! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.tiltDirection !== undefined) {
      DoubleValue.encode({ value: message.tiltDirection! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.tx !== undefined) {
      DoubleValue.encode({ value: message.tx! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.ty !== undefined) {
      DoubleValue.encode({ value: message.ty! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.tz !== undefined) {
      DoubleValue.encode({ value: message.tz! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.kx !== undefined) {
      DoubleValue.encode({ value: message.kx! }, writer.uint32(82).fork()).ldelim();
    }
    if (message.ky !== undefined) {
      DoubleValue.encode({ value: message.ky! }, writer.uint32(90).fork()).ldelim();
    }
    if (message.kz !== undefined) {
      DoubleValue.encode({ value: message.kz! }, writer.uint32(98).fork()).ldelim();
    }
    if (message.delta !== undefined) {
      DoubleValue.encode({ value: message.delta! }, writer.uint32(106).fork()).ldelim();
    }
    if (message.panOffset !== 0) {
      writer.uint32(113).double(message.panOffset);
    }
    if (message.tiltOffset !== 0) {
      writer.uint32(121).double(message.tiltOffset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraCalibration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraCalibration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.basePan = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.baseTilt = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.baseFov = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.baseVfov = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cameraTilt = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tiltDirection = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tx = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ty = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tz = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.kx = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.ky = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.kz = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.delta = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.panOffset = reader.double();
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.tiltOffset = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraCalibration {
    return {
      basePan: isSet(object.basePan) ? globalThis.Number(object.basePan) : 0,
      baseTilt: isSet(object.baseTilt) ? globalThis.Number(object.baseTilt) : 0,
      baseFov: isSet(object.baseFov) ? globalThis.Number(object.baseFov) : 0,
      baseVfov: isSet(object.baseVfov) ? globalThis.Number(object.baseVfov) : 0,
      cameraTilt: isSet(object.cameraTilt) ? Number(object.cameraTilt) : undefined,
      tiltDirection: isSet(object.tiltDirection) ? Number(object.tiltDirection) : undefined,
      tx: isSet(object.tx) ? Number(object.tx) : undefined,
      ty: isSet(object.ty) ? Number(object.ty) : undefined,
      tz: isSet(object.tz) ? Number(object.tz) : undefined,
      kx: isSet(object.kx) ? Number(object.kx) : undefined,
      ky: isSet(object.ky) ? Number(object.ky) : undefined,
      kz: isSet(object.kz) ? Number(object.kz) : undefined,
      delta: isSet(object.delta) ? Number(object.delta) : undefined,
      panOffset: isSet(object.panOffset) ? globalThis.Number(object.panOffset) : 0,
      tiltOffset: isSet(object.tiltOffset) ? globalThis.Number(object.tiltOffset) : 0,
    };
  },

  toJSON(message: DbCameraCalibration): unknown {
    const obj: any = {};
    if (message.basePan !== 0) {
      obj.basePan = message.basePan;
    }
    if (message.baseTilt !== 0) {
      obj.baseTilt = message.baseTilt;
    }
    if (message.baseFov !== 0) {
      obj.baseFov = message.baseFov;
    }
    if (message.baseVfov !== 0) {
      obj.baseVfov = message.baseVfov;
    }
    if (message.cameraTilt !== undefined) {
      obj.cameraTilt = message.cameraTilt;
    }
    if (message.tiltDirection !== undefined) {
      obj.tiltDirection = message.tiltDirection;
    }
    if (message.tx !== undefined) {
      obj.tx = message.tx;
    }
    if (message.ty !== undefined) {
      obj.ty = message.ty;
    }
    if (message.tz !== undefined) {
      obj.tz = message.tz;
    }
    if (message.kx !== undefined) {
      obj.kx = message.kx;
    }
    if (message.ky !== undefined) {
      obj.ky = message.ky;
    }
    if (message.kz !== undefined) {
      obj.kz = message.kz;
    }
    if (message.delta !== undefined) {
      obj.delta = message.delta;
    }
    if (message.panOffset !== 0) {
      obj.panOffset = message.panOffset;
    }
    if (message.tiltOffset !== 0) {
      obj.tiltOffset = message.tiltOffset;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraCalibration>, I>>(base?: I): DbCameraCalibration {
    return DbCameraCalibration.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraCalibration>, I>>(object: I): DbCameraCalibration {
    const message = createBaseDbCameraCalibration();
    message.basePan = object.basePan ?? 0;
    message.baseTilt = object.baseTilt ?? 0;
    message.baseFov = object.baseFov ?? 0;
    message.baseVfov = object.baseVfov ?? 0;
    message.cameraTilt = object.cameraTilt ?? undefined;
    message.tiltDirection = object.tiltDirection ?? undefined;
    message.tx = object.tx ?? undefined;
    message.ty = object.ty ?? undefined;
    message.tz = object.tz ?? undefined;
    message.kx = object.kx ?? undefined;
    message.ky = object.ky ?? undefined;
    message.kz = object.kz ?? undefined;
    message.delta = object.delta ?? undefined;
    message.panOffset = object.panOffset ?? 0;
    message.tiltOffset = object.tiltOffset ?? 0;
    return message;
  },
};

function createBaseDbCamera(): DbCamera {
  return {
    id: 0,
    name: "",
    displayName: "",
    networkId: 0,
    model: undefined,
    longitude: 0,
    latitude: 0,
    groundElevation: undefined,
    heightAboveGround: 0,
    displayLocation: "",
    comment: "",
    externalLink: "",
    configuration: undefined,
    calibration: undefined,
  };
}

export const DbCamera = {
  encode(message: DbCamera, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.displayName !== "") {
      writer.uint32(26).string(message.displayName);
    }
    if (message.networkId !== 0) {
      writer.uint32(32).int64(message.networkId);
    }
    if (message.model !== undefined) {
      DbCameraModel.encode(message.model, writer.uint32(42).fork()).ldelim();
    }
    if (message.longitude !== 0) {
      writer.uint32(49).double(message.longitude);
    }
    if (message.latitude !== 0) {
      writer.uint32(57).double(message.latitude);
    }
    if (message.groundElevation !== undefined) {
      DoubleValue.encode({ value: message.groundElevation! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.heightAboveGround !== 0) {
      writer.uint32(73).double(message.heightAboveGround);
    }
    if (message.displayLocation !== "") {
      writer.uint32(82).string(message.displayLocation);
    }
    if (message.comment !== "") {
      writer.uint32(90).string(message.comment);
    }
    if (message.externalLink !== "") {
      writer.uint32(98).string(message.externalLink);
    }
    if (message.configuration !== undefined) {
      DbCameraConfiguration.encode(message.configuration, writer.uint32(106).fork()).ldelim();
    }
    if (message.calibration !== undefined) {
      DbCameraCalibration.encode(message.calibration, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCamera {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCamera();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.networkId = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = DbCameraModel.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.groundElevation = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.heightAboveGround = reader.double();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.displayLocation = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.externalLink = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.configuration = DbCameraConfiguration.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.calibration = DbCameraCalibration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCamera {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      networkId: isSet(object.networkId) ? globalThis.Number(object.networkId) : 0,
      model: isSet(object.model) ? DbCameraModel.fromJSON(object.model) : undefined,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      groundElevation: isSet(object.groundElevation) ? Number(object.groundElevation) : undefined,
      heightAboveGround: isSet(object.heightAboveGround) ? globalThis.Number(object.heightAboveGround) : 0,
      displayLocation: isSet(object.displayLocation) ? globalThis.String(object.displayLocation) : "",
      comment: isSet(object.comment) ? globalThis.String(object.comment) : "",
      externalLink: isSet(object.externalLink) ? globalThis.String(object.externalLink) : "",
      configuration: isSet(object.configuration) ? DbCameraConfiguration.fromJSON(object.configuration) : undefined,
      calibration: isSet(object.calibration) ? DbCameraCalibration.fromJSON(object.calibration) : undefined,
    };
  },

  toJSON(message: DbCamera): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.networkId !== 0) {
      obj.networkId = Math.round(message.networkId);
    }
    if (message.model !== undefined) {
      obj.model = DbCameraModel.toJSON(message.model);
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.groundElevation !== undefined) {
      obj.groundElevation = message.groundElevation;
    }
    if (message.heightAboveGround !== 0) {
      obj.heightAboveGround = message.heightAboveGround;
    }
    if (message.displayLocation !== "") {
      obj.displayLocation = message.displayLocation;
    }
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    if (message.externalLink !== "") {
      obj.externalLink = message.externalLink;
    }
    if (message.configuration !== undefined) {
      obj.configuration = DbCameraConfiguration.toJSON(message.configuration);
    }
    if (message.calibration !== undefined) {
      obj.calibration = DbCameraCalibration.toJSON(message.calibration);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCamera>, I>>(base?: I): DbCamera {
    return DbCamera.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCamera>, I>>(object: I): DbCamera {
    const message = createBaseDbCamera();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    message.networkId = object.networkId ?? 0;
    message.model = (object.model !== undefined && object.model !== null)
      ? DbCameraModel.fromPartial(object.model)
      : undefined;
    message.longitude = object.longitude ?? 0;
    message.latitude = object.latitude ?? 0;
    message.groundElevation = object.groundElevation ?? undefined;
    message.heightAboveGround = object.heightAboveGround ?? 0;
    message.displayLocation = object.displayLocation ?? "";
    message.comment = object.comment ?? "";
    message.externalLink = object.externalLink ?? "";
    message.configuration = (object.configuration !== undefined && object.configuration !== null)
      ? DbCameraConfiguration.fromPartial(object.configuration)
      : undefined;
    message.calibration = (object.calibration !== undefined && object.calibration !== null)
      ? DbCameraCalibration.fromPartial(object.calibration)
      : undefined;
    return message;
  },
};

function createBaseDbCameraView(): DbCameraView {
  return { id: 0, cameraId: 0, isEphemeral: false, pan: 0, tilt: 0, fov: 0, vfov: 0, viewRangeKm: 0 };
}

export const DbCameraView = {
  encode(message: DbCameraView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.cameraId !== 0) {
      writer.uint32(16).int64(message.cameraId);
    }
    if (message.isEphemeral === true) {
      writer.uint32(24).bool(message.isEphemeral);
    }
    if (message.pan !== 0) {
      writer.uint32(33).double(message.pan);
    }
    if (message.tilt !== 0) {
      writer.uint32(41).double(message.tilt);
    }
    if (message.fov !== 0) {
      writer.uint32(49).double(message.fov);
    }
    if (message.vfov !== 0) {
      writer.uint32(57).double(message.vfov);
    }
    if (message.viewRangeKm !== 0) {
      writer.uint32(64).int32(message.viewRangeKm);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cameraId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isEphemeral = reader.bool();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.pan = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.tilt = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.fov = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.vfov = reader.double();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.viewRangeKm = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraView {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      cameraId: isSet(object.cameraId) ? globalThis.Number(object.cameraId) : 0,
      isEphemeral: isSet(object.isEphemeral) ? globalThis.Boolean(object.isEphemeral) : false,
      pan: isSet(object.pan) ? globalThis.Number(object.pan) : 0,
      tilt: isSet(object.tilt) ? globalThis.Number(object.tilt) : 0,
      fov: isSet(object.fov) ? globalThis.Number(object.fov) : 0,
      vfov: isSet(object.vfov) ? globalThis.Number(object.vfov) : 0,
      viewRangeKm: isSet(object.viewRangeKm) ? globalThis.Number(object.viewRangeKm) : 0,
    };
  },

  toJSON(message: DbCameraView): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.cameraId !== 0) {
      obj.cameraId = Math.round(message.cameraId);
    }
    if (message.isEphemeral === true) {
      obj.isEphemeral = message.isEphemeral;
    }
    if (message.pan !== 0) {
      obj.pan = message.pan;
    }
    if (message.tilt !== 0) {
      obj.tilt = message.tilt;
    }
    if (message.fov !== 0) {
      obj.fov = message.fov;
    }
    if (message.vfov !== 0) {
      obj.vfov = message.vfov;
    }
    if (message.viewRangeKm !== 0) {
      obj.viewRangeKm = Math.round(message.viewRangeKm);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraView>, I>>(base?: I): DbCameraView {
    return DbCameraView.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraView>, I>>(object: I): DbCameraView {
    const message = createBaseDbCameraView();
    message.id = object.id ?? 0;
    message.cameraId = object.cameraId ?? 0;
    message.isEphemeral = object.isEphemeral ?? false;
    message.pan = object.pan ?? 0;
    message.tilt = object.tilt ?? 0;
    message.fov = object.fov ?? 0;
    message.vfov = object.vfov ?? 0;
    message.viewRangeKm = object.viewRangeKm ?? 0;
    return message;
  },
};

function createBaseDbCameraModelUpdate(): DbCameraModelUpdate {
  return { name: undefined, type: 0 };
}

export const DbCameraModelUpdate = {
  encode(message: DbCameraModelUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraModelUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraModelUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraModelUpdate {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      type: isSet(object.type) ? cameraTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: DbCameraModelUpdate): unknown {
    const obj: any = {};
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.type !== 0) {
      obj.type = cameraTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraModelUpdate>, I>>(base?: I): DbCameraModelUpdate {
    return DbCameraModelUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraModelUpdate>, I>>(object: I): DbCameraModelUpdate {
    const message = createBaseDbCameraModelUpdate();
    message.name = object.name ?? undefined;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseDbCameraConfigurationUpdate(): DbCameraConfigurationUpdate {
  return {
    isActive: undefined,
    isHidden: undefined,
    encrypt: undefined,
    reversePan: undefined,
    imageAttempts: undefined,
    archiveImages: undefined,
    useOcrPtz: undefined,
    mightRotate: undefined,
    isRotating: undefined,
    fixedPollInterval: undefined,
    rotatingPollInterval: undefined,
    rotatingStops: undefined,
  };
}

export const DbCameraConfigurationUpdate = {
  encode(message: DbCameraConfigurationUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isActive !== undefined) {
      BoolValue.encode({ value: message.isActive! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.isHidden !== undefined) {
      BoolValue.encode({ value: message.isHidden! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.encrypt !== undefined) {
      BoolValueUpdate.encode(message.encrypt, writer.uint32(26).fork()).ldelim();
    }
    if (message.reversePan !== undefined) {
      BoolValueUpdate.encode(message.reversePan, writer.uint32(34).fork()).ldelim();
    }
    if (message.imageAttempts !== undefined) {
      Int32ValueUpdate.encode(message.imageAttempts, writer.uint32(42).fork()).ldelim();
    }
    if (message.archiveImages !== undefined) {
      BoolValueUpdate.encode(message.archiveImages, writer.uint32(50).fork()).ldelim();
    }
    if (message.useOcrPtz !== undefined) {
      BoolValue.encode({ value: message.useOcrPtz! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.mightRotate !== undefined) {
      BoolValue.encode({ value: message.mightRotate! }, writer.uint32(66).fork()).ldelim();
    }
    if (message.isRotating !== undefined) {
      BoolValue.encode({ value: message.isRotating! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.fixedPollInterval !== undefined) {
      Duration.encode(message.fixedPollInterval, writer.uint32(82).fork()).ldelim();
    }
    if (message.rotatingPollInterval !== undefined) {
      DurationUpdate.encode(message.rotatingPollInterval, writer.uint32(90).fork()).ldelim();
    }
    if (message.rotatingStops !== undefined) {
      Int32ValueUpdate.encode(message.rotatingStops, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraConfigurationUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraConfigurationUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.isActive = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.isHidden = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encrypt = BoolValueUpdate.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.reversePan = BoolValueUpdate.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageAttempts = Int32ValueUpdate.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.archiveImages = BoolValueUpdate.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.useOcrPtz = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.mightRotate = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.isRotating = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.fixedPollInterval = Duration.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.rotatingPollInterval = DurationUpdate.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.rotatingStops = Int32ValueUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraConfigurationUpdate {
    return {
      isActive: isSet(object.isActive) ? Boolean(object.isActive) : undefined,
      isHidden: isSet(object.isHidden) ? Boolean(object.isHidden) : undefined,
      encrypt: isSet(object.encrypt) ? BoolValueUpdate.fromJSON(object.encrypt) : undefined,
      reversePan: isSet(object.reversePan) ? BoolValueUpdate.fromJSON(object.reversePan) : undefined,
      imageAttempts: isSet(object.imageAttempts) ? Int32ValueUpdate.fromJSON(object.imageAttempts) : undefined,
      archiveImages: isSet(object.archiveImages) ? BoolValueUpdate.fromJSON(object.archiveImages) : undefined,
      useOcrPtz: isSet(object.useOcrPtz) ? Boolean(object.useOcrPtz) : undefined,
      mightRotate: isSet(object.mightRotate) ? Boolean(object.mightRotate) : undefined,
      isRotating: isSet(object.isRotating) ? Boolean(object.isRotating) : undefined,
      fixedPollInterval: isSet(object.fixedPollInterval) ? Duration.fromJSON(object.fixedPollInterval) : undefined,
      rotatingPollInterval: isSet(object.rotatingPollInterval)
        ? DurationUpdate.fromJSON(object.rotatingPollInterval)
        : undefined,
      rotatingStops: isSet(object.rotatingStops) ? Int32ValueUpdate.fromJSON(object.rotatingStops) : undefined,
    };
  },

  toJSON(message: DbCameraConfigurationUpdate): unknown {
    const obj: any = {};
    if (message.isActive !== undefined) {
      obj.isActive = message.isActive;
    }
    if (message.isHidden !== undefined) {
      obj.isHidden = message.isHidden;
    }
    if (message.encrypt !== undefined) {
      obj.encrypt = BoolValueUpdate.toJSON(message.encrypt);
    }
    if (message.reversePan !== undefined) {
      obj.reversePan = BoolValueUpdate.toJSON(message.reversePan);
    }
    if (message.imageAttempts !== undefined) {
      obj.imageAttempts = Int32ValueUpdate.toJSON(message.imageAttempts);
    }
    if (message.archiveImages !== undefined) {
      obj.archiveImages = BoolValueUpdate.toJSON(message.archiveImages);
    }
    if (message.useOcrPtz !== undefined) {
      obj.useOcrPtz = message.useOcrPtz;
    }
    if (message.mightRotate !== undefined) {
      obj.mightRotate = message.mightRotate;
    }
    if (message.isRotating !== undefined) {
      obj.isRotating = message.isRotating;
    }
    if (message.fixedPollInterval !== undefined) {
      obj.fixedPollInterval = Duration.toJSON(message.fixedPollInterval);
    }
    if (message.rotatingPollInterval !== undefined) {
      obj.rotatingPollInterval = DurationUpdate.toJSON(message.rotatingPollInterval);
    }
    if (message.rotatingStops !== undefined) {
      obj.rotatingStops = Int32ValueUpdate.toJSON(message.rotatingStops);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraConfigurationUpdate>, I>>(base?: I): DbCameraConfigurationUpdate {
    return DbCameraConfigurationUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraConfigurationUpdate>, I>>(object: I): DbCameraConfigurationUpdate {
    const message = createBaseDbCameraConfigurationUpdate();
    message.isActive = object.isActive ?? undefined;
    message.isHidden = object.isHidden ?? undefined;
    message.encrypt = (object.encrypt !== undefined && object.encrypt !== null)
      ? BoolValueUpdate.fromPartial(object.encrypt)
      : undefined;
    message.reversePan = (object.reversePan !== undefined && object.reversePan !== null)
      ? BoolValueUpdate.fromPartial(object.reversePan)
      : undefined;
    message.imageAttempts = (object.imageAttempts !== undefined && object.imageAttempts !== null)
      ? Int32ValueUpdate.fromPartial(object.imageAttempts)
      : undefined;
    message.archiveImages = (object.archiveImages !== undefined && object.archiveImages !== null)
      ? BoolValueUpdate.fromPartial(object.archiveImages)
      : undefined;
    message.useOcrPtz = object.useOcrPtz ?? undefined;
    message.mightRotate = object.mightRotate ?? undefined;
    message.isRotating = object.isRotating ?? undefined;
    message.fixedPollInterval = (object.fixedPollInterval !== undefined && object.fixedPollInterval !== null)
      ? Duration.fromPartial(object.fixedPollInterval)
      : undefined;
    message.rotatingPollInterval = (object.rotatingPollInterval !== undefined && object.rotatingPollInterval !== null)
      ? DurationUpdate.fromPartial(object.rotatingPollInterval)
      : undefined;
    message.rotatingStops = (object.rotatingStops !== undefined && object.rotatingStops !== null)
      ? Int32ValueUpdate.fromPartial(object.rotatingStops)
      : undefined;
    return message;
  },
};

function createBaseDbCameraCalibrationUpdate(): DbCameraCalibrationUpdate {
  return {
    basePan: undefined,
    baseTilt: undefined,
    baseFov: undefined,
    baseVfov: undefined,
    cameraTilt: undefined,
    tiltDirection: undefined,
    tx: undefined,
    ty: undefined,
    tz: undefined,
    kx: undefined,
    ky: undefined,
    kz: undefined,
    delta: undefined,
    panOffset: undefined,
    tiltOffset: undefined,
  };
}

export const DbCameraCalibrationUpdate = {
  encode(message: DbCameraCalibrationUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.basePan !== undefined) {
      DoubleValue.encode({ value: message.basePan! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.baseTilt !== undefined) {
      DoubleValue.encode({ value: message.baseTilt! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.baseFov !== undefined) {
      DoubleValue.encode({ value: message.baseFov! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.baseVfov !== undefined) {
      DoubleValue.encode({ value: message.baseVfov! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.cameraTilt !== undefined) {
      DoubleValueUpdate.encode(message.cameraTilt, writer.uint32(42).fork()).ldelim();
    }
    if (message.tiltDirection !== undefined) {
      DoubleValueUpdate.encode(message.tiltDirection, writer.uint32(50).fork()).ldelim();
    }
    if (message.tx !== undefined) {
      DoubleValueUpdate.encode(message.tx, writer.uint32(58).fork()).ldelim();
    }
    if (message.ty !== undefined) {
      DoubleValueUpdate.encode(message.ty, writer.uint32(66).fork()).ldelim();
    }
    if (message.tz !== undefined) {
      DoubleValueUpdate.encode(message.tz, writer.uint32(74).fork()).ldelim();
    }
    if (message.kx !== undefined) {
      DoubleValueUpdate.encode(message.kx, writer.uint32(82).fork()).ldelim();
    }
    if (message.ky !== undefined) {
      DoubleValueUpdate.encode(message.ky, writer.uint32(90).fork()).ldelim();
    }
    if (message.kz !== undefined) {
      DoubleValueUpdate.encode(message.kz, writer.uint32(98).fork()).ldelim();
    }
    if (message.delta !== undefined) {
      DoubleValueUpdate.encode(message.delta, writer.uint32(106).fork()).ldelim();
    }
    if (message.panOffset !== undefined) {
      DoubleValue.encode({ value: message.panOffset! }, writer.uint32(114).fork()).ldelim();
    }
    if (message.tiltOffset !== undefined) {
      DoubleValue.encode({ value: message.tiltOffset! }, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraCalibrationUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraCalibrationUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.basePan = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.baseTilt = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.baseFov = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.baseVfov = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cameraTilt = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tiltDirection = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tx = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.ty = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tz = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.kx = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.ky = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.kz = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.delta = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.panOffset = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.tiltOffset = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraCalibrationUpdate {
    return {
      basePan: isSet(object.basePan) ? Number(object.basePan) : undefined,
      baseTilt: isSet(object.baseTilt) ? Number(object.baseTilt) : undefined,
      baseFov: isSet(object.baseFov) ? Number(object.baseFov) : undefined,
      baseVfov: isSet(object.baseVfov) ? Number(object.baseVfov) : undefined,
      cameraTilt: isSet(object.cameraTilt) ? DoubleValueUpdate.fromJSON(object.cameraTilt) : undefined,
      tiltDirection: isSet(object.tiltDirection) ? DoubleValueUpdate.fromJSON(object.tiltDirection) : undefined,
      tx: isSet(object.tx) ? DoubleValueUpdate.fromJSON(object.tx) : undefined,
      ty: isSet(object.ty) ? DoubleValueUpdate.fromJSON(object.ty) : undefined,
      tz: isSet(object.tz) ? DoubleValueUpdate.fromJSON(object.tz) : undefined,
      kx: isSet(object.kx) ? DoubleValueUpdate.fromJSON(object.kx) : undefined,
      ky: isSet(object.ky) ? DoubleValueUpdate.fromJSON(object.ky) : undefined,
      kz: isSet(object.kz) ? DoubleValueUpdate.fromJSON(object.kz) : undefined,
      delta: isSet(object.delta) ? DoubleValueUpdate.fromJSON(object.delta) : undefined,
      panOffset: isSet(object.panOffset) ? Number(object.panOffset) : undefined,
      tiltOffset: isSet(object.tiltOffset) ? Number(object.tiltOffset) : undefined,
    };
  },

  toJSON(message: DbCameraCalibrationUpdate): unknown {
    const obj: any = {};
    if (message.basePan !== undefined) {
      obj.basePan = message.basePan;
    }
    if (message.baseTilt !== undefined) {
      obj.baseTilt = message.baseTilt;
    }
    if (message.baseFov !== undefined) {
      obj.baseFov = message.baseFov;
    }
    if (message.baseVfov !== undefined) {
      obj.baseVfov = message.baseVfov;
    }
    if (message.cameraTilt !== undefined) {
      obj.cameraTilt = DoubleValueUpdate.toJSON(message.cameraTilt);
    }
    if (message.tiltDirection !== undefined) {
      obj.tiltDirection = DoubleValueUpdate.toJSON(message.tiltDirection);
    }
    if (message.tx !== undefined) {
      obj.tx = DoubleValueUpdate.toJSON(message.tx);
    }
    if (message.ty !== undefined) {
      obj.ty = DoubleValueUpdate.toJSON(message.ty);
    }
    if (message.tz !== undefined) {
      obj.tz = DoubleValueUpdate.toJSON(message.tz);
    }
    if (message.kx !== undefined) {
      obj.kx = DoubleValueUpdate.toJSON(message.kx);
    }
    if (message.ky !== undefined) {
      obj.ky = DoubleValueUpdate.toJSON(message.ky);
    }
    if (message.kz !== undefined) {
      obj.kz = DoubleValueUpdate.toJSON(message.kz);
    }
    if (message.delta !== undefined) {
      obj.delta = DoubleValueUpdate.toJSON(message.delta);
    }
    if (message.panOffset !== undefined) {
      obj.panOffset = message.panOffset;
    }
    if (message.tiltOffset !== undefined) {
      obj.tiltOffset = message.tiltOffset;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraCalibrationUpdate>, I>>(base?: I): DbCameraCalibrationUpdate {
    return DbCameraCalibrationUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraCalibrationUpdate>, I>>(object: I): DbCameraCalibrationUpdate {
    const message = createBaseDbCameraCalibrationUpdate();
    message.basePan = object.basePan ?? undefined;
    message.baseTilt = object.baseTilt ?? undefined;
    message.baseFov = object.baseFov ?? undefined;
    message.baseVfov = object.baseVfov ?? undefined;
    message.cameraTilt = (object.cameraTilt !== undefined && object.cameraTilt !== null)
      ? DoubleValueUpdate.fromPartial(object.cameraTilt)
      : undefined;
    message.tiltDirection = (object.tiltDirection !== undefined && object.tiltDirection !== null)
      ? DoubleValueUpdate.fromPartial(object.tiltDirection)
      : undefined;
    message.tx = (object.tx !== undefined && object.tx !== null) ? DoubleValueUpdate.fromPartial(object.tx) : undefined;
    message.ty = (object.ty !== undefined && object.ty !== null) ? DoubleValueUpdate.fromPartial(object.ty) : undefined;
    message.tz = (object.tz !== undefined && object.tz !== null) ? DoubleValueUpdate.fromPartial(object.tz) : undefined;
    message.kx = (object.kx !== undefined && object.kx !== null) ? DoubleValueUpdate.fromPartial(object.kx) : undefined;
    message.ky = (object.ky !== undefined && object.ky !== null) ? DoubleValueUpdate.fromPartial(object.ky) : undefined;
    message.kz = (object.kz !== undefined && object.kz !== null) ? DoubleValueUpdate.fromPartial(object.kz) : undefined;
    message.delta = (object.delta !== undefined && object.delta !== null)
      ? DoubleValueUpdate.fromPartial(object.delta)
      : undefined;
    message.panOffset = object.panOffset ?? undefined;
    message.tiltOffset = object.tiltOffset ?? undefined;
    return message;
  },
};

function createBaseDbCameraUpdate(): DbCameraUpdate {
  return {
    id: 0,
    name: undefined,
    displayName: undefined,
    networkId: undefined,
    model: undefined,
    longitude: undefined,
    latitude: undefined,
    groundElevation: undefined,
    heightAboveGround: undefined,
    displayLocation: undefined,
    comment: undefined,
    externalLink: undefined,
    configuration: undefined,
    calibration: undefined,
  };
}

export const DbCameraUpdate = {
  encode(message: DbCameraUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.displayName !== undefined) {
      StringValue.encode({ value: message.displayName! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.networkId !== undefined) {
      Int64Value.encode({ value: message.networkId! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.model !== undefined) {
      DbCameraModelUpdate.encode(message.model, writer.uint32(42).fork()).ldelim();
    }
    if (message.longitude !== undefined) {
      DoubleValue.encode({ value: message.longitude! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.latitude !== undefined) {
      DoubleValue.encode({ value: message.latitude! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.groundElevation !== undefined) {
      DoubleValueUpdate.encode(message.groundElevation, writer.uint32(66).fork()).ldelim();
    }
    if (message.heightAboveGround !== undefined) {
      DoubleValue.encode({ value: message.heightAboveGround! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.displayLocation !== undefined) {
      StringValue.encode({ value: message.displayLocation! }, writer.uint32(82).fork()).ldelim();
    }
    if (message.comment !== undefined) {
      StringValue.encode({ value: message.comment! }, writer.uint32(90).fork()).ldelim();
    }
    if (message.externalLink !== undefined) {
      StringValue.encode({ value: message.externalLink! }, writer.uint32(98).fork()).ldelim();
    }
    if (message.configuration !== undefined) {
      DbCameraConfigurationUpdate.encode(message.configuration, writer.uint32(106).fork()).ldelim();
    }
    if (message.calibration !== undefined) {
      DbCameraCalibrationUpdate.encode(message.calibration, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayName = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.networkId = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = DbCameraModelUpdate.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.longitude = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.latitude = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.groundElevation = DoubleValueUpdate.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.heightAboveGround = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.displayLocation = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.comment = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.externalLink = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.configuration = DbCameraConfigurationUpdate.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.calibration = DbCameraCalibrationUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraUpdate {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : undefined,
      displayName: isSet(object.displayName) ? String(object.displayName) : undefined,
      networkId: isSet(object.networkId) ? Number(object.networkId) : undefined,
      model: isSet(object.model) ? DbCameraModelUpdate.fromJSON(object.model) : undefined,
      longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
      latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
      groundElevation: isSet(object.groundElevation) ? DoubleValueUpdate.fromJSON(object.groundElevation) : undefined,
      heightAboveGround: isSet(object.heightAboveGround) ? Number(object.heightAboveGround) : undefined,
      displayLocation: isSet(object.displayLocation) ? String(object.displayLocation) : undefined,
      comment: isSet(object.comment) ? String(object.comment) : undefined,
      externalLink: isSet(object.externalLink) ? String(object.externalLink) : undefined,
      configuration: isSet(object.configuration)
        ? DbCameraConfigurationUpdate.fromJSON(object.configuration)
        : undefined,
      calibration: isSet(object.calibration) ? DbCameraCalibrationUpdate.fromJSON(object.calibration) : undefined,
    };
  },

  toJSON(message: DbCameraUpdate): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.displayName !== undefined) {
      obj.displayName = message.displayName;
    }
    if (message.networkId !== undefined) {
      obj.networkId = message.networkId;
    }
    if (message.model !== undefined) {
      obj.model = DbCameraModelUpdate.toJSON(message.model);
    }
    if (message.longitude !== undefined) {
      obj.longitude = message.longitude;
    }
    if (message.latitude !== undefined) {
      obj.latitude = message.latitude;
    }
    if (message.groundElevation !== undefined) {
      obj.groundElevation = DoubleValueUpdate.toJSON(message.groundElevation);
    }
    if (message.heightAboveGround !== undefined) {
      obj.heightAboveGround = message.heightAboveGround;
    }
    if (message.displayLocation !== undefined) {
      obj.displayLocation = message.displayLocation;
    }
    if (message.comment !== undefined) {
      obj.comment = message.comment;
    }
    if (message.externalLink !== undefined) {
      obj.externalLink = message.externalLink;
    }
    if (message.configuration !== undefined) {
      obj.configuration = DbCameraConfigurationUpdate.toJSON(message.configuration);
    }
    if (message.calibration !== undefined) {
      obj.calibration = DbCameraCalibrationUpdate.toJSON(message.calibration);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraUpdate>, I>>(base?: I): DbCameraUpdate {
    return DbCameraUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraUpdate>, I>>(object: I): DbCameraUpdate {
    const message = createBaseDbCameraUpdate();
    message.id = object.id ?? 0;
    message.name = object.name ?? undefined;
    message.displayName = object.displayName ?? undefined;
    message.networkId = object.networkId ?? undefined;
    message.model = (object.model !== undefined && object.model !== null)
      ? DbCameraModelUpdate.fromPartial(object.model)
      : undefined;
    message.longitude = object.longitude ?? undefined;
    message.latitude = object.latitude ?? undefined;
    message.groundElevation = (object.groundElevation !== undefined && object.groundElevation !== null)
      ? DoubleValueUpdate.fromPartial(object.groundElevation)
      : undefined;
    message.heightAboveGround = object.heightAboveGround ?? undefined;
    message.displayLocation = object.displayLocation ?? undefined;
    message.comment = object.comment ?? undefined;
    message.externalLink = object.externalLink ?? undefined;
    message.configuration = (object.configuration !== undefined && object.configuration !== null)
      ? DbCameraConfigurationUpdate.fromPartial(object.configuration)
      : undefined;
    message.calibration = (object.calibration !== undefined && object.calibration !== null)
      ? DbCameraCalibrationUpdate.fromPartial(object.calibration)
      : undefined;
    return message;
  },
};

function createBaseDbCameraViewUpdate(): DbCameraViewUpdate {
  return {
    id: undefined,
    cameraId: undefined,
    isEphemeral: undefined,
    pan: undefined,
    tilt: undefined,
    fov: undefined,
    vfov: undefined,
    viewRangeKm: undefined,
  };
}

export const DbCameraViewUpdate = {
  encode(message: DbCameraViewUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      Int64Value.encode({ value: message.id! }, writer.uint32(10).fork()).ldelim();
    }
    if (message.cameraId !== undefined) {
      Int64Value.encode({ value: message.cameraId! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.isEphemeral !== undefined) {
      BoolValue.encode({ value: message.isEphemeral! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.pan !== undefined) {
      DoubleValue.encode({ value: message.pan! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.tilt !== undefined) {
      DoubleValue.encode({ value: message.tilt! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.fov !== undefined) {
      DoubleValue.encode({ value: message.fov! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.vfov !== undefined) {
      DoubleValue.encode({ value: message.vfov! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.viewRangeKm !== undefined) {
      Int32Value.encode({ value: message.viewRangeKm! }, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DbCameraViewUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDbCameraViewUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cameraId = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.isEphemeral = BoolValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pan = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tilt = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fov = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.vfov = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.viewRangeKm = Int32Value.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DbCameraViewUpdate {
    return {
      id: isSet(object.id) ? Number(object.id) : undefined,
      cameraId: isSet(object.cameraId) ? Number(object.cameraId) : undefined,
      isEphemeral: isSet(object.isEphemeral) ? Boolean(object.isEphemeral) : undefined,
      pan: isSet(object.pan) ? Number(object.pan) : undefined,
      tilt: isSet(object.tilt) ? Number(object.tilt) : undefined,
      fov: isSet(object.fov) ? Number(object.fov) : undefined,
      vfov: isSet(object.vfov) ? Number(object.vfov) : undefined,
      viewRangeKm: isSet(object.viewRangeKm) ? Number(object.viewRangeKm) : undefined,
    };
  },

  toJSON(message: DbCameraViewUpdate): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = message.id;
    }
    if (message.cameraId !== undefined) {
      obj.cameraId = message.cameraId;
    }
    if (message.isEphemeral !== undefined) {
      obj.isEphemeral = message.isEphemeral;
    }
    if (message.pan !== undefined) {
      obj.pan = message.pan;
    }
    if (message.tilt !== undefined) {
      obj.tilt = message.tilt;
    }
    if (message.fov !== undefined) {
      obj.fov = message.fov;
    }
    if (message.vfov !== undefined) {
      obj.vfov = message.vfov;
    }
    if (message.viewRangeKm !== undefined) {
      obj.viewRangeKm = message.viewRangeKm;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DbCameraViewUpdate>, I>>(base?: I): DbCameraViewUpdate {
    return DbCameraViewUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DbCameraViewUpdate>, I>>(object: I): DbCameraViewUpdate {
    const message = createBaseDbCameraViewUpdate();
    message.id = object.id ?? undefined;
    message.cameraId = object.cameraId ?? undefined;
    message.isEphemeral = object.isEphemeral ?? undefined;
    message.pan = object.pan ?? undefined;
    message.tilt = object.tilt ?? undefined;
    message.fov = object.fov ?? undefined;
    message.vfov = object.vfov ?? undefined;
    message.viewRangeKm = object.viewRangeKm ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
