import { GeoJSON } from "leaflet";

import { idColorHash } from "./colorHashes";

export const reportStyle = (
  feature: GeoJSON.Feature<GeoJSON.GeometryObject, any>,
  theme: "light" | "dark"
) => {
  const color = idColorHash(feature!.id!, theme);
  // opacity fades linearly from 1 to 0.6 at 6 hours old
  const strokeOpacity = Math.max(
    0.6,
    Math.min(
      1,
      1 -
        (Date.now() -
          new Date(feature!.properties!.firstDetectionTime).getTime()) /
          1000 /
          60 /
          60 /
          15
    )
  );
  return {
    color,
    fillColor: color,
    weight: 2,
    opacity: strokeOpacity,
  };
};
