import { styled } from "@mui/material";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import Navigation from "./components/layout/Navigation/Navigation";
import { useAuthContext } from "./features/auth/AuthProvider";
import Login from "./features/auth/Login";
import Logout from "./features/auth/Logout";
import CameraDialog from "./features/camera/CameraDialog";
import NotFound from "./features/error/NotFound";
import Home from "./features/home/Home";
import ReportView from "./features/report/ReportView";
import ReportsList from "./features/reportsList/ReportsList";
import routes from "./routes";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export default function AppRoutes() {
  return (
    <SentryRoutes>
      <Route element={<AppLayout />}>
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.logout} element={<Logout />} />
        <Route
          path="/"
          element={
            <AuthenticatedPage>
              <Home sidebar={<ReportsList />} />
            </AuthenticatedPage>
          }
        />
        <Route
          path={routes.camera}
          element={
            <AuthenticatedPage>
              <Home dialog={<CameraDialog />} sidebar={<ReportsList />} />
            </AuthenticatedPage>
          }
        />
        <Route
          path={routes.report}
          element={
            <AuthenticatedPage>
              <Home sidebar={<ReportView />} />
            </AuthenticatedPage>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </SentryRoutes>
  );
}

const BaseLayout = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

function AppLayout() {
  return (
    <BaseLayout>
      <Navigation />
      <Outlet />
    </BaseLayout>
  );
}

function AuthenticatedPage({ children }: { children: ReactNode }) {
  const { t } = useTranslation("common");
  const { state, actions } = useAuthContext();
  const isAuthenticated = state.authenticated;
  useEffect(() => {
    if (!isAuthenticated) {
      actions.authError(t("unauthenticatedError"));
    }
  });

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={routes.login} state={{ from: location }} />
  );
}
