import { HelpOutlineOutlined } from "@mui/icons-material";
import { emphasize, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../../features/auth/AuthProvider";

export default function DetectionBoxLegendIcon({
  includeDashed,
}: {
  includeDashed?: boolean;
}) {
  const { t } = useTranslation("common");
  const {
    state: { scopes },
  } = useAuthContext();
  const canReview = scopes.includes("reports.review");
  return (
    <Tooltip
      title={
        <table style={{ borderSpacing: "8px 0" }}>
          <tbody>
            {canReview && (
              <>
                <tr>
                  <td>
                    <Line color="#fff" />
                  </td>
                  <td>
                    <Typography>
                      {t("components.DetectionBoxLegendIcon.unconfirmed")}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Line color="#888" />
                  </td>
                  <td>
                    <Typography>
                      {t("components.DetectionBoxLegendIcon.rejected")}
                    </Typography>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>
                <Line color="#f00" />
              </td>
              <td>
                <Typography>
                  {t("components.DetectionBoxLegendIcon.confirmed")}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Line color="#ff0" />
              </td>
              <td>
                <Typography>
                  {t("components.DetectionBoxLegendIcon.watched")}
                </Typography>
              </td>
            </tr>
            {includeDashed && (
              <tr>
                <td>
                  <Line color="#fff" dashed />
                </td>
                <td>
                  <Typography>
                    {t("components.DetectionBoxLegendIcon.notViewing")}
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      }
    >
      <IconButton>
        <HelpOutlineOutlined />
      </IconButton>
    </Tooltip>
  );
}

const Line = ({ dashed, color }: { dashed?: boolean; color: string }) => (
  <svg viewBox="0 0 24 6" width={24} style={{ verticalAlign: "middle" }}>
    <line
      x1={0}
      x2={24}
      y1={3}
      y2={3}
      stroke={color}
      strokeWidth={2}
      strokeDasharray={dashed ? 5 : undefined}
      filter={`drop-shadow(1px 1px 0 ${emphasize(color, 0.5)}`}
    />
  </svg>
);
