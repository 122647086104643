import { ArrowLeftOutlined } from "@mui/icons-material";
import { Button, Slide, styled, useMediaQuery, useTheme } from "@mui/material";
import {
  cloneElement,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

const ResponsiveListContainer = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    marginTop: "-2.8rem",
    height: "100%",
    overflowY: "auto",
    "& > * + *": {
      marginBlockStart: theme.spacing(2),
    },
  },
  [theme.breakpoints.down("md")]: {
    //use margins instead of padding so inner elements are visible to screen edge
    display: "flex",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    gap: theme.spacing(2),
    "& > *:not(.prepend)": {
      scrollSnapAlign: "center",
      minWidth: "max(min(400px, 85%), 65%)",
    },
    "& > .prepend": {
      scrollSnapAlign: "center",
    },
  },
}));

const ScrollToTopContainer = styled("div")(({ theme }) => ({
  display: "flex",
  zIndex: 10,
  [theme.breakpoints.up("md")]: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    paddingTop: 1,
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    position: "absolute",
    bottom: "50%",
    left: theme.spacing(1),
  },
}));

export default function ResponsiveList({
  children,
  prepend,
  useScrollToTop,
}: {
  children: ReactNode;
  prepend?: ReactElement;
  useScrollToTop?: boolean;
}) {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollToTopVisible, setScrollToTopVisible] = useState(
    (containerRef.current?.scrollTop ?? 0) > 200 ||
      (containerRef.current?.scrollLeft ?? 0) > 200
  );
  useEffect(() => {
    if (!useScrollToTop || !containerRef.current) return;

    const onScroll: EventListener = (event) => {
      const el = event.currentTarget as HTMLElement;
      if (el.scrollTop > 200 || el.scrollLeft > 200) {
        setScrollToTopVisible(true);
      } else {
        setScrollToTopVisible(false);
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", onScroll, {
      passive: true,
    });
    return () => container.removeEventListener("scroll", onScroll);
  }, [useScrollToTop]);

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      {useScrollToTop && (
        <Slide
          in={scrollToTopVisible}
          container={containerRef.current}
          direction={isMdDown ? "right" : "down"}
        >
          <ScrollToTopContainer>
            <Button
              variant="contained"
              onClick={scrollToTop}
              sx={{ minWidth: "unset", px: 1 }}
            >
              {isMdDown ? (
                <ArrowLeftOutlined
                  aria-label={t("components.ResponsiveList.scrollToTopButton")}
                />
              ) : (
                t("components.ResponsiveList.scrollToTopButton")
              )}
            </Button>
          </ScrollToTopContainer>
        </Slide>
      )}
      <ResponsiveListContainer ref={containerRef}>
        {prepend &&
          cloneElement(prepend, {
            className: `${prepend.props.className ?? ""} prepend`,
          })}
        {children}
      </ResponsiveListContainer>
    </>
  );
}
