import { TFunction } from "i18next";

export default function niceGrpcErrors(
  message: string,
  t: TFunction<["common"]>
) {
  if (message === "Response closed without headers")
    return t("common:errors.noConnection");
  return message;
}
