/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { Timestamp } from "./google/protobuf/timestamp";
import { Polygon } from "./types";
import { NotificationMethod, notificationMethodFromJSON, notificationMethodToJSON } from "./user";

export const protobufPackage = "phoenix.api.v0";

export interface GetUserInfoRes {
  name: string;
  email: string;
  groupName: string;
  /** Timezone is supplied as a TZ database formatted name string */
  timezone: string;
  notificationMethods: NotificationMethod[];
}

export interface TrainingImage {
  /** the database image id */
  imageId: number;
  imageHash: string;
  cameraId: number;
  cameraName: string;
  cameraNetwork: string;
  cameraType: string;
  timestamp: Date | undefined;
  latitude: number;
  longitude: number;
  pan: number;
  tilt: number;
  fov: number;
  vfov: number;
  width: number;
  height: number;
  /** wkt string of detections */
  detections: Polygon[];
}

export interface TrainingSequence {
  images: TrainingImage[];
  /** the database id of the ML incident, if any */
  incidentId: number;
  /** the database image id of the image which was first marked by ML, if any */
  firstDetectionImageId: number;
  /** the database image id of the image which was manually marked as first smoke, if any */
  firstSmokeImageId: number;
  rejectionReason: string;
}

export interface TrainingExportReq {
  pageSize: number;
  pageToken: string;
  trainingType: TrainingExportReq_TrainingType;
}

export const TrainingExportReq_TrainingType = {
  TRAINING_TYPE_UNKNOWN: 0,
  TRAINING_TYPE_CONFIRMED_FIRES: 1,
  TRAINING_TYPE_FALSE_POSITIVE: 2,
  TRAINING_TYPE_MANUAL_FIRST_SMOKE: 3,
  UNRECOGNIZED: -1,
} as const;

export type TrainingExportReq_TrainingType =
  typeof TrainingExportReq_TrainingType[keyof typeof TrainingExportReq_TrainingType];

export namespace TrainingExportReq_TrainingType {
  export type TRAINING_TYPE_UNKNOWN = typeof TrainingExportReq_TrainingType.TRAINING_TYPE_UNKNOWN;
  export type TRAINING_TYPE_CONFIRMED_FIRES = typeof TrainingExportReq_TrainingType.TRAINING_TYPE_CONFIRMED_FIRES;
  export type TRAINING_TYPE_FALSE_POSITIVE = typeof TrainingExportReq_TrainingType.TRAINING_TYPE_FALSE_POSITIVE;
  export type TRAINING_TYPE_MANUAL_FIRST_SMOKE = typeof TrainingExportReq_TrainingType.TRAINING_TYPE_MANUAL_FIRST_SMOKE;
  export type UNRECOGNIZED = typeof TrainingExportReq_TrainingType.UNRECOGNIZED;
}

export function trainingExportReq_TrainingTypeFromJSON(object: any): TrainingExportReq_TrainingType {
  switch (object) {
    case 0:
    case "TRAINING_TYPE_UNKNOWN":
      return TrainingExportReq_TrainingType.TRAINING_TYPE_UNKNOWN;
    case 1:
    case "TRAINING_TYPE_CONFIRMED_FIRES":
      return TrainingExportReq_TrainingType.TRAINING_TYPE_CONFIRMED_FIRES;
    case 2:
    case "TRAINING_TYPE_FALSE_POSITIVE":
      return TrainingExportReq_TrainingType.TRAINING_TYPE_FALSE_POSITIVE;
    case 3:
    case "TRAINING_TYPE_MANUAL_FIRST_SMOKE":
      return TrainingExportReq_TrainingType.TRAINING_TYPE_MANUAL_FIRST_SMOKE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TrainingExportReq_TrainingType.UNRECOGNIZED;
  }
}

export function trainingExportReq_TrainingTypeToJSON(object: TrainingExportReq_TrainingType): string {
  switch (object) {
    case TrainingExportReq_TrainingType.TRAINING_TYPE_UNKNOWN:
      return "TRAINING_TYPE_UNKNOWN";
    case TrainingExportReq_TrainingType.TRAINING_TYPE_CONFIRMED_FIRES:
      return "TRAINING_TYPE_CONFIRMED_FIRES";
    case TrainingExportReq_TrainingType.TRAINING_TYPE_FALSE_POSITIVE:
      return "TRAINING_TYPE_FALSE_POSITIVE";
    case TrainingExportReq_TrainingType.TRAINING_TYPE_MANUAL_FIRST_SMOKE:
      return "TRAINING_TYPE_MANUAL_FIRST_SMOKE";
    case TrainingExportReq_TrainingType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface TrainingExportRes {
  sequences: TrainingSequence[];
  nextPageToken: string;
}

export interface GetMLLeverRes {
  value: number;
}

export interface SetMLLeverReq {
  value: number;
}

function createBaseGetUserInfoRes(): GetUserInfoRes {
  return { name: "", email: "", groupName: "", timezone: "", notificationMethods: [] };
}

export const GetUserInfoRes = {
  encode(message: GetUserInfoRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.groupName !== "") {
      writer.uint32(26).string(message.groupName);
    }
    if (message.timezone !== "") {
      writer.uint32(34).string(message.timezone);
    }
    writer.uint32(42).fork();
    for (const v of message.notificationMethods) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInfoRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserInfoRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.groupName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.timezone = reader.string();
          continue;
        case 5:
          if (tag === 40) {
            message.notificationMethods.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.notificationMethods.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserInfoRes {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      groupName: isSet(object.groupName) ? globalThis.String(object.groupName) : "",
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
      notificationMethods: globalThis.Array.isArray(object?.notificationMethods)
        ? object.notificationMethods.map((e: any) => notificationMethodFromJSON(e))
        : [],
    };
  },

  toJSON(message: GetUserInfoRes): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.groupName !== "") {
      obj.groupName = message.groupName;
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    if (message.notificationMethods?.length) {
      obj.notificationMethods = message.notificationMethods.map((e) => notificationMethodToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserInfoRes>, I>>(base?: I): GetUserInfoRes {
    return GetUserInfoRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserInfoRes>, I>>(object: I): GetUserInfoRes {
    const message = createBaseGetUserInfoRes();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.groupName = object.groupName ?? "";
    message.timezone = object.timezone ?? "";
    message.notificationMethods = object.notificationMethods?.map((e) => e) || [];
    return message;
  },
};

function createBaseTrainingImage(): TrainingImage {
  return {
    imageId: 0,
    imageHash: "",
    cameraId: 0,
    cameraName: "",
    cameraNetwork: "",
    cameraType: "",
    timestamp: undefined,
    latitude: 0,
    longitude: 0,
    pan: 0,
    tilt: 0,
    fov: 0,
    vfov: 0,
    width: 0,
    height: 0,
    detections: [],
  };
}

export const TrainingImage = {
  encode(message: TrainingImage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.imageId !== 0) {
      writer.uint32(8).int64(message.imageId);
    }
    if (message.imageHash !== "") {
      writer.uint32(18).string(message.imageHash);
    }
    if (message.cameraId !== 0) {
      writer.uint32(24).int64(message.cameraId);
    }
    if (message.cameraName !== "") {
      writer.uint32(34).string(message.cameraName);
    }
    if (message.cameraNetwork !== "") {
      writer.uint32(42).string(message.cameraNetwork);
    }
    if (message.cameraType !== "") {
      writer.uint32(50).string(message.cameraType);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(58).fork()).ldelim();
    }
    if (message.latitude !== 0) {
      writer.uint32(65).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(73).double(message.longitude);
    }
    if (message.pan !== 0) {
      writer.uint32(81).double(message.pan);
    }
    if (message.tilt !== 0) {
      writer.uint32(89).double(message.tilt);
    }
    if (message.fov !== 0) {
      writer.uint32(97).double(message.fov);
    }
    if (message.vfov !== 0) {
      writer.uint32(105).double(message.vfov);
    }
    if (message.width !== 0) {
      writer.uint32(112).int32(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(120).int32(message.height);
    }
    for (const v of message.detections) {
      Polygon.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TrainingImage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainingImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.imageId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageHash = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cameraId = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cameraName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cameraNetwork = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.cameraType = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.pan = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.tilt = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.fov = reader.double();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.vfov = reader.double();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.width = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.height = reader.int32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.detections.push(Polygon.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainingImage {
    return {
      imageId: isSet(object.imageId) ? globalThis.Number(object.imageId) : 0,
      imageHash: isSet(object.imageHash) ? globalThis.String(object.imageHash) : "",
      cameraId: isSet(object.cameraId) ? globalThis.Number(object.cameraId) : 0,
      cameraName: isSet(object.cameraName) ? globalThis.String(object.cameraName) : "",
      cameraNetwork: isSet(object.cameraNetwork) ? globalThis.String(object.cameraNetwork) : "",
      cameraType: isSet(object.cameraType) ? globalThis.String(object.cameraType) : "",
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      pan: isSet(object.pan) ? globalThis.Number(object.pan) : 0,
      tilt: isSet(object.tilt) ? globalThis.Number(object.tilt) : 0,
      fov: isSet(object.fov) ? globalThis.Number(object.fov) : 0,
      vfov: isSet(object.vfov) ? globalThis.Number(object.vfov) : 0,
      width: isSet(object.width) ? globalThis.Number(object.width) : 0,
      height: isSet(object.height) ? globalThis.Number(object.height) : 0,
      detections: globalThis.Array.isArray(object?.detections)
        ? object.detections.map((e: any) => Polygon.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TrainingImage): unknown {
    const obj: any = {};
    if (message.imageId !== 0) {
      obj.imageId = Math.round(message.imageId);
    }
    if (message.imageHash !== "") {
      obj.imageHash = message.imageHash;
    }
    if (message.cameraId !== 0) {
      obj.cameraId = Math.round(message.cameraId);
    }
    if (message.cameraName !== "") {
      obj.cameraName = message.cameraName;
    }
    if (message.cameraNetwork !== "") {
      obj.cameraNetwork = message.cameraNetwork;
    }
    if (message.cameraType !== "") {
      obj.cameraType = message.cameraType;
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.pan !== 0) {
      obj.pan = message.pan;
    }
    if (message.tilt !== 0) {
      obj.tilt = message.tilt;
    }
    if (message.fov !== 0) {
      obj.fov = message.fov;
    }
    if (message.vfov !== 0) {
      obj.vfov = message.vfov;
    }
    if (message.width !== 0) {
      obj.width = Math.round(message.width);
    }
    if (message.height !== 0) {
      obj.height = Math.round(message.height);
    }
    if (message.detections?.length) {
      obj.detections = message.detections.map((e) => Polygon.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainingImage>, I>>(base?: I): TrainingImage {
    return TrainingImage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainingImage>, I>>(object: I): TrainingImage {
    const message = createBaseTrainingImage();
    message.imageId = object.imageId ?? 0;
    message.imageHash = object.imageHash ?? "";
    message.cameraId = object.cameraId ?? 0;
    message.cameraName = object.cameraName ?? "";
    message.cameraNetwork = object.cameraNetwork ?? "";
    message.cameraType = object.cameraType ?? "";
    message.timestamp = object.timestamp ?? undefined;
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.pan = object.pan ?? 0;
    message.tilt = object.tilt ?? 0;
    message.fov = object.fov ?? 0;
    message.vfov = object.vfov ?? 0;
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    message.detections = object.detections?.map((e) => Polygon.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTrainingSequence(): TrainingSequence {
  return { images: [], incidentId: 0, firstDetectionImageId: 0, firstSmokeImageId: 0, rejectionReason: "" };
}

export const TrainingSequence = {
  encode(message: TrainingSequence, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.images) {
      TrainingImage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.incidentId !== 0) {
      writer.uint32(16).int64(message.incidentId);
    }
    if (message.firstDetectionImageId !== 0) {
      writer.uint32(24).int64(message.firstDetectionImageId);
    }
    if (message.firstSmokeImageId !== 0) {
      writer.uint32(32).int64(message.firstSmokeImageId);
    }
    if (message.rejectionReason !== "") {
      writer.uint32(42).string(message.rejectionReason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TrainingSequence {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainingSequence();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.images.push(TrainingImage.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.incidentId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.firstDetectionImageId = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.firstSmokeImageId = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rejectionReason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainingSequence {
    return {
      images: globalThis.Array.isArray(object?.images) ? object.images.map((e: any) => TrainingImage.fromJSON(e)) : [],
      incidentId: isSet(object.incidentId) ? globalThis.Number(object.incidentId) : 0,
      firstDetectionImageId: isSet(object.firstDetectionImageId) ? globalThis.Number(object.firstDetectionImageId) : 0,
      firstSmokeImageId: isSet(object.firstSmokeImageId) ? globalThis.Number(object.firstSmokeImageId) : 0,
      rejectionReason: isSet(object.rejectionReason) ? globalThis.String(object.rejectionReason) : "",
    };
  },

  toJSON(message: TrainingSequence): unknown {
    const obj: any = {};
    if (message.images?.length) {
      obj.images = message.images.map((e) => TrainingImage.toJSON(e));
    }
    if (message.incidentId !== 0) {
      obj.incidentId = Math.round(message.incidentId);
    }
    if (message.firstDetectionImageId !== 0) {
      obj.firstDetectionImageId = Math.round(message.firstDetectionImageId);
    }
    if (message.firstSmokeImageId !== 0) {
      obj.firstSmokeImageId = Math.round(message.firstSmokeImageId);
    }
    if (message.rejectionReason !== "") {
      obj.rejectionReason = message.rejectionReason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainingSequence>, I>>(base?: I): TrainingSequence {
    return TrainingSequence.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainingSequence>, I>>(object: I): TrainingSequence {
    const message = createBaseTrainingSequence();
    message.images = object.images?.map((e) => TrainingImage.fromPartial(e)) || [];
    message.incidentId = object.incidentId ?? 0;
    message.firstDetectionImageId = object.firstDetectionImageId ?? 0;
    message.firstSmokeImageId = object.firstSmokeImageId ?? 0;
    message.rejectionReason = object.rejectionReason ?? "";
    return message;
  },
};

function createBaseTrainingExportReq(): TrainingExportReq {
  return { pageSize: 0, pageToken: "", trainingType: 0 };
}

export const TrainingExportReq = {
  encode(message: TrainingExportReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).uint32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.trainingType !== 0) {
      writer.uint32(24).int32(message.trainingType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TrainingExportReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainingExportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.trainingType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainingExportReq {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      trainingType: isSet(object.trainingType) ? trainingExportReq_TrainingTypeFromJSON(object.trainingType) : 0,
    };
  },

  toJSON(message: TrainingExportReq): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.trainingType !== 0) {
      obj.trainingType = trainingExportReq_TrainingTypeToJSON(message.trainingType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainingExportReq>, I>>(base?: I): TrainingExportReq {
    return TrainingExportReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainingExportReq>, I>>(object: I): TrainingExportReq {
    const message = createBaseTrainingExportReq();
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.trainingType = object.trainingType ?? 0;
    return message;
  },
};

function createBaseTrainingExportRes(): TrainingExportRes {
  return { sequences: [], nextPageToken: "" };
}

export const TrainingExportRes = {
  encode(message: TrainingExportRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sequences) {
      TrainingSequence.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TrainingExportRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrainingExportRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sequences.push(TrainingSequence.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TrainingExportRes {
    return {
      sequences: globalThis.Array.isArray(object?.sequences)
        ? object.sequences.map((e: any) => TrainingSequence.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: TrainingExportRes): unknown {
    const obj: any = {};
    if (message.sequences?.length) {
      obj.sequences = message.sequences.map((e) => TrainingSequence.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TrainingExportRes>, I>>(base?: I): TrainingExportRes {
    return TrainingExportRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TrainingExportRes>, I>>(object: I): TrainingExportRes {
    const message = createBaseTrainingExportRes();
    message.sequences = object.sequences?.map((e) => TrainingSequence.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseGetMLLeverRes(): GetMLLeverRes {
  return { value: 0 };
}

export const GetMLLeverRes = {
  encode(message: GetMLLeverRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMLLeverRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMLLeverRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMLLeverRes {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: GetMLLeverRes): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMLLeverRes>, I>>(base?: I): GetMLLeverRes {
    return GetMLLeverRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMLLeverRes>, I>>(object: I): GetMLLeverRes {
    const message = createBaseGetMLLeverRes();
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseSetMLLeverReq(): SetMLLeverReq {
  return { value: 0 };
}

export const SetMLLeverReq = {
  encode(message: SetMLLeverReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== 0) {
      writer.uint32(9).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetMLLeverReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetMLLeverReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetMLLeverReq {
    return { value: isSet(object.value) ? globalThis.Number(object.value) : 0 };
  },

  toJSON(message: SetMLLeverReq): unknown {
    const obj: any = {};
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetMLLeverReq>, I>>(base?: I): SetMLLeverReq {
    return SetMLLeverReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetMLLeverReq>, I>>(object: I): SetMLLeverReq {
    const message = createBaseSetMLLeverReq();
    message.value = object.value ?? 0;
    return message;
  },
};

export interface API {
  /** Get information about the logged-in user */
  getUserInfo(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetUserInfoRes>;
  /** Export data for ML Training. Restricted to admin use only */
  trainingExport(request: DeepPartial<TrainingExportReq>, metadata?: grpc.Metadata): Promise<TrainingExportRes>;
  getMlLever(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetMLLeverRes>;
  setMlLever(request: DeepPartial<SetMLLeverReq>, metadata?: grpc.Metadata): Promise<Empty>;
}

export class APIClientImpl implements API {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.getUserInfo = this.getUserInfo.bind(this);
    this.trainingExport = this.trainingExport.bind(this);
    this.getMlLever = this.getMlLever.bind(this);
    this.setMlLever = this.setMlLever.bind(this);
  }

  getUserInfo(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetUserInfoRes> {
    return this.rpc.unary(APIGetUserInfoDesc, Empty.fromPartial(request), metadata);
  }

  trainingExport(request: DeepPartial<TrainingExportReq>, metadata?: grpc.Metadata): Promise<TrainingExportRes> {
    return this.rpc.unary(APITrainingExportDesc, TrainingExportReq.fromPartial(request), metadata);
  }

  getMlLever(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetMLLeverRes> {
    return this.rpc.unary(APIGetMLLeverDesc, Empty.fromPartial(request), metadata);
  }

  setMlLever(request: DeepPartial<SetMLLeverReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(APISetMLLeverDesc, SetMLLeverReq.fromPartial(request), metadata);
  }
}

export const APIDesc = { serviceName: "phoenix.api.v0.API" };

export const APIGetUserInfoDesc: UnaryMethodDefinitionish = {
  methodName: "GetUserInfo",
  service: APIDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserInfoRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const APITrainingExportDesc: UnaryMethodDefinitionish = {
  methodName: "TrainingExport",
  service: APIDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TrainingExportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TrainingExportRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const APIGetMLLeverDesc: UnaryMethodDefinitionish = {
  methodName: "GetMLLever",
  service: APIDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetMLLeverRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const APISetMLLeverDesc: UnaryMethodDefinitionish = {
  methodName: "SetMLLever",
  service: APIDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetMLLeverReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
