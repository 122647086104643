import { emphasize, styled } from "@mui/material";
import { ImageDetection } from "@phoenix/common/proto/detections";
import { ConfirmationState, Point } from "@phoenix/common/proto/types";
import { MouseEvent, SVGProps } from "react";

const SvgOverlay = styled("svg")({
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
});

const ClickablePolygonGroup = styled("g", {
  shouldForwardProp: (key) => key !== "isClickable",
})<{ isClickable: boolean }>(({ isClickable }) =>
  isClickable
    ? {
        "& > a + polygon": {
          strokeWidth: 3,
        },
        "& > a:hover + polygon": {
          strokeWidth: 6,
        },
      }
    : {
        "& > polygon": {
          strokeWidth: 4,
        },
      }
);

export default function DetectionsOverlay({
  detections,
  reportId,
  viewboxWidth,
  viewboxHeight,
  onDetectionClick,
  onContextMenu,
}: {
  detections: ImageDetection[];
  reportId: number | null;
  viewboxWidth: number;
  viewboxHeight: number;
  onDetectionClick?: (detection: ImageDetection, event: MouseEvent) => void;
  onContextMenu?: SVGProps<SVGSVGElement>["onContextMenu"];
}) {
  return (
    <SvgOverlay
      viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}
      onContextMenu={onContextMenu}
    >
      {detections.map((detection, index) => {
        const pointsString = detection.points.reduce(
          (prev, current) =>
            prev === ""
              ? `${current.x * viewboxWidth} ${current.y * viewboxHeight}`
              : `${prev},${current.x * viewboxWidth} ${
                  current.y * viewboxHeight
                }`,
          ""
        );

        const style = getDetectionStyle(detection, reportId);
        const textCoordinates = getTextCoordinates(
          detection.points,
          viewboxWidth,
          viewboxHeight
        );
        if (!textCoordinates) return null;

        // NOTE: Css relies on order of children (see ClickablePolygonGroup)
        // First polygon inside <a> is invisible (for ease of click)
        // Second is for display
        return (
          <ClickablePolygonGroup key={index} isClickable={!!onDetectionClick}>
            {onDetectionClick && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="#"
                onClick={(event) => onDetectionClick(detection, event)}
              >
                <polygon
                  points={pointsString}
                  fill="none"
                  strokeWidth={32}
                  pointerEvents="stroke"
                  visibility="hidden"
                />
              </a>
            )}
            <polygon
              points={pointsString}
              fill="none"
              stroke={style.color}
              strokeDasharray={style.lineStyle === "dashed" ? 8 : undefined}
              filter={`drop-shadow(1px 1px 0 ${emphasize(style.color, 0.5)}`}
              pointerEvents="none"
            />
            {!!detection.confidence && (
              <text
                x={textCoordinates.x}
                y={textCoordinates.y}
                fill={style.color}
                fontSize={24}
                filter={`drop-shadow(1px 1px 0 ${emphasize(style.color, 0.5)}`}
              >{`${detection.confidence.toFixed(2)}`}</text>
            )}
          </ClickablePolygonGroup>
        );
      })}
    </SvgOverlay>
  );
}

function getDetectionStyle(detection: ImageDetection, reportId: number | null) {
  let color: string | undefined;
  if (detection.state === ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED) {
    color = "#fff";
  } else if (
    detection.state === ConfirmationState.CONFIRMATION_STATE_REJECTED
  ) {
    color = "#888";
  } else if (
    detection.state === ConfirmationState.CONFIRMATION_STATE_CONFIRMED
  ) {
    color = "#f00";
  } else if (detection.state === ConfirmationState.CONFIRMATION_STATE_WATCH) {
    color = "#ff0";
  }
  if (!color) color = "#fff";
  return {
    color,
    lineStyle:
      detection.reportId === reportId || !reportId ? "solid" : "dashed",
  } as const;
}

function getTextCoordinates(
  points: Point[],
  viewboxWidth: number,
  viewboxHeight: number
) {
  //get coordinates for text - the smallest x and y value of the polygon + padding
  const x =
    (points.reduce<number | null>(
      (prev, current) => (!prev ? current.x : Math.min(current.x, prev)),
      null
    ) ?? -Infinity) * viewboxWidth;
  const y =
    (points.reduce<number | null>(
      (prev, current) => (!prev ? current.y : Math.min(current.y, prev)),
      null
    ) ?? -Infinity) *
      viewboxHeight -
    8;

  if (x === -Infinity || y === -Infinity) return null;
  return { x, y };
}
