import { Box } from "@mui/material";
import {
  Children,
  isValidElement,
  MouseEvent,
  ReactNode,
  useState,
} from "react";

export default function MouseZoomContainer({
  maxZoom = 7,
  disablePan,
  children,
}: {
  maxZoom?: number;
  disablePan?: boolean;
  children: ReactNode;
}) {
  const [zoom, setZoom] = useState(1);
  const [zoomOrigin, setZoomOrigin] = useState<[number, number] | null>(null);
  // Note: offsetX/Y may be buggy in older browsers.
  // useRef and getBoundClientRect can be used if it causes problems for clients.
  const handleMouseMove = (event: MouseEvent) => {
    if (zoom === 1 || !(event.buttons & 1)) return;
    event.preventDefault();
    const currentTarget = event.currentTarget;
    setZoomOrigin((prev) => {
      if (!prev) return null;
      return [
        prev[0] - (event.movementX / currentTarget.clientWidth / zoom) * 100,
        prev[1] - (event.movementY / currentTarget.clientHeight / zoom) * 100,
      ];
    });
  };
  const handleWheel: React.WheelEventHandler<HTMLElement> = (event) => {
    const newZoom =
      event.deltaY < 0 && zoom < maxZoom
        ? zoom * 1.5
        : event.deltaY > 0 && zoom > 1
        ? zoom / 1.5
        : zoom;
    if (newZoom === zoom) return;

    setZoom(newZoom);
    setZoomOrigin([
      (event.nativeEvent.offsetX / event.currentTarget.clientWidth) * 100,
      (event.nativeEvent.offsetY / event.currentTarget.clientHeight) * 100,
    ]);
  };

  return (
    <Box display="flex" sx={{ userSelect: "none" }}>
      {Children.map(children, (child, i) => {
        if (!isValidElement(child)) return;
        if (child.props["data-no-zoom"]) return child;
        return (
          <Box
            key={i}
            overflow="hidden"
            onMouseMove={disablePan ? undefined : handleMouseMove}
            onWheel={handleWheel}
            sx={
              zoom !== 1 && !disablePan
                ? {
                    "&:hover": { cursor: "grab" },
                    "&:active": { cursor: "grabbing" },
                  }
                : undefined
            }
          >
            <div
              style={{
                transform: zoomOrigin ? `scale(${zoom})` : undefined,
                transformOrigin: zoomOrigin
                  ? `${zoomOrigin[0]}% ${zoomOrigin[1]}%`
                  : undefined,
              }}
            >
              {child}
            </div>
          </Box>
        );
      })}
    </Box>
  );
}
