import {
  ListItem,
  Menu,
  MenuItem,
  PopoverPosition,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import { ImageDetection } from "@phoenix/common/proto/detections";
import { Empty } from "@phoenix/common/proto/google/protobuf/empty";
import { ReviewReason } from "@phoenix/common/proto/reports";
import { ConfirmationState } from "@phoenix/common/proto/types";
import service from "@phoenix/common/service";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { reviewReasons } from "../../constants";
import { useAuthContext } from "../../features/auth/AuthProvider";
import { routeTo } from "../../routes";
import useInvalidateReports from "../../utils/useInvalidateReports";
import useSnackbar from "../../utils/useSnackbar";

export default function DetectionBoxMenu({
  detection,
  viewingReportId,
  openPosition,
  onClose,
}: {
  detection: ImageDetection;
  viewingReportId: number | null;
  openPosition: PopoverPosition | null;
  onClose: () => void;
}) {
  const { t } = useTranslation("common");
  const {
    state: { scopes },
  } = useAuthContext();
  const navigate = useNavigate();

  const [rejectMenuAnchor, setRejectMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const handleRejectMenuClose = () => setRejectMenuAnchor(null);

  const goToReport = () => {
    onClose();
    navigate(routeTo.report(detection.reportId));
  };

  const canMerge = scopes.includes("reports.merging");

  const invalidateReports = useInvalidateReports();
  const onSuccess = async (data: { newReportId: number } | Empty) => {
    invalidateReports([detection.reportId, viewingReportId ?? 0]);
    onClose();
    if ("newReportId" in data) {
      navigate(routeTo.report(data.newReportId));
    }
  };

  const showSnackbar = useSnackbar();
  const onError = (e: RpcError) => {
    showSnackbar({ kind: "error", message: e.message });
  };

  const moveToNewReport = useMutation(
    () =>
      service.reports.splitReport({
        detectionIds: [detection.id],
        satelliteDetectionIds: [],
      }),
    { onError, onSuccess }
  );
  const moveToNewReportAndReject = useMutation(
    async (reason: ReviewReason) => {
      const { newReportId } = await service.reports.splitReport({
        detectionIds: [detection.id],
        satelliteDetectionIds: [],
      });
      await service.reports.reviewReport({
        reportId: newReportId,
        newState: ConfirmationState.CONFIRMATION_STATE_REJECTED,
        reason,
      });
      return {};
    },
    { onError, onSuccess }
  );
  const moveToCurrentReport = useMutation(
    () =>
      service.reports.moveToReport({
        detectionId: detection.id,
        targetReportId: viewingReportId!,
      }),
    { onError, onSuccess }
  );
  const mergeCurrentReportIntoSelectedDetection = useMutation(
    () =>
      service.reports.moveToReport({
        reportId: viewingReportId!,
        targetReportId: detection.reportId,
      }),
    {
      onError,
      onSuccess: (data) => {
        onSuccess(data);
        navigate(routeTo.report(detection.reportId));
      },
    }
  );

  const anyLoading =
    moveToNewReport.isLoading ||
    moveToNewReportAndReject.isLoading ||
    moveToCurrentReport.isLoading ||
    mergeCurrentReportIntoSelectedDetection.isLoading;

  return (
    <>
      <Menu
        open={!!openPosition}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={openPosition ?? undefined}
      >
        {canMerge && (
          <ListItem>
            <Typography variant="caption">
              {detection.id
                ? t("components.DetectionBoxMenu.title", {
                    detectionId: detection.id,
                  })
                : t("components.DetectionBoxMenu.persistedDetectionTitle")}
            </Typography>
            <Box ml={1} />
            {anyLoading && <CircularProgress size={18} />}
          </ListItem>
        )}
        {!detection.reportId
          ? [
              <ListItem key={1}>
                {t("components.DetectionBoxMenu.noReport")}
              </ListItem>,
              detection.id ? (
                <MenuItem
                  onClick={() => moveToCurrentReport.mutate()}
                  disabled={anyLoading}
                  key={2}
                >
                  {t("components.DetectionBoxMenu.moveToCurrentReport")}
                </MenuItem>
              ) : null,
            ]
          : detection.reportId === viewingReportId
          ? [
              <ListItem key={1}>
                {t("components.DetectionBoxMenu.alreadyViewingReport", {
                  reportId: viewingReportId,
                })}
              </ListItem>,
              canMerge && detection.id ? (
                <MenuItem
                  onClick={() => moveToNewReport.mutate()}
                  disabled={anyLoading}
                  key={2}
                >
                  {t("components.DetectionBoxMenu.moveToNewReport")}
                </MenuItem>
              ) : null,
              canMerge && detection.id ? (
                <MenuItem
                  onClick={(e) => setRejectMenuAnchor(e.currentTarget)}
                  disabled={anyLoading}
                  key={3}
                >
                  {t("components.DetectionBoxMenu.moveToNewReportAndReject")}
                </MenuItem>
              ) : null,
            ]
          : [
              <MenuItem onClick={goToReport} disabled={anyLoading} key={1}>
                {t("components.DetectionBoxMenu.reportId", {
                  reportId: detection.reportId,
                })}
              </MenuItem>,
              canMerge && detection.id ? (
                detection.state ===
                ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED ? (
                  <MenuItem
                    onClick={() => moveToCurrentReport.mutate()}
                    disabled={anyLoading}
                    key={2}
                  >
                    {t("components.DetectionBoxMenu.moveToCurrentReport")}
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() =>
                      mergeCurrentReportIntoSelectedDetection.mutate()
                    }
                    disabled={anyLoading}
                    key={2}
                  >
                    {t(
                      "components.DetectionBoxMenu.mergeCurrentReportIntoSelectedDetection"
                    )}
                  </MenuItem>
                )
              ) : null,
            ]}
      </Menu>
      <Menu
        open={!!rejectMenuAnchor}
        anchorEl={rejectMenuAnchor}
        onClose={handleRejectMenuClose}
      >
        {Array.from(reviewReasons(t).entries()).map(([value, label]) => (
          <MenuItem
            key={value}
            onClick={() => {
              moveToNewReportAndReject.mutate(value);
              handleRejectMenuClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
