import { Theme } from "@mui/material";
import { ReviewReason } from "@phoenix/common/proto/reports";
import { ConfidenceLevel } from "@phoenix/common/proto/satellites";
import { ConfirmationState } from "@phoenix/common/proto/types";
import { TFunction } from "i18next";

import { themes } from "./theme";

export const navWidth = 200;

export const confirmationStates = (t: TFunction<["common"]>) =>
  new Map<ConfirmationState, string>([
    [
      ConfirmationState.CONFIRMATION_STATE_CONFIRMED,
      t("common:state.confirmed"),
    ],
    [
      ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED,
      t("common:state.unconfirmed"),
    ],
    [ConfirmationState.CONFIRMATION_STATE_REJECTED, t("common:state.rejected")],
    [ConfirmationState.CONFIRMATION_STATE_WATCH, t("common:state.watch")],
  ]);

export const reviewReasons = (t: TFunction<"common">) =>
  new Map<ReviewReason, string>([
    [ReviewReason.REVIEW_REASON_CLOUD, t("ReviewReason.cloud")],
    [ReviewReason.REVIEW_REASON_BLUR, t("ReviewReason.blur")],
    [ReviewReason.REVIEW_REASON_FOG, t("ReviewReason.fog")],
    [ReviewReason.REVIEW_REASON_SMOG, t("ReviewReason.smog")],
    [ReviewReason.REVIEW_REASON_DOME, t("ReviewReason.dome")],
    [ReviewReason.REVIEW_REASON_GROUND, t("ReviewReason.ground")],
    [ReviewReason.REVIEW_REASON_SNOW, t("ReviewReason.snow")],
    [ReviewReason.REVIEW_REASON_SKY, t("ReviewReason.sky")],
    [ReviewReason.REVIEW_REASON_OBSTRUCTION, t("ReviewReason.obstruction")],
    [ReviewReason.REVIEW_REASON_RAIN, t("ReviewReason.rain")],
    [ReviewReason.REVIEW_REASON_GLARE, t("ReviewReason.glare")],
    [ReviewReason.REVIEW_REASON_NIGHT, t("ReviewReason.night")],
    [ReviewReason.REVIEW_REASON_INDUSTRIAL, t("ReviewReason.industrial")],
    [ReviewReason.REVIEW_REASON_OTHER, t("ReviewReason.other")],
    [ReviewReason.REVIEW_REASON_KNOWN_FIRE, t("ReviewReason.knownFire")],
  ]);

export const confidenceLevels = (t: TFunction<["common"]>) =>
  new Map<ConfidenceLevel, string>([
    [ConfidenceLevel.CONFIDENCE_LEVEL_LOW, t("common:ConfidenceLevel.low")],
    [
      ConfidenceLevel.CONFIDENCE_LEVEL_MEDIUM,
      t("common:ConfidenceLevel.medium"),
    ],
    [ConfidenceLevel.CONFIDENCE_LEVEL_HIGH, t("common:ConfidenceLevel.high")],
  ]);
export const confidenceLevelColors = (theme: Theme) =>
  new Map<ConfidenceLevel, string>([
    [ConfidenceLevel.CONFIDENCE_LEVEL_LOW, theme.palette.info.main],
    [ConfidenceLevel.CONFIDENCE_LEVEL_MEDIUM, theme.palette.warning.main],
    [ConfidenceLevel.CONFIDENCE_LEVEL_HIGH, theme.palette.error.main],
  ]);

export const themeLabels = (t: TFunction<"common">) =>
  new Map<keyof typeof themes, string>([
    ["dark", t("themes.dark")],
    ["outdoor", t("themes.outdoor")],
  ]);
