import { Menu, MenuItem, PopoverPosition } from "@mui/material";
import ConfirmationDialogWrapper from "@phoenix/common/components/compound/ConfirmationDialogWrapper";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import { CreateManualDetectionRes } from "@phoenix/common/proto/detections";
import service from "@phoenix/common/service";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RpcError } from "grpc-web";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../../features/auth/AuthProvider";
import { useReportsQueryOptions } from "../../features/reportsList/useReportsQuery";
import { imageRangeKey, reportKey, reportPizzasKey } from "../../queryKeys";
import useSnackbar from "../../utils/useSnackbar";

export type ImageContextMenuState = {
  imageId: number;
  position: PopoverPosition;
  imageCoords: { x: number; y: number };
};

export default function ImageContextMenu({
  imageId,
  imageCoords,
  viewingReportId,
  openPosition,
  onClose,
}: {
  imageId: number;
  imageCoords: { x: number; y: number };
  viewingReportId: number | null;
  openPosition: PopoverPosition | null;
  onClose: () => void;
}) {
  const { t } = useTranslation("common");
  const {
    state: { scopes },
  } = useAuthContext();
  const canCreateManualDetection = scopes.includes(
    "detections.create_manual_detection"
  );

  const showSnackbar = useSnackbar();

  const queryClient = useQueryClient();
  const { queryKey: reportsListQueryKey } = useReportsQueryOptions();

  const createManualDetection = useMutation<
    CreateManualDetectionRes,
    RpcError,
    number | null
  >(
    (reportId: number | null) => {
      const polygon = [
        [-0.01, -0.01],
        [0.01, -0.01],
        [0.01, 0.01],
        [-0.01, 0.01],
      ].map<[number, number]>((diff) => [
        Math.min(1, Math.max(0, imageCoords.x + diff[0])),
        Math.min(1, Math.max(0, imageCoords.y + diff[1])),
      ]);
      return service.detections.createManualDetection(
        imageId,
        polygon,
        reportId
      );
    },
    {
      onSuccess: (data) => {
        if (data.reportId) {
          queryClient.invalidateQueries(reportKey(data.reportId));
        }
        queryClient.invalidateQueries([reportPizzasKey()[0]]);

        queryClient.invalidateQueries([
          imageRangeKey({
            sameViewOnly: true,
            centerImageId: 1,
          })[0],
        ]);
        queryClient.invalidateQueries(reportsListQueryKey);

        showSnackbar({
          kind: "success",
          message: t(
            "components.ImageContextMenu.createManualDetectionSuccess",
            { reportId: data.reportId }
          ),
        });
        onClose();
      },
      onError: (error) => {
        showSnackbar({ kind: "error", message: error.message });
      },
    }
  );

  // this line should be updated when other menu items are added to not show the menu
  // if no menu items will be shown
  if (!canCreateManualDetection) return null;

  if (createManualDetection.isLoading) {
    return (
      <Menu
        open={!!openPosition}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={openPosition ?? undefined}
      >
        <MenuItem>
          <CircularProgress size={24} />
        </MenuItem>
      </Menu>
    );
  }

  return (
    <Menu
      open={!!openPosition}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={openPosition ?? undefined}
    >
      {canCreateManualDetection && (
        <ConfirmationDialogWrapper
          action={() => createManualDetection.mutate(null)}
          title={t(
            "components.ImageContextMenu.createManualDetectionConfirmationTitle"
          )}
          message={t(
            "components.ImageContextMenu.createManualDetectionConfirmationMessageNewReport"
          )}
          confirmLabel={t("action.confirm")}
          cancelLabel={t("action.cancel")}
        >
          {({ doConfirmation }) => (
            <MenuItem onClick={doConfirmation}>
              {t("components.ImageContextMenu.createManualDetectionNewReport")}
            </MenuItem>
          )}
        </ConfirmationDialogWrapper>
      )}
      {canCreateManualDetection && viewingReportId && (
        <ConfirmationDialogWrapper
          action={() => createManualDetection.mutate(viewingReportId)}
          title={t(
            "components.ImageContextMenu.createManualDetectionConfirmationTitle"
          )}
          message={t(
            "components.ImageContextMenu.createManualDetectionConfirmationMessageExistingReport",
            { reportId: viewingReportId }
          )}
          confirmLabel={t("action.confirm")}
          cancelLabel={t("action.cancel")}
        >
          {({ doConfirmation }) => (
            <MenuItem onClick={doConfirmation}>
              {t(
                "components.ImageContextMenu.createManualDetectionCurrentReport"
              )}
            </MenuItem>
          )}
        </ConfirmationDialogWrapper>
      )}
    </Menu>
  );
}
