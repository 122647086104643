import { decomposeColor, recomposeColor } from "@mui/material";
import { LatLng } from "leaflet";

const gradientDark = [
  "hsl(190, 44%, 60%)",
  "hsl(267, 47%, 75%)",
  "hsl(339, 41%, 63%)",
  "hsl(51, 48%, 58%)",
  "hsl(123, 39%, 64%)",
];
const gradientDarkHighlight = [
  "#00fffb",
  "#ec5eff",
  "#ff3654",
  "#fff700",
  "#5cff65",
];

const gradientLight = ["#2abedb", "#8136d9", "#db2767", "#d1b726", "#2ac932"];
const gradientLightHighlight = [
  "#00fffb",
  "#ec5eff",
  "#ff3654",
  "#fff700",
  "#5cff65",
];
const loopDistance = 8;

function lerp(value: number, theme: "light" | "dark", highlight: boolean) {
  const gradient =
    theme === "dark"
      ? highlight
        ? gradientDarkHighlight
        : gradientDark
      : highlight
      ? gradientLightHighlight
      : gradientLight;
  if (value === 1) return gradient[gradient.length - 1];
  if (value === 0) return gradient[0];
  const v = (value * gradient.length) % 1;
  const firstIndex = Math.floor(value * gradient.length);
  const firstColor = decomposeColor(gradient[firstIndex]);
  const secondColor = decomposeColor(
    gradient[(firstIndex + 1) % gradient.length]
  );
  const result = { ...firstColor };
  //check for a shorter path that loops around 360 -> 0
  const hDiff =
    secondColor.values[0] - firstColor.values[0] > 180
      ? secondColor.values[0] - firstColor.values[0] - 180
      : secondColor.values[0] - firstColor.values[0] < -180
      ? secondColor.values[0] - firstColor.values[0] + 180
      : secondColor.values[0] - firstColor.values[0];
  result.values[0] = firstColor.values[0] + hDiff * v;
  result.values[1] =
    firstColor.values[1] + (secondColor.values[1] - firstColor.values[1]) * v;
  result.values[2] =
    firstColor.values[2] + (secondColor.values[2] - firstColor.values[2]) * v;
  return recomposeColor(result);
}

export function geoColorHash(
  point: LatLng,
  theme: "light" | "dark",
  highlight = false
) {
  const dist = Math.sqrt(Math.pow(point.lat, 2) + Math.pow(point.lng, 2));
  const value = (dist % loopDistance) / loopDistance;
  return lerp(value, theme, highlight);
}

export function bearingColorHash(
  bearing: number,
  theme: "light" | "dark",
  highlight = false
) {
  const value = mulberry32(bearing * 10);
  return lerp(value < 0 ? value + 1 : value, theme, highlight);
}

export function idColorHash(
  id: number | string,
  theme: "light" | "dark",
  highlight = false
) {
  const gradient =
    theme === "dark"
      ? highlight
        ? gradientDarkHighlight
        : gradientDark
      : highlight
      ? gradientLightHighlight
      : gradientLight;
  const value = typeof id === "string" ? Number.parseInt(id) : id;
  const color = gradient[Math.round(mulberry32(value) * 100) % gradient.length];
  return color;
}

//https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
/** Pure function to generate a pseudorandom number from an integer seed. */
function mulberry32(seed: number) {
  //var t = a += 0x6D2B79F5;
  let t = seed + 0x6d2b79f5;
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
}
