import { Camera, GetCameraReq, ListCamerasReq } from "../proto/cameras";
import {
  ListCameraImageRangeReq,
  ListCameraImageRangeRes,
} from "../proto/images";
import client from "./client";

export async function getCameras(filters: ListCamerasReq): Promise<Camera[]> {
  const res = await client.cameras.listCameras(filters);
  return res.cameras;
}

export async function getCamera(id: number): Promise<Camera> {
  const req: GetCameraReq = { id };
  const res = await client.cameras.getCamera(req);
  return res;
}

export async function getImageRange({
  sameViewOnly,
  centerImage,
  viewerOnly,
  pageToken,
}: {
  sameViewOnly: boolean;
  centerImage: number;
  viewerOnly: boolean;
  pageToken: string | null;
}): Promise<ListCameraImageRangeRes> {
  const req: ListCameraImageRangeReq = {
    centerImageId: centerImage,
    before: undefined,
    after: undefined,
    sameViewOnly,
    pageToken: pageToken ?? "",
  };
  if (viewerOnly) {
    const res = await client.images.listViewerCameraImageRange(req);
    return res;
  } else {
    const res = await client.images.listCameraImageRange(req);
    return res;
  }
}

export function imageUrl(
  id: number | string,
  type:
    | "original"
    | "subtraction"
    | "annotated"
    | "annotatedSimple" = "original",
  reportId?: number
) {
  switch (type) {
    case "original":
      return `${
        import.meta.env.VITE_PX_BASE_URL
      }/images/camera_image2_data/${id}`;
    case "annotated":
      return `${
        import.meta.env.VITE_PX_BASE_URL
      }/images/camera_image2_data/${id}/ann${
        reportId ? `?reportId=${reportId}` : ""
      }`;
    case "annotatedSimple":
      return `${
        import.meta.env.VITE_PX_BASE_URL
      }/images/camera_image2_data/${id}/ann_simple${
        reportId ? `?reportId=${reportId}` : ""
      }`;
    case "subtraction":
      return `${
        import.meta.env.VITE_PX_BASE_URL
      }/images/camera_image2_data/${id}/sub`;
    default:
      throw Error("Unknown type");
  }
}
