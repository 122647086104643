import { styled } from "@mui/material";
import { GeoJSON, GeoJSONProps, MapContainer, TileLayer } from "react-leaflet";

import { useMapTheme } from "../../../theme";

const StyledMapContainer = styled(MapContainer)(({ theme }) => ({
  width: "20vh",
  height: "20vh",
  background: "unset",
  position: "fixed",
  top: theme.spacing(2),
  right: theme.spacing(2),
  [theme.breakpoints.down("xl")]: {
    display: "none",
  },
}));

export default function Minimap({
  geojson,
  geojsonColor,
  latitude,
  longitude,
  zoom,
}: {
  geojson: GeoJSONProps["data"];
  geojsonColor: string;
  latitude: number;
  longitude: number;
  zoom: number;
}) {
  const { mapboxThemeUrl } = useMapTheme();
  return (
    <StyledMapContainer
      center={[latitude, longitude]}
      zoom={zoom}
      zoomControl={false}
      attributionControl={false}
    >
      <TileLayer attribution="&copy; Mapbox" url={mapboxThemeUrl} />
      <GeoJSON
        interactive={false}
        data={geojson}
        style={{
          color: geojsonColor,
          fillColor: geojsonColor,
          fill: true,
          stroke: true,
          weight: 1,
        }}
      />
    </StyledMapContainer>
  );
}
