import { Box, Menu, MenuItem } from "@mui/material";
import ConfirmationDialogWrapper from "@phoenix/common/components/compound/ConfirmationDialogWrapper";
import Button from "@phoenix/common/components/simple/Button";
import {
  ListReportsRes,
  Report,
  ReviewReason,
  ReviewReportReq,
} from "@phoenix/common/proto/reports";
import { ConfirmationState } from "@phoenix/common/proto/types";
import service from "@phoenix/common/service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RpcError } from "grpc-web";
import { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { confirmationStates, reviewReasons } from "../../constants";
import { mergeCandidatesKey, reportKey } from "../../queryKeys";
import useInvalidateReports from "../../utils/useInvalidateReports";
import useSnackbar from "../../utils/useSnackbar";
import { useReportsQueryOptions } from "../reportsList/useReportsQuery";
import MergeDialog from "./MergeDialog";
import MergeDialogButton from "./MergeDialogButton";

export default function ReviewActions({ report }: { report: Report }) {
  const { t } = useTranslation(["common", "report"]);
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { queryKey: reportsListQueryKey } = useReportsQueryOptions();
  const invalidateReports = useInvalidateReports();
  const mutation = useMutation<void, RpcError, ReviewReportReq>(
    (req) => service.reports.reviewReport(req),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(reportKey(report.id), {
          ...report,
          state: variables.newState,
          reason: variables.reason,
        });
        queryClient.setQueryData<ListReportsRes>(
          reportsListQueryKey,
          (oldData) => {
            if (!oldData) return { reports: [], hasMore: false };
            const newData = { ...oldData, reports: [...oldData.reports] };
            const index = newData.reports.findIndex((r) => r.id === report.id);
            if (index !== -1) {
              newData.reports[index].state = variables.newState;
            }
            return newData;
          }
        );
        invalidateReports([report.id]);
        showSnackbar({
          kind: "success",
          message: t("report:ReviewActions.successMessage", {
            reportId: report.id,
            state: confirmationStates(t).get(variables.newState),
          }),
        });
        navigate("/");
      },
      onError: (error) => {
        showSnackbar({ kind: "error", message: error.message });
      },
    }
  );

  const mergeCandidatesQuery = useQuery<
    Awaited<ReturnType<typeof service.reports.listMergeCandidates>>,
    RpcError
  >(mergeCandidatesKey(report.id), () =>
    service.reports.listMergeCandidates(report.id)
  );
  const [preconfirmMergeDialogOpen, setPreconfirmMergeDialogOpen] =
    useState(false);
  const handlePreconfirmMergeCancel = () => setPreconfirmMergeDialogOpen(false);
  const handlePreconfirmMergeSuccess = (finalReportId: number) => {
    setPreconfirmMergeDialogOpen(false);
    invalidateReports([report.id, finalReportId]);
    showSnackbar({
      kind: "success",
      message: t("report:ReviewActions.preconfirmMergeSuccessMessage", {
        reportId: report.id,
      }),
    });
    navigate("/");
  };

  const [rejectMenuEl, setRejectMenuEl] = useState<HTMLButtonElement | null>(
    null
  );
  const handleRejectMenuClose = () => setRejectMenuEl(null);
  const handleRejectMenuOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
    setRejectMenuEl(e.currentTarget);
  };

  const confirm = () =>
    mutation.mutate({
      reportId: report.id,
      newState: ConfirmationState.CONFIRMATION_STATE_CONFIRMED,
      reason: ReviewReason.REVIEW_REASON_UNKNOWN,
    });

  const watch = () =>
    mutation.mutate({
      reportId: report.id,
      newState: ConfirmationState.CONFIRMATION_STATE_WATCH,
      reason: ReviewReason.REVIEW_REASON_UNKNOWN,
    });

  return (
    <Box sx={{ display: "flex", gap: 1 }} data-cy="review-actions">
      {mergeCandidatesQuery.status !== "success" ? (
        <Button
          variant="text"
          size="small"
          disabled
          loading={mergeCandidatesQuery.status === "loading"}
        >
          {t("report:ReviewActions.confirm")}
        </Button>
      ) : (
        <ConfirmationDialogWrapper
          action={confirm}
          title={t("report:ReviewActions.confirmConfirmationTitle", {
            reportId: report.id,
          })}
          message={t("report:ReviewActions.confirmConfirmationMessage", {
            reportId: report.id,
          })}
          confirmLabel={t("common:action.confirm")}
          cancelLabel={t("common:action.cancel")}
        >
          {({ doConfirmation }) => (
            <>
              <Button
                variant="text"
                size="small"
                onClick={() =>
                  mergeCandidatesQuery.data.candidates.length === 0
                    ? doConfirmation()
                    : setPreconfirmMergeDialogOpen(true)
                }
                loading={mutation.isLoading}
              >
                {t("report:ReviewActions.confirm")}
              </Button>
              <MergeDialog
                open={preconfirmMergeDialogOpen}
                onCancel={handlePreconfirmMergeCancel}
                onMergeSuccess={handlePreconfirmMergeSuccess}
                source={mergeCandidatesQuery.data.source}
                mergeCandidates={mergeCandidatesQuery.data.candidates}
                onConfirm={doConfirmation}
                confirmLoading={mutation.isLoading}
              />
            </>
          )}
        </ConfirmationDialogWrapper>
      )}

      <ConfirmationDialogWrapper
        action={watch}
        title={t("report:ReviewActions.watchConfirmationTitle", {
          reportId: report.id,
        })}
        message={t("report:ReviewActions.watchConfirmationMessage", {
          reportId: report.id,
        })}
        confirmLabel={t("common:action.confirm")}
        cancelLabel={t("common:action.cancel")}
      >
        {({ doConfirmation }) => (
          <Button
            variant="text"
            size="small"
            onClick={doConfirmation}
            loading={mutation.isLoading}
          >
            {t("report:ReviewActions.watch")}
          </Button>
        )}
      </ConfirmationDialogWrapper>

      <Button
        variant="text"
        size="small"
        onClick={handleRejectMenuOpen}
        loading={mutation.isLoading}
      >
        {t("report:ReviewActions.reject")}
      </Button>
      <Menu
        open={!!rejectMenuEl}
        anchorEl={rejectMenuEl}
        onClose={handleRejectMenuClose}
      >
        {Array.from(reviewReasons(t).entries()).map(([value, label]) => (
          <MenuItem
            key={value}
            onClick={() => {
              mutation.mutate({
                reportId: report.id,
                newState: ConfirmationState.CONFIRMATION_STATE_REJECTED,
                reason: value,
              });
              handleRejectMenuClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>

      <MergeDialogButton
        mergeCandidates={
          mergeCandidatesQuery.status === "success"
            ? mergeCandidatesQuery.data
            : mergeCandidatesQuery.status
        }
      />
    </Box>
  );
}
