/* eslint-disable simple-import-sort/imports */
import "leaflet/dist/leaflet.css";

import { alpha, emphasize, styled, useTheme } from "@mui/material";
import service from "@phoenix/common/service";
import { useAtomValue } from "jotai";
import * as L from "leaflet";
import { ReactNode, useMemo, useRef } from "react";
import {
  GeoJSON,
  LayersControl,
  MapContainer,
  ScaleControl,
  TileLayer,
  useMapEvent,
  ZoomControl,
} from "react-leaflet";
import { useQuery } from "@tanstack/react-query";

import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/leaflet.markercluster.js";

import { camerasKey, reportPizzasKey } from "../../queryKeys";
import { useMap, useSetMap } from "./MapProvider";
import {
  currentReportSatelliteSourcesAtom,
  currentViewingReportGeojsonAtom,
  highlightedReportAtom,
} from "../state";
import { useAuthContext } from "../auth/AuthProvider";
import { idColorHash } from "../../utils/colorHashes";
import { useNavigate } from "react-router-dom";
import { routeTo } from "../../routes";
import { CustomMarker } from "./CustomMarker";
import { useReportsQueryOptions } from "../reportsList/useReportsQuery";
import { useMapTheme } from "../../theme";
import { reportStyle } from "../../utils/reportStyle";
import { useTranslation } from "react-i18next";
import { usePersistedState } from "@phoenix/common/utils/usePersistedState";
import { TFunction } from "i18next";

const mapLayers = (t: TFunction<["common"]>) =>
  [
    {
      label: t("common:referenceSatelliteLayerLabel"),
      url: "https://api.mapbox.com/styles/v1/fireball-intel/clejnlk1o001u01nvz3jraxm6/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmlyZWJhbGwtaW50ZWwiLCJhIjoiY2tzZm40bnVsMWMxNTJ3cGFvbndzd3R2dCJ9.c5varXwpbFNkgfnLdP8q_A",
      delayMs: 0,
      attribution: "&copy; Mapbox",
    },
    // {
    //   label: "MODIS (Aqua)",
    //   url: "https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/MODIS_Aqua_CorrectedReflectance_TrueColor/default/{date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg",
    //   delayMs: 24 * 60 * 60 * 1000,
    //   maxZoom: undefined,
    //   attribution:
    //     '<a href="https://wiki.earthdata.nasa.gov/display/GIBS">NASA EOSDIS GIBS</a>',
    // },
    // {
    //   label: "MODIS (Terra)",
    //   url: "https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/MODIS_Terra_CorrectedReflectance_TrueColor/default/{date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg",
    //   delayMs: 24 * 60 * 60 * 1000,
    //   maxZoom: undefined,
    //   attribution:
    //     '<a href="https://wiki.earthdata.nasa.gov/display/GIBS">NASA EOSDIS GIBS</a>',
    // },
    // {
    //   label: "VIIRS NOAA-20",
    //   url: "https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/VIIRS_NOAA20_CorrectedReflectance_TrueColor/default/{date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg",
    //   delayMs: 36 * 60 * 60 * 1000,
    //   maxZoom: undefined,
    //   attribution:
    //     '<a href="https://wiki.earthdata.nasa.gov/display/GIBS">NASA EOSDIS GIBS</a>',
    // },
    // {
    //   label: "VIIRS Suomi NPP",
    //   url: "https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/VIIRS_SNPP_CorrectedReflectance_TrueColor/default/{date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg",
    //   delayMs: 36 * 60 * 60 * 1000,
    //   maxZoom: undefined,
    //   attribution:
    //     '<a href="https://wiki.earthdata.nasa.gov/display/GIBS">NASA EOSDIS GIBS</a>',
    // },
    // issues with the epsg3857 proxy for these layers
    // {
    //   label: "GOES-W Red Visible",
    //   url: "https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/GOES-West_ABI_Band2_Red_Visible_1km/default/{date}/GoogleMapsCompatible_Level7/{z}/{y}/{x}.png",
    //   delayMs: 40 * 60 * 1000,
    //   maxZoom: 6,
    // attribution: '<a href="https://wiki.earthdata.nasa.gov/display/GIBS">NASA EOSDIS GIBS</a>',
    // },
    // {
    //   label: "GOES-E Red Visible",
    //   url: "https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/GOES-East_ABI_Band2_Red_Visible_1km/default/{date}/GoogleMapsCompatible_Level7/{z}/{y}/{x}.png",
    //   delayMs: 40 * 60 * 1000,
    //   maxZoom: 6,
    // attribution: '<a href="https://wiki.earthdata.nasa.gov/display/GIBS">NASA EOSDIS GIBS</a>',
    // },
  ] as const;

// hack to fix white lines https://github.com/Leaflet/Leaflet/issues/3575
//@ts-expect-error not included in provided typings
const originalInitTile = L.GridLayer.prototype._initTile;
L.GridLayer.include({
  //@ts-expect-error no typings provided
  _initTile: function (tile) {
    originalInitTile.call(this, tile);

    const tileSize = this.getTileSize();

    tile.style.width = tileSize.x + 0.5 + "px";
    tile.style.height = tileSize.y + 0.5 + "px";
  },
});

// MapContainer styles are not reactive, so we include another outer container
// in order to change the icon colours on theme change
const OuterMapContainer = styled("div", {
  shouldForwardProp: (key) => key !== "highlightedIds",
})<{ highlightedIds: number[] | undefined }>(({ theme, highlightedIds }) => ({
  width: "100%",
  height: "100%",
  "& .leaflet-control-layers-list": {
    fontFamily: theme.typography.fontFamily,
  },
  "& .camera-icon": {
    fill: theme.typography.caption.color,
    "& .camera-icon.highlighted": {
      fill: theme.typography.caption.color,
    },
    "& text": { fill: alpha(theme.typography.body1.color!, 0.8) },
  },
  "&": highlightedIds
    ? Object.fromEntries(
        highlightedIds.map((id) => [
          `& .cameraIconId${id} svg`,
          { transform: "scale(1.5)" },
        ])
      )
    : undefined,
}));
const StyledMapContainer = styled(MapContainer)({
  width: "100%",
  height: "100%",
  background: "unset",
});

const cameraIcon = (id: number) =>
  L.divIcon({
    iconSize: L.point(18, 18),
    className: `leaflet-marker-icon camera-icon single-camera-icon cameraIconId${id}`,
    html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.5 8c.276 0 .5.224.5.5v7c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-7c0-.276.224-.5.5-.5h11zm2.5 0c0-1.104-.896-2-2-2h-12c-1.104 0-2 .896-2 2v8c0 1.104.896 2 2 2h12c1.104 0 2-.896 2-2v-8zm6 1.854v4.293l-2-1.408v-1.478l2-1.407zm2-3.854l-6 4.223v3.554l6 4.223v-12z"/></svg>`,
  });
const camerasIcon = (ids: number[]) =>
  L.divIcon({
    iconSize: L.point(24, 24),
    className: `leaflet-marker-icon camera-icon multiple-cameras-icon ${ids
      .map((id) => `cameraIconId${id}`)
      .join(" ")}`,
    html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><text x="50%" y="50%" font-size="0.7rem" dominant-baseline="middle" text-anchor="middle">${ids.length}</text><path d="M 2.5 8 C 2.224 8 2 8.224 2 8.5 L 2 15.5 C 2 15.776 2.224 16 2.5 16 L 6 16 L 6 18 L 2 18 C 0.896 18 0 17.104 0 16 L 0 8 C 0 6.896 0.896 6 2 6 L 6 6 L 6 8 Z M 20 11.261 L 20 12.739 L 22 14.147 L 22 9.854 Z M 24 18 L 18 13.777 L 18 10.223 L 24 6 Z"/></svg>`,
  });

export default function Map({ children }: { children?: ReactNode }) {
  const {
    state: { scopes },
  } = useAuthContext();
  const { mapboxThemeUrl } = useMapTheme();
  const theme = useTheme();
  const navigate = useNavigate();

  const setMap = useSetMap();
  const map = useMap();
  const mapRef = useRef<L.Map | null>(null);
  const markersLayer = useRef<L.MarkerClusterGroup>();
  //when we need to add bearing info to the marker, see BearingMarker.d.ts/.js
  const markers = useRef<Record<number, CustomMarker>>({});

  const highlightedReport = useAtomValue(highlightedReportAtom);

  useQuery(camerasKey(), () => service.cameras.getCameras({}), {
    onSuccess: (data) => {
      if (!markersLayer.current) {
        markersLayer.current = L.markerClusterGroup({
          showCoverageOnHover: false,
          maxClusterRadius: 20,
          zoomToBoundsOnClick: false,
          chunkedLoading: true,
          spiderfyOnMaxZoom: true,
          spiderfyDistanceMultiplier: 0.7,
          iconCreateFunction: (cluster) => {
            const clusterIds = (
              cluster.getAllChildMarkers() as CustomMarker[]
            ).map((marker) => marker.options.id);
            return camerasIcon(clusterIds);
          },
        });
        map?.addLayer(markersLayer.current);
      } else {
        markersLayer.current.clearLayers();
        markersLayer.current.off();
        markers.current = {};
      }
      data.forEach((cam) => {
        markers.current[cam.info!.id] = new CustomMarker(
          [cam.info!.latitude, cam.info!.longitude],
          {
            id: cam.info!.id,
            icon: cameraIcon(cam.info!.id),
          }
        );
        markers.current[cam.info!.id].bindTooltip(cam.info!.displayName);
        markersLayer.current?.addLayer(markers.current[cam.info!.id]);
      });
      markersLayer.current.on("click", (event) => {
        const marker = event.propagatedFrom as CustomMarker;
        navigate(
          routeTo.camera(
            marker.options.id,
            `${window.location.pathname}${window.location.search}`
          )
        );
      });
      markersLayer.current.on("clusterclick", (event) => {
        const cluster = event.propagatedFrom as L.MarkerCluster;
        cluster.spiderfy();
      });
    },
  });

  const { filters: reportsFilters, range: reportsRange } =
    useReportsQueryOptions();

  const pizzasLayerRef = useRef<L.GeoJSON>(null);
  const pizzas = useQuery(
    reportPizzasKey(reportsFilters, reportsRange),
    () => {
      const reportsFiltersWithRange = {
        ...reportsFilters,
        after: new Date(Date.now() + reportsRange[0] * 1000 * 60 * 60),
        before: new Date(Date.now() + reportsRange[1] * 1000 * 60 * 60),
      };
      return service.reports.getReportsPizzasGeoJSON(
        reportsFiltersWithRange,
        !scopes.includes("reports.list_reports")
      );
    },
    {
      refetchInterval: import.meta.env.PROD ? 5000 : undefined,
      refetchIntervalInBackground: true,
    }
  );

  const currentViewingReportGeojson = useAtomValue(
    currentViewingReportGeojsonAtom
  );
  const currentReportSatelliteSources = useAtomValue(
    currentReportSatelliteSourcesAtom
  );
  const currentReportSatelliteSourcesParsed = useMemo(() => {
    if (!currentReportSatelliteSources) return null;
    return currentReportSatelliteSources.map((source) => ({
      ...source,
      detectionSummary: {
        ...source.detectionSummary,
        areaGeojson: JSON.parse(
          source.detectionSummary?.areaGeojson ?? "{}"
        ) as GeoJSON.GeoJsonObject,
      },
    }));
  }, [currentReportSatelliteSources]);

  const pizzasWithoutCurrentViewingReport = useMemo(() => {
    if (!pizzas.data) return null;
    return {
      ...pizzas.data,
      features: pizzas.data.features.filter(
        (feature) => feature.id !== currentViewingReportGeojson?.id
      ),
      key: Math.random(), // to re-render pizzas whenever this object is re-made
    };
  }, [currentViewingReportGeojson?.id, pizzas.data]);

  return (
    <OuterMapContainer
      highlightedIds={highlightedReport?.sourceSummaries
        .map((source) =>
          source.source?.$case === "cameraViewSummary"
            ? source.source.cameraViewSummary.cameraId
            : null
        )
        .filter((id): id is number => !!id)}
    >
      <StyledMapContainer
        center={[34.0522, -118.2437]}
        zoom={7}
        zoomControl={false}
        attributionControl={false}
        ref={(ref) => {
          if (ref) {
            mapRef.current = ref;
            setMap(mapRef.current!);
          }
        }}
        whenReady={() => {
          //translations seem to cause a race condition for the map sizing
          //even though the map isn't created until they are ready
          setTimeout(() => mapRef.current!.invalidateSize(), 400);
        }}
      >
        {children}
        <ScaleControl position="topleft" />
        <ZoomControl position="topleft" />
        <Layers currentThemeDefaultTiles={mapboxThemeUrl} />
        {pizzasWithoutCurrentViewingReport && (
          <GeoJSON
            key={pizzasWithoutCurrentViewingReport.key}
            data={pizzasWithoutCurrentViewingReport}
            ref={pizzasLayerRef}
            onEachFeature={(feature, layer) => {
              layer.on("click", () =>
                navigate(routeTo.report(feature.id as number))
              );
            }}
            style={(feature) => reportStyle(feature!, theme.palette.mode)}
          />
        )}

        {currentViewingReportGeojson && (
          <GeoJSON
            key={currentViewingReportGeojson.id}
            data={currentViewingReportGeojson}
            style={(feature) => {
              const color = idColorHash(feature!.id!, theme.palette.mode, true);
              return {
                color,
                fillColor: color,
                dashArray: [10],
                weight: 4,
              };
            }}
          />
        )}
        {currentReportSatelliteSourcesParsed &&
          currentViewingReportGeojson &&
          currentReportSatelliteSourcesParsed.map((satelliteSource) => (
            <GeoJSON
              key={satelliteSource.satelliteName}
              data={satelliteSource.detectionSummary.areaGeojson}
              style={() => {
                const color = idColorHash(
                  currentViewingReportGeojson.id ?? 0,
                  theme.palette.mode
                );
                return {
                  color: emphasize(color, 0.3),
                  fillColor: color,
                  stroke: true,
                  weight: 2,
                };
              }}
            />
          ))}
      </StyledMapContainer>
    </OuterMapContainer>
  );
}

function Layers({
  currentThemeDefaultTiles,
}: {
  currentThemeDefaultTiles: string;
}) {
  const { t } = useTranslation(["common"]);
  const [currentBaseLayer, setCurrentBaseLayer] = usePersistedState(
    "currentBaseLayer",
    "default"
  );
  useMapEvent("baselayerchange", (e) =>
    setCurrentBaseLayer(
      e.name === t("common:defaultLabel") ? "default" : e.name
    )
  );
  return (
    <LayersControl
      position="topright"
      sortLayers={true}
      sortFunction={(layerA, layerB, nameA, nameB) => {
        if (nameA === t("common:defaultLabel")) return -1;
        if (nameB === t("common:defaultLabel")) return 1;
        if (nameA === t("common:referenceSatelliteLayerLabel")) return -1;
        if (nameB === t("common:referenceSatelliteLayerLabel")) return 1;
        else return nameA.localeCompare(nameB);
      }}
    >
      <LayersControl.BaseLayer
        name={t("common:defaultLabel")}
        key={t("common:defaultLabel")}
        checked={currentBaseLayer === "default"}
      >
        <TileLayer
          //tile url is not reactive
          key={currentThemeDefaultTiles}
          attribution="&copy; Mapbox"
          url={currentThemeDefaultTiles}
        />
      </LayersControl.BaseLayer>
      {mapLayers(t).map((layer) => (
        <LayersControl.BaseLayer
          name={layer.label}
          key={layer.label}
          checked={currentBaseLayer === layer.label}
        >
          <TileLayer
            attribution={layer.attribution}
            url={layer.url.replace(
              "{date}",
              new Date(Date.now() - layer.delayMs).toISOString().slice(0, 16) +
                ":00Z"
            )}
          />
        </LayersControl.BaseLayer>
      ))}
    </LayersControl>
  );
}
