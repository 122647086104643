import { Box, Typography } from "@mui/material";
import { CameraView, Report } from "@phoenix/common/proto/reports";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CameraImageTimeline from "../../components/compound/CameraImageTimeline";
import Bearing from "../../components/simple/Bearing";
import CameraViewDialog from "./CameraViewDialog";

export default function CameraViewDetection({
  view,
  report,
}: {
  view: CameraView;
  report: Report;
}) {
  const { t } = useTranslation("report");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogInitialImage, setDialogInitialImage] = useState<
    number | undefined
  >(undefined);
  const handleOpen = (initialImageId: number) => {
    setDialogInitialImage(initialImageId);
    setDialogOpen(true);
  };
  const handleClose = () => setDialogOpen(false);

  return (
    <>
      <Box display="flex">
        <Typography variant="h3">
          {t("ReportView.cameraNameHeading", {
            cameraName: view.cameraInfo!.displayName,
          })}
        </Typography>
        <Box pl={1} />
        {view.viewInfo && (
          <Bearing
            angle={view.viewInfo.pan}
            title={t("CameraViewDialog.bearingLabel", {
              bearing: Math.round(view.viewInfo.pan),
            })}
          />
        )}
      </Box>
      <Box
        sx={{ maxWidth: "100%", marginTop: 1 }}
        data-cy={`timeline-${view.cameraInfo?.name}`}
      >
        <CameraImageTimeline
          cameraId={view.cameraInfo!.id}
          sameViewOnly
          sourceId={view.id}
          visibleRangeMinutes={30}
          initialImageId={view.lastDetectionImageId}
          reportId={report.id}
          onImageClick={handleOpen}
        />
      </Box>
      {dialogInitialImage && (
        <CameraViewDialog
          open={dialogOpen}
          onClose={handleClose}
          view={view}
          report={report}
          initialImageId={dialogInitialImage}
        />
      )}
    </>
  );
}
