import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  PopoverPosition,
  useTheme,
} from "@mui/material";
import { ImageDetection } from "@phoenix/common/proto/detections";
import { CameraView, Report } from "@phoenix/common/proto/reports";
import { GeoJsonObject } from "geojson";
import { MouseEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CameraImageTimeline from "../../../components/compound/CameraImageTimeline";
import DetectionBoxLegendIcon from "../../../components/compound/DetectionBoxLegendIcon";
import DetectionBoxMenu from "../../../components/compound/DetectionBoxMenu";
import ImageContextMenu, {
  ImageContextMenuState,
} from "../../../components/compound/ImageContextMenu";
import Bearing from "../../../components/simple/Bearing";
import { idColorHash } from "../../../utils/colorHashes";
import { useAuthContext } from "../../auth/AuthProvider";
import { useMap } from "../../map/MapProvider";
import ReviewActions from "../ReviewActions";
import Minimap from "./Minimap";

type DetectionMenuState = {
  detection: ImageDetection;
  position: PopoverPosition;
};

export default function CameraViewDialog({
  open,
  onClose,
  view,
  report,
  initialImageId,
}: {
  open: boolean;
  onClose: () => void;
  view: CameraView;
  report: Report;
  initialImageId: number;
}) {
  const { t } = useTranslation(["common", "report"]);
  const theme = useTheme();
  const map = useMap();
  const {
    state: { scopes },
  } = useAuthContext();
  const canReview = scopes.includes("reports.review");

  const [detectionMenuState, setDetectionMenuState] =
    useState<DetectionMenuState | null>(null);
  const [imageContextMenuState, setImageContextMenuState] =
    useState<ImageContextMenuState | null>(null);

  const reportGeojson: GeoJsonObject = useMemo(
    () => JSON.parse(report.areaGeojson),
    [report.areaGeojson]
  );

  const handleDetectionClick = (
    detection: ImageDetection,
    event: MouseEvent
  ) => {
    setDetectionMenuState({
      detection,
      position: {
        left: event.clientX,
        top: event.clientY,
      },
    });
  };
  const handleImageContextMenu = (
    imageId: number,
    imageCoords: { x: number; y: number },
    event: MouseEvent
  ) => {
    event.preventDefault();
    setImageContextMenuState({
      imageId,
      imageCoords,
      position: { left: event.clientX, top: event.clientY },
    });
  };
  const handleDetectionMenuClose = () => setDetectionMenuState(null);
  const handleImageContextMenuClose = () => setImageContextMenuState(null);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
        <Box display="flex" alignItems="baseline">
          <DialogTitle>
            {t("report:CameraViewDialog.title", {
              cameraName: view.cameraInfo!.displayName,
            })}
            &nbsp;
            <Bearing
              angle={view.viewInfo!.pan}
              title={t("report:CameraViewDialog.bearingLabel", {
                bearing: Math.round(view.viewInfo!.pan),
              })}
            />
          </DialogTitle>
          {canReview && <ReviewActions report={report} />}
          <Box sx={{ ml: "auto" }} />
          <DetectionBoxLegendIcon includeDashed />
          <IconButton
            aria-label={t("common:action.close")}
            onClick={onClose}
            sx={{
              mr: 1,
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ px: 2, py: 1 }}>
          <CameraImageTimeline
            cameraId={view.cameraInfo!.id}
            sameViewOnly
            sourceId={view.id}
            initialImageId={initialImageId}
            reportId={report.id}
            onDetectionClick={handleDetectionClick}
            onImageContextMenu={handleImageContextMenu}
            imageHeight="60vh"
          />
          {detectionMenuState && (
            <DetectionBoxMenu
              detection={detectionMenuState.detection}
              openPosition={detectionMenuState.position}
              viewingReportId={report.id}
              onClose={handleDetectionMenuClose}
            />
          )}
          {imageContextMenuState && (
            <ImageContextMenu
              imageId={imageContextMenuState.imageId}
              imageCoords={imageContextMenuState.imageCoords}
              openPosition={imageContextMenuState.position}
              viewingReportId={report.id}
              onClose={handleImageContextMenuClose}
            />
          )}
        </DialogContent>
        <Minimap
          geojson={reportGeojson}
          geojsonColor={idColorHash(report.id, theme.palette.mode)}
          latitude={report.latitude}
          longitude={report.longitude}
          zoom={(map?.getZoom() ?? 7) - 2}
        />
      </Dialog>
    </>
  );
}
