/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { NotificationMethod, notificationMethodFromJSON, notificationMethodToJSON } from "./user";

export const protobufPackage = "phoenix.settings.v0";

export interface SetNotificationMethodsReq {
  methods: NotificationMethod[];
}

export interface GetNotificationMethodsRes {
  methods: NotificationMethod[];
}

function createBaseSetNotificationMethodsReq(): SetNotificationMethodsReq {
  return { methods: [] };
}

export const SetNotificationMethodsReq = {
  encode(message: SetNotificationMethodsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.methods) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetNotificationMethodsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetNotificationMethodsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.methods.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.methods.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetNotificationMethodsReq {
    return {
      methods: globalThis.Array.isArray(object?.methods)
        ? object.methods.map((e: any) => notificationMethodFromJSON(e))
        : [],
    };
  },

  toJSON(message: SetNotificationMethodsReq): unknown {
    const obj: any = {};
    if (message.methods?.length) {
      obj.methods = message.methods.map((e) => notificationMethodToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetNotificationMethodsReq>, I>>(base?: I): SetNotificationMethodsReq {
    return SetNotificationMethodsReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetNotificationMethodsReq>, I>>(object: I): SetNotificationMethodsReq {
    const message = createBaseSetNotificationMethodsReq();
    message.methods = object.methods?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetNotificationMethodsRes(): GetNotificationMethodsRes {
  return { methods: [] };
}

export const GetNotificationMethodsRes = {
  encode(message: GetNotificationMethodsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.methods) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNotificationMethodsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNotificationMethodsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.methods.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.methods.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNotificationMethodsRes {
    return {
      methods: globalThis.Array.isArray(object?.methods)
        ? object.methods.map((e: any) => notificationMethodFromJSON(e))
        : [],
    };
  },

  toJSON(message: GetNotificationMethodsRes): unknown {
    const obj: any = {};
    if (message.methods?.length) {
      obj.methods = message.methods.map((e) => notificationMethodToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNotificationMethodsRes>, I>>(base?: I): GetNotificationMethodsRes {
    return GetNotificationMethodsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNotificationMethodsRes>, I>>(object: I): GetNotificationMethodsRes {
    const message = createBaseGetNotificationMethodsRes();
    message.methods = object.methods?.map((e) => e) || [];
    return message;
  },
};

export interface Settings {
  /** Return the current subscriber's email preference (api v2) */
  getNotificationMethods(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetNotificationMethodsRes>;
  /** Enable or disable email notifications for users (api v2, but will set appropriate v1 subscriber) */
  setNotificationMethods(
    request: DeepPartial<SetNotificationMethodsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetNotificationMethodsRes>;
}

export class SettingsClientImpl implements Settings {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.getNotificationMethods = this.getNotificationMethods.bind(this);
    this.setNotificationMethods = this.setNotificationMethods.bind(this);
  }

  getNotificationMethods(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetNotificationMethodsRes> {
    return this.rpc.unary(SettingsGetNotificationMethodsDesc, Empty.fromPartial(request), metadata);
  }

  setNotificationMethods(
    request: DeepPartial<SetNotificationMethodsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetNotificationMethodsRes> {
    return this.rpc.unary(SettingsSetNotificationMethodsDesc, SetNotificationMethodsReq.fromPartial(request), metadata);
  }
}

export const SettingsDesc = { serviceName: "phoenix.settings.v0.Settings" };

export const SettingsGetNotificationMethodsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNotificationMethods",
  service: SettingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNotificationMethodsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const SettingsSetNotificationMethodsDesc: UnaryMethodDefinitionish = {
  methodName: "SetNotificationMethods",
  service: SettingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetNotificationMethodsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNotificationMethodsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
