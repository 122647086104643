import { grpc } from "@improbable-eng/grpc-web";

export class RpcError extends Error {
  code: grpc.Code;
  metadata?: grpc.Metadata;
  constructor(code: grpc.Code, message: string, metadata?: grpc.Metadata) {
    super(message);
    this.code = code;
    this.metadata = metadata;
  }
}
