/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "phoenix.cameras.v0";

export interface CameraInfo {
  id: number;
  name: string;
  displayName: string;
  network: string;
  latitude: number;
  longitude: number;
  altitude: number;
  externalLink: string;
}

export interface ViewInfo {
  pan: number;
  tilt: number;
  fov: number;
  vfov: number;
}

export interface Camera {
  info: CameraInfo | undefined;
  view: ViewInfo | undefined;
  latestImageId: number;
}

/** todo - filters? */
export interface ListCamerasReq {
}

export interface ListCamerasRes {
  cameras: Camera[];
}

export interface GetCameraReq {
  id: number;
}

function createBaseCameraInfo(): CameraInfo {
  return { id: 0, name: "", displayName: "", network: "", latitude: 0, longitude: 0, altitude: 0, externalLink: "" };
}

export const CameraInfo = {
  encode(message: CameraInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.displayName !== "") {
      writer.uint32(26).string(message.displayName);
    }
    if (message.network !== "") {
      writer.uint32(34).string(message.network);
    }
    if (message.latitude !== 0) {
      writer.uint32(41).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(49).double(message.longitude);
    }
    if (message.altitude !== 0) {
      writer.uint32(57).double(message.altitude);
    }
    if (message.externalLink !== "") {
      writer.uint32(66).string(message.externalLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.network = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.altitude = reader.double();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.externalLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraInfo {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      network: isSet(object.network) ? globalThis.String(object.network) : "",
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      altitude: isSet(object.altitude) ? globalThis.Number(object.altitude) : 0,
      externalLink: isSet(object.externalLink) ? globalThis.String(object.externalLink) : "",
    };
  },

  toJSON(message: CameraInfo): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.network !== "") {
      obj.network = message.network;
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.altitude !== 0) {
      obj.altitude = message.altitude;
    }
    if (message.externalLink !== "") {
      obj.externalLink = message.externalLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraInfo>, I>>(base?: I): CameraInfo {
    return CameraInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraInfo>, I>>(object: I): CameraInfo {
    const message = createBaseCameraInfo();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    message.network = object.network ?? "";
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.altitude = object.altitude ?? 0;
    message.externalLink = object.externalLink ?? "";
    return message;
  },
};

function createBaseViewInfo(): ViewInfo {
  return { pan: 0, tilt: 0, fov: 0, vfov: 0 };
}

export const ViewInfo = {
  encode(message: ViewInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pan !== 0) {
      writer.uint32(9).double(message.pan);
    }
    if (message.tilt !== 0) {
      writer.uint32(17).double(message.tilt);
    }
    if (message.fov !== 0) {
      writer.uint32(25).double(message.fov);
    }
    if (message.vfov !== 0) {
      writer.uint32(33).double(message.vfov);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ViewInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseViewInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.pan = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.tilt = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.fov = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.vfov = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ViewInfo {
    return {
      pan: isSet(object.pan) ? globalThis.Number(object.pan) : 0,
      tilt: isSet(object.tilt) ? globalThis.Number(object.tilt) : 0,
      fov: isSet(object.fov) ? globalThis.Number(object.fov) : 0,
      vfov: isSet(object.vfov) ? globalThis.Number(object.vfov) : 0,
    };
  },

  toJSON(message: ViewInfo): unknown {
    const obj: any = {};
    if (message.pan !== 0) {
      obj.pan = message.pan;
    }
    if (message.tilt !== 0) {
      obj.tilt = message.tilt;
    }
    if (message.fov !== 0) {
      obj.fov = message.fov;
    }
    if (message.vfov !== 0) {
      obj.vfov = message.vfov;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ViewInfo>, I>>(base?: I): ViewInfo {
    return ViewInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ViewInfo>, I>>(object: I): ViewInfo {
    const message = createBaseViewInfo();
    message.pan = object.pan ?? 0;
    message.tilt = object.tilt ?? 0;
    message.fov = object.fov ?? 0;
    message.vfov = object.vfov ?? 0;
    return message;
  },
};

function createBaseCamera(): Camera {
  return { info: undefined, view: undefined, latestImageId: 0 };
}

export const Camera = {
  encode(message: Camera, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.info !== undefined) {
      CameraInfo.encode(message.info, writer.uint32(10).fork()).ldelim();
    }
    if (message.view !== undefined) {
      ViewInfo.encode(message.view, writer.uint32(18).fork()).ldelim();
    }
    if (message.latestImageId !== 0) {
      writer.uint32(24).int64(message.latestImageId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Camera {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCamera();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.info = CameraInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.view = ViewInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latestImageId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Camera {
    return {
      info: isSet(object.info) ? CameraInfo.fromJSON(object.info) : undefined,
      view: isSet(object.view) ? ViewInfo.fromJSON(object.view) : undefined,
      latestImageId: isSet(object.latestImageId) ? globalThis.Number(object.latestImageId) : 0,
    };
  },

  toJSON(message: Camera): unknown {
    const obj: any = {};
    if (message.info !== undefined) {
      obj.info = CameraInfo.toJSON(message.info);
    }
    if (message.view !== undefined) {
      obj.view = ViewInfo.toJSON(message.view);
    }
    if (message.latestImageId !== 0) {
      obj.latestImageId = Math.round(message.latestImageId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Camera>, I>>(base?: I): Camera {
    return Camera.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Camera>, I>>(object: I): Camera {
    const message = createBaseCamera();
    message.info = (object.info !== undefined && object.info !== null)
      ? CameraInfo.fromPartial(object.info)
      : undefined;
    message.view = (object.view !== undefined && object.view !== null) ? ViewInfo.fromPartial(object.view) : undefined;
    message.latestImageId = object.latestImageId ?? 0;
    return message;
  },
};

function createBaseListCamerasReq(): ListCamerasReq {
  return {};
}

export const ListCamerasReq = {
  encode(_: ListCamerasReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCamerasReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCamerasReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListCamerasReq {
    return {};
  },

  toJSON(_: ListCamerasReq): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCamerasReq>, I>>(base?: I): ListCamerasReq {
    return ListCamerasReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCamerasReq>, I>>(_: I): ListCamerasReq {
    const message = createBaseListCamerasReq();
    return message;
  },
};

function createBaseListCamerasRes(): ListCamerasRes {
  return { cameras: [] };
}

export const ListCamerasRes = {
  encode(message: ListCamerasRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cameras) {
      Camera.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCamerasRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCamerasRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameras.push(Camera.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCamerasRes {
    return {
      cameras: globalThis.Array.isArray(object?.cameras) ? object.cameras.map((e: any) => Camera.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListCamerasRes): unknown {
    const obj: any = {};
    if (message.cameras?.length) {
      obj.cameras = message.cameras.map((e) => Camera.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCamerasRes>, I>>(base?: I): ListCamerasRes {
    return ListCamerasRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCamerasRes>, I>>(object: I): ListCamerasRes {
    const message = createBaseListCamerasRes();
    message.cameras = object.cameras?.map((e) => Camera.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCameraReq(): GetCameraReq {
  return { id: 0 };
}

export const GetCameraReq = {
  encode(message: GetCameraReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCameraReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraReq {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: GetCameraReq): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraReq>, I>>(base?: I): GetCameraReq {
    return GetCameraReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraReq>, I>>(object: I): GetCameraReq {
    const message = createBaseGetCameraReq();
    message.id = object.id ?? 0;
    return message;
  },
};

export interface Cameras {
  /** List cameras */
  listCameras(request: DeepPartial<ListCamerasReq>, metadata?: grpc.Metadata): Promise<ListCamerasRes>;
  /** Get camera info/metadata */
  getCamera(request: DeepPartial<GetCameraReq>, metadata?: grpc.Metadata): Promise<Camera>;
}

export class CamerasClientImpl implements Cameras {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.listCameras = this.listCameras.bind(this);
    this.getCamera = this.getCamera.bind(this);
  }

  listCameras(request: DeepPartial<ListCamerasReq>, metadata?: grpc.Metadata): Promise<ListCamerasRes> {
    return this.rpc.unary(CamerasListCamerasDesc, ListCamerasReq.fromPartial(request), metadata);
  }

  getCamera(request: DeepPartial<GetCameraReq>, metadata?: grpc.Metadata): Promise<Camera> {
    return this.rpc.unary(CamerasGetCameraDesc, GetCameraReq.fromPartial(request), metadata);
  }
}

export const CamerasDesc = { serviceName: "phoenix.cameras.v0.Cameras" };

export const CamerasListCamerasDesc: UnaryMethodDefinitionish = {
  methodName: "ListCameras",
  service: CamerasDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCamerasReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCamerasRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CamerasGetCameraDesc: UnaryMethodDefinitionish = {
  methodName: "GetCamera",
  service: CamerasDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCameraReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Camera.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
