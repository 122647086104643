import { styled } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";

import Map from "../map/Map";
import { useMap } from "../map/MapProvider";

const LayoutBase = styled("div")({
  display: "flex",
  position: "relative",
  width: "100%",
  height: "100%",
  minHeight: 0,
});
const MapChild = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    zIndex: 1,
  },
}));
const ListChild = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  [theme.breakpoints.up("md")]: {
    minHeight: 0,
    height: "100%",
    minWidth: 450,
    width: "33%",
    flexShrink: 0,
    overflowY: "auto",
    resize: "horizontal",
  },
}));

export default function Home({
  sidebar,
  dialog,
}: {
  sidebar?: ReactNode;
  dialog?: ReactNode;
}) {
  const map = useMap();
  const listDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!listDiv.current) return;
    const onResize = () => map?.invalidateSize();
    const observer = new ResizeObserver(onResize);
    observer.observe(listDiv.current);
    return () => observer.disconnect();
  }, [map]);
  return (
    <LayoutBase>
      <ListChild ref={listDiv}>{sidebar}</ListChild>
      <MapChild>
        <Map />
      </MapChild>
      {dialog}
    </LayoutBase>
  );
}
