import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";

import Button from "../simple/Button";

interface ConfirmationDialogWrapperProps {
  action: () => void;
  children: ({ doConfirmation }: { doConfirmation: () => void }) => ReactNode;
  skipConfirmation?: boolean;
  title?: string;
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
}

export default function ConfirmationDialogWrapper({
  action,
  children: renderFn,
  skipConfirmation = false,
  title,
  message,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
}: ConfirmationDialogWrapperProps) {
  const [isOpen, setIsOpen] = useState(false);
  const doConfirmation = useCallback(
    () => (skipConfirmation ? action() : setIsOpen(true)),
    [skipConfirmation, action]
  );
  const children = useMemo(
    () => renderFn({ doConfirmation }),
    [renderFn, doConfirmation]
  );
  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title ?? "Confirm Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message ?? "Are you sure you want to do that?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              action();
              setIsOpen(false);
            }}
          >
            {confirmLabel}
          </Button>
          <Button variant="outlined" onClick={() => setIsOpen(false)}>
            {cancelLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
}
