import Button from "@phoenix/common/components/simple/Button";
import { ReactNode } from "react";

export default function FilterButton({
  children,
  icon,
  onClick,
}: {
  children: ReactNode;
  icon?: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <Button
      onClick={onClick}
      sx={{ "&&": { backgroundColor: "background.paper" }, px: 2, py: 1 }}
      size="small"
      endIcon={icon}
    >
      {children}
    </Button>
  );
}
