import { Map } from "leaflet";
import React, { Context, ReactNode, useContext, useRef, useState } from "react";

type MapContextType = {
  map?: Map;
  setMap: React.Dispatch<React.SetStateAction<Map | undefined>>;
};
export const MapContext = React.createContext<null | MapContextType>(null);

function useMapContext(context: Context<MapContextType | null>) {
  const contextValue = useContext(context);
  if (contextValue === null) {
    throw Error("Tried to use the map outside the provider!");
  }
  return contextValue;
}

export default function MapProvider({ children }: { children: ReactNode }) {
  const mapRef = useRef<Map>();
  const [state, setState] = useState(mapRef.current);
  const contextValue = { map: state, setMap: setState };

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
}

export const useMap = () => useMapContext(MapContext).map;
export const useSetMap = () => useMapContext(MapContext).setMap;
