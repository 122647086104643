import { CheckOutlined } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { MouseEventHandler, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { themeLabels } from "../../../../constants";
import { useMapTheme } from "../../../../theme";

export function ThemeSelectorMenuItem({
  closeParentMenu,
}: {
  closeParentMenu: () => void;
}) {
  const { t } = useTranslation("common");
  const { themeKey, setThemeKey } = useMapTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuEl = useRef<HTMLDivElement>(null);
  const handleClose: MouseEventHandler<HTMLElement> = (e) => {
    if (!(e.relatedTarget instanceof HTMLElement)) return;
    if (
      !menuEl.current?.contains(e.relatedTarget) &&
      menuEl.current !== e.relatedTarget
    )
      setAnchorEl(null);
  };
  const handleCloseAll = () => {
    setAnchorEl(null);
    closeParentMenu();
  };

  return (
    <>
      <MenuItem
        onClick={(e) => setAnchorEl(e.currentTarget)}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={handleClose}
      >
        {t("components.UserMenu.selectThemeButton")}
      </MenuItem>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        ref={menuEl}
        style={{ pointerEvents: "none" }}
        PaperProps={{
          onMouseLeave: handleClose,
          style: { pointerEvents: "initial" },
        }}
      >
        {[...themeLabels(t).entries()].map(([key, label]) => (
          <MenuItem
            key={key}
            onClick={() => {
              setThemeKey(key);
              handleCloseAll();
            }}
          >
            {key === themeKey && (
              <ListItemIcon>
                <CheckOutlined />
              </ListItemIcon>
            )}
            <ListItemText inset={key !== themeKey}>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
