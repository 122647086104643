import { SetMLLeverReq } from "../proto/api";
import { CreateManualDetectionReq } from "../proto/detections";
import client from "./client";

export async function createManualDetection(
  imageId: number,
  points: [number, number][],
  reportId: number | null
) {
  const req: CreateManualDetectionReq = {
    imageId: imageId,
    points: points.map((p) => ({ x: p[0], y: p[1] })),
    reportId: reportId ?? 0,
  };
  const res = await client.detections.createManualDetection(req);
  return res;
}

export async function getMlLeverValue() {
  const res = await client.pxApi.getMlLever({});
  return res.value;
}

export async function setMlLeverValue(value: number) {
  const req: SetMLLeverReq = { value };
  return client.pxApi.setMlLever(req);
}
