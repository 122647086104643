import {
  Box,
  CircularProgress as MUICircularProgress,
  CircularProgressProps,
  styled,
} from "@mui/material";

const CenteredCircularProgress = styled(MUICircularProgress)({
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
});

export default function CircularProgress({
  overlay,
  mt,
  size,
}: {
  overlay?: boolean;
  mt?: number;
  size?: CircularProgressProps["size"];
}) {
  return (
    <Box
      position={overlay ? "absolute" : undefined}
      left={0}
      right={0}
      top={0}
      mt={mt}
    >
      <CenteredCircularProgress size={size} />
    </Box>
  );
}
