import { useCallback, useState } from "react";

export function usePersistedState<T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] {
  const saved = window.localStorage.getItem(key);
  let parsed;
  try {
    parsed = saved !== null ? JSON.parse(saved) : null;
  } catch (e) {
    parsed = null;
  }
  const [_state, _setState] = useState<T>(
    parsed !== null ? parsed : defaultValue
  );
  const setState = useCallback(
    (value: T) => {
      if (value === undefined) {
        console.warn(`${key} can't be stored as undefined, casting to null.`);
      }
      const v = value === undefined ? null : value;
      window.localStorage.setItem(key, JSON.stringify(v));
      _setState(value);
    },
    [key]
  );
  return [_state, setState];
}
