import * as admin from "./admin";
import * as auth from "./auth";
import * as cameras from "./cameras";
import * as detections from "./detections";
import * as reports from "./reports";
import * as user from "./user";
import * as satellites from "./satellites";
import * as settings from "./settings";

const service = {
  admin,
  auth,
  cameras,
  detections,
  reports,
  satellites,
  settings,
  user,
};

export default service;
