import { Link as MuiLink, styled, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import { navbarHeightMd, navbarHeightXs } from "../../../theme";
import LogoText from "./LogoText";
import UserMenu from "./UserMenu";

const NavContainer = styled("nav")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  zIndex: 401 /* hack for leaflet */,
  padding: theme.spacing(1),
  height: navbarHeightXs,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
    height: navbarHeightMd,
  },
}));
const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  [theme.breakpoints.up("md")]: {},
  "& > *": {
    height: "100%",
    marginInlineEnd: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginInlineEnd: theme.spacing(2),
    },
  },
}));

export default function Navigation() {
  const theme = useTheme();
  return (
    <NavContainer>
      <MuiLink to="/" component={Link}>
        <ImageContainer>
          <LogoText dark={theme.palette.mode === "dark"} />
        </ImageContainer>
      </MuiLink>
      <UserMenu />
    </NavContainer>
  );
}
