import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Container,
  IconButton,
  InputAdornment,
  styled,
  TextField,
} from "@mui/material";
import Button from "@phoenix/common/components/simple/Button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import PageTitle from "../../../components/simple/PageTitle";
import { useAuthContext } from "../AuthProvider";

const Form = styled("form")(({ theme }) => ({ marginTop: theme.spacing(1) }));
const LoginField = styled(TextField)(({ theme }) => ({
  display: "block",
  margin: theme.spacing(2, 0),
}));
const LoginButton = styled(Button)(({ theme }) => ({
  display: "block",
  margin: theme.spacing(4, 0),
}));

export default function Login() {
  const { t } = useTranslation("auth");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>();
  const auth = useAuthContext();

  const onSubmit = handleSubmit(({ email, password }) => {
    auth.actions.clearError();
    auth.actions.login({ email, password });
  });

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container maxWidth="md">
      {auth.state.authenticated && <Navigate to="/" />}
      <PageTitle>{t("login")}</PageTitle>
      <Form onSubmit={onSubmit}>
        {auth.state.error && <Alert severity="error">{auth.state.error}</Alert>}
        <LoginField
          id="email"
          label={t("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
          variant="outlined"
          {...register("email", { required: true })}
        />
        <LoginField
          id="password"
          label={t("password")}
          type={showPassword ? "text" : "password"}
          error={!!errors.password}
          helperText={errors.password?.message}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  onMouseDown={undefined}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register("password", { required: true })}
        />
        <LoginButton
          type="submit"
          variant="contained"
          loading={auth.state.loading}
        >
          {t("ok")}
        </LoginButton>
      </Form>
    </Container>
  );
}
