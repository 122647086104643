import { useTranslation } from "react-i18next";

///TODO: Nice error page
export default function FatalError({ error }: { error: Error }) {
  const { t } = useTranslation("error");
  return (
    <>
      <div>{t("fatalErrorText")}</div>
      <div>{error.message}</div>
    </>
  );
}
