import client from "./client";

export async function login({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return await client.auth.authenticate({ email, password });
}

export function logout() {
  return client.auth.deauthenticate({});
}

export function completePasswordReset({
  token,
  password,
}: {
  token: string;
  password: string;
}) {
  return client.auth.completePasswordReset({
    passwordResetToken: token,
    newPassword: password,
  });
}
