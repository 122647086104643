import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import StyledLink from "@phoenix/common/components/simple/StyledLink";
import service from "@phoenix/common/service";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../../../../features/auth/AuthProvider";
import { userKey } from "../../../../queryKeys";
import routes from "../../../../routes";
import LeverDialogMenuItem from "./LeverDialogMenuItem";
import { NotificationsCheckboxMenuItem } from "./NotificationsCheckboxMenuItem";
import { ThemeSelectorMenuItem } from "./ThemeSelectorMenuItem";

export default function UserMenu() {
  const { t } = useTranslation("common");
  const auth = useAuthContext();
  const userInfoQuery = useQuery(userKey, service.user.getUserInfo, {
    enabled: auth.state.authenticated,
  });
  const canSetLever = auth.state.scopes.includes("api.ml_lever");

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleClose = () => setMenuAnchor(null);

  const userEmail = userInfoQuery.data?.email;
  useEffect(() => {
    if (!userEmail) return;
    Sentry.setUser({ email: userEmail });
  }, [userEmail]);

  if (!auth.state.authenticated) return null;

  return userInfoQuery.status == "loading" ? (
    <Box display="flex" alignItems="center">
      <Skeleton variant="text" width={64} />
      <Box mx={0.5} />
      <Skeleton variant="circular" height={32} width={32} />
    </Box>
  ) : userInfoQuery.status == "success" ? (
    <>
      <ButtonBase onClick={(event) => setMenuAnchor(event.currentTarget)}>
        <Typography>{userInfoQuery.data.name.split(" ")[0]}</Typography>
        <Box mx={0.5} component="span" />
        <Avatar sx={{ height: 32, width: 32 }} component="span">
          {userInfoQuery.data.name.slice(0, 1)}
        </Avatar>
      </ButtonBase>
      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={handleClose}>
        {canSetLever && <LeverDialogMenuItem closeParentMenu={handleClose} />}
        <NotificationsCheckboxMenuItem />
        <ThemeSelectorMenuItem closeParentMenu={handleClose} />
        <MenuItem>
          <StyledLink
            to={routes.logout}
            color="inherit"
            underline="none"
            onClick={handleClose}
          >
            {t("components.UserMenu.logout")}
          </StyledLink>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <ButtonBase onClick={(event) => setMenuAnchor(event.currentTarget)}>
        <Avatar sx={{ height: 32, width: 32 }} component="span">
          ?
        </Avatar>
      </ButtonBase>
      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={handleClose}>
        {canSetLever && <LeverDialogMenuItem closeParentMenu={handleClose} />}
        <NotificationsCheckboxMenuItem />
        <ThemeSelectorMenuItem closeParentMenu={handleClose} />
        <MenuItem>
          <StyledLink to={routes.logout} color="inherit" underline="none">
            {t("components.UserMenu.logout")}
          </StyledLink>
        </MenuItem>
      </Menu>
    </>
  );
}
