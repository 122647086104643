import { Typography } from "@mui/material";
import { useEffect } from "react";

export default function PageTitle({
  children,
  noSiteName,
  noText,
}: {
  children: string;
  noSiteName?: boolean;
  noText?: boolean;
}) {
  useEffect(() => {
    document.title = noSiteName ? children : `${children} - Fireball Map`;
  }, [children, noSiteName]);

  return noText ? null : <Typography variant="h1">{children}</Typography>;
}
