import { ListReportsRes } from "@phoenix/common/proto/reports";
import service from "@phoenix/common/service";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { reportsKey } from "../../queryKeys";
import { useAuthContext } from "../auth/AuthProvider";
import {
  reportListTimeRangeAtom,
  reportsSortAtom,
  useFiltersValue,
} from "../state";

export type ReportsSort = {
  column: "first" | "latest";
  order: "desc" | "asc";
};

export const useReportsQueryOptions = () => {
  const range = useAtomValue(reportListTimeRangeAtom);
  const filters = useFiltersValue();
  const sort = useAtomValue(reportsSortAtom);
  const queryKey = reportsKey({ filters, range });
  return { range, filters, sort, queryKey };
};

export default function useReportsQuery() {
  const { range, filters, sort, queryKey } = useReportsQueryOptions();
  const {
    state: { scopes },
  } = useAuthContext();

  const reviewedReportsOnly = !scopes.includes("reports.list_reports");

  const query = useQuery<ListReportsRes, RpcError>(
    queryKey,
    async () => {
      const filtersWithRange = {
        ...filters,
        after: new Date(Date.now() + range[0] * 60 * 60 * 1000),
        before: new Date(Date.now() + range[1] * 60 * 60 * 1000),
      };
      const res = await service.reports.listReports(
        filtersWithRange,
        reviewedReportsOnly
      );
      const reports = res.reports;
      if (sort.column === "first") {
        reports.sort((a, b) =>
          a.firstDetectionTime! > b.firstDetectionTime!
            ? sort.order === "asc"
              ? 1
              : -1
            : sort.order === "asc"
            ? -1
            : 1
        );
      } else if (sort.column === "latest") {
        reports.sort((a, b) =>
          a.latestDetectionTime! > b.latestDetectionTime!
            ? sort.order === "asc"
              ? 1
              : -1
            : sort.order === "asc"
            ? -1
            : 1
        );
      }
      return { ...res, reports };
    },
    {
      keepPreviousData: true,
      refetchInterval: import.meta.env.PROD ? 5000 : undefined,
      refetchIntervalInBackground: true,
    }
  );

  return query;
}
