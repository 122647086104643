import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { timeStyleAtom } from "../../state";
import FilterButton from "./FilterButton";

export default function TimeStyleButton() {
  const { t } = useTranslation("reportsList");
  const [timeStyle, setTimeStyle] = useAtom(timeStyleAtom);

  const handleClick = () => {
    setTimeStyle((prev) => (prev === "absolute" ? "relative" : "absolute"));
  };

  return (
    <FilterButton onClick={handleClick}>
      {timeStyle === "absolute"
        ? t("absoluteTimeLabel")
        : t("relativeTimeLabel")}
    </FilterButton>
  );
}
