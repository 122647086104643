/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Duration } from "./google/protobuf/duration";
import { BoolValue, DoubleValue, Int32Value } from "./google/protobuf/wrappers";

export const protobufPackage = "phoenix.types.v0";

export const DetectionType = {
  DETECTION_TYPE_UNKNOWN: 0,
  DETECTION_TYPE_CAMERA: 1,
  DETECTION_TYPE_SATELLITE: 2,
  UNRECOGNIZED: -1,
} as const;

export type DetectionType = typeof DetectionType[keyof typeof DetectionType];

export namespace DetectionType {
  export type DETECTION_TYPE_UNKNOWN = typeof DetectionType.DETECTION_TYPE_UNKNOWN;
  export type DETECTION_TYPE_CAMERA = typeof DetectionType.DETECTION_TYPE_CAMERA;
  export type DETECTION_TYPE_SATELLITE = typeof DetectionType.DETECTION_TYPE_SATELLITE;
  export type UNRECOGNIZED = typeof DetectionType.UNRECOGNIZED;
}

export function detectionTypeFromJSON(object: any): DetectionType {
  switch (object) {
    case 0:
    case "DETECTION_TYPE_UNKNOWN":
      return DetectionType.DETECTION_TYPE_UNKNOWN;
    case 1:
    case "DETECTION_TYPE_CAMERA":
      return DetectionType.DETECTION_TYPE_CAMERA;
    case 2:
    case "DETECTION_TYPE_SATELLITE":
      return DetectionType.DETECTION_TYPE_SATELLITE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DetectionType.UNRECOGNIZED;
  }
}

export function detectionTypeToJSON(object: DetectionType): string {
  switch (object) {
    case DetectionType.DETECTION_TYPE_UNKNOWN:
      return "DETECTION_TYPE_UNKNOWN";
    case DetectionType.DETECTION_TYPE_CAMERA:
      return "DETECTION_TYPE_CAMERA";
    case DetectionType.DETECTION_TYPE_SATELLITE:
      return "DETECTION_TYPE_SATELLITE";
    case DetectionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export const ConfirmationState = {
  CONFIRMATION_STATE_UNKNOWN: 0,
  CONFIRMATION_STATE_UNCONFIRMED: 1,
  CONFIRMATION_STATE_CONFIRMED: 2,
  CONFIRMATION_STATE_REJECTED: 3,
  CONFIRMATION_STATE_WATCH: 4,
  UNRECOGNIZED: -1,
} as const;

export type ConfirmationState = typeof ConfirmationState[keyof typeof ConfirmationState];

export namespace ConfirmationState {
  export type CONFIRMATION_STATE_UNKNOWN = typeof ConfirmationState.CONFIRMATION_STATE_UNKNOWN;
  export type CONFIRMATION_STATE_UNCONFIRMED = typeof ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED;
  export type CONFIRMATION_STATE_CONFIRMED = typeof ConfirmationState.CONFIRMATION_STATE_CONFIRMED;
  export type CONFIRMATION_STATE_REJECTED = typeof ConfirmationState.CONFIRMATION_STATE_REJECTED;
  export type CONFIRMATION_STATE_WATCH = typeof ConfirmationState.CONFIRMATION_STATE_WATCH;
  export type UNRECOGNIZED = typeof ConfirmationState.UNRECOGNIZED;
}

export function confirmationStateFromJSON(object: any): ConfirmationState {
  switch (object) {
    case 0:
    case "CONFIRMATION_STATE_UNKNOWN":
      return ConfirmationState.CONFIRMATION_STATE_UNKNOWN;
    case 1:
    case "CONFIRMATION_STATE_UNCONFIRMED":
      return ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED;
    case 2:
    case "CONFIRMATION_STATE_CONFIRMED":
      return ConfirmationState.CONFIRMATION_STATE_CONFIRMED;
    case 3:
    case "CONFIRMATION_STATE_REJECTED":
      return ConfirmationState.CONFIRMATION_STATE_REJECTED;
    case 4:
    case "CONFIRMATION_STATE_WATCH":
      return ConfirmationState.CONFIRMATION_STATE_WATCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfirmationState.UNRECOGNIZED;
  }
}

export function confirmationStateToJSON(object: ConfirmationState): string {
  switch (object) {
    case ConfirmationState.CONFIRMATION_STATE_UNKNOWN:
      return "CONFIRMATION_STATE_UNKNOWN";
    case ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED:
      return "CONFIRMATION_STATE_UNCONFIRMED";
    case ConfirmationState.CONFIRMATION_STATE_CONFIRMED:
      return "CONFIRMATION_STATE_CONFIRMED";
    case ConfirmationState.CONFIRMATION_STATE_REJECTED:
      return "CONFIRMATION_STATE_REJECTED";
    case ConfirmationState.CONFIRMATION_STATE_WATCH:
      return "CONFIRMATION_STATE_WATCH";
    case ConfirmationState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DetectionId {
  id: number;
  type: DetectionType;
}

export interface Point {
  x: number;
  y: number;
}

export interface Polygon {
  points: Point[];
}

/**
 * wrapper field to support partial update of a
 * nullable bool field on a model
 */
export interface BoolValueUpdate {
  update: boolean | undefined;
}

/**
 * wrapper field to support partial update of a
 * nullable double field on a model
 */
export interface DoubleValueUpdate {
  update: number | undefined;
}

/**
 * wrapper field to support partial update of a
 * nullable int32 field on a model
 */
export interface Int32ValueUpdate {
  update: number | undefined;
}

/**
 * wrapper field to support partial update of a
 * nullable duration field on a model
 */
export interface DurationUpdate {
  update: Duration | undefined;
}

function createBaseDetectionId(): DetectionId {
  return { id: 0, type: 0 };
}

export const DetectionId = {
  encode(message: DetectionId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetectionId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetectionId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetectionId {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      type: isSet(object.type) ? detectionTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: DetectionId): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.type !== 0) {
      obj.type = detectionTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetectionId>, I>>(base?: I): DetectionId {
    return DetectionId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetectionId>, I>>(object: I): DetectionId {
    const message = createBaseDetectionId();
    message.id = object.id ?? 0;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBasePoint(): Point {
  return { x: 0, y: 0 };
}

export const Point = {
  encode(message: Point, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.x !== 0) {
      writer.uint32(9).double(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(17).double(message.y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Point {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.x = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.y = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Point {
    return {
      x: isSet(object.x) ? globalThis.Number(object.x) : 0,
      y: isSet(object.y) ? globalThis.Number(object.y) : 0,
    };
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    if (message.x !== 0) {
      obj.x = message.x;
    }
    if (message.y !== 0) {
      obj.y = message.y;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Point>, I>>(base?: I): Point {
    return Point.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Point>, I>>(object: I): Point {
    const message = createBasePoint();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    return message;
  },
};

function createBasePolygon(): Polygon {
  return { points: [] };
}

export const Polygon = {
  encode(message: Polygon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.points) {
      Point.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Polygon {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.points.push(Point.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Polygon {
    return { points: globalThis.Array.isArray(object?.points) ? object.points.map((e: any) => Point.fromJSON(e)) : [] };
  },

  toJSON(message: Polygon): unknown {
    const obj: any = {};
    if (message.points?.length) {
      obj.points = message.points.map((e) => Point.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Polygon>, I>>(base?: I): Polygon {
    return Polygon.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Polygon>, I>>(object: I): Polygon {
    const message = createBasePolygon();
    message.points = object.points?.map((e) => Point.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBoolValueUpdate(): BoolValueUpdate {
  return { update: undefined };
}

export const BoolValueUpdate = {
  encode(message: BoolValueUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.update !== undefined) {
      BoolValue.encode({ value: message.update! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BoolValueUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoolValueUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.update = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BoolValueUpdate {
    return { update: isSet(object.update) ? Boolean(object.update) : undefined };
  },

  toJSON(message: BoolValueUpdate): unknown {
    const obj: any = {};
    if (message.update !== undefined) {
      obj.update = message.update;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BoolValueUpdate>, I>>(base?: I): BoolValueUpdate {
    return BoolValueUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BoolValueUpdate>, I>>(object: I): BoolValueUpdate {
    const message = createBaseBoolValueUpdate();
    message.update = object.update ?? undefined;
    return message;
  },
};

function createBaseDoubleValueUpdate(): DoubleValueUpdate {
  return { update: undefined };
}

export const DoubleValueUpdate = {
  encode(message: DoubleValueUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.update !== undefined) {
      DoubleValue.encode({ value: message.update! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DoubleValueUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoubleValueUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.update = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DoubleValueUpdate {
    return { update: isSet(object.update) ? Number(object.update) : undefined };
  },

  toJSON(message: DoubleValueUpdate): unknown {
    const obj: any = {};
    if (message.update !== undefined) {
      obj.update = message.update;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DoubleValueUpdate>, I>>(base?: I): DoubleValueUpdate {
    return DoubleValueUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DoubleValueUpdate>, I>>(object: I): DoubleValueUpdate {
    const message = createBaseDoubleValueUpdate();
    message.update = object.update ?? undefined;
    return message;
  },
};

function createBaseInt32ValueUpdate(): Int32ValueUpdate {
  return { update: undefined };
}

export const Int32ValueUpdate = {
  encode(message: Int32ValueUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.update !== undefined) {
      Int32Value.encode({ value: message.update! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Int32ValueUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInt32ValueUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.update = Int32Value.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Int32ValueUpdate {
    return { update: isSet(object.update) ? Number(object.update) : undefined };
  },

  toJSON(message: Int32ValueUpdate): unknown {
    const obj: any = {};
    if (message.update !== undefined) {
      obj.update = message.update;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Int32ValueUpdate>, I>>(base?: I): Int32ValueUpdate {
    return Int32ValueUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Int32ValueUpdate>, I>>(object: I): Int32ValueUpdate {
    const message = createBaseInt32ValueUpdate();
    message.update = object.update ?? undefined;
    return message;
  },
};

function createBaseDurationUpdate(): DurationUpdate {
  return { update: undefined };
}

export const DurationUpdate = {
  encode(message: DurationUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.update !== undefined) {
      Duration.encode(message.update, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DurationUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDurationUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.update = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DurationUpdate {
    return { update: isSet(object.update) ? Duration.fromJSON(object.update) : undefined };
  },

  toJSON(message: DurationUpdate): unknown {
    const obj: any = {};
    if (message.update !== undefined) {
      obj.update = Duration.toJSON(message.update);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DurationUpdate>, I>>(base?: I): DurationUpdate {
    return DurationUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DurationUpdate>, I>>(object: I): DurationUpdate {
    const message = createBaseDurationUpdate();
    message.update = (object.update !== undefined && object.update !== null)
      ? Duration.fromPartial(object.update)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
