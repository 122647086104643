import { FireExtinguisherOutlined } from "@mui/icons-material";
import { alpha, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import { ReportSummary } from "@phoenix/common/proto/reports";
import { usePreloadImages } from "@phoenix/common/utils/usePreloadImage";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { InView } from "react-intersection-observer";

import ErrorWithIcon from "../../components/compound/ErrorWithIcon";
import ResponsiveList from "../../components/layout/ResponsiveList";
import PageTitle from "../../components/simple/PageTitle";
import { useAuthContext } from "../auth/AuthProvider";
import { highlightedReportAtom, reportListScrollIdAtom } from "../state";
import FiltersPanel from "./FiltersPanel";
import SortButton from "./FiltersPanel/SortButton";
import SourcesButton from "./FiltersPanel/SourcesButton";
import StateButton from "./FiltersPanel/StateButton";
import TimeRangeButton from "./FiltersPanel/TimeRangeButton";
import TimeStyleButton from "./FiltersPanel/TimeStyleButton";
import ReportCard from "./ReportCard";
import useReportsQuery from "./useReportsQuery";

export default function ReportsList() {
  const { t } = useTranslation("reportsList");
  const {
    state: { scopes },
  } = useAuthContext();
  const reportsQuery = useReportsQuery();

  const preloadImagesList = useMemo(
    () =>
      reportsQuery.data
        ? reportsQuery.data.reports
            .flatMap((report) => report.preloadImageUrls)
            .slice(0, 5)
        : [],
    [reportsQuery.data]
  );
  usePreloadImages(preloadImagesList);

  //scroll to previous report id on first load
  const reportListScrollId = useAtomValue(reportListScrollIdAtom);
  //this is reset to null after scrolling
  const scrollToId = useRef<number | null>(reportListScrollId);
  useEffect(() => {
    if (scrollToId.current && reportsQuery.data) {
      document.getElementById(`report-${scrollToId.current}`)?.scrollIntoView();
      scrollToId.current = null;
    }
  }, [reportsQuery.data]);

  const [highlighted, setHighlighted] = useAtom(highlightedReportAtom);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleMouseEnter = useCallback(
    (report: ReportSummary) => setHighlighted(report),
    [setHighlighted]
  );
  const handleMouseLeave = useCallback(
    () => setHighlighted(null),
    [setHighlighted]
  );

  return (
    <>
      <PageTitle noSiteName noText>
        {t("pageTitle")}
      </PageTitle>
      <ResponsiveList
        useScrollToTop
        prepend={
          <FiltersPanel>
            <SortButton />
            <TimeStyleButton />
            <TimeRangeButton />
            {scopes.includes("reports.list_reports") && <StateButton />}
            <SourcesButton />
          </FiltersPanel>
        }
      >
        {reportsQuery.status === "loading" ? (
          <CircularProgress />
        ) : reportsQuery.status === "error" ? (
          <ErrorWithIcon message={reportsQuery.error.message} fontSize={32} />
        ) : null}
        {reportsQuery.data && (
          <>
            {reportsQuery.data?.hasMore && (
              <Typography
                sx={{
                  color: (theme) => theme.typography.caption.color,
                  py: 1,
                  padding: { xs: "1rem", md: "unset" },
                  background: (theme) => theme.palette.background.default,
                  borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                  "&&": { minWidth: { xs: "12rem", md: "unset" } },
                }}
              >
                {t("notAllReportsWarning")}
              </Typography>
            )}
            {reportsQuery.data.reports.map((report) => (
              <InView
                onChange={
                  isMdDown
                    ? (inView) => (inView ? handleMouseEnter(report) : null)
                    : undefined
                }
                threshold={1}
                key={report.id}
              >
                <ReportCard
                  report={report}
                  highlight={highlighted?.id === report.id}
                  onMouseEnter={isMdDown ? undefined : handleMouseEnter}
                  onMouseLeave={isMdDown ? undefined : handleMouseLeave}
                />
              </InView>
            ))}
            {
              //isFetched seems to be the only way to differentiate between a refetch and a change in query key
              !reportsQuery.isFetched && <CircularProgress overlay mt={1} />
            }
          </>
        )}
        {reportsQuery.data?.reports.length === 0 && <EmptyState />}
      </ResponsiveList>
    </>
  );
}

function EmptyState() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: (theme) => theme.typography.caption,
        background: (theme) => alpha(theme.palette.background.default, 0.6),
        padding: 2,
      }}
    >
      <FireExtinguisherOutlined fontSize="inherit" sx={{ fontSize: 96 }} />
      <Box my={1} />
      <Typography>No reports to show.</Typography>
    </Box>
  );
}
