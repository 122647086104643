/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { CameraNetwork, CameraNetworkUpdate } from "./camera_networks";
import { DbCamera, DbCameraUpdate } from "./db_cameras";
import { Empty } from "./google/protobuf/empty";
import { Timestamp } from "./google/protobuf/timestamp";
import { CameraImage } from "./images";
import { GetReportReq } from "./reports";
import { ConfirmationState, confirmationStateFromJSON, confirmationStateToJSON } from "./types";
import {
  NotificationMethod,
  notificationMethodFromJSON,
  notificationMethodToJSON,
  User,
  UserGroup,
  UserGroupUpdate,
  UserUpdate,
} from "./user";

export const protobufPackage = "phoenix.admin.v0";

export interface ResetPasswordReq {
  email: string;
}

export interface ResetPasswordRes {
  passwordResetToken: string;
}

export interface UserInfo {
  id: number;
  name: string;
  email: string;
}

export interface APIKey {
  key: string;
  user: UserInfo | undefined;
  created: Date | undefined;
  expiry: Date | undefined;
}

export interface ListAPIKeysReq {
  /** optional user filter */
  userId: number;
}

export interface ListAPIKeysRes {
  apiKeys: APIKey[];
}

export interface CreateAPIKeyReq {
  userId: number;
  expiry: Date | undefined;
}

export interface CreateAPIKeyRes {
  apiKey: APIKey | undefined;
}

export interface DeleteAPIKeyReq {
  key: string;
}

export interface CreateUserReq {
  user: User | undefined;
}

export interface UpdateUserReq {
  user: UserUpdate | undefined;
}

export interface ListUsersRes {
  users: User[];
}

export interface CreateUserGroupReq {
  group: UserGroup | undefined;
}

export interface UpdateUserGroupReq {
  group: UserGroupUpdate | undefined;
}

export interface ListUserGroupsRes {
  groups: UserGroup[];
}

export interface ListCameraNetworksRes {
  cameraNetworks: CameraNetwork[];
}

export interface CreateCameraNetworkReq {
  cameraNetwork: CameraNetwork | undefined;
}

export interface UpdateCameraNetworkReq {
  cameraNetwork: CameraNetworkUpdate | undefined;
}

export interface ListCamerasRes {
  cameras: DbCamera[];
}

export interface CreateCameraReq {
  camera: DbCamera | undefined;
}

export interface UpdateCameraReq {
  camera: DbCameraUpdate | undefined;
}

export interface ReportInfo {
  id: number;
  state: ConfirmationState;
  reason: string;
  /** User-facing description of location, eg. Kern County, California, USA */
  location: string;
  latitude: number;
  longitude: number;
  createdTime: Date | undefined;
  areaGeojson: string;
}

export interface NotificationInfo {
  id: number;
  sentAt: Date | undefined;
  recipient: string;
  reason: string;
  method: NotificationMethod;
  success: boolean;
}

export interface ReportAction {
  id: number;
  userId: number;
  userEmail: string;
  performedAt: Date | undefined;
  action: string;
}

/**
 * Report details
 * All lists are in ascending date order
 */
export interface GetReportDetailsRes {
  report: ReportInfo | undefined;
  images: CameraImage[];
  notifications: NotificationInfo[];
  actions: ReportAction[];
}

function createBaseResetPasswordReq(): ResetPasswordReq {
  return { email: "" };
}

export const ResetPasswordReq = {
  encode(message: ResetPasswordReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetPasswordReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResetPasswordReq {
    return { email: isSet(object.email) ? globalThis.String(object.email) : "" };
  },

  toJSON(message: ResetPasswordReq): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetPasswordReq>, I>>(base?: I): ResetPasswordReq {
    return ResetPasswordReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetPasswordReq>, I>>(object: I): ResetPasswordReq {
    const message = createBaseResetPasswordReq();
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseResetPasswordRes(): ResetPasswordRes {
  return { passwordResetToken: "" };
}

export const ResetPasswordRes = {
  encode(message: ResetPasswordRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.passwordResetToken !== "") {
      writer.uint32(10).string(message.passwordResetToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetPasswordRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPasswordRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.passwordResetToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResetPasswordRes {
    return { passwordResetToken: isSet(object.passwordResetToken) ? globalThis.String(object.passwordResetToken) : "" };
  },

  toJSON(message: ResetPasswordRes): unknown {
    const obj: any = {};
    if (message.passwordResetToken !== "") {
      obj.passwordResetToken = message.passwordResetToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetPasswordRes>, I>>(base?: I): ResetPasswordRes {
    return ResetPasswordRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetPasswordRes>, I>>(object: I): ResetPasswordRes {
    const message = createBaseResetPasswordRes();
    message.passwordResetToken = object.passwordResetToken ?? "";
    return message;
  },
};

function createBaseUserInfo(): UserInfo {
  return { id: 0, name: "", email: "" };
}

export const UserInfo = {
  encode(message: UserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInfo {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
    };
  },

  toJSON(message: UserInfo): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInfo>, I>>(base?: I): UserInfo {
    return UserInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserInfo>, I>>(object: I): UserInfo {
    const message = createBaseUserInfo();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseAPIKey(): APIKey {
  return { key: "", user: undefined, created: undefined, expiry: undefined };
}

export const APIKey = {
  encode(message: APIKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.user !== undefined) {
      UserInfo.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.expiry !== undefined) {
      Timestamp.encode(toTimestamp(message.expiry), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): APIKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAPIKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = UserInfo.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expiry = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): APIKey {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      user: isSet(object.user) ? UserInfo.fromJSON(object.user) : undefined,
      created: isSet(object.created) ? fromJsonTimestamp(object.created) : undefined,
      expiry: isSet(object.expiry) ? fromJsonTimestamp(object.expiry) : undefined,
    };
  },

  toJSON(message: APIKey): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.user !== undefined) {
      obj.user = UserInfo.toJSON(message.user);
    }
    if (message.created !== undefined) {
      obj.created = message.created.toISOString();
    }
    if (message.expiry !== undefined) {
      obj.expiry = message.expiry.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<APIKey>, I>>(base?: I): APIKey {
    return APIKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<APIKey>, I>>(object: I): APIKey {
    const message = createBaseAPIKey();
    message.key = object.key ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? UserInfo.fromPartial(object.user) : undefined;
    message.created = object.created ?? undefined;
    message.expiry = object.expiry ?? undefined;
    return message;
  },
};

function createBaseListAPIKeysReq(): ListAPIKeysReq {
  return { userId: 0 };
}

export const ListAPIKeysReq = {
  encode(message: ListAPIKeysReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int64(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAPIKeysReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAPIKeysReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAPIKeysReq {
    return { userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0 };
  },

  toJSON(message: ListAPIKeysReq): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAPIKeysReq>, I>>(base?: I): ListAPIKeysReq {
    return ListAPIKeysReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAPIKeysReq>, I>>(object: I): ListAPIKeysReq {
    const message = createBaseListAPIKeysReq();
    message.userId = object.userId ?? 0;
    return message;
  },
};

function createBaseListAPIKeysRes(): ListAPIKeysRes {
  return { apiKeys: [] };
}

export const ListAPIKeysRes = {
  encode(message: ListAPIKeysRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.apiKeys) {
      APIKey.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListAPIKeysRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAPIKeysRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apiKeys.push(APIKey.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAPIKeysRes {
    return {
      apiKeys: globalThis.Array.isArray(object?.apiKeys) ? object.apiKeys.map((e: any) => APIKey.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListAPIKeysRes): unknown {
    const obj: any = {};
    if (message.apiKeys?.length) {
      obj.apiKeys = message.apiKeys.map((e) => APIKey.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListAPIKeysRes>, I>>(base?: I): ListAPIKeysRes {
    return ListAPIKeysRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListAPIKeysRes>, I>>(object: I): ListAPIKeysRes {
    const message = createBaseListAPIKeysRes();
    message.apiKeys = object.apiKeys?.map((e) => APIKey.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateAPIKeyReq(): CreateAPIKeyReq {
  return { userId: 0, expiry: undefined };
}

export const CreateAPIKeyReq = {
  encode(message: CreateAPIKeyReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== 0) {
      writer.uint32(8).int64(message.userId);
    }
    if (message.expiry !== undefined) {
      Timestamp.encode(toTimestamp(message.expiry), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAPIKeyReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAPIKeyReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.userId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.expiry = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAPIKeyReq {
    return {
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      expiry: isSet(object.expiry) ? fromJsonTimestamp(object.expiry) : undefined,
    };
  },

  toJSON(message: CreateAPIKeyReq): unknown {
    const obj: any = {};
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.expiry !== undefined) {
      obj.expiry = message.expiry.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAPIKeyReq>, I>>(base?: I): CreateAPIKeyReq {
    return CreateAPIKeyReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAPIKeyReq>, I>>(object: I): CreateAPIKeyReq {
    const message = createBaseCreateAPIKeyReq();
    message.userId = object.userId ?? 0;
    message.expiry = object.expiry ?? undefined;
    return message;
  },
};

function createBaseCreateAPIKeyRes(): CreateAPIKeyRes {
  return { apiKey: undefined };
}

export const CreateAPIKeyRes = {
  encode(message: CreateAPIKeyRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.apiKey !== undefined) {
      APIKey.encode(message.apiKey, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAPIKeyRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAPIKeyRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.apiKey = APIKey.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAPIKeyRes {
    return { apiKey: isSet(object.apiKey) ? APIKey.fromJSON(object.apiKey) : undefined };
  },

  toJSON(message: CreateAPIKeyRes): unknown {
    const obj: any = {};
    if (message.apiKey !== undefined) {
      obj.apiKey = APIKey.toJSON(message.apiKey);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAPIKeyRes>, I>>(base?: I): CreateAPIKeyRes {
    return CreateAPIKeyRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAPIKeyRes>, I>>(object: I): CreateAPIKeyRes {
    const message = createBaseCreateAPIKeyRes();
    message.apiKey = (object.apiKey !== undefined && object.apiKey !== null)
      ? APIKey.fromPartial(object.apiKey)
      : undefined;
    return message;
  },
};

function createBaseDeleteAPIKeyReq(): DeleteAPIKeyReq {
  return { key: "" };
}

export const DeleteAPIKeyReq = {
  encode(message: DeleteAPIKeyReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAPIKeyReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAPIKeyReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteAPIKeyReq {
    return { key: isSet(object.key) ? globalThis.String(object.key) : "" };
  },

  toJSON(message: DeleteAPIKeyReq): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAPIKeyReq>, I>>(base?: I): DeleteAPIKeyReq {
    return DeleteAPIKeyReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAPIKeyReq>, I>>(object: I): DeleteAPIKeyReq {
    const message = createBaseDeleteAPIKeyReq();
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseCreateUserReq(): CreateUserReq {
  return { user: undefined };
}

export const CreateUserReq = {
  encode(message: CreateUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUserReq {
    return { user: isSet(object.user) ? User.fromJSON(object.user) : undefined };
  },

  toJSON(message: CreateUserReq): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUserReq>, I>>(base?: I): CreateUserReq {
    return CreateUserReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserReq>, I>>(object: I): CreateUserReq {
    const message = createBaseCreateUserReq();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseUpdateUserReq(): UpdateUserReq {
  return { user: undefined };
}

export const UpdateUserReq = {
  encode(message: UpdateUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      UserUpdate.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = UserUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserReq {
    return { user: isSet(object.user) ? UserUpdate.fromJSON(object.user) : undefined };
  },

  toJSON(message: UpdateUserReq): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = UserUpdate.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserReq>, I>>(base?: I): UpdateUserReq {
    return UpdateUserReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserReq>, I>>(object: I): UpdateUserReq {
    const message = createBaseUpdateUserReq();
    message.user = (object.user !== undefined && object.user !== null)
      ? UserUpdate.fromPartial(object.user)
      : undefined;
    return message;
  },
};

function createBaseListUsersRes(): ListUsersRes {
  return { users: [] };
}

export const ListUsersRes = {
  encode(message: ListUsersRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUsersRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUsersRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUsersRes {
    return { users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [] };
  },

  toJSON(message: ListUsersRes): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUsersRes>, I>>(base?: I): ListUsersRes {
    return ListUsersRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUsersRes>, I>>(object: I): ListUsersRes {
    const message = createBaseListUsersRes();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateUserGroupReq(): CreateUserGroupReq {
  return { group: undefined };
}

export const CreateUserGroupReq = {
  encode(message: CreateUserGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroup.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUserGroupReq {
    return { group: isSet(object.group) ? UserGroup.fromJSON(object.group) : undefined };
  },

  toJSON(message: CreateUserGroupReq): unknown {
    const obj: any = {};
    if (message.group !== undefined) {
      obj.group = UserGroup.toJSON(message.group);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUserGroupReq>, I>>(base?: I): CreateUserGroupReq {
    return CreateUserGroupReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserGroupReq>, I>>(object: I): CreateUserGroupReq {
    const message = createBaseCreateUserGroupReq();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroup.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseUpdateUserGroupReq(): UpdateUserGroupReq {
  return { group: undefined };
}

export const UpdateUserGroupReq = {
  encode(message: UpdateUserGroupReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.group !== undefined) {
      UserGroupUpdate.encode(message.group, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserGroupReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserGroupReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.group = UserGroupUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserGroupReq {
    return { group: isSet(object.group) ? UserGroupUpdate.fromJSON(object.group) : undefined };
  },

  toJSON(message: UpdateUserGroupReq): unknown {
    const obj: any = {};
    if (message.group !== undefined) {
      obj.group = UserGroupUpdate.toJSON(message.group);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserGroupReq>, I>>(base?: I): UpdateUserGroupReq {
    return UpdateUserGroupReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserGroupReq>, I>>(object: I): UpdateUserGroupReq {
    const message = createBaseUpdateUserGroupReq();
    message.group = (object.group !== undefined && object.group !== null)
      ? UserGroupUpdate.fromPartial(object.group)
      : undefined;
    return message;
  },
};

function createBaseListUserGroupsRes(): ListUserGroupsRes {
  return { groups: [] };
}

export const ListUserGroupsRes = {
  encode(message: ListUserGroupsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      UserGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUserGroupsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUserGroupsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groups.push(UserGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUserGroupsRes {
    return {
      groups: globalThis.Array.isArray(object?.groups) ? object.groups.map((e: any) => UserGroup.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListUserGroupsRes): unknown {
    const obj: any = {};
    if (message.groups?.length) {
      obj.groups = message.groups.map((e) => UserGroup.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUserGroupsRes>, I>>(base?: I): ListUserGroupsRes {
    return ListUserGroupsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUserGroupsRes>, I>>(object: I): ListUserGroupsRes {
    const message = createBaseListUserGroupsRes();
    message.groups = object.groups?.map((e) => UserGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListCameraNetworksRes(): ListCameraNetworksRes {
  return { cameraNetworks: [] };
}

export const ListCameraNetworksRes = {
  encode(message: ListCameraNetworksRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cameraNetworks) {
      CameraNetwork.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCameraNetworksRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCameraNetworksRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameraNetworks.push(CameraNetwork.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCameraNetworksRes {
    return {
      cameraNetworks: globalThis.Array.isArray(object?.cameraNetworks)
        ? object.cameraNetworks.map((e: any) => CameraNetwork.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListCameraNetworksRes): unknown {
    const obj: any = {};
    if (message.cameraNetworks?.length) {
      obj.cameraNetworks = message.cameraNetworks.map((e) => CameraNetwork.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCameraNetworksRes>, I>>(base?: I): ListCameraNetworksRes {
    return ListCameraNetworksRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCameraNetworksRes>, I>>(object: I): ListCameraNetworksRes {
    const message = createBaseListCameraNetworksRes();
    message.cameraNetworks = object.cameraNetworks?.map((e) => CameraNetwork.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateCameraNetworkReq(): CreateCameraNetworkReq {
  return { cameraNetwork: undefined };
}

export const CreateCameraNetworkReq = {
  encode(message: CreateCameraNetworkReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cameraNetwork !== undefined) {
      CameraNetwork.encode(message.cameraNetwork, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCameraNetworkReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCameraNetworkReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameraNetwork = CameraNetwork.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCameraNetworkReq {
    return { cameraNetwork: isSet(object.cameraNetwork) ? CameraNetwork.fromJSON(object.cameraNetwork) : undefined };
  },

  toJSON(message: CreateCameraNetworkReq): unknown {
    const obj: any = {};
    if (message.cameraNetwork !== undefined) {
      obj.cameraNetwork = CameraNetwork.toJSON(message.cameraNetwork);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateCameraNetworkReq>, I>>(base?: I): CreateCameraNetworkReq {
    return CreateCameraNetworkReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCameraNetworkReq>, I>>(object: I): CreateCameraNetworkReq {
    const message = createBaseCreateCameraNetworkReq();
    message.cameraNetwork = (object.cameraNetwork !== undefined && object.cameraNetwork !== null)
      ? CameraNetwork.fromPartial(object.cameraNetwork)
      : undefined;
    return message;
  },
};

function createBaseUpdateCameraNetworkReq(): UpdateCameraNetworkReq {
  return { cameraNetwork: undefined };
}

export const UpdateCameraNetworkReq = {
  encode(message: UpdateCameraNetworkReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cameraNetwork !== undefined) {
      CameraNetworkUpdate.encode(message.cameraNetwork, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCameraNetworkReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCameraNetworkReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameraNetwork = CameraNetworkUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCameraNetworkReq {
    return {
      cameraNetwork: isSet(object.cameraNetwork) ? CameraNetworkUpdate.fromJSON(object.cameraNetwork) : undefined,
    };
  },

  toJSON(message: UpdateCameraNetworkReq): unknown {
    const obj: any = {};
    if (message.cameraNetwork !== undefined) {
      obj.cameraNetwork = CameraNetworkUpdate.toJSON(message.cameraNetwork);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCameraNetworkReq>, I>>(base?: I): UpdateCameraNetworkReq {
    return UpdateCameraNetworkReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCameraNetworkReq>, I>>(object: I): UpdateCameraNetworkReq {
    const message = createBaseUpdateCameraNetworkReq();
    message.cameraNetwork = (object.cameraNetwork !== undefined && object.cameraNetwork !== null)
      ? CameraNetworkUpdate.fromPartial(object.cameraNetwork)
      : undefined;
    return message;
  },
};

function createBaseListCamerasRes(): ListCamerasRes {
  return { cameras: [] };
}

export const ListCamerasRes = {
  encode(message: ListCamerasRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cameras) {
      DbCamera.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCamerasRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCamerasRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameras.push(DbCamera.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCamerasRes {
    return {
      cameras: globalThis.Array.isArray(object?.cameras) ? object.cameras.map((e: any) => DbCamera.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListCamerasRes): unknown {
    const obj: any = {};
    if (message.cameras?.length) {
      obj.cameras = message.cameras.map((e) => DbCamera.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCamerasRes>, I>>(base?: I): ListCamerasRes {
    return ListCamerasRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCamerasRes>, I>>(object: I): ListCamerasRes {
    const message = createBaseListCamerasRes();
    message.cameras = object.cameras?.map((e) => DbCamera.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateCameraReq(): CreateCameraReq {
  return { camera: undefined };
}

export const CreateCameraReq = {
  encode(message: CreateCameraReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.camera !== undefined) {
      DbCamera.encode(message.camera, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCameraReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCameraReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camera = DbCamera.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCameraReq {
    return { camera: isSet(object.camera) ? DbCamera.fromJSON(object.camera) : undefined };
  },

  toJSON(message: CreateCameraReq): unknown {
    const obj: any = {};
    if (message.camera !== undefined) {
      obj.camera = DbCamera.toJSON(message.camera);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateCameraReq>, I>>(base?: I): CreateCameraReq {
    return CreateCameraReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCameraReq>, I>>(object: I): CreateCameraReq {
    const message = createBaseCreateCameraReq();
    message.camera = (object.camera !== undefined && object.camera !== null)
      ? DbCamera.fromPartial(object.camera)
      : undefined;
    return message;
  },
};

function createBaseUpdateCameraReq(): UpdateCameraReq {
  return { camera: undefined };
}

export const UpdateCameraReq = {
  encode(message: UpdateCameraReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.camera !== undefined) {
      DbCameraUpdate.encode(message.camera, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCameraReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCameraReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.camera = DbCameraUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCameraReq {
    return { camera: isSet(object.camera) ? DbCameraUpdate.fromJSON(object.camera) : undefined };
  },

  toJSON(message: UpdateCameraReq): unknown {
    const obj: any = {};
    if (message.camera !== undefined) {
      obj.camera = DbCameraUpdate.toJSON(message.camera);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCameraReq>, I>>(base?: I): UpdateCameraReq {
    return UpdateCameraReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCameraReq>, I>>(object: I): UpdateCameraReq {
    const message = createBaseUpdateCameraReq();
    message.camera = (object.camera !== undefined && object.camera !== null)
      ? DbCameraUpdate.fromPartial(object.camera)
      : undefined;
    return message;
  },
};

function createBaseReportInfo(): ReportInfo {
  return {
    id: 0,
    state: 0,
    reason: "",
    location: "",
    latitude: 0,
    longitude: 0,
    createdTime: undefined,
    areaGeojson: "",
  };
}

export const ReportInfo = {
  encode(message: ReportInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.reason !== "") {
      writer.uint32(26).string(message.reason);
    }
    if (message.location !== "") {
      writer.uint32(34).string(message.location);
    }
    if (message.latitude !== 0) {
      writer.uint32(41).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(49).double(message.longitude);
    }
    if (message.createdTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.areaGeojson !== "") {
      writer.uint32(66).string(message.areaGeojson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.areaGeojson = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportInfo {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      state: isSet(object.state) ? confirmationStateFromJSON(object.state) : 0,
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      createdTime: isSet(object.createdTime) ? fromJsonTimestamp(object.createdTime) : undefined,
      areaGeojson: isSet(object.areaGeojson) ? globalThis.String(object.areaGeojson) : "",
    };
  },

  toJSON(message: ReportInfo): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.state !== 0) {
      obj.state = confirmationStateToJSON(message.state);
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.createdTime !== undefined) {
      obj.createdTime = message.createdTime.toISOString();
    }
    if (message.areaGeojson !== "") {
      obj.areaGeojson = message.areaGeojson;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportInfo>, I>>(base?: I): ReportInfo {
    return ReportInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportInfo>, I>>(object: I): ReportInfo {
    const message = createBaseReportInfo();
    message.id = object.id ?? 0;
    message.state = object.state ?? 0;
    message.reason = object.reason ?? "";
    message.location = object.location ?? "";
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.createdTime = object.createdTime ?? undefined;
    message.areaGeojson = object.areaGeojson ?? "";
    return message;
  },
};

function createBaseNotificationInfo(): NotificationInfo {
  return { id: 0, sentAt: undefined, recipient: "", reason: "", method: 0, success: false };
}

export const NotificationInfo = {
  encode(message: NotificationInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.sentAt !== undefined) {
      Timestamp.encode(toTimestamp(message.sentAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.recipient !== "") {
      writer.uint32(34).string(message.recipient);
    }
    if (message.reason !== "") {
      writer.uint32(42).string(message.reason);
    }
    if (message.method !== 0) {
      writer.uint32(48).int32(message.method);
    }
    if (message.success === true) {
      writer.uint32(56).bool(message.success);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sentAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.recipient = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.method = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.success = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationInfo {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      sentAt: isSet(object.sentAt) ? fromJsonTimestamp(object.sentAt) : undefined,
      recipient: isSet(object.recipient) ? globalThis.String(object.recipient) : "",
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
      method: isSet(object.method) ? notificationMethodFromJSON(object.method) : 0,
      success: isSet(object.success) ? globalThis.Boolean(object.success) : false,
    };
  },

  toJSON(message: NotificationInfo): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.sentAt !== undefined) {
      obj.sentAt = message.sentAt.toISOString();
    }
    if (message.recipient !== "") {
      obj.recipient = message.recipient;
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    if (message.method !== 0) {
      obj.method = notificationMethodToJSON(message.method);
    }
    if (message.success === true) {
      obj.success = message.success;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationInfo>, I>>(base?: I): NotificationInfo {
    return NotificationInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NotificationInfo>, I>>(object: I): NotificationInfo {
    const message = createBaseNotificationInfo();
    message.id = object.id ?? 0;
    message.sentAt = object.sentAt ?? undefined;
    message.recipient = object.recipient ?? "";
    message.reason = object.reason ?? "";
    message.method = object.method ?? 0;
    message.success = object.success ?? false;
    return message;
  },
};

function createBaseReportAction(): ReportAction {
  return { id: 0, userId: 0, userEmail: "", performedAt: undefined, action: "" };
}

export const ReportAction = {
  encode(message: ReportAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.userId !== 0) {
      writer.uint32(16).int64(message.userId);
    }
    if (message.userEmail !== "") {
      writer.uint32(26).string(message.userEmail);
    }
    if (message.performedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.performedAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.action !== "") {
      writer.uint32(42).string(message.action);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userEmail = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.performedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.action = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportAction {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      userId: isSet(object.userId) ? globalThis.Number(object.userId) : 0,
      userEmail: isSet(object.userEmail) ? globalThis.String(object.userEmail) : "",
      performedAt: isSet(object.performedAt) ? fromJsonTimestamp(object.performedAt) : undefined,
      action: isSet(object.action) ? globalThis.String(object.action) : "",
    };
  },

  toJSON(message: ReportAction): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.userId !== 0) {
      obj.userId = Math.round(message.userId);
    }
    if (message.userEmail !== "") {
      obj.userEmail = message.userEmail;
    }
    if (message.performedAt !== undefined) {
      obj.performedAt = message.performedAt.toISOString();
    }
    if (message.action !== "") {
      obj.action = message.action;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportAction>, I>>(base?: I): ReportAction {
    return ReportAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportAction>, I>>(object: I): ReportAction {
    const message = createBaseReportAction();
    message.id = object.id ?? 0;
    message.userId = object.userId ?? 0;
    message.userEmail = object.userEmail ?? "";
    message.performedAt = object.performedAt ?? undefined;
    message.action = object.action ?? "";
    return message;
  },
};

function createBaseGetReportDetailsRes(): GetReportDetailsRes {
  return { report: undefined, images: [], notifications: [], actions: [] };
}

export const GetReportDetailsRes = {
  encode(message: GetReportDetailsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.report !== undefined) {
      ReportInfo.encode(message.report, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.images) {
      CameraImage.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.notifications) {
      NotificationInfo.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.actions) {
      ReportAction.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReportDetailsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReportDetailsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.report = ReportInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.images.push(CameraImage.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.notifications.push(NotificationInfo.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.actions.push(ReportAction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReportDetailsRes {
    return {
      report: isSet(object.report) ? ReportInfo.fromJSON(object.report) : undefined,
      images: globalThis.Array.isArray(object?.images) ? object.images.map((e: any) => CameraImage.fromJSON(e)) : [],
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => NotificationInfo.fromJSON(e))
        : [],
      actions: globalThis.Array.isArray(object?.actions)
        ? object.actions.map((e: any) => ReportAction.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetReportDetailsRes): unknown {
    const obj: any = {};
    if (message.report !== undefined) {
      obj.report = ReportInfo.toJSON(message.report);
    }
    if (message.images?.length) {
      obj.images = message.images.map((e) => CameraImage.toJSON(e));
    }
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => NotificationInfo.toJSON(e));
    }
    if (message.actions?.length) {
      obj.actions = message.actions.map((e) => ReportAction.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReportDetailsRes>, I>>(base?: I): GetReportDetailsRes {
    return GetReportDetailsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReportDetailsRes>, I>>(object: I): GetReportDetailsRes {
    const message = createBaseGetReportDetailsRes();
    message.report = (object.report !== undefined && object.report !== null)
      ? ReportInfo.fromPartial(object.report)
      : undefined;
    message.images = object.images?.map((e) => CameraImage.fromPartial(e)) || [];
    message.notifications = object.notifications?.map((e) => NotificationInfo.fromPartial(e)) || [];
    message.actions = object.actions?.map((e) => ReportAction.fromPartial(e)) || [];
    return message;
  },
};

export interface Admin {
  /** Create and return a password reset token for a user */
  resetPassword(request: DeepPartial<ResetPasswordReq>, metadata?: grpc.Metadata): Promise<ResetPasswordRes>;
  /** Lists API keys for a user or all users */
  listApiKeys(request: DeepPartial<ListAPIKeysReq>, metadata?: grpc.Metadata): Promise<ListAPIKeysRes>;
  /** Create and return an api key for a user */
  createApiKey(request: DeepPartial<CreateAPIKeyReq>, metadata?: grpc.Metadata): Promise<CreateAPIKeyRes>;
  /** Deletes an API key */
  deleteApiKey(request: DeepPartial<DeleteAPIKeyReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Create a user in the system */
  createUser(request: DeepPartial<CreateUserReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Update an existing user in the system */
  updateUser(request: DeepPartial<UpdateUserReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** List all camera networks */
  listCameraNetworks(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListCameraNetworksRes>;
  /** Create a camera network in the system */
  createCameraNetwork(request: DeepPartial<CreateCameraNetworkReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Update an existing camera network in the system */
  updateCameraNetwork(request: DeepPartial<UpdateCameraNetworkReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** List all cameras */
  listCameras(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListCamerasRes>;
  /** Create a camera in the system */
  createCamera(request: DeepPartial<CreateCameraReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Update an existing camera in the system */
  updateCamera(request: DeepPartial<UpdateCameraReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** List the registered users */
  listUsers(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListUsersRes>;
  /** List the registered users - legacy tables */
  legacyListUsers(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListUsersRes>;
  /** Create a user group in the system */
  createUserGroup(request: DeepPartial<CreateUserGroupReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Update an existing user group in the system */
  updateUserGroup(request: DeepPartial<UpdateUserGroupReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** List the user groups */
  listUserGroups(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListUserGroupsRes>;
  /** Get administrator information about a report */
  getReportDetails(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<GetReportDetailsRes>;
}

export class AdminClientImpl implements Admin {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.resetPassword = this.resetPassword.bind(this);
    this.listApiKeys = this.listApiKeys.bind(this);
    this.createApiKey = this.createApiKey.bind(this);
    this.deleteApiKey = this.deleteApiKey.bind(this);
    this.createUser = this.createUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.listCameraNetworks = this.listCameraNetworks.bind(this);
    this.createCameraNetwork = this.createCameraNetwork.bind(this);
    this.updateCameraNetwork = this.updateCameraNetwork.bind(this);
    this.listCameras = this.listCameras.bind(this);
    this.createCamera = this.createCamera.bind(this);
    this.updateCamera = this.updateCamera.bind(this);
    this.listUsers = this.listUsers.bind(this);
    this.legacyListUsers = this.legacyListUsers.bind(this);
    this.createUserGroup = this.createUserGroup.bind(this);
    this.updateUserGroup = this.updateUserGroup.bind(this);
    this.listUserGroups = this.listUserGroups.bind(this);
    this.getReportDetails = this.getReportDetails.bind(this);
  }

  resetPassword(request: DeepPartial<ResetPasswordReq>, metadata?: grpc.Metadata): Promise<ResetPasswordRes> {
    return this.rpc.unary(AdminResetPasswordDesc, ResetPasswordReq.fromPartial(request), metadata);
  }

  listApiKeys(request: DeepPartial<ListAPIKeysReq>, metadata?: grpc.Metadata): Promise<ListAPIKeysRes> {
    return this.rpc.unary(AdminListAPIKeysDesc, ListAPIKeysReq.fromPartial(request), metadata);
  }

  createApiKey(request: DeepPartial<CreateAPIKeyReq>, metadata?: grpc.Metadata): Promise<CreateAPIKeyRes> {
    return this.rpc.unary(AdminCreateAPIKeyDesc, CreateAPIKeyReq.fromPartial(request), metadata);
  }

  deleteApiKey(request: DeepPartial<DeleteAPIKeyReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminDeleteAPIKeyDesc, DeleteAPIKeyReq.fromPartial(request), metadata);
  }

  createUser(request: DeepPartial<CreateUserReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminCreateUserDesc, CreateUserReq.fromPartial(request), metadata);
  }

  updateUser(request: DeepPartial<UpdateUserReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminUpdateUserDesc, UpdateUserReq.fromPartial(request), metadata);
  }

  listCameraNetworks(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListCameraNetworksRes> {
    return this.rpc.unary(AdminListCameraNetworksDesc, Empty.fromPartial(request), metadata);
  }

  createCameraNetwork(request: DeepPartial<CreateCameraNetworkReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminCreateCameraNetworkDesc, CreateCameraNetworkReq.fromPartial(request), metadata);
  }

  updateCameraNetwork(request: DeepPartial<UpdateCameraNetworkReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminUpdateCameraNetworkDesc, UpdateCameraNetworkReq.fromPartial(request), metadata);
  }

  listCameras(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListCamerasRes> {
    return this.rpc.unary(AdminListCamerasDesc, Empty.fromPartial(request), metadata);
  }

  createCamera(request: DeepPartial<CreateCameraReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminCreateCameraDesc, CreateCameraReq.fromPartial(request), metadata);
  }

  updateCamera(request: DeepPartial<UpdateCameraReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminUpdateCameraDesc, UpdateCameraReq.fromPartial(request), metadata);
  }

  listUsers(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListUsersRes> {
    return this.rpc.unary(AdminListUsersDesc, Empty.fromPartial(request), metadata);
  }

  legacyListUsers(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListUsersRes> {
    return this.rpc.unary(AdminLegacyListUsersDesc, Empty.fromPartial(request), metadata);
  }

  createUserGroup(request: DeepPartial<CreateUserGroupReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminCreateUserGroupDesc, CreateUserGroupReq.fromPartial(request), metadata);
  }

  updateUserGroup(request: DeepPartial<UpdateUserGroupReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(AdminUpdateUserGroupDesc, UpdateUserGroupReq.fromPartial(request), metadata);
  }

  listUserGroups(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListUserGroupsRes> {
    return this.rpc.unary(AdminListUserGroupsDesc, Empty.fromPartial(request), metadata);
  }

  getReportDetails(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<GetReportDetailsRes> {
    return this.rpc.unary(AdminGetReportDetailsDesc, GetReportReq.fromPartial(request), metadata);
  }
}

export const AdminDesc = { serviceName: "phoenix.admin.v0.Admin" };

export const AdminResetPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "ResetPassword",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetPasswordReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ResetPasswordRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminListAPIKeysDesc: UnaryMethodDefinitionish = {
  methodName: "ListAPIKeys",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListAPIKeysReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListAPIKeysRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminCreateAPIKeyDesc: UnaryMethodDefinitionish = {
  methodName: "CreateAPIKey",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateAPIKeyReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateAPIKeyRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminDeleteAPIKeyDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteAPIKey",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteAPIKeyReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminCreateUserDesc: UnaryMethodDefinitionish = {
  methodName: "CreateUser",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUserReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminUpdateUserDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUser",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateUserReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminListCameraNetworksDesc: UnaryMethodDefinitionish = {
  methodName: "ListCameraNetworks",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCameraNetworksRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminCreateCameraNetworkDesc: UnaryMethodDefinitionish = {
  methodName: "CreateCameraNetwork",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCameraNetworkReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminUpdateCameraNetworkDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCameraNetwork",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCameraNetworkReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminListCamerasDesc: UnaryMethodDefinitionish = {
  methodName: "ListCameras",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCamerasRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminCreateCameraDesc: UnaryMethodDefinitionish = {
  methodName: "CreateCamera",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCameraReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminUpdateCameraDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCamera",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCameraReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminListUsersDesc: UnaryMethodDefinitionish = {
  methodName: "ListUsers",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListUsersRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminLegacyListUsersDesc: UnaryMethodDefinitionish = {
  methodName: "LegacyListUsers",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListUsersRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminCreateUserGroupDesc: UnaryMethodDefinitionish = {
  methodName: "CreateUserGroup",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUserGroupReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminUpdateUserGroupDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUserGroup",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateUserGroupReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminListUserGroupsDesc: UnaryMethodDefinitionish = {
  methodName: "ListUserGroups",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListUserGroupsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AdminGetReportDetailsDesc: UnaryMethodDefinitionish = {
  methodName: "GetReportDetails",
  service: AdminDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetReportDetailsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
