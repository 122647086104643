import service from "@phoenix/common/service";
import { setOnUnauthorized } from "@phoenix/common/service/client";
import isError from "@phoenix/common/utils/isError";
import { usePersistedState } from "@phoenix/common/utils/usePersistedState";
import { useEffect, useMemo, useState } from "react";

export default function useAuthStore() {
  const [authenticated, setAuthenticated] = usePersistedState(
    "authenticated",
    false
  );
  const [scopes, setScopes] = usePersistedState<string[]>("scopes", []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const actions = useMemo(
    () => ({
      authError(message: string) {
        setError(message);
      },
      clearError() {
        setError(null);
      },
      async logout() {
        setError(null);
        setLoading(true);
        try {
          await service.auth.logout();
        } catch (e) {
          setError(isError(e) ? e.message : "Error");
        }
        setAuthenticated(false);
        setLoading(false);
      },
      async login({ email, password }: { email: string; password: string }) {
        setError(null);
        setLoading(true);
        let res;
        try {
          res = await service.auth.login({ email, password });
          setAuthenticated(true);
          setScopes(res.allowedScopes);
        } catch (e) {
          setError(isError(e) ? e.message : "Error");
        }
        setLoading(false);
      },
    }),
    //there should be no dependenices on the state
    [setAuthenticated, setScopes]
  );

  // unauthenticated handler
  useEffect(() => {
    setOnUnauthorized(async () => {
      await actions.logout();
    });
  }, [actions]);

  return {
    actions,
    state: {
      authenticated,
      scopes,
      error,
      loading,
    },
  };
}

export type AuthStoreType = ReturnType<typeof useAuthStore>;
