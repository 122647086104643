import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

/** Rerenders every second if date is <1min ago, otherwise every minute */
export default function PeriodicRerender({
  date,
  children,
}: {
  date: Date;
  children: () => ReactNode;
}) {
  const [, rerender] = useState(0);
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const dateMs = date.getTime();

  const handle = useCallback(() => {
    rerender((prev) => prev + 1);
    if (new Date().getTime() - dateMs < 60000) {
      timer.current = setTimeout(handle, 1000);
    } else {
      timer.current = setTimeout(handle, 60000);
    }
  }, [dateMs]);

  useEffect(() => {
    if (new Date().getTime() - dateMs < 60000) {
      timer.current = setTimeout(handle, 1000);
    } else {
      timer.current = setTimeout(handle, 60000);
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [handle, dateMs]);

  return <>{children()}</>;
}
