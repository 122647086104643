import { Box, Popover, Slider } from "@mui/material";
import { relativeTimeFormat } from "@phoenix/common/utils/date";
import { useAtom } from "jotai";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { reportListTimeRangeAtom } from "../../state";
import FilterButton from "./FilterButton";

function sliderScale(thumbs: [number, number]): [number, number] {
  return [
    -Math.pow(Math.abs(thumbs[0]) * 100, 0.4),
    -Math.pow(Math.abs(thumbs[1]) * 100, 0.4),
  ];
}

function sliderUnscale(thumbs: [number, number]): [number, number] {
  return [
    -0.01 * Math.pow(Math.abs(thumbs[0]), 2.5),
    -0.01 * Math.pow(Math.abs(thumbs[1]), 2.5),
  ];
}

export default function TimeRangeButton() {
  const { t } = useTranslation(["common", "reportsList"]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = !!anchorEl;

  const [timeRange, setTimeRange] = useAtom(reportListTimeRangeAtom);
  const scaledTimeRange = sliderScale(timeRange);
  const [scaledDirtyRange, setScaledDirtyRange] = useState<
    [number, number] | null
  >(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const displayRange = scaledDirtyRange
    ? sliderUnscale(scaledDirtyRange)
    : timeRange;

  return (
    <>
      <FilterButton onClick={handleClick}>
        {t("reportsList:timeRangeButtonLabel", {
          from: relativeTimeFormat(displayRange[0] * 60 * 60 * 1000, true),
          to:
            displayRange[1] === 0
              ? t("common:now")
              : relativeTimeFormat(displayRange[1] * 60 * 60 * 1000, true),
        })}
      </FilterButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box width="200px" px={2} py={1}>
          <Slider
            getAriaLabel={(idx) =>
              idx === 0
                ? t("reportsList:timeRangeSliderFromLabel")
                : t("reportsList:timeRangeSliderToLabel")
            }
            // we can't directly scale here as it messes up the mouse movement on the slider
            value={scaledDirtyRange ?? scaledTimeRange}
            max={0}
            min={-23}
            step={0.1}
            size="small"
            onChange={(_, value) => {
              if (!Array.isArray(value) || value[0] === 0) return;
              setScaledDirtyRange(value as [number, number]);
            }}
            onChangeCommitted={(_, value) => {
              if (!Array.isArray(value) || value[0] === 0) return;
              setTimeRange(sliderUnscale(value as [number, number]));
              setScaledDirtyRange(null);
            }}
          />
        </Box>
      </Popover>
    </>
  );
}
