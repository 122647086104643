import "leaflet/dist/leaflet.css";

import Button from "@phoenix/common/components/simple/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routeTo } from "../../../routes";
import { ParsedMergeCandidate } from "../../../utils/types";
import useSnackbar from "../../../utils/useSnackbar";
import MergeDialog from "../MergeDialog";

export default function MergeDialogButton({
  mergeCandidates,
}: {
  mergeCandidates:
    | { source: ParsedMergeCandidate; candidates: ParsedMergeCandidate[] }
    | "loading"
    | "error";
}) {
  const { t } = useTranslation(["common", "report"]);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [open, setOpen] = useState(false);
  const onSuccess = (finalReportId: number) => {
    setOpen(false);
    navigate(routeTo.report(finalReportId));
  };
  const handleClick = () => {
    if (typeof mergeCandidates === "string") return;
    if (mergeCandidates.candidates.length === 0) {
      snackbar({
        kind: "info",
        message: t("report:MergeDialogButton.noMergeCandidates"),
      });
      return;
    }
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        disabled={typeof mergeCandidates === "string"}
        loading={mergeCandidates === "loading"}
      >
        {t("report:MergeDialogButton.label")}
      </Button>
      {typeof mergeCandidates !== "string" && (
        <MergeDialog
          mergeCandidates={mergeCandidates.candidates}
          open={open}
          onCancel={() => setOpen(false)}
          onMergeSuccess={onSuccess}
          source={mergeCandidates.source}
        />
      )}
    </>
  );
}
