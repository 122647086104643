import { ReportSummary, SatelliteSource } from "@phoenix/common/proto/reports";
import { ConfirmationState } from "@phoenix/common/proto/types";
import { atom, useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useCallback } from "react";
import { GeoJSON } from "react-leaflet";

import { CameraImageType, ReportFilters } from "../utils/types";
import { useAuthContext } from "./auth/AuthProvider";
import { ReportsSort } from "./reportsList/useReportsQuery";

export const highlightedReportAtom = atom<ReportSummary | null>(null);

export const defaultFilters = "default";
const resolveDefaultFilter = (
  filters: ReportFilters | typeof defaultFilters,
  scopes: string[]
): ReportFilters => {
  return filters !== defaultFilters
    ? filters
    : scopes.includes("reports.list_reports")
    ? {
        states: [
          ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED,
          ConfirmationState.CONFIRMATION_STATE_CONFIRMED,
          ConfirmationState.CONFIRMATION_STATE_WATCH,
        ],
        topleft: undefined,
        bottomright: undefined,
        cameraId: 0,
        satelliteId: 0,
        omitCameraOnly: false,
        omitSatelliteOnly: false,
      }
    : {
        states: [
          ConfirmationState.CONFIRMATION_STATE_CONFIRMED,
          ConfirmationState.CONFIRMATION_STATE_WATCH,
        ],
        topleft: undefined,
        bottomright: undefined,
        cameraId: 0,
        satelliteId: 0,
        omitCameraOnly: false,
        omitSatelliteOnly: false,
      };
};
const reportsFilterAtom = atomWithStorage<
  ReportFilters | typeof defaultFilters
>("detectionsFilter", defaultFilters);
// we want to prevent using the filters without resolveDefaultFilter,
// but resolveDefaultFilter requires the user scope to be known, so only export below function
export const useFilters = () => {
  const {
    state: { scopes },
  } = useAuthContext();
  const [filters, setFilters] = useAtom(reportsFilterAtom);
  const resolvedFilters = resolveDefaultFilter(filters, scopes);
  const resolvedSetFilters = useCallback(
    (
      action:
        | "default"
        | ReportFilters
        | ((prev: ReportFilters) => ReportFilters)
    ) => {
      if (typeof action === "function") {
        setFilters((prev) => {
          const resolvedPrev =
            prev === "default" ? resolveDefaultFilter("default", scopes) : prev;
          return action(resolvedPrev);
        });
      } else setFilters(action);
    },
    [setFilters, scopes]
  );
  return [resolvedFilters, resolvedSetFilters] as const;
};
export const useFiltersValue = () => {
  const {
    state: { scopes },
  } = useAuthContext();
  const filters = useAtomValue(reportsFilterAtom);
  const resolvedFilters = resolveDefaultFilter(filters, scopes);
  return resolvedFilters;
};

export const reportsSortAtom = atomWithStorage<ReportsSort>("detectionSort", {
  column: "first",
  order: "desc",
});

export const timeStyleAtom = atomWithStorage<"relative" | "absolute">(
  "timeStyle",
  "relative"
);

export const reportListScrollIdAtom = atom<number | null>(null);

/** Atom with an array containing from and to value as hours relative to now. */
export const reportListTimeRangeAtom = atomWithStorage<[number, number]>(
  "reportListTimeRange",
  [-4, 0]
);

export const currentViewingReportGeojsonAtom = atom<GeoJSON.Feature | null>(
  null
);

export const currentReportSatelliteSourcesAtom = atom<SatelliteSource[] | null>(
  null
);

export const viewingImageTypeAtom = atomWithStorage<CameraImageType>(
  "viewingImageType",
  "standard"
);
