///TODO: Nice 404 page
export default function NotFound() {
  return (
    <>
      <div>
        That page couldn't be found. <a href="/">Go home</a> and try again.
      </div>
    </>
  );
}
