import { Box, BoxProps, styled } from "@mui/material";
import {
  DetailedHTMLProps,
  forwardRef,
  ImgHTMLAttributes,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react";

import usePreloadImage from "../../utils/usePreloadImage";
import CircularProgress from "../simple/CircularProgress";

const CenteredOverlay = styled("div")({
  position: "absolute",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
});

//make src required
type LoadableImgProps = Omit<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  "src"
> & { src: string; sx?: BoxProps["sx"] };

const LoadableImg = forwardRef(
  (
    { src, sx = {}, ...props }: LoadableImgProps,
    ref: Ref<HTMLImageElement>
  ) => {
    const prevSrc = useRef(src);
    const [currentSrc, setCurrentSrc] = useState<string>(src);
    useEffect(() => {
      setCurrentSrc((prev) => {
        prevSrc.current = prev;
        return src;
      });
    }, [src]);
    const { hasLoaded, hasError } = usePreloadImage(currentSrc);
    return (
      <Box
        sx={{
          position: "relative",
          ...sx,
        }}
      >
        <Img
          {...props}
          ref={ref}
          alt={props.alt}
          src={!hasLoaded && !hasError ? prevSrc.current : currentSrc}
        />
        {!hasLoaded && (
          <CenteredOverlay>
            <CircularProgress />
          </CenteredOverlay>
        )}
      </Box>
    );
  }
);

LoadableImg.displayName = "LoadableImg";
export default LoadableImg;
