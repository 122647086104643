import "./i18n";

import { CircularProgress, CssBaseline } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AppRoutes from "./AppRoutes";
import AuthProvider from "./features/auth/AuthProvider";
import FatalError from "./features/error/FatalError";
import MapProvider from "./features/map/MapProvider";
import { AppThemeProvider } from "./theme";
import { GlobalSnackbar } from "./utils/useSnackbar";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <AppThemeProvider>
        <CssBaseline />
        {/* fallback while translations load */}
        <Suspense fallback={<CircularProgress />}>
          <ErrorBoundary fallback={({ error }) => <FatalError error={error} />}>
            <AuthProvider>
              <MapProvider>
                <Router>
                  <AppRoutes />
                </Router>
              </MapProvider>
            </AuthProvider>
            <GlobalSnackbar />
          </ErrorBoundary>
        </Suspense>
      </AppThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
