/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { CameraInfo, ViewInfo } from "./cameras";
import { ImageDetection } from "./detections";
import { HttpBody } from "./google/api/httpbody";
import { Duration } from "./google/protobuf/duration";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "phoenix.images.v0";

export const ImageType = {
  IMAGE_TYPE_UNKNOWN: 0,
  IMAGE_TYPE_ORIGINAL: 1,
  IMAGE_TYPE_ANNOTATED_SIMPLE: 2,
  IMAGE_TYPE_SUBTRACTION: 3,
  IMAGE_TYPE_ANNOTATED_DETAILED: 4,
  UNRECOGNIZED: -1,
} as const;

export type ImageType = typeof ImageType[keyof typeof ImageType];

export namespace ImageType {
  export type IMAGE_TYPE_UNKNOWN = typeof ImageType.IMAGE_TYPE_UNKNOWN;
  export type IMAGE_TYPE_ORIGINAL = typeof ImageType.IMAGE_TYPE_ORIGINAL;
  export type IMAGE_TYPE_ANNOTATED_SIMPLE = typeof ImageType.IMAGE_TYPE_ANNOTATED_SIMPLE;
  export type IMAGE_TYPE_SUBTRACTION = typeof ImageType.IMAGE_TYPE_SUBTRACTION;
  export type IMAGE_TYPE_ANNOTATED_DETAILED = typeof ImageType.IMAGE_TYPE_ANNOTATED_DETAILED;
  export type UNRECOGNIZED = typeof ImageType.UNRECOGNIZED;
}

export function imageTypeFromJSON(object: any): ImageType {
  switch (object) {
    case 0:
    case "IMAGE_TYPE_UNKNOWN":
      return ImageType.IMAGE_TYPE_UNKNOWN;
    case 1:
    case "IMAGE_TYPE_ORIGINAL":
      return ImageType.IMAGE_TYPE_ORIGINAL;
    case 2:
    case "IMAGE_TYPE_ANNOTATED_SIMPLE":
      return ImageType.IMAGE_TYPE_ANNOTATED_SIMPLE;
    case 3:
    case "IMAGE_TYPE_SUBTRACTION":
      return ImageType.IMAGE_TYPE_SUBTRACTION;
    case 4:
    case "IMAGE_TYPE_ANNOTATED_DETAILED":
      return ImageType.IMAGE_TYPE_ANNOTATED_DETAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ImageType.UNRECOGNIZED;
  }
}

export function imageTypeToJSON(object: ImageType): string {
  switch (object) {
    case ImageType.IMAGE_TYPE_UNKNOWN:
      return "IMAGE_TYPE_UNKNOWN";
    case ImageType.IMAGE_TYPE_ORIGINAL:
      return "IMAGE_TYPE_ORIGINAL";
    case ImageType.IMAGE_TYPE_ANNOTATED_SIMPLE:
      return "IMAGE_TYPE_ANNOTATED_SIMPLE";
    case ImageType.IMAGE_TYPE_SUBTRACTION:
      return "IMAGE_TYPE_SUBTRACTION";
    case ImageType.IMAGE_TYPE_ANNOTATED_DETAILED:
      return "IMAGE_TYPE_ANNOTATED_DETAILED";
    case ImageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface CameraImage {
  id: number;
  url: string;
  subtractionUrl: string;
  takenAt: Date | undefined;
  cameraInfo: CameraInfo | undefined;
  viewInfo: ViewInfo | undefined;
  detections: ImageDetection[];
}

export interface ListCameraImageRangeReq {
  centerImageId: number;
  before: Duration | undefined;
  after: Duration | undefined;
  sameViewOnly: boolean;
  pageToken: string;
}

export interface ListCameraImageRangeRes {
  images: CameraImage[];
  prevPageToken: string;
  nextPageToken: string;
}

export interface GetCameraImageDataReq {
  id: number;
}

export interface GetAnnotationImageDataReq {
  id: number;
  reportId: number;
}

export interface GetCameraImageOpenReq {
  id: number;
  type: ImageType;
  /** report_id used for differentiating other reports' detections in annotated image */
  reportId: number;
  sig: Uint8Array;
}

function createBaseCameraImage(): CameraImage {
  return {
    id: 0,
    url: "",
    subtractionUrl: "",
    takenAt: undefined,
    cameraInfo: undefined,
    viewInfo: undefined,
    detections: [],
  };
}

export const CameraImage = {
  encode(message: CameraImage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    if (message.subtractionUrl !== "") {
      writer.uint32(26).string(message.subtractionUrl);
    }
    if (message.takenAt !== undefined) {
      Timestamp.encode(toTimestamp(message.takenAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.cameraInfo !== undefined) {
      CameraInfo.encode(message.cameraInfo, writer.uint32(42).fork()).ldelim();
    }
    if (message.viewInfo !== undefined) {
      ViewInfo.encode(message.viewInfo, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.detections) {
      ImageDetection.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraImage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subtractionUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.takenAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cameraInfo = CameraInfo.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.viewInfo = ViewInfo.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.detections.push(ImageDetection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraImage {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      subtractionUrl: isSet(object.subtractionUrl) ? globalThis.String(object.subtractionUrl) : "",
      takenAt: isSet(object.takenAt) ? fromJsonTimestamp(object.takenAt) : undefined,
      cameraInfo: isSet(object.cameraInfo) ? CameraInfo.fromJSON(object.cameraInfo) : undefined,
      viewInfo: isSet(object.viewInfo) ? ViewInfo.fromJSON(object.viewInfo) : undefined,
      detections: globalThis.Array.isArray(object?.detections)
        ? object.detections.map((e: any) => ImageDetection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CameraImage): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.subtractionUrl !== "") {
      obj.subtractionUrl = message.subtractionUrl;
    }
    if (message.takenAt !== undefined) {
      obj.takenAt = message.takenAt.toISOString();
    }
    if (message.cameraInfo !== undefined) {
      obj.cameraInfo = CameraInfo.toJSON(message.cameraInfo);
    }
    if (message.viewInfo !== undefined) {
      obj.viewInfo = ViewInfo.toJSON(message.viewInfo);
    }
    if (message.detections?.length) {
      obj.detections = message.detections.map((e) => ImageDetection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraImage>, I>>(base?: I): CameraImage {
    return CameraImage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraImage>, I>>(object: I): CameraImage {
    const message = createBaseCameraImage();
    message.id = object.id ?? 0;
    message.url = object.url ?? "";
    message.subtractionUrl = object.subtractionUrl ?? "";
    message.takenAt = object.takenAt ?? undefined;
    message.cameraInfo = (object.cameraInfo !== undefined && object.cameraInfo !== null)
      ? CameraInfo.fromPartial(object.cameraInfo)
      : undefined;
    message.viewInfo = (object.viewInfo !== undefined && object.viewInfo !== null)
      ? ViewInfo.fromPartial(object.viewInfo)
      : undefined;
    message.detections = object.detections?.map((e) => ImageDetection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListCameraImageRangeReq(): ListCameraImageRangeReq {
  return { centerImageId: 0, before: undefined, after: undefined, sameViewOnly: false, pageToken: "" };
}

export const ListCameraImageRangeReq = {
  encode(message: ListCameraImageRangeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.centerImageId !== 0) {
      writer.uint32(8).int64(message.centerImageId);
    }
    if (message.before !== undefined) {
      Duration.encode(message.before, writer.uint32(18).fork()).ldelim();
    }
    if (message.after !== undefined) {
      Duration.encode(message.after, writer.uint32(26).fork()).ldelim();
    }
    if (message.sameViewOnly === true) {
      writer.uint32(32).bool(message.sameViewOnly);
    }
    if (message.pageToken !== "") {
      writer.uint32(42).string(message.pageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCameraImageRangeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCameraImageRangeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.centerImageId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.before = Duration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.after = Duration.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sameViewOnly = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCameraImageRangeReq {
    return {
      centerImageId: isSet(object.centerImageId) ? globalThis.Number(object.centerImageId) : 0,
      before: isSet(object.before) ? Duration.fromJSON(object.before) : undefined,
      after: isSet(object.after) ? Duration.fromJSON(object.after) : undefined,
      sameViewOnly: isSet(object.sameViewOnly) ? globalThis.Boolean(object.sameViewOnly) : false,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
    };
  },

  toJSON(message: ListCameraImageRangeReq): unknown {
    const obj: any = {};
    if (message.centerImageId !== 0) {
      obj.centerImageId = Math.round(message.centerImageId);
    }
    if (message.before !== undefined) {
      obj.before = Duration.toJSON(message.before);
    }
    if (message.after !== undefined) {
      obj.after = Duration.toJSON(message.after);
    }
    if (message.sameViewOnly === true) {
      obj.sameViewOnly = message.sameViewOnly;
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCameraImageRangeReq>, I>>(base?: I): ListCameraImageRangeReq {
    return ListCameraImageRangeReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCameraImageRangeReq>, I>>(object: I): ListCameraImageRangeReq {
    const message = createBaseListCameraImageRangeReq();
    message.centerImageId = object.centerImageId ?? 0;
    message.before = (object.before !== undefined && object.before !== null)
      ? Duration.fromPartial(object.before)
      : undefined;
    message.after = (object.after !== undefined && object.after !== null)
      ? Duration.fromPartial(object.after)
      : undefined;
    message.sameViewOnly = object.sameViewOnly ?? false;
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseListCameraImageRangeRes(): ListCameraImageRangeRes {
  return { images: [], prevPageToken: "", nextPageToken: "" };
}

export const ListCameraImageRangeRes = {
  encode(message: ListCameraImageRangeRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.images) {
      CameraImage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.prevPageToken !== "") {
      writer.uint32(18).string(message.prevPageToken);
    }
    if (message.nextPageToken !== "") {
      writer.uint32(26).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCameraImageRangeRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCameraImageRangeRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.images.push(CameraImage.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.prevPageToken = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCameraImageRangeRes {
    return {
      images: globalThis.Array.isArray(object?.images) ? object.images.map((e: any) => CameraImage.fromJSON(e)) : [],
      prevPageToken: isSet(object.prevPageToken) ? globalThis.String(object.prevPageToken) : "",
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListCameraImageRangeRes): unknown {
    const obj: any = {};
    if (message.images?.length) {
      obj.images = message.images.map((e) => CameraImage.toJSON(e));
    }
    if (message.prevPageToken !== "") {
      obj.prevPageToken = message.prevPageToken;
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCameraImageRangeRes>, I>>(base?: I): ListCameraImageRangeRes {
    return ListCameraImageRangeRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCameraImageRangeRes>, I>>(object: I): ListCameraImageRangeRes {
    const message = createBaseListCameraImageRangeRes();
    message.images = object.images?.map((e) => CameraImage.fromPartial(e)) || [];
    message.prevPageToken = object.prevPageToken ?? "";
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseGetCameraImageDataReq(): GetCameraImageDataReq {
  return { id: 0 };
}

export const GetCameraImageDataReq = {
  encode(message: GetCameraImageDataReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCameraImageDataReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraImageDataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraImageDataReq {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: GetCameraImageDataReq): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraImageDataReq>, I>>(base?: I): GetCameraImageDataReq {
    return GetCameraImageDataReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraImageDataReq>, I>>(object: I): GetCameraImageDataReq {
    const message = createBaseGetCameraImageDataReq();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseGetAnnotationImageDataReq(): GetAnnotationImageDataReq {
  return { id: 0, reportId: 0 };
}

export const GetAnnotationImageDataReq = {
  encode(message: GetAnnotationImageDataReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.reportId !== 0) {
      writer.uint32(16).int64(message.reportId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAnnotationImageDataReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAnnotationImageDataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.reportId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAnnotationImageDataReq {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      reportId: isSet(object.reportId) ? globalThis.Number(object.reportId) : 0,
    };
  },

  toJSON(message: GetAnnotationImageDataReq): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.reportId !== 0) {
      obj.reportId = Math.round(message.reportId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAnnotationImageDataReq>, I>>(base?: I): GetAnnotationImageDataReq {
    return GetAnnotationImageDataReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAnnotationImageDataReq>, I>>(object: I): GetAnnotationImageDataReq {
    const message = createBaseGetAnnotationImageDataReq();
    message.id = object.id ?? 0;
    message.reportId = object.reportId ?? 0;
    return message;
  },
};

function createBaseGetCameraImageOpenReq(): GetCameraImageOpenReq {
  return { id: 0, type: 0, reportId: 0, sig: new Uint8Array(0) };
}

export const GetCameraImageOpenReq = {
  encode(message: GetCameraImageOpenReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.reportId !== 0) {
      writer.uint32(24).int64(message.reportId);
    }
    if (message.sig.length !== 0) {
      writer.uint32(34).bytes(message.sig);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCameraImageOpenReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCameraImageOpenReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.reportId = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sig = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCameraImageOpenReq {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      type: isSet(object.type) ? imageTypeFromJSON(object.type) : 0,
      reportId: isSet(object.reportId) ? globalThis.Number(object.reportId) : 0,
      sig: isSet(object.sig) ? bytesFromBase64(object.sig) : new Uint8Array(0),
    };
  },

  toJSON(message: GetCameraImageOpenReq): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.type !== 0) {
      obj.type = imageTypeToJSON(message.type);
    }
    if (message.reportId !== 0) {
      obj.reportId = Math.round(message.reportId);
    }
    if (message.sig.length !== 0) {
      obj.sig = base64FromBytes(message.sig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCameraImageOpenReq>, I>>(base?: I): GetCameraImageOpenReq {
    return GetCameraImageOpenReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCameraImageOpenReq>, I>>(object: I): GetCameraImageOpenReq {
    const message = createBaseGetCameraImageOpenReq();
    message.id = object.id ?? 0;
    message.type = object.type ?? 0;
    message.reportId = object.reportId ?? 0;
    message.sig = object.sig ?? new Uint8Array(0);
    return message;
  },
};

export interface Images {
  /**
   * Get a list of images within a time period centered on a particular image
   * Must have reviewer scope
   */
  listCameraImageRange(
    request: DeepPartial<ListCameraImageRangeReq>,
    metadata?: grpc.Metadata,
  ): Promise<ListCameraImageRangeRes>;
  /** Get a list of images within a time period centered on a particular image */
  listViewerCameraImageRange(
    request: DeepPartial<ListCameraImageRangeReq>,
    metadata?: grpc.Metadata,
  ): Promise<ListCameraImageRangeRes>;
  /** Return a raw jpg image using Image2 id, can be used directly in <img> tags */
  getCameraImage2Data(request: DeepPartial<GetCameraImageDataReq>, metadata?: grpc.Metadata): Promise<HttpBody>;
  /** Return a subtraction jpg image using Image2 id, can be used directly in <img> tags */
  getCameraSubtractionImage2Data(
    request: DeepPartial<GetCameraImageDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<HttpBody>;
  /** Return an annotated jpg image using Image2 id and a report2 id, can be used directly in <img> tags */
  getCameraAnnotatedImage2Data(
    request: DeepPartial<GetAnnotationImageDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<HttpBody>;
  /** Return a simply annotated jpg image for viewers using Image2 id and a report2 id, can be used directly in <img> tags */
  getCameraSimpleAnnotatedImage2Data(
    request: DeepPartial<GetAnnotationImageDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<HttpBody>;
  /** open / no-auth endpoint which requires a valid hash of concatenated image2_id + image_type + report_id to receive image data */
  getCameraImageOpen(request: DeepPartial<GetCameraImageOpenReq>, metadata?: grpc.Metadata): Promise<HttpBody>;
}

export class ImagesClientImpl implements Images {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.listCameraImageRange = this.listCameraImageRange.bind(this);
    this.listViewerCameraImageRange = this.listViewerCameraImageRange.bind(this);
    this.getCameraImage2Data = this.getCameraImage2Data.bind(this);
    this.getCameraSubtractionImage2Data = this.getCameraSubtractionImage2Data.bind(this);
    this.getCameraAnnotatedImage2Data = this.getCameraAnnotatedImage2Data.bind(this);
    this.getCameraSimpleAnnotatedImage2Data = this.getCameraSimpleAnnotatedImage2Data.bind(this);
    this.getCameraImageOpen = this.getCameraImageOpen.bind(this);
  }

  listCameraImageRange(
    request: DeepPartial<ListCameraImageRangeReq>,
    metadata?: grpc.Metadata,
  ): Promise<ListCameraImageRangeRes> {
    return this.rpc.unary(ImagesListCameraImageRangeDesc, ListCameraImageRangeReq.fromPartial(request), metadata);
  }

  listViewerCameraImageRange(
    request: DeepPartial<ListCameraImageRangeReq>,
    metadata?: grpc.Metadata,
  ): Promise<ListCameraImageRangeRes> {
    return this.rpc.unary(ImagesListViewerCameraImageRangeDesc, ListCameraImageRangeReq.fromPartial(request), metadata);
  }

  getCameraImage2Data(request: DeepPartial<GetCameraImageDataReq>, metadata?: grpc.Metadata): Promise<HttpBody> {
    return this.rpc.unary(ImagesGetCameraImage2DataDesc, GetCameraImageDataReq.fromPartial(request), metadata);
  }

  getCameraSubtractionImage2Data(
    request: DeepPartial<GetCameraImageDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<HttpBody> {
    return this.rpc.unary(
      ImagesGetCameraSubtractionImage2DataDesc,
      GetCameraImageDataReq.fromPartial(request),
      metadata,
    );
  }

  getCameraAnnotatedImage2Data(
    request: DeepPartial<GetAnnotationImageDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<HttpBody> {
    return this.rpc.unary(
      ImagesGetCameraAnnotatedImage2DataDesc,
      GetAnnotationImageDataReq.fromPartial(request),
      metadata,
    );
  }

  getCameraSimpleAnnotatedImage2Data(
    request: DeepPartial<GetAnnotationImageDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<HttpBody> {
    return this.rpc.unary(
      ImagesGetCameraSimpleAnnotatedImage2DataDesc,
      GetAnnotationImageDataReq.fromPartial(request),
      metadata,
    );
  }

  getCameraImageOpen(request: DeepPartial<GetCameraImageOpenReq>, metadata?: grpc.Metadata): Promise<HttpBody> {
    return this.rpc.unary(ImagesGetCameraImageOpenDesc, GetCameraImageOpenReq.fromPartial(request), metadata);
  }
}

export const ImagesDesc = { serviceName: "phoenix.images.v0.Images" };

export const ImagesListCameraImageRangeDesc: UnaryMethodDefinitionish = {
  methodName: "ListCameraImageRange",
  service: ImagesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCameraImageRangeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCameraImageRangeRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ImagesListViewerCameraImageRangeDesc: UnaryMethodDefinitionish = {
  methodName: "ListViewerCameraImageRange",
  service: ImagesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCameraImageRangeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCameraImageRangeRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ImagesGetCameraImage2DataDesc: UnaryMethodDefinitionish = {
  methodName: "GetCameraImage2Data",
  service: ImagesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCameraImageDataReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ImagesGetCameraSubtractionImage2DataDesc: UnaryMethodDefinitionish = {
  methodName: "GetCameraSubtractionImage2Data",
  service: ImagesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCameraImageDataReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ImagesGetCameraAnnotatedImage2DataDesc: UnaryMethodDefinitionish = {
  methodName: "GetCameraAnnotatedImage2Data",
  service: ImagesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAnnotationImageDataReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ImagesGetCameraSimpleAnnotatedImage2DataDesc: UnaryMethodDefinitionish = {
  methodName: "GetCameraSimpleAnnotatedImage2Data",
  service: ImagesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAnnotationImageDataReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ImagesGetCameraImageOpenDesc: UnaryMethodDefinitionish = {
  methodName: "GetCameraImageOpen",
  service: ImagesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCameraImageOpenReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
