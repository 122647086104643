import { Autocomplete, Box, Popover, TextField } from "@mui/material";
import service from "@phoenix/common/service";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { camerasKey, satellitesKey } from "../../../queryKeys";
import { useFilters } from "../../state";
import FilterButton from "./FilterButton";

const camerasOnlyOption = {
  type: "camerasOnly",
  groupLabel: "",
} as const;
const satellitesOnlyOption = {
  type: "satellitesOnly",
  groupLabel: "",
} as const;

export default function SourcesButton() {
  const { t } = useTranslation(["common", "reportsList"]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = !!anchorEl;
  const [reportFilters, setReportFilters] = useFilters();

  const cameras = useQuery(camerasKey(), () => service.cameras.getCameras({}));
  const cameraOptions = useMemo(() => {
    if (!cameras.data) return undefined;
    const data = [...cameras.data];
    data.sort((a, b) => a.info!.displayName.localeCompare(b.info!.displayName));
    return data.map((camera) => ({
      type: "camera" as const,
      camera,
      groupLabel: t("reportsList:camerasSourceGroup"),
    }));
  }, [cameras.data]);

  const satellites = useQuery(satellitesKey, () =>
    service.satellites.listSatellites()
  );
  const satelliteOptions = useMemo(() => {
    if (!satellites.data) return undefined;
    const data = [...satellites.data];
    data.sort((a, b) => a.displayName.localeCompare(b.displayName));
    return data.map((satellite) => ({
      type: "satellite" as const,
      satellite,
      groupLabel: t("reportsList:satellitesSourceGroup"),
    }));
  }, [satellites.data]);

  let selectedSource = null;

  if (reportFilters.cameraId) {
    const selectedCamera = cameraOptions?.find(
      (camera) => camera.camera.info!.id === reportFilters.cameraId
    );
    if (selectedCamera) selectedSource = selectedCamera;
  } else if (reportFilters.satelliteId) {
    const selectedSatellite = satelliteOptions?.find(
      (satellite) => satellite.satellite.id === reportFilters.satelliteId
    );
    if (selectedSatellite) selectedSource = selectedSatellite;
  } else if (reportFilters.omitCameraOnly) {
    selectedSource = satellitesOnlyOption;
  } else if (reportFilters.omitSatelliteOnly) {
    selectedSource = camerasOnlyOption;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const options = useMemo(
    () => [
      camerasOnlyOption,
      satellitesOnlyOption,
      ...(satelliteOptions ?? []),
      ...(cameraOptions ?? []),
    ],
    [satelliteOptions, cameraOptions]
  );

  return (
    <>
      <FilterButton onClick={handleClick}>
        {selectedSource === null
          ? t("reportsList:sourceButtonLabel")
          : selectedSource.type === "camera"
          ? selectedSource.camera.info!.displayName
          : selectedSource.type === "satellite"
          ? selectedSource.satellite.displayName
          : selectedSource.type === "camerasOnly"
          ? t("reportsList:camerasOnlyOption")
          : t("reportsList:satellitesOnlyOption")}
      </FilterButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box width="200px" px={2} py={1}>
          <Autocomplete
            loading={cameras.isLoading || satellites.isLoading}
            options={options}
            getOptionLabel={(option) =>
              option.type === "camera"
                ? option.camera.info!.displayName
                : option.type === "satellite"
                ? option.satellite.displayName
                : option.type === "camerasOnly"
                ? t("reportsList:camerasOnlyOption")
                : t("reportsList:satellitesOnlyOption")
            }
            groupBy={(option) => option.groupLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("reportsList:sourceButtonLabel")}
              />
            )}
            onChange={(_, value) =>
              setReportFilters((prev) =>
                value?.type === "camera"
                  ? {
                      ...prev,
                      cameraId: value.camera.info!.id,
                      satelliteId: 0,
                      omitCameraOnly: false,
                      omitSatelliteOnly: false,
                    }
                  : value?.type === "satellite"
                  ? {
                      ...prev,
                      cameraId: 0,
                      satelliteId: value.satellite.id,
                      omitCameraOnly: false,
                      omitSatelliteOnly: false,
                    }
                  : value?.type === "camerasOnly"
                  ? {
                      ...prev,
                      cameraId: 0,
                      satelliteId: 0,
                      omitCameraOnly: false,
                      omitSatelliteOnly: true,
                    }
                  : value?.type === "satellitesOnly"
                  ? {
                      ...prev,
                      cameraId: 0,
                      satelliteId: 0,
                      omitCameraOnly: true,
                      omitSatelliteOnly: false,
                    }
                  : {
                      ...prev,
                      cameraId: 0,
                      satelliteId: 0,
                      omitCameraOnly: false,
                      omitSatelliteOnly: false,
                    }
              )
            }
            value={selectedSource}
          />
        </Box>
      </Popover>
    </>
  );
}
