import { useEffect, useRef, useState } from "react";

const usePreloadImage = (src: string) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;

    if (image.complete) {
      setHasLoaded(true);
      setHasError(false);
    } else {
      setHasLoaded(false);
      setHasError(false);
    }

    const handleError = () => {
      setHasError(true);
      setHasLoaded(true);
    };

    const handleLoad = () => {
      setHasLoaded(true);
      setHasError(false);
    };

    image.addEventListener("error", handleError);
    image.addEventListener("load", handleLoad);

    return () => {
      image.remove();
    };
  }, [src]);

  return { hasLoaded, hasError };
};

export default usePreloadImage;

export function usePreloadImages(
  srcs: string[],
  {
    numSimultaneous = 3,
  }: {
    numSimultaneous?: number;
  } = {}
) {
  const [hasLoaded, setHasLoaded] = useState(() => srcs.map(() => false));
  const [hasError, setHasError] = useState(() => srcs.map(() => false));

  //array of indexes of src in the queue
  const remaining = useRef<number[]>([]);
  const numLoading = useRef(0);
  const cleanupFns = useRef<(() => void)[]>([]);

  useEffect(() => {
    if (srcs.length == 0) return;
    setHasLoaded(Array(srcs.length).fill(false));
    setHasError(Array(srcs.length).fill(false));
    remaining.current = srcs.map((_, idx) => idx);
    numLoading.current = 0;
    cleanupFns.current = [];

    const startImage = (idx: number) => {
      if (!srcs[idx]) return () => {};
      numLoading.current++;
      const image = new Image();
      image.src = srcs[idx];

      const finished = (success: boolean) => {
        setHasLoaded((prev) => replaceIndex(prev, idx, true));
        setHasError((prev) => replaceIndex(prev, idx, !success));
        numLoading.current--;
        if (remaining.current.length > 0)
          cleanupFns.current.push(startImage(remaining.current.pop()!));
      };

      if (image.complete) {
        finished(true);
      } else {
        setHasLoaded((prev) => replaceIndex(prev, idx, false));
        setHasError((prev) => replaceIndex(prev, idx, false));
      }

      const handleError = () => {
        finished(false);
      };
      const handleLoad = () => {
        finished(true);
      };

      image.addEventListener("error", handleError);
      image.addEventListener("load", handleLoad);

      return () => image.remove();
    };

    Array(numSimultaneous)
      .fill(0)
      .forEach(() =>
        cleanupFns.current.push(startImage(remaining.current.pop()!))
      );

    return () => cleanupFns.current.forEach((clean) => clean());
  }, [srcs, numSimultaneous]);

  return {
    hasLoaded: !hasLoaded.includes(false),
    hasError: hasError.includes(true),
  };
}

function replaceIndex<T>(arr: T[], index: number, newVal: T) {
  return [...arr.slice(0, index), newVal, ...arr.slice(index + 1)];
}
