import { grpc } from "@improbable-eng/grpc-web";

import {
  AdminClientImpl as AdminClient,
  GrpcWebImpl as AdminImpl,
} from "../proto/admin";
import {
  APIClientImpl as ApiClient,
  GrpcWebImpl as ApiImpl,
} from "../proto/api";
import {
  AuthClientImpl as AuthClient,
  GrpcWebImpl as AuthImpl,
} from "../proto/auth";
import {
  CamerasClientImpl as CamerasClient,
  GrpcWebImpl as CamerasImpl,
} from "../proto/cameras";
import {
  DetectionsClientImpl as DetectionsClient,
  GrpcWebImpl as DetectionsImpl,
} from "../proto/detections";
import {
  ImagesClientImpl as ImagesClient,
  GrpcWebImpl as ImagesImpl,
} from "../proto/images";
import {
  ReportsClientImpl as ReportsClient,
  GrpcWebImpl as ReportsImpl,
} from "../proto/reports";
import {
  SatellitesClientImpl as SatellitesClient,
  GrpcWebImpl as SatellitesImpl,
} from "../proto/satellites";
import {
  SettingsClientImpl as SettingsClient,
  GrpcWebImpl as SettingsImpl,
} from "../proto/settings";

if (!import.meta.env.VITE_PX_BASE_URL) throw Error("PX base url not set");
const host = import.meta.env.VITE_PX_BASE_URL!;
const transport = grpc.CrossBrowserHttpTransport({ withCredentials: true });

let onUnauthorized = () => {};
export const setOnUnauthorized = (func: () => void) => {
  onUnauthorized = func;
};

const Intercepted: any = (RpcImpl: any): any =>
  class extends RpcImpl {
    constructor(...constructorArgs: any[]) {
      super(...constructorArgs);
      this.unary = async (...args: any[]) => {
        try {
          const res = await super.unary(...args);
          return res;
        } catch (error) {
          if (error instanceof Error && "code" in error) {
            // @ts-ignore
            if (error.code === grpc.Code.Unauthenticated) {
              onUnauthorized();
            }
          }
          throw error;
        }
      };
    }
  };

const rpcs = {
  admin: new (Intercepted(AdminImpl))(host, { transport }),
  auth: new (Intercepted(AuthImpl))(host, { transport }),
  cameras: new (Intercepted(CamerasImpl))(host, { transport }),
  detections: new (Intercepted(DetectionsImpl))(host, { transport }),
  images: new (Intercepted(ImagesImpl))(host, { transport }),
  pxApi: new (Intercepted(ApiImpl))(host, { transport }),
  reports: new (Intercepted(ReportsImpl))(host, { transport }),
  satellites: new (Intercepted(SatellitesImpl))(host, { transport }),
  settings: new (Intercepted(SettingsImpl))(host, { transport }),
};

const client = {
  admin: new AdminClient(rpcs.admin),
  auth: new AuthClient(rpcs.auth),
  cameras: new CamerasClient(rpcs.cameras),
  detections: new DetectionsClient(rpcs.detections),
  images: new ImagesClient(rpcs.images),
  pxApi: new ApiClient(rpcs.pxApi),
  reports: new ReportsClient(rpcs.reports),
  satellites: new SatellitesClient(rpcs.satellites),
  settings: new SettingsClient(rpcs.settings),
};

export default client;
