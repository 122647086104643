/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { DoubleValue, Int32Value, Int64Value, StringValue } from "./google/protobuf/wrappers";

export const protobufPackage = "phoenix.user.v0";

export const UserRole = {
  /** USER_ROLE_UNKNOWN - error */
  USER_ROLE_UNKNOWN: 0,
  USER_ROLE_GOD: 1,
  USER_ROLE_REVIEWER: 2,
  USER_ROLE_VIEWER: 3,
  USER_ROLE_DISABLED: 4,
  UNRECOGNIZED: -1,
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];

export namespace UserRole {
  export type USER_ROLE_UNKNOWN = typeof UserRole.USER_ROLE_UNKNOWN;
  export type USER_ROLE_GOD = typeof UserRole.USER_ROLE_GOD;
  export type USER_ROLE_REVIEWER = typeof UserRole.USER_ROLE_REVIEWER;
  export type USER_ROLE_VIEWER = typeof UserRole.USER_ROLE_VIEWER;
  export type USER_ROLE_DISABLED = typeof UserRole.USER_ROLE_DISABLED;
  export type UNRECOGNIZED = typeof UserRole.UNRECOGNIZED;
}

export function userRoleFromJSON(object: any): UserRole {
  switch (object) {
    case 0:
    case "USER_ROLE_UNKNOWN":
      return UserRole.USER_ROLE_UNKNOWN;
    case 1:
    case "USER_ROLE_GOD":
      return UserRole.USER_ROLE_GOD;
    case 2:
    case "USER_ROLE_REVIEWER":
      return UserRole.USER_ROLE_REVIEWER;
    case 3:
    case "USER_ROLE_VIEWER":
      return UserRole.USER_ROLE_VIEWER;
    case 4:
    case "USER_ROLE_DISABLED":
      return UserRole.USER_ROLE_DISABLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserRole.UNRECOGNIZED;
  }
}

export function userRoleToJSON(object: UserRole): string {
  switch (object) {
    case UserRole.USER_ROLE_UNKNOWN:
      return "USER_ROLE_UNKNOWN";
    case UserRole.USER_ROLE_GOD:
      return "USER_ROLE_GOD";
    case UserRole.USER_ROLE_REVIEWER:
      return "USER_ROLE_REVIEWER";
    case UserRole.USER_ROLE_VIEWER:
      return "USER_ROLE_VIEWER";
    case UserRole.USER_ROLE_DISABLED:
      return "USER_ROLE_DISABLED";
    case UserRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export const NotificationMethod = {
  /** NOTIFICATION_METHOD_UNKNOWN - error */
  NOTIFICATION_METHOD_UNKNOWN: 0,
  NOTIFICATION_METHOD_EMAIL: 1,
  NOTIFICATION_METHOD_SMS: 2,
  NOTIFICATION_METHOD_WEBHOOK: 3,
  UNRECOGNIZED: -1,
} as const;

export type NotificationMethod = typeof NotificationMethod[keyof typeof NotificationMethod];

export namespace NotificationMethod {
  export type NOTIFICATION_METHOD_UNKNOWN = typeof NotificationMethod.NOTIFICATION_METHOD_UNKNOWN;
  export type NOTIFICATION_METHOD_EMAIL = typeof NotificationMethod.NOTIFICATION_METHOD_EMAIL;
  export type NOTIFICATION_METHOD_SMS = typeof NotificationMethod.NOTIFICATION_METHOD_SMS;
  export type NOTIFICATION_METHOD_WEBHOOK = typeof NotificationMethod.NOTIFICATION_METHOD_WEBHOOK;
  export type UNRECOGNIZED = typeof NotificationMethod.UNRECOGNIZED;
}

export function notificationMethodFromJSON(object: any): NotificationMethod {
  switch (object) {
    case 0:
    case "NOTIFICATION_METHOD_UNKNOWN":
      return NotificationMethod.NOTIFICATION_METHOD_UNKNOWN;
    case 1:
    case "NOTIFICATION_METHOD_EMAIL":
      return NotificationMethod.NOTIFICATION_METHOD_EMAIL;
    case 2:
    case "NOTIFICATION_METHOD_SMS":
      return NotificationMethod.NOTIFICATION_METHOD_SMS;
    case 3:
    case "NOTIFICATION_METHOD_WEBHOOK":
      return NotificationMethod.NOTIFICATION_METHOD_WEBHOOK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NotificationMethod.UNRECOGNIZED;
  }
}

export function notificationMethodToJSON(object: NotificationMethod): string {
  switch (object) {
    case NotificationMethod.NOTIFICATION_METHOD_UNKNOWN:
      return "NOTIFICATION_METHOD_UNKNOWN";
    case NotificationMethod.NOTIFICATION_METHOD_EMAIL:
      return "NOTIFICATION_METHOD_EMAIL";
    case NotificationMethod.NOTIFICATION_METHOD_SMS:
      return "NOTIFICATION_METHOD_SMS";
    case NotificationMethod.NOTIFICATION_METHOD_WEBHOOK:
      return "NOTIFICATION_METHOD_WEBHOOK";
    case NotificationMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface User {
  id: number;
  name: string;
  email: string;
  /** WKT format string used to describe area of interest */
  areaOfInterest: string;
  role: UserRole;
  groupId: number;
  /** Timezone is supplied as a TZ database formatted name string */
  timezone: string;
  preferredNotificationMethod: NotificationMethod[];
}

export interface PreferredNotificationMethodUpdate {
  methods: NotificationMethod[];
}

export interface UserUpdate {
  id: number;
  name: string | undefined;
  email:
    | string
    | undefined;
  /** WKT format used to describe area of interest */
  areaOfInterest: string | undefined;
  role: UserRole;
  groupId:
    | number
    | undefined;
  /** Timezone is supplied as a TZ database formatted name string */
  timezone: string | undefined;
  preferredNotificationMethod: PreferredNotificationMethodUpdate | undefined;
}

export interface UserGroup {
  id: number;
  name: string;
  latitude: number | undefined;
  longitude: number | undefined;
  zoomLevel:
    | number
    | undefined;
  /** WKT format string used to describe area of interest */
  areaOfInterest: string;
}

export interface UserGroupUpdate {
  id: number;
  name: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  zoomLevel:
    | number
    | undefined;
  /** WKT format string used to describe area of interest */
  areaOfInterest: string | undefined;
}

function createBaseUser(): User {
  return {
    id: 0,
    name: "",
    email: "",
    areaOfInterest: "",
    role: 0,
    groupId: 0,
    timezone: "",
    preferredNotificationMethod: [],
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.areaOfInterest !== "") {
      writer.uint32(34).string(message.areaOfInterest);
    }
    if (message.role !== 0) {
      writer.uint32(40).int32(message.role);
    }
    if (message.groupId !== 0) {
      writer.uint32(48).int64(message.groupId);
    }
    if (message.timezone !== "") {
      writer.uint32(58).string(message.timezone);
    }
    writer.uint32(66).fork();
    for (const v of message.preferredNotificationMethod) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.areaOfInterest = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.groupId = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.timezone = reader.string();
          continue;
        case 8:
          if (tag === 64) {
            message.preferredNotificationMethod.push(reader.int32() as any);

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.preferredNotificationMethod.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      areaOfInterest: isSet(object.areaOfInterest) ? globalThis.String(object.areaOfInterest) : "",
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
      groupId: isSet(object.groupId) ? globalThis.Number(object.groupId) : 0,
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
      preferredNotificationMethod: globalThis.Array.isArray(object?.preferredNotificationMethod)
        ? object.preferredNotificationMethod.map((e: any) => notificationMethodFromJSON(e))
        : [],
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.areaOfInterest !== "") {
      obj.areaOfInterest = message.areaOfInterest;
    }
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    if (message.groupId !== 0) {
      obj.groupId = Math.round(message.groupId);
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    if (message.preferredNotificationMethod?.length) {
      obj.preferredNotificationMethod = message.preferredNotificationMethod.map((e) => notificationMethodToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.areaOfInterest = object.areaOfInterest ?? "";
    message.role = object.role ?? 0;
    message.groupId = object.groupId ?? 0;
    message.timezone = object.timezone ?? "";
    message.preferredNotificationMethod = object.preferredNotificationMethod?.map((e) => e) || [];
    return message;
  },
};

function createBasePreferredNotificationMethodUpdate(): PreferredNotificationMethodUpdate {
  return { methods: [] };
}

export const PreferredNotificationMethodUpdate = {
  encode(message: PreferredNotificationMethodUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.methods) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreferredNotificationMethodUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreferredNotificationMethodUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.methods.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.methods.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreferredNotificationMethodUpdate {
    return {
      methods: globalThis.Array.isArray(object?.methods)
        ? object.methods.map((e: any) => notificationMethodFromJSON(e))
        : [],
    };
  },

  toJSON(message: PreferredNotificationMethodUpdate): unknown {
    const obj: any = {};
    if (message.methods?.length) {
      obj.methods = message.methods.map((e) => notificationMethodToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreferredNotificationMethodUpdate>, I>>(
    base?: I,
  ): PreferredNotificationMethodUpdate {
    return PreferredNotificationMethodUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreferredNotificationMethodUpdate>, I>>(
    object: I,
  ): PreferredNotificationMethodUpdate {
    const message = createBasePreferredNotificationMethodUpdate();
    message.methods = object.methods?.map((e) => e) || [];
    return message;
  },
};

function createBaseUserUpdate(): UserUpdate {
  return {
    id: 0,
    name: undefined,
    email: undefined,
    areaOfInterest: undefined,
    role: 0,
    groupId: undefined,
    timezone: undefined,
    preferredNotificationMethod: undefined,
  };
}

export const UserUpdate = {
  encode(message: UserUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.email !== undefined) {
      StringValue.encode({ value: message.email! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.areaOfInterest !== undefined) {
      StringValue.encode({ value: message.areaOfInterest! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.role !== 0) {
      writer.uint32(40).int32(message.role);
    }
    if (message.groupId !== undefined) {
      Int64Value.encode({ value: message.groupId! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.timezone !== undefined) {
      StringValue.encode({ value: message.timezone! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.preferredNotificationMethod !== undefined) {
      PreferredNotificationMethodUpdate.encode(message.preferredNotificationMethod, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.areaOfInterest = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.groupId = Int64Value.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.timezone = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.preferredNotificationMethod = PreferredNotificationMethodUpdate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserUpdate {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      areaOfInterest: isSet(object.areaOfInterest) ? String(object.areaOfInterest) : undefined,
      role: isSet(object.role) ? userRoleFromJSON(object.role) : 0,
      groupId: isSet(object.groupId) ? Number(object.groupId) : undefined,
      timezone: isSet(object.timezone) ? String(object.timezone) : undefined,
      preferredNotificationMethod: isSet(object.preferredNotificationMethod)
        ? PreferredNotificationMethodUpdate.fromJSON(object.preferredNotificationMethod)
        : undefined,
    };
  },

  toJSON(message: UserUpdate): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.email !== undefined) {
      obj.email = message.email;
    }
    if (message.areaOfInterest !== undefined) {
      obj.areaOfInterest = message.areaOfInterest;
    }
    if (message.role !== 0) {
      obj.role = userRoleToJSON(message.role);
    }
    if (message.groupId !== undefined) {
      obj.groupId = message.groupId;
    }
    if (message.timezone !== undefined) {
      obj.timezone = message.timezone;
    }
    if (message.preferredNotificationMethod !== undefined) {
      obj.preferredNotificationMethod = PreferredNotificationMethodUpdate.toJSON(message.preferredNotificationMethod);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserUpdate>, I>>(base?: I): UserUpdate {
    return UserUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserUpdate>, I>>(object: I): UserUpdate {
    const message = createBaseUserUpdate();
    message.id = object.id ?? 0;
    message.name = object.name ?? undefined;
    message.email = object.email ?? undefined;
    message.areaOfInterest = object.areaOfInterest ?? undefined;
    message.role = object.role ?? 0;
    message.groupId = object.groupId ?? undefined;
    message.timezone = object.timezone ?? undefined;
    message.preferredNotificationMethod =
      (object.preferredNotificationMethod !== undefined && object.preferredNotificationMethod !== null)
        ? PreferredNotificationMethodUpdate.fromPartial(object.preferredNotificationMethod)
        : undefined;
    return message;
  },
};

function createBaseUserGroup(): UserGroup {
  return { id: 0, name: "", latitude: undefined, longitude: undefined, zoomLevel: undefined, areaOfInterest: "" };
}

export const UserGroup = {
  encode(message: UserGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.latitude !== undefined) {
      DoubleValue.encode({ value: message.latitude! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.longitude !== undefined) {
      DoubleValue.encode({ value: message.longitude! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.zoomLevel !== undefined) {
      Int32Value.encode({ value: message.zoomLevel! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.areaOfInterest !== "") {
      writer.uint32(50).string(message.areaOfInterest);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.latitude = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.longitude = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.zoomLevel = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.areaOfInterest = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroup {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
      longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
      zoomLevel: isSet(object.zoomLevel) ? Number(object.zoomLevel) : undefined,
      areaOfInterest: isSet(object.areaOfInterest) ? globalThis.String(object.areaOfInterest) : "",
    };
  },

  toJSON(message: UserGroup): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.latitude !== undefined) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== undefined) {
      obj.longitude = message.longitude;
    }
    if (message.zoomLevel !== undefined) {
      obj.zoomLevel = message.zoomLevel;
    }
    if (message.areaOfInterest !== "") {
      obj.areaOfInterest = message.areaOfInterest;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroup>, I>>(base?: I): UserGroup {
    return UserGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserGroup>, I>>(object: I): UserGroup {
    const message = createBaseUserGroup();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.latitude = object.latitude ?? undefined;
    message.longitude = object.longitude ?? undefined;
    message.zoomLevel = object.zoomLevel ?? undefined;
    message.areaOfInterest = object.areaOfInterest ?? "";
    return message;
  },
};

function createBaseUserGroupUpdate(): UserGroupUpdate {
  return {
    id: 0,
    name: undefined,
    latitude: undefined,
    longitude: undefined,
    zoomLevel: undefined,
    areaOfInterest: undefined,
  };
}

export const UserGroupUpdate = {
  encode(message: UserGroupUpdate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.latitude !== undefined) {
      DoubleValue.encode({ value: message.latitude! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.longitude !== undefined) {
      DoubleValue.encode({ value: message.longitude! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.zoomLevel !== undefined) {
      Int32Value.encode({ value: message.zoomLevel! }, writer.uint32(42).fork()).ldelim();
    }
    if (message.areaOfInterest !== undefined) {
      StringValue.encode({ value: message.areaOfInterest! }, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserGroupUpdate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserGroupUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.latitude = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.longitude = DoubleValue.decode(reader, reader.uint32()).value;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.zoomLevel = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.areaOfInterest = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserGroupUpdate {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : undefined,
      latitude: isSet(object.latitude) ? Number(object.latitude) : undefined,
      longitude: isSet(object.longitude) ? Number(object.longitude) : undefined,
      zoomLevel: isSet(object.zoomLevel) ? Number(object.zoomLevel) : undefined,
      areaOfInterest: isSet(object.areaOfInterest) ? String(object.areaOfInterest) : undefined,
    };
  },

  toJSON(message: UserGroupUpdate): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.latitude !== undefined) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== undefined) {
      obj.longitude = message.longitude;
    }
    if (message.zoomLevel !== undefined) {
      obj.zoomLevel = message.zoomLevel;
    }
    if (message.areaOfInterest !== undefined) {
      obj.areaOfInterest = message.areaOfInterest;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserGroupUpdate>, I>>(base?: I): UserGroupUpdate {
    return UserGroupUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserGroupUpdate>, I>>(object: I): UserGroupUpdate {
    const message = createBaseUserGroupUpdate();
    message.id = object.id ?? 0;
    message.name = object.name ?? undefined;
    message.latitude = object.latitude ?? undefined;
    message.longitude = object.longitude ?? undefined;
    message.zoomLevel = object.zoomLevel ?? undefined;
    message.areaOfInterest = object.areaOfInterest ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
