import { SetNotificationMethodsReq } from "../proto/settings";
import { NotificationMethod } from "../proto/user";
import client from "./client";

export async function setNotificationMethods(methods: NotificationMethod[]) {
  const req: SetNotificationMethodsReq = { methods };
  return await client.settings.setNotificationMethods(req);
}

export async function getNotificationMethods() {
  return await client.settings.getNotificationMethods({});
}
