import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import niceGrpcErrors from "../../utils/niceGrpcErrors";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export default function ErrorWithIcon({
  message,
  fontSize,
}: {
  message: string;
  fontSize: number;
}) {
  const { t } = useTranslation(["common"]);
  return (
    <Container>
      <svg
        width={fontSize * 3}
        height={fontSize * 3}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
      >
        <path
          d="M24 23h-24l12-22 12 22zm-22.315-1h20.63l-10.315-18.912-10.315 18.912zm10.315-2c.466 0 .845-.378.845-.845 0-.466-.379-.844-.845-.844-.466 0-.845.378-.845.844 0 .467.379.845.845.845zm.5-11v8h-1v-8h1z"
          stroke="currentColor"
        />
      </svg>
      <Typography fontSize={fontSize}>{niceGrpcErrors(message, t)}</Typography>
    </Container>
  );
}
