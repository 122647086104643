import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import routes from "../../routes";
import { useAuthContext } from "./AuthProvider";

export default function Logout() {
  const auth = useAuthContext();
  useEffect(() => {
    auth.actions.logout();
  });

  return <Navigate to={routes.login} />;
}
