import geojson from "geojson";

import {
  ListReportFilters,
  ListReportsRes,
  MoveToReportReq,
  Report,
  ReviewReportReq,
  SplitReportReq,
  UndoMergeReq,
} from "../proto/reports";
import { ConfirmationState } from "../proto/types";
import client from "./client";

export async function getReportsPizzasGeoJSON(
  filters: ListReportFilters,
  reviewedOnly: boolean
): Promise<
  geojson.FeatureCollection<geojson.MultiPolygon, { firstDetectionTime: Date }>
> {
  const res = reviewedOnly
    ? await client.reports.getReviewedReportAreasGeojson(filters)
    : await client.reports.getReportAreasGeojson(filters);
  const decoder = new TextDecoder();
  const text = decoder.decode(res.data);
  return JSON.parse(text);
}

export async function getReport(
  id: number,
  reviewedOnly: boolean
): Promise<Report> {
  if (reviewedOnly) {
    const res = await client.reports.getReviewedReport({ id });
    return res;
  } else {
    const res = await client.reports.getReport({ id });
    return res;
  }
}

export async function listReports(
  filters: ListReportFilters,
  reviewedOnly: boolean
): Promise<ListReportsRes> {
  if (reviewedOnly) {
    const res = await client.reports.listReviewedReports(filters);
    return res;
  } else {
    const res = await client.reports.listReports(filters);
    return res;
  }
}

export async function listMergeCandidates(reportId: number) {
  const res = await client.reports.listMergeCandidates({ id: reportId });
  return {
    ...res,
    source: {
      ...res.source!,
      areaGeojson: JSON.parse(res.source!.areaGeojson) as geojson.GeoJsonObject,
    },
    candidates: res.candidates.map((candidate) => ({
      ...candidate,
      areaGeojson: JSON.parse(candidate.areaGeojson) as geojson.GeoJsonObject,
    })),
  };
}

export function getMergeTimeline(reportId: number) {
  return client.reports.getReportMergeTimeline({ id: reportId });
}

export function undoMerge(req: UndoMergeReq) {
  return client.reports.undoMerge(req);
}

export async function reviewReport(req: ReviewReportReq) {
  await client.reports.reviewReport(req);
}

export function moveToReport(
  data: ({ reportId: number } | { detectionId: number }) & {
    targetReportId: number;
  }
) {
  const req: MoveToReportReq = {
    origin:
      "reportId" in data
        ? { $case: "reportId", reportId: data.reportId }
        : { $case: "detectionId", detectionId: data.detectionId },
    targetReportId: data.targetReportId,
  };
  return client.reports.moveToReport(req);
}

export async function splitReport(req: SplitReportReq) {
  return client.reports.splitReport(req);
}
