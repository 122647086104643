import { grpc } from "@improbable-eng/grpc-web";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Slider,
} from "@mui/material";
import Button from "@phoenix/common/components/simple/Button";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import service from "@phoenix/common/service";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { leverKey } from "../../../../queryKeys";
import useSnackbar from "../../../../utils/useSnackbar";

export default function LeverDialogMenuItem({
  closeParentMenu,
}: {
  closeParentMenu: () => void;
}) {
  const { t } = useTranslation("common");
  const showSnackbar = useSnackbar();

  const [dirtyLeverValue, setDirtyLeverValue] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const leverQuery = useQuery<number, RpcError>(leverKey, () =>
    service.detections.getMlLeverValue()
  );
  const queryClient = useQueryClient();
  const leverMutation = useMutation<Record<never, never>, RpcError, number>(
    (value) => {
      if (value < 0.3)
        throw new RpcError(
          grpc.Code.InvalidArgument,
          t("components.UserMenu.leverGreaterThan0_3Error")
        );
      return service.detections.setMlLeverValue(value);
    },
    {
      onError: (e) => showSnackbar({ kind: "error", message: e.message }),
      onSuccess: (data, value) => {
        queryClient.setQueryData(leverKey, value);
        queryClient.invalidateQueries(leverKey);
        setDirtyLeverValue(null);
        closeParentMenu();
      },
    }
  );

  return (
    <>
      <MenuItem onClick={(e) => setAnchorEl(e.currentTarget)}>
        {t("components.UserMenu.mlLever")}
      </MenuItem>
      <Dialog open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        <DialogTitle>{t("components.UserMenu.mlLever")}</DialogTitle>
        <DialogContent>
          {leverQuery.status === "loading" ? (
            <CircularProgress />
          ) : leverQuery.status === "error" ? (
            <Alert severity="error">{leverQuery.error.message}</Alert>
          ) : (
            <DialogContentText>
              Keep {(dirtyLeverValue ?? leverQuery.data) * 100}% of images
            </DialogContentText>
          )}
          <Slider
            color="secondary"
            value={dirtyLeverValue ?? leverQuery.data}
            disabled={leverQuery.isLoading || leverMutation.isLoading}
            min={0}
            max={1}
            step={0.1}
            valueLabelDisplay="off"
            valueLabelFormat={(val) => `${val * 100}%`}
            onChange={(e, value) =>
              Array.isArray(value)
                ? setDirtyLeverValue(value[0])
                : setDirtyLeverValue(value)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              if (dirtyLeverValue) leverMutation.mutate(dirtyLeverValue);
            }}
            loading={leverQuery.isLoading || leverMutation.isLoading}
            disabled={
              dirtyLeverValue === leverQuery.data || dirtyLeverValue === null
            }
          >
            {t("action.confirm")}
          </Button>
          <Button onClick={() => setAnchorEl(null)}>{t("action.close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
