import { RefreshOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  emphasize,
  FormControlLabel,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import Button from "@phoenix/common/components/simple/Button";
import { ImageDetection } from "@phoenix/common/proto/detections";
import { ReviewReason } from "@phoenix/common/proto/reports";
import { ConfirmationState } from "@phoenix/common/proto/types";
import service from "@phoenix/common/service";
import { RpcError } from "@phoenix/common/utils/rpcError";
import { useMutation } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { reviewReasons } from "../../../constants";
import { useAuthContext } from "../../../features/auth/AuthProvider";
import { viewingImageTypeAtom } from "../../../features/state";
import useInvalidateReports from "../../../utils/useInvalidateReports";
import useSnackbar from "../../../utils/useSnackbar";

export default function ImageOptionsRow({
  currentDetections,
  isCameraSource,
  jumpTo,
  viewingMostRecent,
  viewingFirstDetection,
  viewingLatestActivity,
}: {
  currentDetections: ImageDetection[] | null;
  isCameraSource: boolean;
  jumpTo: (
    where: "firstDetection" | "latestActivity" | "mostRecent"
  ) => Promise<void>;
  viewingMostRecent: boolean;
  viewingFirstDetection: boolean;
  viewingLatestActivity: boolean;
}) {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const {
    state: { scopes },
  } = useAuthContext();
  const canViewSubtractionImages = scopes.includes(
    "images.get_camera_subtraction_image_data"
  );
  const canReview = scopes.includes("reports.review");

  const [imageType, setImageType] = useAtom(viewingImageTypeAtom);
  const [rejectMenuAnchor, setRejectMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const handleRejectMenuClose = () => setRejectMenuAnchor(null);

  const invalidateReports = useInvalidateReports();
  const showSnackbar = useSnackbar();
  const rejectAllUnconfirmed = useMutation<number[], RpcError, ReviewReason>(
    async (reason) => {
      if (!currentDetections) return [];
      await Promise.all(
        currentDetections.map(async (detection) => {
          if (
            detection.state !== ConfirmationState.CONFIRMATION_STATE_UNCONFIRMED
          )
            return;
          if (!detection.reportId) return;
          return service.reports.reviewReport({
            reportId: detection.reportId,
            newState: ConfirmationState.CONFIRMATION_STATE_REJECTED,
            reason,
          });
        })
      );
      return currentDetections.map((detection) => detection.reportId);
    },
    {
      onSuccess: (rejectedIds) => {
        invalidateReports(rejectedIds);
      },
      onError: (e) => {
        showSnackbar({ kind: "error", message: e.message });
      },
    }
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", flexWrap: "wrap" }}>
      {canReview && (
        <>
          <Button
            onClick={(e) => setRejectMenuAnchor(e.currentTarget)}
            variant="text"
            loading={rejectAllUnconfirmed.isLoading}
            sx={{ marginInlineEnd: "auto" }}
          >
            {t("components.CameraImageTimeline.rejectAllUnconfirmedButton")}
          </Button>
          <Menu
            open={!!rejectMenuAnchor}
            anchorEl={rejectMenuAnchor}
            onClose={handleRejectMenuClose}
          >
            {Array.from(reviewReasons(t).entries()).map(([value, label]) => (
              <MenuItem
                key={value}
                onClick={() => {
                  rejectAllUnconfirmed.mutate(value);
                  handleRejectMenuClose();
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {viewingMostRecent && (
          <Tooltip
            title={t("components.CameraImageTimeline.autoRefreshTooltip")}
          >
            <RefreshOutlined sx={{ color: theme.typography.caption.color }} />
          </Tooltip>
        )}
        {isCameraSource && (
          <>
            <Button
              onClick={() => jumpTo("firstDetection")}
              variant="text"
              sx={
                viewingFirstDetection
                  ? {
                      fontWeight: "bold",
                      color: (theme) =>
                        emphasize(theme.palette.primary.main, 0.3),
                    }
                  : undefined
              }
            >
              {t("components.CameraImageTimeline.firstDetectionButton")}
            </Button>
            <Button
              onClick={() => jumpTo("latestActivity")}
              variant="text"
              sx={
                viewingLatestActivity
                  ? {
                      fontWeight: "bold",
                      color: (theme) =>
                        emphasize(theme.palette.primary.main, 0.3),
                    }
                  : undefined
              }
            >
              {t("components.CameraImageTimeline.latestActivityButton")}
            </Button>
          </>
        )}
        <Button
          onClick={() => jumpTo("mostRecent")}
          variant="text"
          sx={
            viewingMostRecent
              ? {
                  fontWeight: "bold",
                  color: (theme) => emphasize(theme.palette.primary.main, 0.3),
                }
              : undefined
          }
        >
          {t("components.CameraImageTimeline.latestImageButton")}
        </Button>
        {canViewSubtractionImages && (
          <FormControlLabel
            control={
              <Checkbox size="small" checked={imageType === "subtraction"} />
            }
            labelPlacement="start"
            label={t("components.CameraImageTimeline.subtractionCheckboxLabel")}
            value={imageType === "subtraction"}
            onChange={(_, checked) =>
              setImageType(checked ? "subtraction" : "standard")
            }
            componentsProps={{ typography: { noWrap: true } }}
          />
        )}
      </Box>
    </Box>
  );
}
