import { CheckOutlined, ContentCopyOutlined } from "@mui/icons-material";
import { Fade, IconButton, SxProps } from "@mui/material";
import { useState } from "react";

export default function CopyButton({
  title,
  data,
  onError,
  sx,
}: {
  title: string;
  data: string;
  onError?: (e: unknown) => void;
  sx?: SxProps;
}) {
  const [copied, setCopied] = useState(false);
  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(data);
      setCopied(true);
    } catch (e) {
      onError?.(e);
    }
  };
  return (
    <IconButton onClick={handleClick} title={title} sx={sx}>
      <Fade in={copied} style={{ position: "absolute" }}>
        <CheckOutlined fontSize="small" />
      </Fade>
      <Fade in={!copied}>
        <ContentCopyOutlined fontSize="small" />
      </Fade>
    </IconButton>
  );
}
