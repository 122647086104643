import "./index.css";

import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import App from "./App";

if (
  (import.meta.env.VITE_FIT_ENV === "production" ||
    import.meta.env.VITE_FIT_ENV === "staging") &&
  import.meta.env.VITE_SENTRY_DSN
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.4,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [/https:\/\/px\.image-software\.net\/.*/],
        networkResponseHeaders: ["x-envoy-upstream-service-time"],
        networkCaptureBodies: false,
      }),
    ],
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
