import { MenuItem } from "@mui/material";
import CircularProgress from "@phoenix/common/components/simple/CircularProgress";
import { GetNotificationMethodsRes } from "@phoenix/common/proto/settings";
import { NotificationMethod } from "@phoenix/common/proto/user";
import service from "@phoenix/common/service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RpcError } from "grpc-web";
import { useTranslation } from "react-i18next";

import { notificationMethodsKey } from "../../../../queryKeys";
import useSnackbar from "../../../../utils/useSnackbar";

export function NotificationsCheckboxMenuItem() {
  const { t } = useTranslation("common");
  const showSnackbar = useSnackbar();

  const notificationMethods = useQuery(notificationMethodsKey, () =>
    service.settings.getNotificationMethods()
  );
  const checked = notificationMethods.data?.methods.includes(
    NotificationMethod.NOTIFICATION_METHOD_EMAIL
  );
  const queryClient = useQueryClient();
  const receiveEmails = useMutation<
    GetNotificationMethodsRes,
    RpcError,
    boolean
  >(
    (receive: boolean) => {
      return service.settings.setNotificationMethods(
        receive ? [NotificationMethod.NOTIFICATION_METHOD_EMAIL] : []
      );
    },
    {
      onError: (e) => {
        showSnackbar({ kind: "error", message: e.message });
      },
      onSuccess: (data) => {
        queryClient.setQueryData(notificationMethodsKey, data);
        queryClient.invalidateQueries(notificationMethodsKey);
      },
    }
  );

  const handleClick = () => receiveEmails.mutate(!checked);

  return (
    <MenuItem onClick={handleClick} disabled={receiveEmails.isLoading}>
      <>
        {receiveEmails.isLoading ? (
          <>
            <CircularProgress size={16} />
            &nbsp;
          </>
        ) : checked ? (
          "☑"
        ) : (
          "☐"
        )}{" "}
        {t("components.UserMenu.receiveEmails")}
      </>
    </MenuItem>
  );
}
