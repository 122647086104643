import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InView } from "react-intersection-observer";

import { reverseGeocodeKey } from "../../queryKeys";
import { reverseGeocode } from "../../utils/geocode";

export default function LocationWithFallback({
  latitude,
  longitude,
  location,
}: {
  latitude: number;
  longitude: number;
  location?: string;
}) {
  const { t, i18n } = useTranslation("common");
  const lat = Number(latitude.toFixed(3));
  const long = Number(longitude.toFixed(3));

  const [inView, setInView] = useState(false);
  const query = useQuery<string, Error>(
    reverseGeocodeKey({
      language: i18n.language,
      latitude: lat,
      longitude: long,
    }),
    () =>
      reverseGeocode({
        latitude: lat,
        longitude: long,
        language: i18n.language,
      }),
    {
      enabled: !location && inView,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  if (location) return <>{location}</>;
  switch (query.status) {
    case "loading":
      return (
        <InView
          onChange={(isInView) => setInView(isInView)}
          rootMargin="500px 500px"
          style={{ display: "inline" }}
        >
          <CircularProgress size="10px" sx={{ ml: 1 }} />
        </InView>
      );
    case "error":
      return <>{t("components.LocationWithFallback.error")}</>;
    case "success":
      return <>{query.data}</>;
  }
}
