const routes = {
  login: "/login",
  logout: "/logout",
  camera: "/camera/:id",
  report: "/report/:id",
};

export const routeTo = {
  camera: (id: number, from: string) => ({
    pathname: `/camera/${id}`,
    state: { from },
  }),
  report: (id: number) => `/report/${id}`,
};

export default routes;
