/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";

export const protobufPackage = "phoenix.satellites.v0";

export const ConfidenceLevel = {
  CONFIDENCE_LEVEL_UNKNOWN: 0,
  CONFIDENCE_LEVEL_LOW: 1,
  CONFIDENCE_LEVEL_MEDIUM: 2,
  CONFIDENCE_LEVEL_HIGH: 3,
  UNRECOGNIZED: -1,
} as const;

export type ConfidenceLevel = typeof ConfidenceLevel[keyof typeof ConfidenceLevel];

export namespace ConfidenceLevel {
  export type CONFIDENCE_LEVEL_UNKNOWN = typeof ConfidenceLevel.CONFIDENCE_LEVEL_UNKNOWN;
  export type CONFIDENCE_LEVEL_LOW = typeof ConfidenceLevel.CONFIDENCE_LEVEL_LOW;
  export type CONFIDENCE_LEVEL_MEDIUM = typeof ConfidenceLevel.CONFIDENCE_LEVEL_MEDIUM;
  export type CONFIDENCE_LEVEL_HIGH = typeof ConfidenceLevel.CONFIDENCE_LEVEL_HIGH;
  export type UNRECOGNIZED = typeof ConfidenceLevel.UNRECOGNIZED;
}

export function confidenceLevelFromJSON(object: any): ConfidenceLevel {
  switch (object) {
    case 0:
    case "CONFIDENCE_LEVEL_UNKNOWN":
      return ConfidenceLevel.CONFIDENCE_LEVEL_UNKNOWN;
    case 1:
    case "CONFIDENCE_LEVEL_LOW":
      return ConfidenceLevel.CONFIDENCE_LEVEL_LOW;
    case 2:
    case "CONFIDENCE_LEVEL_MEDIUM":
      return ConfidenceLevel.CONFIDENCE_LEVEL_MEDIUM;
    case 3:
    case "CONFIDENCE_LEVEL_HIGH":
      return ConfidenceLevel.CONFIDENCE_LEVEL_HIGH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfidenceLevel.UNRECOGNIZED;
  }
}

export function confidenceLevelToJSON(object: ConfidenceLevel): string {
  switch (object) {
    case ConfidenceLevel.CONFIDENCE_LEVEL_UNKNOWN:
      return "CONFIDENCE_LEVEL_UNKNOWN";
    case ConfidenceLevel.CONFIDENCE_LEVEL_LOW:
      return "CONFIDENCE_LEVEL_LOW";
    case ConfidenceLevel.CONFIDENCE_LEVEL_MEDIUM:
      return "CONFIDENCE_LEVEL_MEDIUM";
    case ConfidenceLevel.CONFIDENCE_LEVEL_HIGH:
      return "CONFIDENCE_LEVEL_HIGH";
    case ConfidenceLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Satellite {
  id: number;
  name: string;
  displayName: string;
}

export interface ListSatellitesRes {
  satellites: Satellite[];
}

export interface SatelliteDetection {
  id: number;
  confidence: ConfidenceLevel;
  areaGeojson: string;
  /** MW */
  fireRadiativePower: number;
  /** observed brightness temperature at fire wavelength, in Kelvin */
  observedBt4: number;
  /** ambient brightness temperature at fire wavelength, in Kelvin */
  ambientBt4: number;
  /** observed brightness temperature at background wavelength, in Kelvin */
  observedBt11: number;
  /** ambient brightness temperature at background wavelength, in Kelvin */
  ambientBt11: number;
}

function createBaseSatellite(): Satellite {
  return { id: 0, name: "", displayName: "" };
}

export const Satellite = {
  encode(message: Satellite, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.displayName !== "") {
      writer.uint32(26).string(message.displayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Satellite {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSatellite();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Satellite {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
    };
  },

  toJSON(message: Satellite): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Satellite>, I>>(base?: I): Satellite {
    return Satellite.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Satellite>, I>>(object: I): Satellite {
    const message = createBaseSatellite();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    return message;
  },
};

function createBaseListSatellitesRes(): ListSatellitesRes {
  return { satellites: [] };
}

export const ListSatellitesRes = {
  encode(message: ListSatellitesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.satellites) {
      Satellite.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListSatellitesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSatellitesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.satellites.push(Satellite.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSatellitesRes {
    return {
      satellites: globalThis.Array.isArray(object?.satellites)
        ? object.satellites.map((e: any) => Satellite.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListSatellitesRes): unknown {
    const obj: any = {};
    if (message.satellites?.length) {
      obj.satellites = message.satellites.map((e) => Satellite.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListSatellitesRes>, I>>(base?: I): ListSatellitesRes {
    return ListSatellitesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListSatellitesRes>, I>>(object: I): ListSatellitesRes {
    const message = createBaseListSatellitesRes();
    message.satellites = object.satellites?.map((e) => Satellite.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSatelliteDetection(): SatelliteDetection {
  return {
    id: 0,
    confidence: 0,
    areaGeojson: "",
    fireRadiativePower: 0,
    observedBt4: 0,
    ambientBt4: 0,
    observedBt11: 0,
    ambientBt11: 0,
  };
}

export const SatelliteDetection = {
  encode(message: SatelliteDetection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.confidence !== 0) {
      writer.uint32(16).int32(message.confidence);
    }
    if (message.areaGeojson !== "") {
      writer.uint32(26).string(message.areaGeojson);
    }
    if (message.fireRadiativePower !== 0) {
      writer.uint32(33).double(message.fireRadiativePower);
    }
    if (message.observedBt4 !== 0) {
      writer.uint32(41).double(message.observedBt4);
    }
    if (message.ambientBt4 !== 0) {
      writer.uint32(49).double(message.ambientBt4);
    }
    if (message.observedBt11 !== 0) {
      writer.uint32(57).double(message.observedBt11);
    }
    if (message.ambientBt11 !== 0) {
      writer.uint32(65).double(message.ambientBt11);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SatelliteDetection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSatelliteDetection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.confidence = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.areaGeojson = reader.string();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.fireRadiativePower = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.observedBt4 = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.ambientBt4 = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.observedBt11 = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.ambientBt11 = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SatelliteDetection {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      confidence: isSet(object.confidence) ? confidenceLevelFromJSON(object.confidence) : 0,
      areaGeojson: isSet(object.areaGeojson) ? globalThis.String(object.areaGeojson) : "",
      fireRadiativePower: isSet(object.fireRadiativePower) ? globalThis.Number(object.fireRadiativePower) : 0,
      observedBt4: isSet(object.observedBt4) ? globalThis.Number(object.observedBt4) : 0,
      ambientBt4: isSet(object.ambientBt4) ? globalThis.Number(object.ambientBt4) : 0,
      observedBt11: isSet(object.observedBt11) ? globalThis.Number(object.observedBt11) : 0,
      ambientBt11: isSet(object.ambientBt11) ? globalThis.Number(object.ambientBt11) : 0,
    };
  },

  toJSON(message: SatelliteDetection): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.confidence !== 0) {
      obj.confidence = confidenceLevelToJSON(message.confidence);
    }
    if (message.areaGeojson !== "") {
      obj.areaGeojson = message.areaGeojson;
    }
    if (message.fireRadiativePower !== 0) {
      obj.fireRadiativePower = message.fireRadiativePower;
    }
    if (message.observedBt4 !== 0) {
      obj.observedBt4 = message.observedBt4;
    }
    if (message.ambientBt4 !== 0) {
      obj.ambientBt4 = message.ambientBt4;
    }
    if (message.observedBt11 !== 0) {
      obj.observedBt11 = message.observedBt11;
    }
    if (message.ambientBt11 !== 0) {
      obj.ambientBt11 = message.ambientBt11;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SatelliteDetection>, I>>(base?: I): SatelliteDetection {
    return SatelliteDetection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SatelliteDetection>, I>>(object: I): SatelliteDetection {
    const message = createBaseSatelliteDetection();
    message.id = object.id ?? 0;
    message.confidence = object.confidence ?? 0;
    message.areaGeojson = object.areaGeojson ?? "";
    message.fireRadiativePower = object.fireRadiativePower ?? 0;
    message.observedBt4 = object.observedBt4 ?? 0;
    message.ambientBt4 = object.ambientBt4 ?? 0;
    message.observedBt11 = object.observedBt11 ?? 0;
    message.ambientBt11 = object.ambientBt11 ?? 0;
    return message;
  },
};

export interface Satellites {
  /** List satellites */
  listSatellites(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListSatellitesRes>;
}

export class SatellitesClientImpl implements Satellites {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.listSatellites = this.listSatellites.bind(this);
  }

  listSatellites(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<ListSatellitesRes> {
    return this.rpc.unary(SatellitesListSatellitesDesc, Empty.fromPartial(request), metadata);
  }
}

export const SatellitesDesc = { serviceName: "phoenix.satellites.v0.Satellites" };

export const SatellitesListSatellitesDesc: UnaryMethodDefinitionish = {
  methodName: "ListSatellites",
  service: SatellitesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListSatellitesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
