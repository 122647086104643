/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { CameraInfo, ViewInfo } from "./cameras";
import { HttpBody } from "./google/api/httpbody";
import { Empty } from "./google/protobuf/empty";
import { Timestamp } from "./google/protobuf/timestamp";
import { ConfidenceLevel, confidenceLevelFromJSON, confidenceLevelToJSON, SatelliteDetection } from "./satellites";
import {
  ConfirmationState,
  confirmationStateFromJSON,
  confirmationStateToJSON,
  DetectionId,
  Point,
  Polygon,
} from "./types";

export const protobufPackage = "phoenix.reports.v0";

export const ReviewReason = {
  REVIEW_REASON_UNKNOWN: 0,
  REVIEW_REASON_GROUND: 1,
  REVIEW_REASON_DOME: 2,
  REVIEW_REASON_BLUR: 3,
  REVIEW_REASON_GLARE: 4,
  REVIEW_REASON_CLOUD: 5,
  REVIEW_REASON_SMOG: 6,
  REVIEW_REASON_FOG: 7,
  REVIEW_REASON_RAIN: 8,
  REVIEW_REASON_SKY: 9,
  REVIEW_REASON_NIGHT: 10,
  REVIEW_REASON_OBSTRUCTION: 11,
  REVIEW_REASON_SNOW: 12,
  REVIEW_REASON_INDUSTRIAL: 13,
  REVIEW_REASON_OTHER: 14,
  REVIEW_REASON_KNOWN_FIRE: 15,
  UNRECOGNIZED: -1,
} as const;

export type ReviewReason = typeof ReviewReason[keyof typeof ReviewReason];

export namespace ReviewReason {
  export type REVIEW_REASON_UNKNOWN = typeof ReviewReason.REVIEW_REASON_UNKNOWN;
  export type REVIEW_REASON_GROUND = typeof ReviewReason.REVIEW_REASON_GROUND;
  export type REVIEW_REASON_DOME = typeof ReviewReason.REVIEW_REASON_DOME;
  export type REVIEW_REASON_BLUR = typeof ReviewReason.REVIEW_REASON_BLUR;
  export type REVIEW_REASON_GLARE = typeof ReviewReason.REVIEW_REASON_GLARE;
  export type REVIEW_REASON_CLOUD = typeof ReviewReason.REVIEW_REASON_CLOUD;
  export type REVIEW_REASON_SMOG = typeof ReviewReason.REVIEW_REASON_SMOG;
  export type REVIEW_REASON_FOG = typeof ReviewReason.REVIEW_REASON_FOG;
  export type REVIEW_REASON_RAIN = typeof ReviewReason.REVIEW_REASON_RAIN;
  export type REVIEW_REASON_SKY = typeof ReviewReason.REVIEW_REASON_SKY;
  export type REVIEW_REASON_NIGHT = typeof ReviewReason.REVIEW_REASON_NIGHT;
  export type REVIEW_REASON_OBSTRUCTION = typeof ReviewReason.REVIEW_REASON_OBSTRUCTION;
  export type REVIEW_REASON_SNOW = typeof ReviewReason.REVIEW_REASON_SNOW;
  export type REVIEW_REASON_INDUSTRIAL = typeof ReviewReason.REVIEW_REASON_INDUSTRIAL;
  export type REVIEW_REASON_OTHER = typeof ReviewReason.REVIEW_REASON_OTHER;
  export type REVIEW_REASON_KNOWN_FIRE = typeof ReviewReason.REVIEW_REASON_KNOWN_FIRE;
  export type UNRECOGNIZED = typeof ReviewReason.UNRECOGNIZED;
}

export function reviewReasonFromJSON(object: any): ReviewReason {
  switch (object) {
    case 0:
    case "REVIEW_REASON_UNKNOWN":
      return ReviewReason.REVIEW_REASON_UNKNOWN;
    case 1:
    case "REVIEW_REASON_GROUND":
      return ReviewReason.REVIEW_REASON_GROUND;
    case 2:
    case "REVIEW_REASON_DOME":
      return ReviewReason.REVIEW_REASON_DOME;
    case 3:
    case "REVIEW_REASON_BLUR":
      return ReviewReason.REVIEW_REASON_BLUR;
    case 4:
    case "REVIEW_REASON_GLARE":
      return ReviewReason.REVIEW_REASON_GLARE;
    case 5:
    case "REVIEW_REASON_CLOUD":
      return ReviewReason.REVIEW_REASON_CLOUD;
    case 6:
    case "REVIEW_REASON_SMOG":
      return ReviewReason.REVIEW_REASON_SMOG;
    case 7:
    case "REVIEW_REASON_FOG":
      return ReviewReason.REVIEW_REASON_FOG;
    case 8:
    case "REVIEW_REASON_RAIN":
      return ReviewReason.REVIEW_REASON_RAIN;
    case 9:
    case "REVIEW_REASON_SKY":
      return ReviewReason.REVIEW_REASON_SKY;
    case 10:
    case "REVIEW_REASON_NIGHT":
      return ReviewReason.REVIEW_REASON_NIGHT;
    case 11:
    case "REVIEW_REASON_OBSTRUCTION":
      return ReviewReason.REVIEW_REASON_OBSTRUCTION;
    case 12:
    case "REVIEW_REASON_SNOW":
      return ReviewReason.REVIEW_REASON_SNOW;
    case 13:
    case "REVIEW_REASON_INDUSTRIAL":
      return ReviewReason.REVIEW_REASON_INDUSTRIAL;
    case 14:
    case "REVIEW_REASON_OTHER":
      return ReviewReason.REVIEW_REASON_OTHER;
    case 15:
    case "REVIEW_REASON_KNOWN_FIRE":
      return ReviewReason.REVIEW_REASON_KNOWN_FIRE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReviewReason.UNRECOGNIZED;
  }
}

export function reviewReasonToJSON(object: ReviewReason): string {
  switch (object) {
    case ReviewReason.REVIEW_REASON_UNKNOWN:
      return "REVIEW_REASON_UNKNOWN";
    case ReviewReason.REVIEW_REASON_GROUND:
      return "REVIEW_REASON_GROUND";
    case ReviewReason.REVIEW_REASON_DOME:
      return "REVIEW_REASON_DOME";
    case ReviewReason.REVIEW_REASON_BLUR:
      return "REVIEW_REASON_BLUR";
    case ReviewReason.REVIEW_REASON_GLARE:
      return "REVIEW_REASON_GLARE";
    case ReviewReason.REVIEW_REASON_CLOUD:
      return "REVIEW_REASON_CLOUD";
    case ReviewReason.REVIEW_REASON_SMOG:
      return "REVIEW_REASON_SMOG";
    case ReviewReason.REVIEW_REASON_FOG:
      return "REVIEW_REASON_FOG";
    case ReviewReason.REVIEW_REASON_RAIN:
      return "REVIEW_REASON_RAIN";
    case ReviewReason.REVIEW_REASON_SKY:
      return "REVIEW_REASON_SKY";
    case ReviewReason.REVIEW_REASON_NIGHT:
      return "REVIEW_REASON_NIGHT";
    case ReviewReason.REVIEW_REASON_OBSTRUCTION:
      return "REVIEW_REASON_OBSTRUCTION";
    case ReviewReason.REVIEW_REASON_SNOW:
      return "REVIEW_REASON_SNOW";
    case ReviewReason.REVIEW_REASON_INDUSTRIAL:
      return "REVIEW_REASON_INDUSTRIAL";
    case ReviewReason.REVIEW_REASON_OTHER:
      return "REVIEW_REASON_OTHER";
    case ReviewReason.REVIEW_REASON_KNOWN_FIRE:
      return "REVIEW_REASON_KNOWN_FIRE";
    case ReviewReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface CameraView {
  id: number;
  firstDetectionImageId: number;
  lastDetectionImageId: number;
  cameraInfo: CameraInfo | undefined;
  viewInfo: ViewInfo | undefined;
}

export interface SatelliteSource {
  satelliteName: string;
  detectionIds: number[];
  firstDetection: Date | undefined;
  latestDetection: Date | undefined;
  detectionSummary: SatelliteDetection | undefined;
}

export interface ReportSource {
  source?: { $case: "cameraView"; cameraView: CameraView } | {
    $case: "satelliteSource";
    satelliteSource: SatelliteSource;
  } | undefined;
}

export interface ConfidenceEntry {
  time: Date | undefined;
  confidence: number;
}

export interface Report {
  id: number;
  state: ConfirmationState;
  reason: string;
  /** User-facing description of location, eg. Kern County, California, USA */
  location: string;
  latitude: number;
  longitude: number;
  createdTime: Date | undefined;
  firstDetectionTime: Date | undefined;
  latestDetectionTime: Date | undefined;
  areaGeojson: string;
  sources: ReportSource[];
  confidenceHistory: ConfidenceEntry[];
}

export interface GetReportReq {
  id: number;
}

/**
 * Filters for returning a report list
 * Each filter is joined with AND (not OR)
 */
export interface ListReportFilters {
  /** empty list treated as all states */
  states: ConfirmationState[];
  /** defaults to 8 hours ago */
  after: Date | undefined;
  before:
    | Date
    | undefined;
  /** bounding box in lon/lat (x/y), both must be set or neither */
  topleft: Point | undefined;
  bottomright:
    | Point
    | undefined;
  /** only show reports with detections from this camera/satellite */
  cameraId: number;
  satelliteId: number;
  /** omit reports containing only camera detections */
  omitCameraOnly: boolean;
  /** omit reports containing only satellite detections */
  omitSatelliteOnly: boolean;
}

export interface CameraViewSummary {
  viewId: number;
  cameraId: number;
  cameraDisplayName: string;
}

export interface SatelliteSourceSummary {
  satelliteName: string;
}

export interface ReportSourceSummary {
  source?: { $case: "cameraViewSummary"; cameraViewSummary: CameraViewSummary } | {
    $case: "satelliteSourceSummary";
    satelliteSourceSummary: SatelliteSourceSummary;
  } | undefined;
}

/** Summary used in endpoints for listing many reports */
export interface ReportSummary {
  id: number;
  state: ConfirmationState;
  latitude: number;
  longitude: number;
  /** User-facing description of location, eg. Kern County, California, USA */
  location: string;
  createdTime: Date | undefined;
  firstDetectionTime: Date | undefined;
  latestDetectionTime: Date | undefined;
  confidence: number;
  sourceSummaries: ReportSourceSummary[];
  preloadImageUrls: string[];
}

export interface ListReportsRes {
  /** ordered by latest_detection_time descending */
  reports: ReportSummary[];
  /**
   * This endpoint is not intended to be paginated, however the number of reports
   * returned is set at a high limit (eg. 200)
   * If more are required, the appropriate filters should be set
   */
  hasMore: boolean;
}

export interface ImageMergeCandidateDetection {
  /** detection polygon with normalized points (0,1) */
  polygon: Polygon | undefined;
  isThisReport: boolean;
}

export interface ImageMergeCandidate {
  cameraDisplayName: string;
  imageUrl: string;
  detections: ImageMergeCandidateDetection[];
}

export interface SatelliteMergeCandidate {
  satelliteDisplayName: string;
  confidence: ConfidenceLevel;
  /** MW */
  fireRadiativePower: number;
}

export interface MergeCandidate {
  id: number;
  state: ConfirmationState;
  latitude: number;
  longitude: number;
  firstDetectionTime: Date | undefined;
  latestDetectionTime: Date | undefined;
  areaGeojson: string;
  data?:
    | { $case: "image"; image: ImageMergeCandidate }
    | { $case: "satellite"; satellite: SatelliteMergeCandidate }
    | undefined;
}

export interface ListMergeCandidatesRes {
  source: MergeCandidate | undefined;
  candidates: MergeCandidate[];
}

export interface MergeTimelineItem {
  time: Date | undefined;
  previousReportId: number;
  detectionIds: DetectionId[];
  reason: MergeTimelineItem_MergeReason;
  sourceDisplayNames: string[];
}

export const MergeTimelineItem_MergeReason = {
  MERGE_REASON_UNKNOWN: 0,
  MERGE_REASON_NO_MATCHING_REPORT: 1,
  MERGE_REASON_SAME_SOURCE: 2,
  MERGE_REASON_SAME_LOCATION: 3,
  MERGE_REASON_MANUAL: 4,
  UNRECOGNIZED: -1,
} as const;

export type MergeTimelineItem_MergeReason =
  typeof MergeTimelineItem_MergeReason[keyof typeof MergeTimelineItem_MergeReason];

export namespace MergeTimelineItem_MergeReason {
  export type MERGE_REASON_UNKNOWN = typeof MergeTimelineItem_MergeReason.MERGE_REASON_UNKNOWN;
  export type MERGE_REASON_NO_MATCHING_REPORT = typeof MergeTimelineItem_MergeReason.MERGE_REASON_NO_MATCHING_REPORT;
  export type MERGE_REASON_SAME_SOURCE = typeof MergeTimelineItem_MergeReason.MERGE_REASON_SAME_SOURCE;
  export type MERGE_REASON_SAME_LOCATION = typeof MergeTimelineItem_MergeReason.MERGE_REASON_SAME_LOCATION;
  export type MERGE_REASON_MANUAL = typeof MergeTimelineItem_MergeReason.MERGE_REASON_MANUAL;
  export type UNRECOGNIZED = typeof MergeTimelineItem_MergeReason.UNRECOGNIZED;
}

export function mergeTimelineItem_MergeReasonFromJSON(object: any): MergeTimelineItem_MergeReason {
  switch (object) {
    case 0:
    case "MERGE_REASON_UNKNOWN":
      return MergeTimelineItem_MergeReason.MERGE_REASON_UNKNOWN;
    case 1:
    case "MERGE_REASON_NO_MATCHING_REPORT":
      return MergeTimelineItem_MergeReason.MERGE_REASON_NO_MATCHING_REPORT;
    case 2:
    case "MERGE_REASON_SAME_SOURCE":
      return MergeTimelineItem_MergeReason.MERGE_REASON_SAME_SOURCE;
    case 3:
    case "MERGE_REASON_SAME_LOCATION":
      return MergeTimelineItem_MergeReason.MERGE_REASON_SAME_LOCATION;
    case 4:
    case "MERGE_REASON_MANUAL":
      return MergeTimelineItem_MergeReason.MERGE_REASON_MANUAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MergeTimelineItem_MergeReason.UNRECOGNIZED;
  }
}

export function mergeTimelineItem_MergeReasonToJSON(object: MergeTimelineItem_MergeReason): string {
  switch (object) {
    case MergeTimelineItem_MergeReason.MERGE_REASON_UNKNOWN:
      return "MERGE_REASON_UNKNOWN";
    case MergeTimelineItem_MergeReason.MERGE_REASON_NO_MATCHING_REPORT:
      return "MERGE_REASON_NO_MATCHING_REPORT";
    case MergeTimelineItem_MergeReason.MERGE_REASON_SAME_SOURCE:
      return "MERGE_REASON_SAME_SOURCE";
    case MergeTimelineItem_MergeReason.MERGE_REASON_SAME_LOCATION:
      return "MERGE_REASON_SAME_LOCATION";
    case MergeTimelineItem_MergeReason.MERGE_REASON_MANUAL:
      return "MERGE_REASON_MANUAL";
    case MergeTimelineItem_MergeReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface GetReportMergeTimelineRes {
  items: MergeTimelineItem[];
}

export interface UndoMergeReq {
  reportId: number;
  detectionIds: DetectionId[];
  /**
   * if true, all non-manually merged detections after the earliest provided id
   * will be invalidated and re-sent to the synthesizer
   */
  resynthesizeFollowing: boolean;
}

export interface MoveToReportReq {
  origin?: { $case: "reportId"; reportId: number } | { $case: "detectionId"; detectionId: number } | {
    $case: "satelliteDetectionId";
    satelliteDetectionId: number;
  } | undefined;
  targetReportId: number;
}

export interface SplitReportReq {
  detectionIds: number[];
  satelliteDetectionIds: number[];
}

export interface SplitReportRes {
  newReportId: number;
}

export interface ReviewReportReq {
  reportId: number;
  newState: ConfirmationState;
  /** reason for rejected reports only */
  reason: ReviewReason;
}

function createBaseCameraView(): CameraView {
  return { id: 0, firstDetectionImageId: 0, lastDetectionImageId: 0, cameraInfo: undefined, viewInfo: undefined };
}

export const CameraView = {
  encode(message: CameraView, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.firstDetectionImageId !== 0) {
      writer.uint32(16).int64(message.firstDetectionImageId);
    }
    if (message.lastDetectionImageId !== 0) {
      writer.uint32(24).int64(message.lastDetectionImageId);
    }
    if (message.cameraInfo !== undefined) {
      CameraInfo.encode(message.cameraInfo, writer.uint32(34).fork()).ldelim();
    }
    if (message.viewInfo !== undefined) {
      ViewInfo.encode(message.viewInfo, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraView {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraView();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.firstDetectionImageId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.lastDetectionImageId = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cameraInfo = CameraInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.viewInfo = ViewInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraView {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      firstDetectionImageId: isSet(object.firstDetectionImageId) ? globalThis.Number(object.firstDetectionImageId) : 0,
      lastDetectionImageId: isSet(object.lastDetectionImageId) ? globalThis.Number(object.lastDetectionImageId) : 0,
      cameraInfo: isSet(object.cameraInfo) ? CameraInfo.fromJSON(object.cameraInfo) : undefined,
      viewInfo: isSet(object.viewInfo) ? ViewInfo.fromJSON(object.viewInfo) : undefined,
    };
  },

  toJSON(message: CameraView): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.firstDetectionImageId !== 0) {
      obj.firstDetectionImageId = Math.round(message.firstDetectionImageId);
    }
    if (message.lastDetectionImageId !== 0) {
      obj.lastDetectionImageId = Math.round(message.lastDetectionImageId);
    }
    if (message.cameraInfo !== undefined) {
      obj.cameraInfo = CameraInfo.toJSON(message.cameraInfo);
    }
    if (message.viewInfo !== undefined) {
      obj.viewInfo = ViewInfo.toJSON(message.viewInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraView>, I>>(base?: I): CameraView {
    return CameraView.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraView>, I>>(object: I): CameraView {
    const message = createBaseCameraView();
    message.id = object.id ?? 0;
    message.firstDetectionImageId = object.firstDetectionImageId ?? 0;
    message.lastDetectionImageId = object.lastDetectionImageId ?? 0;
    message.cameraInfo = (object.cameraInfo !== undefined && object.cameraInfo !== null)
      ? CameraInfo.fromPartial(object.cameraInfo)
      : undefined;
    message.viewInfo = (object.viewInfo !== undefined && object.viewInfo !== null)
      ? ViewInfo.fromPartial(object.viewInfo)
      : undefined;
    return message;
  },
};

function createBaseSatelliteSource(): SatelliteSource {
  return {
    satelliteName: "",
    detectionIds: [],
    firstDetection: undefined,
    latestDetection: undefined,
    detectionSummary: undefined,
  };
}

export const SatelliteSource = {
  encode(message: SatelliteSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.satelliteName !== "") {
      writer.uint32(10).string(message.satelliteName);
    }
    writer.uint32(18).fork();
    for (const v of message.detectionIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.firstDetection !== undefined) {
      Timestamp.encode(toTimestamp(message.firstDetection), writer.uint32(26).fork()).ldelim();
    }
    if (message.latestDetection !== undefined) {
      Timestamp.encode(toTimestamp(message.latestDetection), writer.uint32(34).fork()).ldelim();
    }
    if (message.detectionSummary !== undefined) {
      SatelliteDetection.encode(message.detectionSummary, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SatelliteSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSatelliteSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.satelliteName = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.detectionIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.detectionIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstDetection = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.latestDetection = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.detectionSummary = SatelliteDetection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SatelliteSource {
    return {
      satelliteName: isSet(object.satelliteName) ? globalThis.String(object.satelliteName) : "",
      detectionIds: globalThis.Array.isArray(object?.detectionIds)
        ? object.detectionIds.map((e: any) => globalThis.Number(e))
        : [],
      firstDetection: isSet(object.firstDetection) ? fromJsonTimestamp(object.firstDetection) : undefined,
      latestDetection: isSet(object.latestDetection) ? fromJsonTimestamp(object.latestDetection) : undefined,
      detectionSummary: isSet(object.detectionSummary)
        ? SatelliteDetection.fromJSON(object.detectionSummary)
        : undefined,
    };
  },

  toJSON(message: SatelliteSource): unknown {
    const obj: any = {};
    if (message.satelliteName !== "") {
      obj.satelliteName = message.satelliteName;
    }
    if (message.detectionIds?.length) {
      obj.detectionIds = message.detectionIds.map((e) => Math.round(e));
    }
    if (message.firstDetection !== undefined) {
      obj.firstDetection = message.firstDetection.toISOString();
    }
    if (message.latestDetection !== undefined) {
      obj.latestDetection = message.latestDetection.toISOString();
    }
    if (message.detectionSummary !== undefined) {
      obj.detectionSummary = SatelliteDetection.toJSON(message.detectionSummary);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SatelliteSource>, I>>(base?: I): SatelliteSource {
    return SatelliteSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SatelliteSource>, I>>(object: I): SatelliteSource {
    const message = createBaseSatelliteSource();
    message.satelliteName = object.satelliteName ?? "";
    message.detectionIds = object.detectionIds?.map((e) => e) || [];
    message.firstDetection = object.firstDetection ?? undefined;
    message.latestDetection = object.latestDetection ?? undefined;
    message.detectionSummary = (object.detectionSummary !== undefined && object.detectionSummary !== null)
      ? SatelliteDetection.fromPartial(object.detectionSummary)
      : undefined;
    return message;
  },
};

function createBaseReportSource(): ReportSource {
  return { source: undefined };
}

export const ReportSource = {
  encode(message: ReportSource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.source?.$case) {
      case "cameraView":
        CameraView.encode(message.source.cameraView, writer.uint32(10).fork()).ldelim();
        break;
      case "satelliteSource":
        SatelliteSource.encode(message.source.satelliteSource, writer.uint32(18).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportSource {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.source = { $case: "cameraView", cameraView: CameraView.decode(reader, reader.uint32()) };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = {
            $case: "satelliteSource",
            satelliteSource: SatelliteSource.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportSource {
    return {
      source: isSet(object.cameraView)
        ? { $case: "cameraView", cameraView: CameraView.fromJSON(object.cameraView) }
        : isSet(object.satelliteSource)
        ? { $case: "satelliteSource", satelliteSource: SatelliteSource.fromJSON(object.satelliteSource) }
        : undefined,
    };
  },

  toJSON(message: ReportSource): unknown {
    const obj: any = {};
    if (message.source?.$case === "cameraView") {
      obj.cameraView = CameraView.toJSON(message.source.cameraView);
    }
    if (message.source?.$case === "satelliteSource") {
      obj.satelliteSource = SatelliteSource.toJSON(message.source.satelliteSource);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportSource>, I>>(base?: I): ReportSource {
    return ReportSource.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportSource>, I>>(object: I): ReportSource {
    const message = createBaseReportSource();
    if (
      object.source?.$case === "cameraView" &&
      object.source?.cameraView !== undefined &&
      object.source?.cameraView !== null
    ) {
      message.source = { $case: "cameraView", cameraView: CameraView.fromPartial(object.source.cameraView) };
    }
    if (
      object.source?.$case === "satelliteSource" &&
      object.source?.satelliteSource !== undefined &&
      object.source?.satelliteSource !== null
    ) {
      message.source = {
        $case: "satelliteSource",
        satelliteSource: SatelliteSource.fromPartial(object.source.satelliteSource),
      };
    }
    return message;
  },
};

function createBaseConfidenceEntry(): ConfidenceEntry {
  return { time: undefined, confidence: 0 };
}

export const ConfidenceEntry = {
  encode(message: ConfidenceEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(toTimestamp(message.time), writer.uint32(10).fork()).ldelim();
    }
    if (message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfidenceEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfidenceEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfidenceEntry {
    return {
      time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
    };
  },

  toJSON(message: ConfidenceEntry): unknown {
    const obj: any = {};
    if (message.time !== undefined) {
      obj.time = message.time.toISOString();
    }
    if (message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfidenceEntry>, I>>(base?: I): ConfidenceEntry {
    return ConfidenceEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfidenceEntry>, I>>(object: I): ConfidenceEntry {
    const message = createBaseConfidenceEntry();
    message.time = object.time ?? undefined;
    message.confidence = object.confidence ?? 0;
    return message;
  },
};

function createBaseReport(): Report {
  return {
    id: 0,
    state: 0,
    reason: "",
    location: "",
    latitude: 0,
    longitude: 0,
    createdTime: undefined,
    firstDetectionTime: undefined,
    latestDetectionTime: undefined,
    areaGeojson: "",
    sources: [],
    confidenceHistory: [],
  };
}

export const Report = {
  encode(message: Report, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.reason !== "") {
      writer.uint32(26).string(message.reason);
    }
    if (message.location !== "") {
      writer.uint32(34).string(message.location);
    }
    if (message.latitude !== 0) {
      writer.uint32(41).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(49).double(message.longitude);
    }
    if (message.createdTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.firstDetectionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.firstDetectionTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.latestDetectionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.latestDetectionTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.areaGeojson !== "") {
      writer.uint32(82).string(message.areaGeojson);
    }
    for (const v of message.sources) {
      ReportSource.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.confidenceHistory) {
      ConfidenceEntry.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Report {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reason = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.location = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.firstDetectionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.latestDetectionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.areaGeojson = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sources.push(ReportSource.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.confidenceHistory.push(ConfidenceEntry.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Report {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      state: isSet(object.state) ? confirmationStateFromJSON(object.state) : 0,
      reason: isSet(object.reason) ? globalThis.String(object.reason) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      createdTime: isSet(object.createdTime) ? fromJsonTimestamp(object.createdTime) : undefined,
      firstDetectionTime: isSet(object.firstDetectionTime) ? fromJsonTimestamp(object.firstDetectionTime) : undefined,
      latestDetectionTime: isSet(object.latestDetectionTime)
        ? fromJsonTimestamp(object.latestDetectionTime)
        : undefined,
      areaGeojson: isSet(object.areaGeojson) ? globalThis.String(object.areaGeojson) : "",
      sources: globalThis.Array.isArray(object?.sources)
        ? object.sources.map((e: any) => ReportSource.fromJSON(e))
        : [],
      confidenceHistory: globalThis.Array.isArray(object?.confidenceHistory)
        ? object.confidenceHistory.map((e: any) => ConfidenceEntry.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Report): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.state !== 0) {
      obj.state = confirmationStateToJSON(message.state);
    }
    if (message.reason !== "") {
      obj.reason = message.reason;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.createdTime !== undefined) {
      obj.createdTime = message.createdTime.toISOString();
    }
    if (message.firstDetectionTime !== undefined) {
      obj.firstDetectionTime = message.firstDetectionTime.toISOString();
    }
    if (message.latestDetectionTime !== undefined) {
      obj.latestDetectionTime = message.latestDetectionTime.toISOString();
    }
    if (message.areaGeojson !== "") {
      obj.areaGeojson = message.areaGeojson;
    }
    if (message.sources?.length) {
      obj.sources = message.sources.map((e) => ReportSource.toJSON(e));
    }
    if (message.confidenceHistory?.length) {
      obj.confidenceHistory = message.confidenceHistory.map((e) => ConfidenceEntry.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Report>, I>>(base?: I): Report {
    return Report.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Report>, I>>(object: I): Report {
    const message = createBaseReport();
    message.id = object.id ?? 0;
    message.state = object.state ?? 0;
    message.reason = object.reason ?? "";
    message.location = object.location ?? "";
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.createdTime = object.createdTime ?? undefined;
    message.firstDetectionTime = object.firstDetectionTime ?? undefined;
    message.latestDetectionTime = object.latestDetectionTime ?? undefined;
    message.areaGeojson = object.areaGeojson ?? "";
    message.sources = object.sources?.map((e) => ReportSource.fromPartial(e)) || [];
    message.confidenceHistory = object.confidenceHistory?.map((e) => ConfidenceEntry.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetReportReq(): GetReportReq {
  return { id: 0 };
}

export const GetReportReq = {
  encode(message: GetReportReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReportReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReportReq {
    return { id: isSet(object.id) ? globalThis.Number(object.id) : 0 };
  },

  toJSON(message: GetReportReq): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReportReq>, I>>(base?: I): GetReportReq {
    return GetReportReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReportReq>, I>>(object: I): GetReportReq {
    const message = createBaseGetReportReq();
    message.id = object.id ?? 0;
    return message;
  },
};

function createBaseListReportFilters(): ListReportFilters {
  return {
    states: [],
    after: undefined,
    before: undefined,
    topleft: undefined,
    bottomright: undefined,
    cameraId: 0,
    satelliteId: 0,
    omitCameraOnly: false,
    omitSatelliteOnly: false,
  };
}

export const ListReportFilters = {
  encode(message: ListReportFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.states) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.after !== undefined) {
      Timestamp.encode(toTimestamp(message.after), writer.uint32(18).fork()).ldelim();
    }
    if (message.before !== undefined) {
      Timestamp.encode(toTimestamp(message.before), writer.uint32(26).fork()).ldelim();
    }
    if (message.topleft !== undefined) {
      Point.encode(message.topleft, writer.uint32(34).fork()).ldelim();
    }
    if (message.bottomright !== undefined) {
      Point.encode(message.bottomright, writer.uint32(42).fork()).ldelim();
    }
    if (message.cameraId !== 0) {
      writer.uint32(48).int32(message.cameraId);
    }
    if (message.satelliteId !== 0) {
      writer.uint32(56).int32(message.satelliteId);
    }
    if (message.omitCameraOnly === true) {
      writer.uint32(64).bool(message.omitCameraOnly);
    }
    if (message.omitSatelliteOnly === true) {
      writer.uint32(72).bool(message.omitSatelliteOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListReportFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListReportFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.states.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.states.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.after = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.before = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.topleft = Point.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.bottomright = Point.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.cameraId = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.satelliteId = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.omitCameraOnly = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.omitSatelliteOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListReportFilters {
    return {
      states: globalThis.Array.isArray(object?.states)
        ? object.states.map((e: any) => confirmationStateFromJSON(e))
        : [],
      after: isSet(object.after) ? fromJsonTimestamp(object.after) : undefined,
      before: isSet(object.before) ? fromJsonTimestamp(object.before) : undefined,
      topleft: isSet(object.topleft) ? Point.fromJSON(object.topleft) : undefined,
      bottomright: isSet(object.bottomright) ? Point.fromJSON(object.bottomright) : undefined,
      cameraId: isSet(object.cameraId) ? globalThis.Number(object.cameraId) : 0,
      satelliteId: isSet(object.satelliteId) ? globalThis.Number(object.satelliteId) : 0,
      omitCameraOnly: isSet(object.omitCameraOnly) ? globalThis.Boolean(object.omitCameraOnly) : false,
      omitSatelliteOnly: isSet(object.omitSatelliteOnly) ? globalThis.Boolean(object.omitSatelliteOnly) : false,
    };
  },

  toJSON(message: ListReportFilters): unknown {
    const obj: any = {};
    if (message.states?.length) {
      obj.states = message.states.map((e) => confirmationStateToJSON(e));
    }
    if (message.after !== undefined) {
      obj.after = message.after.toISOString();
    }
    if (message.before !== undefined) {
      obj.before = message.before.toISOString();
    }
    if (message.topleft !== undefined) {
      obj.topleft = Point.toJSON(message.topleft);
    }
    if (message.bottomright !== undefined) {
      obj.bottomright = Point.toJSON(message.bottomright);
    }
    if (message.cameraId !== 0) {
      obj.cameraId = Math.round(message.cameraId);
    }
    if (message.satelliteId !== 0) {
      obj.satelliteId = Math.round(message.satelliteId);
    }
    if (message.omitCameraOnly === true) {
      obj.omitCameraOnly = message.omitCameraOnly;
    }
    if (message.omitSatelliteOnly === true) {
      obj.omitSatelliteOnly = message.omitSatelliteOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListReportFilters>, I>>(base?: I): ListReportFilters {
    return ListReportFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListReportFilters>, I>>(object: I): ListReportFilters {
    const message = createBaseListReportFilters();
    message.states = object.states?.map((e) => e) || [];
    message.after = object.after ?? undefined;
    message.before = object.before ?? undefined;
    message.topleft = (object.topleft !== undefined && object.topleft !== null)
      ? Point.fromPartial(object.topleft)
      : undefined;
    message.bottomright = (object.bottomright !== undefined && object.bottomright !== null)
      ? Point.fromPartial(object.bottomright)
      : undefined;
    message.cameraId = object.cameraId ?? 0;
    message.satelliteId = object.satelliteId ?? 0;
    message.omitCameraOnly = object.omitCameraOnly ?? false;
    message.omitSatelliteOnly = object.omitSatelliteOnly ?? false;
    return message;
  },
};

function createBaseCameraViewSummary(): CameraViewSummary {
  return { viewId: 0, cameraId: 0, cameraDisplayName: "" };
}

export const CameraViewSummary = {
  encode(message: CameraViewSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.viewId !== 0) {
      writer.uint32(8).int64(message.viewId);
    }
    if (message.cameraId !== 0) {
      writer.uint32(16).int64(message.cameraId);
    }
    if (message.cameraDisplayName !== "") {
      writer.uint32(26).string(message.cameraDisplayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CameraViewSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCameraViewSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.viewId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.cameraId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cameraDisplayName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CameraViewSummary {
    return {
      viewId: isSet(object.viewId) ? globalThis.Number(object.viewId) : 0,
      cameraId: isSet(object.cameraId) ? globalThis.Number(object.cameraId) : 0,
      cameraDisplayName: isSet(object.cameraDisplayName) ? globalThis.String(object.cameraDisplayName) : "",
    };
  },

  toJSON(message: CameraViewSummary): unknown {
    const obj: any = {};
    if (message.viewId !== 0) {
      obj.viewId = Math.round(message.viewId);
    }
    if (message.cameraId !== 0) {
      obj.cameraId = Math.round(message.cameraId);
    }
    if (message.cameraDisplayName !== "") {
      obj.cameraDisplayName = message.cameraDisplayName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CameraViewSummary>, I>>(base?: I): CameraViewSummary {
    return CameraViewSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CameraViewSummary>, I>>(object: I): CameraViewSummary {
    const message = createBaseCameraViewSummary();
    message.viewId = object.viewId ?? 0;
    message.cameraId = object.cameraId ?? 0;
    message.cameraDisplayName = object.cameraDisplayName ?? "";
    return message;
  },
};

function createBaseSatelliteSourceSummary(): SatelliteSourceSummary {
  return { satelliteName: "" };
}

export const SatelliteSourceSummary = {
  encode(message: SatelliteSourceSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.satelliteName !== "") {
      writer.uint32(10).string(message.satelliteName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SatelliteSourceSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSatelliteSourceSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.satelliteName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SatelliteSourceSummary {
    return { satelliteName: isSet(object.satelliteName) ? globalThis.String(object.satelliteName) : "" };
  },

  toJSON(message: SatelliteSourceSummary): unknown {
    const obj: any = {};
    if (message.satelliteName !== "") {
      obj.satelliteName = message.satelliteName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SatelliteSourceSummary>, I>>(base?: I): SatelliteSourceSummary {
    return SatelliteSourceSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SatelliteSourceSummary>, I>>(object: I): SatelliteSourceSummary {
    const message = createBaseSatelliteSourceSummary();
    message.satelliteName = object.satelliteName ?? "";
    return message;
  },
};

function createBaseReportSourceSummary(): ReportSourceSummary {
  return { source: undefined };
}

export const ReportSourceSummary = {
  encode(message: ReportSourceSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.source?.$case) {
      case "cameraViewSummary":
        CameraViewSummary.encode(message.source.cameraViewSummary, writer.uint32(10).fork()).ldelim();
        break;
      case "satelliteSourceSummary":
        SatelliteSourceSummary.encode(message.source.satelliteSourceSummary, writer.uint32(18).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportSourceSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportSourceSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.source = {
            $case: "cameraViewSummary",
            cameraViewSummary: CameraViewSummary.decode(reader, reader.uint32()),
          };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = {
            $case: "satelliteSourceSummary",
            satelliteSourceSummary: SatelliteSourceSummary.decode(reader, reader.uint32()),
          };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportSourceSummary {
    return {
      source: isSet(object.cameraViewSummary)
        ? { $case: "cameraViewSummary", cameraViewSummary: CameraViewSummary.fromJSON(object.cameraViewSummary) }
        : isSet(object.satelliteSourceSummary)
        ? {
          $case: "satelliteSourceSummary",
          satelliteSourceSummary: SatelliteSourceSummary.fromJSON(object.satelliteSourceSummary),
        }
        : undefined,
    };
  },

  toJSON(message: ReportSourceSummary): unknown {
    const obj: any = {};
    if (message.source?.$case === "cameraViewSummary") {
      obj.cameraViewSummary = CameraViewSummary.toJSON(message.source.cameraViewSummary);
    }
    if (message.source?.$case === "satelliteSourceSummary") {
      obj.satelliteSourceSummary = SatelliteSourceSummary.toJSON(message.source.satelliteSourceSummary);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportSourceSummary>, I>>(base?: I): ReportSourceSummary {
    return ReportSourceSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportSourceSummary>, I>>(object: I): ReportSourceSummary {
    const message = createBaseReportSourceSummary();
    if (
      object.source?.$case === "cameraViewSummary" &&
      object.source?.cameraViewSummary !== undefined &&
      object.source?.cameraViewSummary !== null
    ) {
      message.source = {
        $case: "cameraViewSummary",
        cameraViewSummary: CameraViewSummary.fromPartial(object.source.cameraViewSummary),
      };
    }
    if (
      object.source?.$case === "satelliteSourceSummary" &&
      object.source?.satelliteSourceSummary !== undefined &&
      object.source?.satelliteSourceSummary !== null
    ) {
      message.source = {
        $case: "satelliteSourceSummary",
        satelliteSourceSummary: SatelliteSourceSummary.fromPartial(object.source.satelliteSourceSummary),
      };
    }
    return message;
  },
};

function createBaseReportSummary(): ReportSummary {
  return {
    id: 0,
    state: 0,
    latitude: 0,
    longitude: 0,
    location: "",
    createdTime: undefined,
    firstDetectionTime: undefined,
    latestDetectionTime: undefined,
    confidence: 0,
    sourceSummaries: [],
    preloadImageUrls: [],
  };
}

export const ReportSummary = {
  encode(message: ReportSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.latitude !== 0) {
      writer.uint32(25).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(33).double(message.longitude);
    }
    if (message.location !== "") {
      writer.uint32(42).string(message.location);
    }
    if (message.createdTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createdTime), writer.uint32(50).fork()).ldelim();
    }
    if (message.firstDetectionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.firstDetectionTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.latestDetectionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.latestDetectionTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.confidence !== 0) {
      writer.uint32(73).double(message.confidence);
    }
    for (const v of message.sourceSummaries) {
      ReportSourceSummary.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.preloadImageUrls) {
      writer.uint32(90).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.location = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.firstDetectionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.latestDetectionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.confidence = reader.double();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.sourceSummaries.push(ReportSourceSummary.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.preloadImageUrls.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportSummary {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      state: isSet(object.state) ? confirmationStateFromJSON(object.state) : 0,
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      createdTime: isSet(object.createdTime) ? fromJsonTimestamp(object.createdTime) : undefined,
      firstDetectionTime: isSet(object.firstDetectionTime) ? fromJsonTimestamp(object.firstDetectionTime) : undefined,
      latestDetectionTime: isSet(object.latestDetectionTime)
        ? fromJsonTimestamp(object.latestDetectionTime)
        : undefined,
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      sourceSummaries: globalThis.Array.isArray(object?.sourceSummaries)
        ? object.sourceSummaries.map((e: any) => ReportSourceSummary.fromJSON(e))
        : [],
      preloadImageUrls: globalThis.Array.isArray(object?.preloadImageUrls)
        ? object.preloadImageUrls.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ReportSummary): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.state !== 0) {
      obj.state = confirmationStateToJSON(message.state);
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.createdTime !== undefined) {
      obj.createdTime = message.createdTime.toISOString();
    }
    if (message.firstDetectionTime !== undefined) {
      obj.firstDetectionTime = message.firstDetectionTime.toISOString();
    }
    if (message.latestDetectionTime !== undefined) {
      obj.latestDetectionTime = message.latestDetectionTime.toISOString();
    }
    if (message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.sourceSummaries?.length) {
      obj.sourceSummaries = message.sourceSummaries.map((e) => ReportSourceSummary.toJSON(e));
    }
    if (message.preloadImageUrls?.length) {
      obj.preloadImageUrls = message.preloadImageUrls;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportSummary>, I>>(base?: I): ReportSummary {
    return ReportSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportSummary>, I>>(object: I): ReportSummary {
    const message = createBaseReportSummary();
    message.id = object.id ?? 0;
    message.state = object.state ?? 0;
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.location = object.location ?? "";
    message.createdTime = object.createdTime ?? undefined;
    message.firstDetectionTime = object.firstDetectionTime ?? undefined;
    message.latestDetectionTime = object.latestDetectionTime ?? undefined;
    message.confidence = object.confidence ?? 0;
    message.sourceSummaries = object.sourceSummaries?.map((e) => ReportSourceSummary.fromPartial(e)) || [];
    message.preloadImageUrls = object.preloadImageUrls?.map((e) => e) || [];
    return message;
  },
};

function createBaseListReportsRes(): ListReportsRes {
  return { reports: [], hasMore: false };
}

export const ListReportsRes = {
  encode(message: ListReportsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.reports) {
      ReportSummary.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasMore === true) {
      writer.uint32(16).bool(message.hasMore);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListReportsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListReportsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reports.push(ReportSummary.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasMore = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListReportsRes {
    return {
      reports: globalThis.Array.isArray(object?.reports)
        ? object.reports.map((e: any) => ReportSummary.fromJSON(e))
        : [],
      hasMore: isSet(object.hasMore) ? globalThis.Boolean(object.hasMore) : false,
    };
  },

  toJSON(message: ListReportsRes): unknown {
    const obj: any = {};
    if (message.reports?.length) {
      obj.reports = message.reports.map((e) => ReportSummary.toJSON(e));
    }
    if (message.hasMore === true) {
      obj.hasMore = message.hasMore;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListReportsRes>, I>>(base?: I): ListReportsRes {
    return ListReportsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListReportsRes>, I>>(object: I): ListReportsRes {
    const message = createBaseListReportsRes();
    message.reports = object.reports?.map((e) => ReportSummary.fromPartial(e)) || [];
    message.hasMore = object.hasMore ?? false;
    return message;
  },
};

function createBaseImageMergeCandidateDetection(): ImageMergeCandidateDetection {
  return { polygon: undefined, isThisReport: false };
}

export const ImageMergeCandidateDetection = {
  encode(message: ImageMergeCandidateDetection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.polygon !== undefined) {
      Polygon.encode(message.polygon, writer.uint32(10).fork()).ldelim();
    }
    if (message.isThisReport === true) {
      writer.uint32(16).bool(message.isThisReport);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageMergeCandidateDetection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageMergeCandidateDetection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.polygon = Polygon.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isThisReport = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageMergeCandidateDetection {
    return {
      polygon: isSet(object.polygon) ? Polygon.fromJSON(object.polygon) : undefined,
      isThisReport: isSet(object.isThisReport) ? globalThis.Boolean(object.isThisReport) : false,
    };
  },

  toJSON(message: ImageMergeCandidateDetection): unknown {
    const obj: any = {};
    if (message.polygon !== undefined) {
      obj.polygon = Polygon.toJSON(message.polygon);
    }
    if (message.isThisReport === true) {
      obj.isThisReport = message.isThisReport;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageMergeCandidateDetection>, I>>(base?: I): ImageMergeCandidateDetection {
    return ImageMergeCandidateDetection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageMergeCandidateDetection>, I>>(object: I): ImageMergeCandidateDetection {
    const message = createBaseImageMergeCandidateDetection();
    message.polygon = (object.polygon !== undefined && object.polygon !== null)
      ? Polygon.fromPartial(object.polygon)
      : undefined;
    message.isThisReport = object.isThisReport ?? false;
    return message;
  },
};

function createBaseImageMergeCandidate(): ImageMergeCandidate {
  return { cameraDisplayName: "", imageUrl: "", detections: [] };
}

export const ImageMergeCandidate = {
  encode(message: ImageMergeCandidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cameraDisplayName !== "") {
      writer.uint32(10).string(message.cameraDisplayName);
    }
    if (message.imageUrl !== "") {
      writer.uint32(18).string(message.imageUrl);
    }
    for (const v of message.detections) {
      ImageMergeCandidateDetection.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImageMergeCandidate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImageMergeCandidate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cameraDisplayName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.imageUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.detections.push(ImageMergeCandidateDetection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImageMergeCandidate {
    return {
      cameraDisplayName: isSet(object.cameraDisplayName) ? globalThis.String(object.cameraDisplayName) : "",
      imageUrl: isSet(object.imageUrl) ? globalThis.String(object.imageUrl) : "",
      detections: globalThis.Array.isArray(object?.detections)
        ? object.detections.map((e: any) => ImageMergeCandidateDetection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ImageMergeCandidate): unknown {
    const obj: any = {};
    if (message.cameraDisplayName !== "") {
      obj.cameraDisplayName = message.cameraDisplayName;
    }
    if (message.imageUrl !== "") {
      obj.imageUrl = message.imageUrl;
    }
    if (message.detections?.length) {
      obj.detections = message.detections.map((e) => ImageMergeCandidateDetection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageMergeCandidate>, I>>(base?: I): ImageMergeCandidate {
    return ImageMergeCandidate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImageMergeCandidate>, I>>(object: I): ImageMergeCandidate {
    const message = createBaseImageMergeCandidate();
    message.cameraDisplayName = object.cameraDisplayName ?? "";
    message.imageUrl = object.imageUrl ?? "";
    message.detections = object.detections?.map((e) => ImageMergeCandidateDetection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSatelliteMergeCandidate(): SatelliteMergeCandidate {
  return { satelliteDisplayName: "", confidence: 0, fireRadiativePower: 0 };
}

export const SatelliteMergeCandidate = {
  encode(message: SatelliteMergeCandidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.satelliteDisplayName !== "") {
      writer.uint32(10).string(message.satelliteDisplayName);
    }
    if (message.confidence !== 0) {
      writer.uint32(16).int32(message.confidence);
    }
    if (message.fireRadiativePower !== 0) {
      writer.uint32(25).double(message.fireRadiativePower);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SatelliteMergeCandidate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSatelliteMergeCandidate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.satelliteDisplayName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.confidence = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.fireRadiativePower = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SatelliteMergeCandidate {
    return {
      satelliteDisplayName: isSet(object.satelliteDisplayName) ? globalThis.String(object.satelliteDisplayName) : "",
      confidence: isSet(object.confidence) ? confidenceLevelFromJSON(object.confidence) : 0,
      fireRadiativePower: isSet(object.fireRadiativePower) ? globalThis.Number(object.fireRadiativePower) : 0,
    };
  },

  toJSON(message: SatelliteMergeCandidate): unknown {
    const obj: any = {};
    if (message.satelliteDisplayName !== "") {
      obj.satelliteDisplayName = message.satelliteDisplayName;
    }
    if (message.confidence !== 0) {
      obj.confidence = confidenceLevelToJSON(message.confidence);
    }
    if (message.fireRadiativePower !== 0) {
      obj.fireRadiativePower = message.fireRadiativePower;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SatelliteMergeCandidate>, I>>(base?: I): SatelliteMergeCandidate {
    return SatelliteMergeCandidate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SatelliteMergeCandidate>, I>>(object: I): SatelliteMergeCandidate {
    const message = createBaseSatelliteMergeCandidate();
    message.satelliteDisplayName = object.satelliteDisplayName ?? "";
    message.confidence = object.confidence ?? 0;
    message.fireRadiativePower = object.fireRadiativePower ?? 0;
    return message;
  },
};

function createBaseMergeCandidate(): MergeCandidate {
  return {
    id: 0,
    state: 0,
    latitude: 0,
    longitude: 0,
    firstDetectionTime: undefined,
    latestDetectionTime: undefined,
    areaGeojson: "",
    data: undefined,
  };
}

export const MergeCandidate = {
  encode(message: MergeCandidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.latitude !== 0) {
      writer.uint32(25).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(33).double(message.longitude);
    }
    if (message.firstDetectionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.firstDetectionTime), writer.uint32(42).fork()).ldelim();
    }
    if (message.latestDetectionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.latestDetectionTime), writer.uint32(50).fork()).ldelim();
    }
    if (message.areaGeojson !== "") {
      writer.uint32(58).string(message.areaGeojson);
    }
    switch (message.data?.$case) {
      case "image":
        ImageMergeCandidate.encode(message.data.image, writer.uint32(66).fork()).ldelim();
        break;
      case "satellite":
        SatelliteMergeCandidate.encode(message.data.satellite, writer.uint32(74).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MergeCandidate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMergeCandidate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstDetectionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.latestDetectionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.areaGeojson = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.data = { $case: "image", image: ImageMergeCandidate.decode(reader, reader.uint32()) };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.data = { $case: "satellite", satellite: SatelliteMergeCandidate.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MergeCandidate {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      state: isSet(object.state) ? confirmationStateFromJSON(object.state) : 0,
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      firstDetectionTime: isSet(object.firstDetectionTime) ? fromJsonTimestamp(object.firstDetectionTime) : undefined,
      latestDetectionTime: isSet(object.latestDetectionTime)
        ? fromJsonTimestamp(object.latestDetectionTime)
        : undefined,
      areaGeojson: isSet(object.areaGeojson) ? globalThis.String(object.areaGeojson) : "",
      data: isSet(object.image)
        ? { $case: "image", image: ImageMergeCandidate.fromJSON(object.image) }
        : isSet(object.satellite)
        ? { $case: "satellite", satellite: SatelliteMergeCandidate.fromJSON(object.satellite) }
        : undefined,
    };
  },

  toJSON(message: MergeCandidate): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.state !== 0) {
      obj.state = confirmationStateToJSON(message.state);
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.firstDetectionTime !== undefined) {
      obj.firstDetectionTime = message.firstDetectionTime.toISOString();
    }
    if (message.latestDetectionTime !== undefined) {
      obj.latestDetectionTime = message.latestDetectionTime.toISOString();
    }
    if (message.areaGeojson !== "") {
      obj.areaGeojson = message.areaGeojson;
    }
    if (message.data?.$case === "image") {
      obj.image = ImageMergeCandidate.toJSON(message.data.image);
    }
    if (message.data?.$case === "satellite") {
      obj.satellite = SatelliteMergeCandidate.toJSON(message.data.satellite);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MergeCandidate>, I>>(base?: I): MergeCandidate {
    return MergeCandidate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MergeCandidate>, I>>(object: I): MergeCandidate {
    const message = createBaseMergeCandidate();
    message.id = object.id ?? 0;
    message.state = object.state ?? 0;
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.firstDetectionTime = object.firstDetectionTime ?? undefined;
    message.latestDetectionTime = object.latestDetectionTime ?? undefined;
    message.areaGeojson = object.areaGeojson ?? "";
    if (object.data?.$case === "image" && object.data?.image !== undefined && object.data?.image !== null) {
      message.data = { $case: "image", image: ImageMergeCandidate.fromPartial(object.data.image) };
    }
    if (object.data?.$case === "satellite" && object.data?.satellite !== undefined && object.data?.satellite !== null) {
      message.data = { $case: "satellite", satellite: SatelliteMergeCandidate.fromPartial(object.data.satellite) };
    }
    return message;
  },
};

function createBaseListMergeCandidatesRes(): ListMergeCandidatesRes {
  return { source: undefined, candidates: [] };
}

export const ListMergeCandidatesRes = {
  encode(message: ListMergeCandidatesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.source !== undefined) {
      MergeCandidate.encode(message.source, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.candidates) {
      MergeCandidate.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMergeCandidatesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMergeCandidatesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.source = MergeCandidate.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidates.push(MergeCandidate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMergeCandidatesRes {
    return {
      source: isSet(object.source) ? MergeCandidate.fromJSON(object.source) : undefined,
      candidates: globalThis.Array.isArray(object?.candidates)
        ? object.candidates.map((e: any) => MergeCandidate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListMergeCandidatesRes): unknown {
    const obj: any = {};
    if (message.source !== undefined) {
      obj.source = MergeCandidate.toJSON(message.source);
    }
    if (message.candidates?.length) {
      obj.candidates = message.candidates.map((e) => MergeCandidate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMergeCandidatesRes>, I>>(base?: I): ListMergeCandidatesRes {
    return ListMergeCandidatesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMergeCandidatesRes>, I>>(object: I): ListMergeCandidatesRes {
    const message = createBaseListMergeCandidatesRes();
    message.source = (object.source !== undefined && object.source !== null)
      ? MergeCandidate.fromPartial(object.source)
      : undefined;
    message.candidates = object.candidates?.map((e) => MergeCandidate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMergeTimelineItem(): MergeTimelineItem {
  return { time: undefined, previousReportId: 0, detectionIds: [], reason: 0, sourceDisplayNames: [] };
}

export const MergeTimelineItem = {
  encode(message: MergeTimelineItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== undefined) {
      Timestamp.encode(toTimestamp(message.time), writer.uint32(10).fork()).ldelim();
    }
    if (message.previousReportId !== 0) {
      writer.uint32(16).int64(message.previousReportId);
    }
    for (const v of message.detectionIds) {
      DetectionId.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.reason !== 0) {
      writer.uint32(32).int32(message.reason);
    }
    for (const v of message.sourceDisplayNames) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MergeTimelineItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMergeTimelineItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.previousReportId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.detectionIds.push(DetectionId.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reason = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sourceDisplayNames.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MergeTimelineItem {
    return {
      time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
      previousReportId: isSet(object.previousReportId) ? globalThis.Number(object.previousReportId) : 0,
      detectionIds: globalThis.Array.isArray(object?.detectionIds)
        ? object.detectionIds.map((e: any) => DetectionId.fromJSON(e))
        : [],
      reason: isSet(object.reason) ? mergeTimelineItem_MergeReasonFromJSON(object.reason) : 0,
      sourceDisplayNames: globalThis.Array.isArray(object?.sourceDisplayNames)
        ? object.sourceDisplayNames.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: MergeTimelineItem): unknown {
    const obj: any = {};
    if (message.time !== undefined) {
      obj.time = message.time.toISOString();
    }
    if (message.previousReportId !== 0) {
      obj.previousReportId = Math.round(message.previousReportId);
    }
    if (message.detectionIds?.length) {
      obj.detectionIds = message.detectionIds.map((e) => DetectionId.toJSON(e));
    }
    if (message.reason !== 0) {
      obj.reason = mergeTimelineItem_MergeReasonToJSON(message.reason);
    }
    if (message.sourceDisplayNames?.length) {
      obj.sourceDisplayNames = message.sourceDisplayNames;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MergeTimelineItem>, I>>(base?: I): MergeTimelineItem {
    return MergeTimelineItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MergeTimelineItem>, I>>(object: I): MergeTimelineItem {
    const message = createBaseMergeTimelineItem();
    message.time = object.time ?? undefined;
    message.previousReportId = object.previousReportId ?? 0;
    message.detectionIds = object.detectionIds?.map((e) => DetectionId.fromPartial(e)) || [];
    message.reason = object.reason ?? 0;
    message.sourceDisplayNames = object.sourceDisplayNames?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetReportMergeTimelineRes(): GetReportMergeTimelineRes {
  return { items: [] };
}

export const GetReportMergeTimelineRes = {
  encode(message: GetReportMergeTimelineRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.items) {
      MergeTimelineItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReportMergeTimelineRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReportMergeTimelineRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(MergeTimelineItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReportMergeTimelineRes {
    return {
      items: globalThis.Array.isArray(object?.items) ? object.items.map((e: any) => MergeTimelineItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetReportMergeTimelineRes): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => MergeTimelineItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetReportMergeTimelineRes>, I>>(base?: I): GetReportMergeTimelineRes {
    return GetReportMergeTimelineRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReportMergeTimelineRes>, I>>(object: I): GetReportMergeTimelineRes {
    const message = createBaseGetReportMergeTimelineRes();
    message.items = object.items?.map((e) => MergeTimelineItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUndoMergeReq(): UndoMergeReq {
  return { reportId: 0, detectionIds: [], resynthesizeFollowing: false };
}

export const UndoMergeReq = {
  encode(message: UndoMergeReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reportId !== 0) {
      writer.uint32(8).int64(message.reportId);
    }
    for (const v of message.detectionIds) {
      DetectionId.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.resynthesizeFollowing === true) {
      writer.uint32(24).bool(message.resynthesizeFollowing);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UndoMergeReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUndoMergeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.reportId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.detectionIds.push(DetectionId.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.resynthesizeFollowing = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UndoMergeReq {
    return {
      reportId: isSet(object.reportId) ? globalThis.Number(object.reportId) : 0,
      detectionIds: globalThis.Array.isArray(object?.detectionIds)
        ? object.detectionIds.map((e: any) => DetectionId.fromJSON(e))
        : [],
      resynthesizeFollowing: isSet(object.resynthesizeFollowing)
        ? globalThis.Boolean(object.resynthesizeFollowing)
        : false,
    };
  },

  toJSON(message: UndoMergeReq): unknown {
    const obj: any = {};
    if (message.reportId !== 0) {
      obj.reportId = Math.round(message.reportId);
    }
    if (message.detectionIds?.length) {
      obj.detectionIds = message.detectionIds.map((e) => DetectionId.toJSON(e));
    }
    if (message.resynthesizeFollowing === true) {
      obj.resynthesizeFollowing = message.resynthesizeFollowing;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UndoMergeReq>, I>>(base?: I): UndoMergeReq {
    return UndoMergeReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UndoMergeReq>, I>>(object: I): UndoMergeReq {
    const message = createBaseUndoMergeReq();
    message.reportId = object.reportId ?? 0;
    message.detectionIds = object.detectionIds?.map((e) => DetectionId.fromPartial(e)) || [];
    message.resynthesizeFollowing = object.resynthesizeFollowing ?? false;
    return message;
  },
};

function createBaseMoveToReportReq(): MoveToReportReq {
  return { origin: undefined, targetReportId: 0 };
}

export const MoveToReportReq = {
  encode(message: MoveToReportReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.origin?.$case) {
      case "reportId":
        writer.uint32(8).int64(message.origin.reportId);
        break;
      case "detectionId":
        writer.uint32(16).int64(message.origin.detectionId);
        break;
      case "satelliteDetectionId":
        writer.uint32(24).int64(message.origin.satelliteDetectionId);
        break;
    }
    if (message.targetReportId !== 0) {
      writer.uint32(32).int64(message.targetReportId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MoveToReportReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMoveToReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.origin = { $case: "reportId", reportId: longToNumber(reader.int64() as Long) };
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.origin = { $case: "detectionId", detectionId: longToNumber(reader.int64() as Long) };
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.origin = {
            $case: "satelliteDetectionId",
            satelliteDetectionId: longToNumber(reader.int64() as Long),
          };
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.targetReportId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MoveToReportReq {
    return {
      origin: isSet(object.reportId)
        ? { $case: "reportId", reportId: globalThis.Number(object.reportId) }
        : isSet(object.detectionId)
        ? { $case: "detectionId", detectionId: globalThis.Number(object.detectionId) }
        : isSet(object.satelliteDetectionId)
        ? { $case: "satelliteDetectionId", satelliteDetectionId: globalThis.Number(object.satelliteDetectionId) }
        : undefined,
      targetReportId: isSet(object.targetReportId) ? globalThis.Number(object.targetReportId) : 0,
    };
  },

  toJSON(message: MoveToReportReq): unknown {
    const obj: any = {};
    if (message.origin?.$case === "reportId") {
      obj.reportId = Math.round(message.origin.reportId);
    }
    if (message.origin?.$case === "detectionId") {
      obj.detectionId = Math.round(message.origin.detectionId);
    }
    if (message.origin?.$case === "satelliteDetectionId") {
      obj.satelliteDetectionId = Math.round(message.origin.satelliteDetectionId);
    }
    if (message.targetReportId !== 0) {
      obj.targetReportId = Math.round(message.targetReportId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MoveToReportReq>, I>>(base?: I): MoveToReportReq {
    return MoveToReportReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MoveToReportReq>, I>>(object: I): MoveToReportReq {
    const message = createBaseMoveToReportReq();
    if (
      object.origin?.$case === "reportId" && object.origin?.reportId !== undefined && object.origin?.reportId !== null
    ) {
      message.origin = { $case: "reportId", reportId: object.origin.reportId };
    }
    if (
      object.origin?.$case === "detectionId" &&
      object.origin?.detectionId !== undefined &&
      object.origin?.detectionId !== null
    ) {
      message.origin = { $case: "detectionId", detectionId: object.origin.detectionId };
    }
    if (
      object.origin?.$case === "satelliteDetectionId" &&
      object.origin?.satelliteDetectionId !== undefined &&
      object.origin?.satelliteDetectionId !== null
    ) {
      message.origin = { $case: "satelliteDetectionId", satelliteDetectionId: object.origin.satelliteDetectionId };
    }
    message.targetReportId = object.targetReportId ?? 0;
    return message;
  },
};

function createBaseSplitReportReq(): SplitReportReq {
  return { detectionIds: [], satelliteDetectionIds: [] };
}

export const SplitReportReq = {
  encode(message: SplitReportReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.detectionIds) {
      writer.int64(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.satelliteDetectionIds) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SplitReportReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSplitReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.detectionIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.detectionIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.satelliteDetectionIds.push(longToNumber(reader.int64() as Long));

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.satelliteDetectionIds.push(longToNumber(reader.int64() as Long));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SplitReportReq {
    return {
      detectionIds: globalThis.Array.isArray(object?.detectionIds)
        ? object.detectionIds.map((e: any) => globalThis.Number(e))
        : [],
      satelliteDetectionIds: globalThis.Array.isArray(object?.satelliteDetectionIds)
        ? object.satelliteDetectionIds.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: SplitReportReq): unknown {
    const obj: any = {};
    if (message.detectionIds?.length) {
      obj.detectionIds = message.detectionIds.map((e) => Math.round(e));
    }
    if (message.satelliteDetectionIds?.length) {
      obj.satelliteDetectionIds = message.satelliteDetectionIds.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SplitReportReq>, I>>(base?: I): SplitReportReq {
    return SplitReportReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SplitReportReq>, I>>(object: I): SplitReportReq {
    const message = createBaseSplitReportReq();
    message.detectionIds = object.detectionIds?.map((e) => e) || [];
    message.satelliteDetectionIds = object.satelliteDetectionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSplitReportRes(): SplitReportRes {
  return { newReportId: 0 };
}

export const SplitReportRes = {
  encode(message: SplitReportRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newReportId !== 0) {
      writer.uint32(8).int64(message.newReportId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SplitReportRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSplitReportRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.newReportId = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SplitReportRes {
    return { newReportId: isSet(object.newReportId) ? globalThis.Number(object.newReportId) : 0 };
  },

  toJSON(message: SplitReportRes): unknown {
    const obj: any = {};
    if (message.newReportId !== 0) {
      obj.newReportId = Math.round(message.newReportId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SplitReportRes>, I>>(base?: I): SplitReportRes {
    return SplitReportRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SplitReportRes>, I>>(object: I): SplitReportRes {
    const message = createBaseSplitReportRes();
    message.newReportId = object.newReportId ?? 0;
    return message;
  },
};

function createBaseReviewReportReq(): ReviewReportReq {
  return { reportId: 0, newState: 0, reason: 0 };
}

export const ReviewReportReq = {
  encode(message: ReviewReportReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reportId !== 0) {
      writer.uint32(8).int64(message.reportId);
    }
    if (message.newState !== 0) {
      writer.uint32(16).int32(message.newState);
    }
    if (message.reason !== 0) {
      writer.uint32(24).int32(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReviewReportReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReviewReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.reportId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.newState = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.reason = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReviewReportReq {
    return {
      reportId: isSet(object.reportId) ? globalThis.Number(object.reportId) : 0,
      newState: isSet(object.newState) ? confirmationStateFromJSON(object.newState) : 0,
      reason: isSet(object.reason) ? reviewReasonFromJSON(object.reason) : 0,
    };
  },

  toJSON(message: ReviewReportReq): unknown {
    const obj: any = {};
    if (message.reportId !== 0) {
      obj.reportId = Math.round(message.reportId);
    }
    if (message.newState !== 0) {
      obj.newState = confirmationStateToJSON(message.newState);
    }
    if (message.reason !== 0) {
      obj.reason = reviewReasonToJSON(message.reason);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewReportReq>, I>>(base?: I): ReviewReportReq {
    return ReviewReportReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReviewReportReq>, I>>(object: I): ReviewReportReq {
    const message = createBaseReviewReportReq();
    message.reportId = object.reportId ?? 0;
    message.newState = object.newState ?? 0;
    message.reason = object.reason ?? 0;
    return message;
  },
};

export interface Reports {
  /** Returns detail about a report */
  getReport(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<Report>;
  /** Returns detail about a report */
  getReviewedReport(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<Report>;
  /**
   * Return a GeoJSON Feature Collection of Multipolygons of report areas
   * The features should must have an id (the report id) and the property firstDetectionTime
   */
  getReportAreasGeojson(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<HttpBody>;
  /**
   * Return a GeoJSON Feature Collection of Multipolygons of reviewed report areas
   * The features must have an id (the report id) and the property firstDetectionTime
   */
  getReviewedReportAreasGeojson(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<HttpBody>;
  /** Return a list of ReportSummaries */
  listReports(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<ListReportsRes>;
  /** Return a list of reviewed ReportSummaries */
  listReviewedReports(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<ListReportsRes>;
  /** Return a list of Reports which could be merge candidates for a report */
  listMergeCandidates(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<ListMergeCandidatesRes>;
  /** Return a timeline of a report's merges */
  getReportMergeTimeline(
    request: DeepPartial<GetReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetReportMergeTimelineRes>;
  undoMerge(request: DeepPartial<UndoMergeReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Move a single detection or all detections in a report into another report */
  moveToReport(request: DeepPartial<MoveToReportReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Split some detections into a new report */
  splitReport(request: DeepPartial<SplitReportReq>, metadata?: grpc.Metadata): Promise<SplitReportRes>;
  /** Review a report, updating it's status */
  reviewReport(request: DeepPartial<ReviewReportReq>, metadata?: grpc.Metadata): Promise<Empty>;
  /** Returns an html document containing javascript that calls ReviewReport upon loading */
  triggerReviewReport(request: DeepPartial<ReviewReportReq>, metadata?: grpc.Metadata): Promise<HttpBody>;
}

export class ReportsClientImpl implements Reports {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.getReport = this.getReport.bind(this);
    this.getReviewedReport = this.getReviewedReport.bind(this);
    this.getReportAreasGeojson = this.getReportAreasGeojson.bind(this);
    this.getReviewedReportAreasGeojson = this.getReviewedReportAreasGeojson.bind(this);
    this.listReports = this.listReports.bind(this);
    this.listReviewedReports = this.listReviewedReports.bind(this);
    this.listMergeCandidates = this.listMergeCandidates.bind(this);
    this.getReportMergeTimeline = this.getReportMergeTimeline.bind(this);
    this.undoMerge = this.undoMerge.bind(this);
    this.moveToReport = this.moveToReport.bind(this);
    this.splitReport = this.splitReport.bind(this);
    this.reviewReport = this.reviewReport.bind(this);
    this.triggerReviewReport = this.triggerReviewReport.bind(this);
  }

  getReport(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<Report> {
    return this.rpc.unary(ReportsGetReportDesc, GetReportReq.fromPartial(request), metadata);
  }

  getReviewedReport(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<Report> {
    return this.rpc.unary(ReportsGetReviewedReportDesc, GetReportReq.fromPartial(request), metadata);
  }

  getReportAreasGeojson(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<HttpBody> {
    return this.rpc.unary(ReportsGetReportAreasGeojsonDesc, ListReportFilters.fromPartial(request), metadata);
  }

  getReviewedReportAreasGeojson(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<HttpBody> {
    return this.rpc.unary(ReportsGetReviewedReportAreasGeojsonDesc, ListReportFilters.fromPartial(request), metadata);
  }

  listReports(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<ListReportsRes> {
    return this.rpc.unary(ReportsListReportsDesc, ListReportFilters.fromPartial(request), metadata);
  }

  listReviewedReports(request: DeepPartial<ListReportFilters>, metadata?: grpc.Metadata): Promise<ListReportsRes> {
    return this.rpc.unary(ReportsListReviewedReportsDesc, ListReportFilters.fromPartial(request), metadata);
  }

  listMergeCandidates(request: DeepPartial<GetReportReq>, metadata?: grpc.Metadata): Promise<ListMergeCandidatesRes> {
    return this.rpc.unary(ReportsListMergeCandidatesDesc, GetReportReq.fromPartial(request), metadata);
  }

  getReportMergeTimeline(
    request: DeepPartial<GetReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetReportMergeTimelineRes> {
    return this.rpc.unary(ReportsGetReportMergeTimelineDesc, GetReportReq.fromPartial(request), metadata);
  }

  undoMerge(request: DeepPartial<UndoMergeReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ReportsUndoMergeDesc, UndoMergeReq.fromPartial(request), metadata);
  }

  moveToReport(request: DeepPartial<MoveToReportReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ReportsMoveToReportDesc, MoveToReportReq.fromPartial(request), metadata);
  }

  splitReport(request: DeepPartial<SplitReportReq>, metadata?: grpc.Metadata): Promise<SplitReportRes> {
    return this.rpc.unary(ReportsSplitReportDesc, SplitReportReq.fromPartial(request), metadata);
  }

  reviewReport(request: DeepPartial<ReviewReportReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ReportsReviewReportDesc, ReviewReportReq.fromPartial(request), metadata);
  }

  triggerReviewReport(request: DeepPartial<ReviewReportReq>, metadata?: grpc.Metadata): Promise<HttpBody> {
    return this.rpc.unary(ReportsTriggerReviewReportDesc, ReviewReportReq.fromPartial(request), metadata);
  }
}

export const ReportsDesc = { serviceName: "phoenix.reports.v0.Reports" };

export const ReportsGetReportDesc: UnaryMethodDefinitionish = {
  methodName: "GetReport",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Report.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsGetReviewedReportDesc: UnaryMethodDefinitionish = {
  methodName: "GetReviewedReport",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Report.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsGetReportAreasGeojsonDesc: UnaryMethodDefinitionish = {
  methodName: "GetReportAreasGeojson",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListReportFilters.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsGetReviewedReportAreasGeojsonDesc: UnaryMethodDefinitionish = {
  methodName: "GetReviewedReportAreasGeojson",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListReportFilters.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsListReportsDesc: UnaryMethodDefinitionish = {
  methodName: "ListReports",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListReportFilters.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListReportsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsListReviewedReportsDesc: UnaryMethodDefinitionish = {
  methodName: "ListReviewedReports",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListReportFilters.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListReportsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsListMergeCandidatesDesc: UnaryMethodDefinitionish = {
  methodName: "ListMergeCandidates",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMergeCandidatesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsGetReportMergeTimelineDesc: UnaryMethodDefinitionish = {
  methodName: "GetReportMergeTimeline",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetReportMergeTimelineRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsUndoMergeDesc: UnaryMethodDefinitionish = {
  methodName: "UndoMerge",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UndoMergeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsMoveToReportDesc: UnaryMethodDefinitionish = {
  methodName: "MoveToReport",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MoveToReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsSplitReportDesc: UnaryMethodDefinitionish = {
  methodName: "SplitReport",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SplitReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SplitReportRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsReviewReportDesc: UnaryMethodDefinitionish = {
  methodName: "ReviewReport",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ReviewReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ReportsTriggerReviewReportDesc: UnaryMethodDefinitionish = {
  methodName: "TriggerReviewReport",
  service: ReportsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ReviewReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HttpBody.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
